import React, { Component } from 'react';


import {Container,Typography,Grid,Box} from '@material-ui/core'


import ScrollAnimation from 'react-animate-on-scroll'

class AboutUsPage extends Component {
    render() {
        return (
      <Container maxWidth="lg">
            <div style={{ display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:50}}>

<ScrollAnimation animateIn='fadeIn'
  animateOut='fadeOut'>
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
           Who we are ?
            </Typography>
            <Container maxWidth="sm">
            <Typography variant="button" align="center" color="textSecondary" paragraph>
            SUNSHUBHLOANS is a new-age financial services platform that helps people to get quick and easy money for their immediate needs.
            </Typography>
            </Container>
          </ScrollAnimation>
          <Box m={4}></Box>     
        <Grid container spacing={2}>

            <Grid item xs={6} lg={6}>
            <Box m={4}></Box>    
                 <ScrollAnimation animateIn='bounce'
                        initiallyVisible={true}
                        animateOnce={false}>  
                                    <Typography variant="h4" color="initial" align="center">VISION</Typography>
                            
                        </ScrollAnimation>
            <Box m={4}></Box>     

<Typography variant="body1" color="initial" style={{textAlign:"justify"}}>Vision to create a trustworthy, transparent and highest integrity financial institution that positively advances the socio-economic well-being of lower middle class to middle class Indian households while protecting the interests of all stakeholders
</Typography>

            </Grid>










            <Grid item xs={6} lg={6}>
            <Box m={4}></Box>      
            <ScrollAnimation animateIn='bounce'
                        initiallyVisible={true}
                        animateOnce={false}>  
            <Typography variant="h4" color="initial" align="center">MISSION</Typography>
            </ScrollAnimation>
            <Box m={4}></Box>     

<Typography variant="body1" color="initial" style={{textAlign:"justify"}}>
Mission to be relentless in inculcating and nurturing a culture of continuous innovation and execution excellence by combining cutting edge technology, data science and deep financial domain expertise and delivering the best suited profitable products in the most dignified experiences for every customer 


</Typography>



                
            </Grid>


        </Grid>





            </div>
            </Container>
        );
    }
}

export default AboutUsPage;