import React, { Component } from 'react';

import  {Grid,Typography,Container,Box,ButtonBase, Card,Paper} from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import MoneyIcon from '@material-ui/icons/Money';



import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PaymentIcon from '@material-ui/icons/Payment';  //advance salary
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'; //medical
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';  //CASH
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';  //bank emi
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Category'; //working capital





import PersonalLoanForm from './PersonalLoanForm';
class PersonalLoan extends Component {
   




    render() {
        return (

        <Container maxWidth="xl">
          
            <Box m={4}/>
            <Typography variant="h2" color="initial" align="center">Personal Loan</Typography>
            <Box m={8}/>
        <Grid container spacing={2}>
                
                {/**left bar */}
            <Grid item xl={6} lg ={6} md={6} sm={12}>
            <Typography variant="h4" color="primary" align="center" gutterBottom>One Application  Multiple Lenders.</Typography>                      
                <Typography variant="subtitle1" color="initial" align="center" gutterBottom>Get upto 5 Lakhs , We Send Your Application to Multiple Lender <br/> so  you can get best Lender for your Application.</Typography>
                <Box m={8}/>
             <Typography variant="h4" color="initial" align="center">Why Us ?</Typography>
   

             <Box m={8}/>
                <Grid container spacing={2} justify="center" style={{textAlign:"center"}}>
                    
                    
                <Grid item lg={4} md={4} xs={6} sm={3} justify="center">

                                    
                                       <ButtonBase >
                                                  <TimerIcon  color="primary" style={{ fontSize: 60 , marginBottom:20 }}/>
                                            </ButtonBase>
                                       
                                       <br/>
                                                <Typography gutterBottom variant="subtitle2">
                                               Within Minutes
                                                </Typography>
                                               
                                              
                                         
                                         
                </Grid>

                    
                <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                
                <ButtonBase >
                                                  <SupervisedUserCircleIcon  color="primary" style={{ fontSize: 60 , marginBottom:20 }}/>
                                            </ButtonBase>
                                       
                                       <br/>
                                                <Typography gutterBottom variant="subtitle2">
                                               Multiple Lenders
                                                </Typography>              
                                         
                </Grid>


                    
                <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                
                <ButtonBase >
                                                  <MoneyIcon  color="primary" style={{ fontSize: 60 , marginBottom:20 }}/>
                                            </ButtonBase>
                                       
                                       <br/>
                                                <Typography gutterBottom variant="subtitle2">
                                            Upto 5 Lacs
                                                </Typography>  
                                         
                </Grid>



                    
                    
                    
                    
                    </Grid>       

                    <Box m={8}/>
             <Typography variant="h4" color="initial" align="center">What Our Loan Covers?</Typography>
             <Box m={8}/>

             <Grid
                  container
                  spacing={2}
                  justify="center"
                  style={{ textAlign: "center" }}
                >
                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <FlightTakeoffIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Travel Support
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <PaymentIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Advance Salary
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <CardGiftcardIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Providing Gift
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <LocalHospitalIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Medical Emergency
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <AttachMoneyIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Cash Crunch
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <HomeIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Home Renovation
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <AccountBalanceIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Debt Consolidation
                      </Typography>
                    </Paper>
                  </Grid>




                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <CategoryIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                        Working Capital
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>




            </Grid>


            {/**left bar */}

            <Grid item xl={6} md={6} lg={6} sm={12}>
          
                <Card variant="outlined">
    {/*Business Loan form*/}
    <PersonalLoanForm/>

                </Card>
             
            </Grid>


        </Grid>

        </Container>


        );
    }
}

export default PersonalLoan;