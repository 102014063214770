import React, { Component } from 'react';


import {Container,FormControl,InputLabel,Select,MenuItem, TextField, Button} from '@material-ui/core'

import UserContext from '../ContextManage/UserContext'


class SearchUI extends Component {
    static contextType =UserContext;
  
  constructor(props){
  
    super(props);
   
    this.state={
       
              ID:null,
            Email:null,
            Role:null,
            Token:null,
            isloggedin:false,
            SK:'ID',
            SQUERY:''
    }
}



async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

   }



   async SetQuery(event){
       await this.setState({SQUERY:event.target.value})
    
   }

  async SetKeyword(event){
   
    await this.setState({SK:event.target.value})
  
}



Search(){
   let QUERY=this.state.SQUERY


   if(QUERY.length>=3){
      
    console.log("perform query")



   }else{

    alert("At least Enter 3 Charcter or Value")

   }


}







    render() {
        return (
            <Container maxWidth="lg">
                  <FormControl style={{width:200}}  variant="filled">
        <InputLabel>Select Keyword</InputLabel>
        <Select
          labelId="keyword-selector"
          id="keyword selector"
        value={this.state.SK}
        onChange={(event)=>this.SetKeyword(event)}
        >
         
       
                <MenuItem  value='ID'>ID</MenuItem>
                <MenuItem value='Full_Name'>Full_Name</MenuItem>
                <MenuItem value='Email'>Email</MenuItem>
                <MenuItem value='PhoneNo'>PhoneNo</MenuItem>
              
         
        </Select>
      </FormControl>

        

      <TextField
        id="search-user"
        label=""
        variant="outlined"
        onChange={(event) => this.SetQuery(event)}

      />

      <Button onClick={()=>this.Search()} variant="contained" color="default" size="large">
        Search
      </Button>

            </Container>
        );
    }
}

export default SearchUI;