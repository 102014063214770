import React, { Component } from "react";
import {
  Card,
  TableHead,
  Table,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  TextField,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button
} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import Stack from "@mui/material/Stack";
import { Alert, AlertTitle } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/DeleteOutline";

import * as ApiFunctions from "../../util/apifunction";
import UserContext from "../ContextManage/UserContext";

class LoanEmis extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      Email: null,
      Role: null,
      Token: null,
      isloggedin: false,
      Emis:this.props.emis,
      FullName:null
    };
  }

  async componentDidMount() {
    const UserContextData = this.context;
    await this.setState({
      ID: UserContextData.state.ID,
      Email: UserContextData.state.Email,
      isloggedin: UserContextData.state.loggedin,
      Token: UserContextData.state.Token,
      Role: UserContextData.state.Role,
      FullName:UserContextData.state.FullName
    });




  }

  async deleteemi(lid) {
   

    if (window.confirm("Are You Sure ?") == true) {
      const header = {
        "Content-Type": "application/json",
        "x-access-token": this.state.Token,
      };
  
      try {
        const Data = await ApiFunctions.DeleteApiData(`/emi/${lid}`, header);
  
        if (Data.Status === 200) {
          alert("Emi Deleted Successfully!");
          window.location.href = window.location;
        }
        if (Data.Status !== 200) {
          alert("Can't Delete Emi Right Now!");
        }
      } catch (err) {
        console.log(err);
      }
  
    }


  }



  SetDelayAmount(event,id){

    const DelayAmount=event.target.value;
    const data=[...this.state.Emis]
    data.map(item => {
          if(item.ID === id) {
            item.Delay_Amount = DelayAmount
             console.log("ID",item.Delay_Amount)
          }
          return item
        })
        
        this.setState({Emis:data})
    
  }

  SetPaidStatus(event,id){

    const PaidStatus=event.target.value;
    const data=[...this.state.Emis]
    data.map(item => {
          if(item.ID === id) {
            item.Paid = PaidStatus
             console.log("ID",item.Paid)
          }
          return item
        })
        
        this.setState({Emis:data})
    
  }


  async updatemi(Eid,delayamount,Status) {


    console.log(Eid,delayamount,Status)

    
    const header = {
      "Content-Type": "application/json",
      "x-access-token": this.state.Token,
    };

    const data={
      ID:Eid,
      Delay_Amount:delayamount,
      Paid:Status
    }

    try {
      const Data = await ApiFunctions.UpdateApiData(`/emi`, header,data);

      if (Data.Status === 200) {
        alert("Emi Updated Successfully!");
      
      }
      if (Data.Status !== 200) {
        alert("Can't Delete Emi Right Now!");
      }
    } catch (err) {
      console.log(err);
    } 



  }

  SendEmiEmails= async(OutStandingAmount,Delay_Amount,Paid,EmailType)=>{
    const data={
      "OutStandingAmount":OutStandingAmount,
      "Delay_Amount":Delay_Amount,
      "Paid":Paid,
      "EmailType":EmailType,
      "FullName":this.state.FullName,
      "Email":this.state.Email
      }

      
    const header = {
      "Content-Type": "application/json",
      "x-access-token": this.state.Token,
    };

   
    try {
      const Data = await ApiFunctions.PostApiData(`/emi/email`, header,data);

      if (Data.Status === 200) {
        alert("Email Sent");
      
      }
      if (Data.Status !== 200) {
        alert("Can't Send Email Right Now!");
      }
    } catch (err) {
      console.log(err);
    } 










  }


  render() {
    return (
      <React.Fragment>
        <Card style={{ marginBottom: 10 }}>
          {this.state.Emis <= 0 || null || undefined ? (
            <Alert severity="info">
              <AlertTitle>Emis</AlertTitle>
              No Emis Found For This Loan —{" "}
              <strong>To Add Emi Click on "Add New Emi " Button.</strong>
            </Alert>
          ) : (
            <React.Fragment>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Emi ID</TableCell>
                      <TableCell align="left">Outstanding</TableCell>
                      <TableCell align="left">DelayAmount</TableCell>
                      <TableCell align="left">Total Amount</TableCell>

                      <TableCell align="left">EmiDate (YYYY-MM-DD)</TableCell>
                      <TableCell align="left">Paid</TableCell>
                      <TableCell align="left">Emails</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.emis.map((data, index) => (
                      <React.Fragment>
                        {data.Type === "EMI" ? (
                          <TableRow key={data.ID}>
                            <TableCell align="left">{index + 1}</TableCell>

                            <TableCell align="left">
                              <TextField
                                id="OutStanding_Amount"
                                label="Outstanding Amount"
                                variant="outlined"
                                value={data.Outstanding}
                                disabled
                             
                              />
                            </TableCell>

                            <TableCell align="left">
                              <TextField
                                id="Delay_Amount"
                                label="Delay Amount"
                                variant="outlined"
                                value={data.Delay_Amount}
                                onChange={(event)=>this.SetDelayAmount(event,data.ID)}
                              />

                              {}
                            </TableCell>
                            <TableCell align="left">{Number(data.Outstanding)+Number(data.Delay_Amount)}</TableCell>

                            <TableCell align="left">{data.EmiDate}</TableCell>
                            <TableCell align="left">
                              <FormControl
                                variant="outlined"
                                style={{ width: 100 }}
                              >
                                <InputLabel htmlFor="EmiPaidStatus">
                                   Status
                                </InputLabel>
                                <Select
                                  label="Emi Status"
                                  inputProps={{
                                    name: "EmiPaidStatus",
                                    id: "EmiPaidStatus",
                                  }}
                                  value={data.Paid}
                                  onChange={(event)=>this.SetPaidStatus(event,data.ID)}
                                >
                                  <option value="YES">YES</option>
                                  <option value="NO">NO</option>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell align="left">
                              {this.state.Role === "Admin" ? (
                                <Stack direction="row" 
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={2}>
                                  <Button onClick={()=>this.SendEmiEmails(data.Outstanding,data.Delay_Amount,data.Paid,"PAID")} disableElevation variant="contained" color="primary">
                                    Paid
                                  </Button>
                                  <Button onClick={()=>this.SendEmiEmails(data.Outstanding,data.Delay_Amount,data.Paid,"DELAY")} disableElevation variant="contained" color="default">
                                    Delay
                                  </Button>
                                  <Button disableElevation variant="outlined" color="default">
                                    Reminder
                                  </Button>
                                </Stack>
                              ) : (
                                <span></span>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {this.state.Role === "Admin" ? (
                                <Stack direction="row" spacing={2}>

                                          <Button   onClick={() => this.updatemi(data.ID,data.Delay_Amount,data.Paid)} disableElevation variant="contained" color="primary">
                                            Update
                                          </Button>

                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => this.deleteemi(data.ID)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Stack>
                              ) : (
                                <span></span>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <span></span>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

export default LoanEmis;
