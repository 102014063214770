import React, { Component } from 'react';


import {Dialog,TextField,Button,DialogTitle,DialogContent,DialogActions,Box} from '@material-ui/core'

import UserContext from '../ContextManage/UserContext'

import * as ApiFunctions from '../../util/apifunction'

class AddNotes extends Component {

    static contextType =UserContext;

    constructor(props){
        super(props);
        this.state={
                Comment:'',
                ID:null,
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                delaydialogoepn:false,
                LID:this.props.ID
        }
    
    }


    async componentDidMount(){
        const UserContextData = this.context
        await this.setState({
          ID:UserContextData.state.ID,
          Email:UserContextData.state.Email,
          isloggedin:UserContextData.state.loggedin,
          Token:UserContextData.state.Token,
          Role:UserContextData.state.Role
         })
    

    
       }
    
       opennotepanel=()=>{this.setState({delaydialogoepn:true})}
       closedialog=()=>{this.setState({delaydialogoepn:false})}


       async AddNote(){

        const header={
          'Content-Type': 'application/json',
    'x-access-token':this.state.Token
         };
    
  
         const data={
            Loan_ID:this.props.ID,
            Comment:this.state.Comment
       
         }
  
  
         try{
          const Data = await ApiFunctions.PostApiData(`/note/`,header,data)
  
        
         if( Data.Status===200){
             alert("New Note Added!")  
             window.location=window.location.href;
         }
         if( Data.Status!==200){
          alert("Cant Add Note Right Now!")      
     }
       
        }catch(err){
          this.setState({isError:true})
       }
    
   }
  

   setcomment=(event)=>{
this.setState({Comment:event.target.value})
   }


    render() {
        return (
         <React.Fragment>
        
                <Box m={2}/>
        <Button variant="contained" color="default" onClick={this.opennotepanel}>
          ADD NOTE
        </Button>
        <Box m={3}/>
<Dialog
        
        fullWidth
        maxWidth="sm"
        onClose={this.closedelaydialog}
        aria-labelledby="confirmation-dialog-title"
        open={this.state.delaydialogoepn}
    
      >
        <DialogTitle id="confirmation-dialog-title">Add Note</DialogTitle>
        <DialogContent dividers>
      
           <TextField
           id="note"
           fullWidth
           label="Note"
           multiline
           rows={4}
           value={this.state.Comment}
           onChange={(event)=>this.setcomment(event)}
           variant="filled"
         
           />
          
        </DialogContent>
        <DialogActions>
          <Button  onClick={this.closedialog}  color="primary">
            Cancel
          </Button>
          <Button  
           onClick={()=>this.AddNote()}
          color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>



             
         </React.Fragment>
        );
    }
}

export default AddNotes;