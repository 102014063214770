import React, { Component } from 'react';
import  {Container,Typography,Box} from '@material-ui/core'
class TermsandCondition extends Component {
    render() {
        return (
      <Container maxWidth="lg">
      
            <div style={{ display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop:50}}>
           <Typography variant="h3" alignCenter color="primary">Terms and Condition</Typography>
         <Box m={5}></Box>
         </div>
        <div style={{textAlign:"justify",width:'100%'}}>



        <p>1. Terms of Use</p>
<p>1.1 Thank you for visiting www.sunshubhloans.com, by accessing or using the website</p>
<p>(&ldquo;Website/ Sunshubh Loans&ldquo;), whether automated or otherwise, you, a registered or guest user</p>
<p>in terms of the eligibility criteria set out herein (&ldquo;User&ldquo;) agree to be bound by these Terms and</p>
<p>Conditions (&ldquo;Terms of Use&ldquo;) and any additional terms and conditions that are referenced below</p>
<p>or otherwise may apply to specific areas of the Website, from time to time. We encourage our</p>
<p>Users to read these Terms of Use carefully while using the Website.</p>
<p>1.2 The Website is owned and operated by Sunshubh Solutions Private Limited a company</p>
<p>incorporated under the provisions of the Companies Act, 2013 bearing corporate identity</p>
<p>number U72900MH2022PTC376589. All references to Website in these Terms of Use shall</p>
<p>deem to refer to the aforesaid entity in inclusion of the online portal.</p>
<p>1.3 The contents set out herein form an electronic record in terms of the Information Technology</p>
<p>Act, 2000 and rules there under as applicable and as amended from time to time. As such, this</p>
<p>document does not require any physical or digital signatures and forms a valid and binding</p>
<p>agreement between the Website and the User. Further, please be advised that these Terms of</p>
<p>Use are made available to the User pursuant to and in accordance with the provisions of Rule 3</p>
<p>(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing</p>
<p>the rules, regulations, privacy policy and terms of use for access or usage of the Website.</p>
<p>1.4 We reserve the right to change these Terms of Use at any time. The changes will be</p>
<p>effective from the date and time of the posting of such changes. By continuing to use the</p>
<p>Website after we post any such changes, you accept the Terms of Use as modified.</p>
<p>2. Customer Eligibility</p>
<p>2.1 Use of the Website is available only to such persons who can legally contract under the</p>
<p>Indian Contract Act, 1872. Persons who are &ldquo;incompetent to contract&rdquo; within the meaning of the</p>
<p>Indian Contract Act, 1872 including minors, un-discharged insolvents etc. shall not be eligible to</p>
<p>use the Website. If the person accessing the Website is a minor i.e. under the age of 18 years,</p>
<p>such person shall not register as a User of the Website and shall not transact on or use the</p>
<p>Website.</p>
<p>2.2 The Website reserves the right to terminate any membership and/or refuse to provide</p>
<p>access to the Website if it is brought to the Website&rsquo;s notice or if it is discovered that the person</p>
<p>accessing/using the Website is under the age of 18 years.</p>
<p>2.3 By accepting the Terms of Use, the User irrevocably declares and undertakes that he/she is</p>
<p>of legal age i.e. 18 years or older and capable of entering into a binding contract and that such</p>
<p>acceptance shall be deemed to form a contract between the Website and such User to the</p>
<p>extent permissible under applicable laws.</p>
<p>2.4 The User must have a rupee denominated savings/current account with a bank in India.</p>
<p>2.5 User must have PAN (Permanent Account Number), allotted by the Income Tax Department</p>
<p>of India.</p>
<p>2.6 By accepting the Terms of Use, you acknowledge that you have also read and understood</p>
<p>the risks involved in borrowing through the Website.</p>
<p>2.7 To be eligible as a Borrower, User must be either:</p>
<p>2.7.1 User must be a businesswoman or a salaried individual.</p>
<p>2.7.2 In case the User is a businesswoman, then the User&rsquo;s entity: (a) must be based in India</p>
<p>with an annual turnover not exceeding Rs. 25,00,000 (Twenty Five Lakhs Only); (b) should not</p>
<p>be operating in any Prohibited Sectors; and (c) User must not have an interest in any business</p>
<p>that operates in any of the Prohibited Sectors;</p>
<p>2.7.3 In case the User is a salaried individual, then the User:(a) must be based in India;(b)</p>
<p>should not be unemployed at any point of time during last 6 months;(c) should not have any kind</p>
<p>of civil or criminal litigation instituted against him/her;(d) must be receiving his/her monthly</p>
<p>salary in the bank account; and (e) must only be seeking a loan amount in between Rs. 1000</p>
<p>and Rs. 10,00,000 with a loan term of not more than 24 months.</p>
<p>2.7.4. In case the user has availed loans from other platforms, the total outstanding at the time</p>
<p>of application does not exceed Rs. 10,00,000/-</p>
<p>3. Sign up and Registration</p>
<p>3.1 As indicated, any person may access the Website either by registering on the Website or</p>
<p>using the Website as a guest. However, a guest user may not have access to all sections of the</p>
<p>Website which shall be reserved only for the purpose of registered Users, and which may</p>
<p>change from time to time at the sole discretion of the Website.</p>
<p>3.2 All eligible Users who wish/have to register themselves with the Website shall be required to</p>
<p>create an account by filling in the details prescribed in the Website.</p>
<p>3.3 By applying for registration, the User agrees and authorizes Sunshubh Loans to carry out</p>
<p>such checks and verification. As part of the registration process:</p>
<p>3.3.1 we shall run identity, KYC, and fraud checks.</p>
<p>3.3.2 obtain a credit rating for Users from the Credit Information Bureau;</p>
<p>3.3.3 carry out residence and/or work verification at your employment location;</p>
<p>3.4 If any User provides any information that is untrue, false, not updated, and incomplete or the</p>
<p>Website has reasonable grounds to believe that such information is untrue, false, not updated,</p>
<p>incomplete, the Website shall have the right to suspend or terminate the relevant User account</p>
<p>and refuse any and all current or future use of the Website (or any portion thereof).</p>
<p>3.5 Users of the Website may be required to provide certain personal information and expressly</p>
<p>permit the Website for accessing and/or collecting and retaining such personal information of</p>
<p>the Users. Such provision and/or collection, storage, retention, use and disclosure of the</p>
<p>-personal information of the Users shall be subject to the Website&rsquo;s privacy policy available at</p>
<p>https://sunshubhloans.com/app/privacy</p>
<p>3.6 Registration Fee to register as a Borrower: We will charge each Borrower a one- time</p>
<p>registration fee of INR 750 for the services that we provide to the Borrowers. For any</p>
<p>subsequent application, borrower will pay INR 199 to 750 for re-verification charges depending</p>
<p>on the product. These fees are non-refundable.</p>
<p>4. Use of Website</p>
<p>4.1 To lend through the Website, you must log on to SUNSHUBH LOANS Account.</p>
<p>4.2 All registered Users of the Website shall be solely responsible for activities relating to the</p>
<p>account and the Website or the entity operating the same shall not in any manner be held</p>
<p>responsible and/or liable for any illegal acts including but not limited to misuse, un-authorized</p>
<p>access, hacking of the Website or refusal to access the Website due to such reason.</p>
<p>4.3 All Website Users hereby confirm and acknowledge that they have been provided with copy</p>
<p>of the loan agreement (&ldquo;Loan Agreement&rdquo;) to be entered between the Borrower and the</p>
<p>Sunshubh Solutions Pvt Ltd. All Users further confirm that have read and understood all the</p>
<p>terms and conditions of the Loan Agreement and upon execution do hereby covenant and</p>
<p>undertake to unconditionally abide by the same, without raising any defense of whatsoever</p>
<p>nature in respect thereof. To view a copy of the Loan Agreement, please click on the link.</p>
<p>4.4 If any User learns or is made or becomes aware of any instance of hacking or misuse of its</p>
<p>Sunshubh Loans Account, it shall without delay notify the Website of the same.</p>
<p>4.5 We can refuse to act on any instruction received from User that we believe: (a) was unclear;</p>
<p>(b) was not given by the user; (c) might cause us to breach a legal or other duty; or (d) suggests</p>
<p>that the Website is being used for an illegal purpose.</p>
<p>4.6 The Website disclaims, and the User waives off, any and all claims/liabilities arising out of</p>
<p>any unlawful usage of the Website, the personal information of the User, inaccessibility of the</p>
<p>Website for any reason whatsoever, which are caused owing to causes beyond the control of</p>
<p>the Website. While the Website employs best efforts to ensure the aforesaid, it cannot</p>
<p>guarantee the same. No User shall trade or sell such User accounts in any manner whatsoever.</p>
<p>4.7 Notwithstanding anything contained herein, the Website may be inaccessible for such</p>
<p>purposes as it may, at its sole discretions deem necessary, including but not limited to regular</p>
<p>maintenance. The Website may intimate the Users from time to time regarding such</p>
<p>inaccessibility. However, under no circumstances will the Website be held liable for any losses</p>
<p>or claims arising out of such inaccessibility to the Users, and the User expressly waives off any</p>
<p>and all claims/liabilities arising to this end.</p>
<p>4.8 The Website grants every User a limited, non-exclusive and revocable license to access the</p>
<p>Website and only to the extent such use does not violate these Terms of Use including, without</p>
<p>limitation, the prohibitions listed in clause 6.1 of the Terms of Use.</p>
<p>4.9 The User shall solely be responsible for obtaining access to the Website, and that access</p>
<p>may involve third-party fees (such as internet service provider fees or airtime charges). In</p>
<p>addition, the User must provide and shall be responsible for all equipment necessary to access</p>
<p>the Website. The User may not bypass any measures that have been implemented to prevent or</p>
<p>restrict access to this Website.</p>
<p>4.10 Any unauthorized use and/or access of/to the Website by a User (including any access</p>
<p>through the registered account of that User or any device that the User may use to access the</p>
<p>Website) shall terminate the permission or license granted to the User by the Website.</p>
<p>4.11 Under no circumstance shall any User save copies, download, and print or otherwise store</p>
<p>any content of the Website for any commercial purposes. Accessing, downloading, storing, or</p>
<p>otherwise using the content of the Website for any commercial purpose will constitute a material</p>
<p>breach of these terms &amp; conditions.</p>
<p>4.12 The Website strictly prohibits any use of the content available through the Website,</p>
<p>including but not limited to: (i) any downloading, copying or other use of the content or the</p>
<p>Website for purposes competitive to the Website or for the benefit of any third party; (ii) any</p>
<p>caching, unauthorized linking to the Website or the framing of any content available on the</p>
<p>Website; (iii) any modification, distribution, transmission, performance, broadcast, publication,</p>
<p>uploading, licensing, reverse engineering, transfer or sale of, or the creation of derivative works</p>
<p>from, any content, products or Services obtained from the Website that the User does not have</p>
<p>a right to make available (such as the intellectual property of another party); (iv) any uploading,</p>
<p>posting or transmitting of any material that contains software viruses or any other computer</p>
<p>code, files or programs designed to interrupt, destroy or limit the functionality of any computer;</p>
<p>(v) using any hardware or software intended to surreptitiously intercept or otherwise obtain any</p>
<p>information (such as system data or personal information) from the Website (including, but not</p>
<p>limited to the use of any &ldquo;scraping&rdquo; or other data mining techniques, robots or similar data</p>
<p>gathering and extraction tools); or (vi) any action that imposes or may impose (in the Website&rsquo;s</p>
<p>sole discretion) an unreasonable or disproportionately large load on the Website&rsquo;s infrastructure,</p>
<p>or damage or interfere with the proper working of the Website&rsquo;s infrastructure.</p>
<p>4.13 The Website reserves the right to refuse or cancel any registration of its Users, remove any</p>
<p>person from the Website and prohibit any person from using the Website for any reason</p>
<p>whatsoever, and to limit or terminate such access to or use of the Website at any time without</p>
<p>notice.</p>
<p>5. Terms and Conditions for Borrowing</p>
<p>5.1 A Borrower can borrow from the Website by posting a loan requirement through the Website</p>
<p>and completing the application process.</p>
<p>5.2 During the registration process, you must also enter the details of your bank account for the</p>
<p>purposes of repayments due to the Sunshubh Loans.</p>
<p>5.3 By posting Loan Request, you agree:</p>
<p>a) to provide us with certain additional information which we will ask you for, and that if we</p>
<p>discover that this additional information and/or the information you gave us during the</p>
<p>application process was inaccurate or deficient in any respect, then the Loan Request will be</p>
<p>void and we have the right to take appropriate actions against the Users.</p>
<p>b) to authorize us to check your credit rating from any of the Credit Information Bureau in India,</p>
<p>which are required by us to assess the credibility of the repayments.</p>
<p>c) User acknowledges that Credit Information Bureau link together the records of the User and</p>
<p>anyone that the User has advised as a financial associate (e.g. someone with whom you have a</p>
<p>joint credit account) including previous and subsequent names of parties to the account. While</p>
<p>assessing the credibility of your repayments, we may take into account information about your</p>
<p>financial associates in your credit reference.</p>
<p>d) Any loan amount offered by sunshubh loans will be acceptable to the Borrower</p>
<p>5.4 If so required by Sunshubh Loans after considering the profile of the Borrower, Sunshubh</p>
<p>Loans may request the Borrower to arrange for a co-signor as a condition for disbursement of</p>
<p>the loan. In case, the Borrower fails to arrange for a co-signor, Sunshubh Loans shall have the</p>
<p>right to reject the loan request.</p>
<p>5.5 Formation of Loan Agreements</p>
<p>a) The Borrower agrees to accept each of the loan offer that meets your borrowing criteria.</p>
<p>b) The Borrower agrees to sign and execute the loan agreements electronically or physically. In</p>
<p>case of multiple loan agreements, the Website will provide you with a summary of loan availed</p>
<p>in the Sunshubh Loans Account.</p>
<p>5.6 Disbursement of Loan Proceeds upon execution of all the required documents and</p>
<p>fulfillment of all conditions for disbursements, to the sole satisfaction of the Company, the</p>
<p>borrowed amount will be credited to the account of the Borrower. The platform follows a</p>
<p>rounding down approach during loan disbursement to the borrower for simplicity purposes. For</p>
<p>eg, if the disbursement amount after deduction of all charges and fees amounts to Rs. 9411.18,</p>
<p>the disbursement amount will be Rs. 9,411 and Rs. 0.18 will be transferred to the platform as</p>
<p>the adjustment amount.</p>
<p>5.7 Charges and Fees: Website shall be entitled to charge the following fees and charges.</p>
<p>a) Loan processing charge for every loan availed by the borrower</p>
<p>b) GAP Interest charges if eligible</p>
<p>c) Tracing charges (finding you if we are unable to find you at the resident address provided by</p>
<p>you or the work location provided by you at the time of Loan Request and we have to trace your</p>
<p>new address) &ndash; Rs. 300;</p>
<p>d) No-Response fee (if you fall into arrears and we cannot contact you for 15 consecutive days)</p>
<p>&ndash; Rs. 300;</p>
<p>e) Recollection Fee (If our recovery person visits your office or residence to collect your EMI</p>
<p>payment or visits your office or residence premises as you are not paying your EMI on time) &ndash;</p>
<p>Rs. 300</p>
<p>f) Change of Repayment Date (If you want to change EMI repayment date) &ndash; Rs. 300</p>
<p>g) Change of Nominated Account (If you want to change the Nominated Account from which</p>
<p>your EMIs are being repaid) &ndash; Rs. 300</p>
<p>h) Litigation costs and expenses, including legal fees and expenses, to cover litigation recover</p>
<p>outstanding arrears, in which case the Borrower will be advised of such costs and expenses if</p>
<p>such action proves necessary.</p>
<p>i) Late Payment Fee: If a Borrower has delayed a repayment, Sunshubh Loans reserves the</p>
<p>right to charge you an administration fee for the work it does in attempting to collect your</p>
<p>Repayments (&ldquo;Late Payment Fee&rdquo;).</p>
<p>Delay charges levied for loan amount from 3000/- to 10000/-</p>
<p>1. If the Borrower has delayed his repayment upto 5 days from EMI date, he will be charged Rs.</p>
<p>250 /-</p>
<p>2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged</p>
<p>Rs. 500 /-</p>
<p>3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged</p>
<p>Rs. 750 /-</p>
<p>4. If the Borrower has delayed his repayment upto 20 days from EMI date, he will be charged</p>
<p>Rs. 1000/-</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month from the EMI date (30 days from</p>
<p>EMI date) ,he will be charged Rs. 1500 /-</p>
<p>6. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal</p>
<p>interest rate; on the overdue amount.</p>
<p>Delay charges levied for loan amount from 11000/- to 15000/-</p>
<p>1. If the Borrower has delayed his repayment upto 5 days from EMI date, he will be charged Rs.</p>
<p>300 /-</p>
<p>2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged</p>
<p>Rs. 600 /-</p>
<p>3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged</p>
<p>Rs. 900 /-</p>
<p>4. If the Borrower has delayed his repayment upto 20 days from EMI date, he will be charged</p>
<p>Rs. 1200/-</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month (30 days from EMI date) ,he will be</p>
<p>charged Rs. 1800 /-</p>
<p>6. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal</p>
<p>interest rate; on the overdue amount.</p>
<p>Delay charges levied for loan amount from 16000/- and 1,00,000/-.</p>
<p>1. If the Borrower has delayed his repayment upto 5 days from EMI date, he will be charged Rs.</p>
<p>400 /-</p>
<p>2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged</p>
<p>Rs. 800 /-</p>
<p>3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged</p>
<p>Rs. 1200 /-</p>
<p>4. If the Borrower has delayed his repayment upto 20 days from EMI date, he will be charged</p>
<p>Rs. 1800/-</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month (30 days from EMI date) , he will be</p>
<p>charged Rs. 2500 /-</p>
<p>6. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal</p>
<p>interest rate; on the overdue amount.</p>
<p>In case the Loan amount is more than Rs. 1,00,001 but less than or equal to Rs. 3,00,000</p>
<p>1. If the Borrower has delayed his repayment upto 5 days from EMI date, he will be charged Rs.</p>
<p>1,000</p>
<p>2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged</p>
<p>Rs. 2,000</p>
<p>3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged</p>
<p>Rs. 3,000</p>
<p>4.If the Borrower has delayed his repayment upto 20 days from EMI date, he will be charged</p>
<p>Rs. 4,000</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month (30 days from EMI date) ,he will be</p>
<p>charged Rs. 6000</p>
<p>5. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal</p>
<p>interest rate; on the overdue amount</p>
<p>In case the Loan amount is more than Rs. 3,00,000</p>
<p>1. If the Borrower has delayed his repayment upto 5 days from EMI date,he will be charged Rs.</p>
<p>1,500</p>
<p>2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged</p>
<p>Rs. 3,000</p>
<p>3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged</p>
<p>Rs. 4,500</p>
<p>4.If the Borrower has delayed his repayment upto 20 days from EMI date, he will be charged</p>
<p>Rs. 6,000</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month (30 days from EMI date) ,he will be</p>
<p>charged Rs. 8000</p>
<p>5. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal</p>
<p>interest rate; on the overdue amount</p>
<p>The above charges will be taken by Sunshubh Loans. These charges will be applicable on all</p>
<p>the loans currently running on the platform.</p>
<p>j) All cost in relation to stamp duty and other related costs for preparation of the documents</p>
<p>k) Loan Cancellation charges to borrower when cancelled by him/her &ndash; Rs. 1,000</p>
<p>6. Loan Administration</p>
<p>6.1 EMI: EMIs will start as per the date of disbursement of the loan.</p>
<p>6.2 Prepayment Terms : Any Borrower who wishes to prepay the loan can pre-pay the loan</p>
<p>without any prepayment charges. For prepayment, please email us at</p>
<p>company@sunshubhloans.com</p>
<p>6.3 Missed Repayments</p>
<p>In case a Borrower misses a repayment or anticipates that for any reason whatsoever the</p>
<p>Borrower will not be able to repay as per the repayment schedule under the Loan Agreement,</p>
<p>then the Borrower should promptly contact the Company by sending an email to</p>
<p>company@sunshubhloans.com. Upon receipt, of the e-mail, we shall undertake the following</p>
<p>actions:</p>
<p>a) explain the delay charges and options available with you</p>
<p>b) inform if your account is being passed on to internal recovery team for further legal recovery</p>
<p>as your loan has been classified under Default category.</p>
<p>c) Inform the Credit Information Bureau about the Borrower&rsquo;s default in repayment. As a word of</p>
<p>caution, we would like to inform you that this reporting may have serious consequences on your</p>
<p>ability to avail any credit in the future.</p>
<p>6.4 Default Category</p>
<p>A Borrower shall be considered under &ldquo;default&rdquo; category, if either of the below event has</p>
<p>occurred:</p>
<p>a) if he fails to pay the EMI for three consecutive months;</p>
<p>b) we receive confirmation that the Borrower has died;</p>
<p>c) the Borrower has entered into a terminal arrangement affecting the Borrower&rsquo;s Loan</p>
<p>Agreements</p>
<p>d) In Other specific situations, which may arise at any point in time, the website will have a right</p>
<p>to decide whether to classify any Loan Agreement under &ldquo;Default&rdquo; or not.</p>
<p>6.5 Repayment Date Changes</p>
<p>In case a Borrower wishes to change the repayment date (&ldquo;Repayment Date&rdquo;) other than in</p>
<p>case of an inability to make a payment at all), the Borrower may:</p>
<p>(a) You may ask Sunshubh Loans to change the Repayment Date by notifying Sunshubh Loans</p>
<p>that you wish to do so and specifying the new date within the month upon which you wish your</p>
<p>Any request for change of Repayment Date may be made by signing in to Sunshubh Loans</p>
<p>Account and following the instructions there, or by email to company@sunshubhloans.com.</p>
<p>(b) You can change your Repayment Date only once in 6 months:</p>
<p>(c) Depending on whether the Repayment Date is before or after the original Repayment Date,</p>
<p>the amount payable on the Repayment Date will be adjusted to take account of the number of</p>
<p>days between the Repayment Date and the original repayment date. The remaining</p>
<p>Repayments (assuming no further changes) will revert to the usual Repayment amount</p>
<p>thereafter.</p>
<p>(d) Sunshubh Loans will then acknowledge and confirm the new Repayment Date and any</p>
<p>change to the amount of your next Repayment as a result, before effecting any change.</p>
<p>Depending on how you request the change, this acknowledgment and confirmation will take</p>
<p>place online, during the course of your telephone call or by an exchange of email with you.</p>
<p>(e) Sunshubh Loans may request justification for the change to the repayment date and</p>
<p>reserves the right not to complete the request if in its sole and absolute discretion it determines</p>
<p>that the change is not due to an appropriate change in circumstances or that the process set out</p>
<p>above is not being followed.</p>
<p>(f) You will have to pay applicable charges on the same</p>
<p>7. Privacy and Data Protection</p>
<p>7.1 Service Communications</p>
<p>We understand your concerns of not sending unnecessary, calls, emails or SMS to you. There</p>
<p>are some messages (&ldquo;Service Messages&rdquo;) that we will need to send you. These may be sent via</p>
<p>email, post, call or text message and include notifications at various stage of your loan or</p>
<p>investment.</p>
<p>7.2 Occasional Updates</p>
<p>When you register on Sunshubh Loans you are offered the option to receive our occasional</p>
<p>updates. These messages notify you about new features added on the Lending Platform, ask</p>
<p>your opinion regarding various situations and services, and tell you about services you might not</p>
<p>be using yet. If you decide you no longer want to receive these messages you can change your</p>
<p>contact preferences in the &ldquo;My Contact Preferences&rdquo; area once you are logged in to your</p>
<p>Sunshubh Loans Account or email your preference to us at company@sunshubhloans.com</p>
<p>8. Use of Content</p>
<p>While using the Website, the User specifically undertakes not to host, display, upload, modify,</p>
<p>publish, transmit, update or share any information or content that:</p>
<p>(i) belongs to another person and to which the User does not have any right to;</p>
<p>(ii) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic,</p>
<p>libelous, invasive of another&rsquo;s privacy, hateful, or racially, ethnically objectionable, disparaging,</p>
<p>relating or encouraging money laundering or gambling, or otherwise unlawful in any manner</p>
<p>whatsoever;</p>
<p>(iii) harms minors in any way;</p>
<p>(iv) impersonate any person or entity, or falsely state or otherwise misrepresent the User&rsquo;s</p>
<p>affiliation with a person or entity;</p>
<p>(v) threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with</p>
<p>foreign states or public order or causes incitement to the commission of any cognizable offence</p>
<p>or prevents investigation of any offence or is insulting of any other nation;</p>
<p>(vi) infringes any patent, trademark, copyright or other IPR (defined below);</p>
<p>(vii) contain software viruses or any other computer code, files or programs designed to interrupt</p>
<p>destroy or limit the functionality of any computer resource;</p>
<p>(viii) deceives or misleads the addressee about the origin of such messages or communicates</p>
<p>any information which is grossly offensive or menacing in nature;</p>
<p>(ix) engage in any activity that interferes with or disrupts access to the Website or the Products</p>
<p>(or the servers and networks which are connected to the Website);</p>
<p>(x) attempt to gain unauthorized access to any portion or feature of the Website, any other</p>
<p>systems or networks connected to the Website, to any Website server, or to any of the Products</p>
<p>offered on or through the Website, by hacking, password mining or any other illegitimate means;</p>
<p>(xi) probe, scan or test the vulnerability of the Website or any network connected to the Website,</p>
<p>nor breach the security or authentication measures on the Website or any network connected to</p>
<p>the Website;</p>
<p>(xii) disrupt or interfere with the security of, or otherwise cause harm to, the Website, systems</p>
<p>resources, accounts, passwords, servers or networks connected to or accessible through the</p>
<p>Websites or any affiliated or linked sites;</p>
<p>(xiii) use the Website or content therein for any purpose that is unlawful or prohibited by the</p>
<p>Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes</p>
<p>the rights of the Website or other third parties;</p>
<p>(xiv) falsify or delete any author attributions, legal or other proper notices or proprietary</p>
<p>designations or labels of the origin or source of software or other material contained in a file that</p>
<p>is uploaded;</p>
<p>(xv) violate any code of conduct or other guidelines, which may be applicable for or to any</p>
<p>particular service;</p>
<p>(xvi) violate any applicable laws or regulations for the time being in force within or outside India;</p>
<p>9. Intellectual Property Rights</p>
<p>9.1 Intellectual Property Rights (&ldquo;IPR&ldquo;) for the purpose of these Terms of Use shall always mean</p>
<p>and include copyrights whether registered or not, patents including rights of filing patents,</p>
<p>trademarks, trade names, trade dresses, house marks, collective marks, associate marks and</p>
<p>the right to register them, designs both industrial and layout, geographical indicators, moral</p>
<p>rights, broadcasting rights, displaying rights, distribution rights, selling rights, abridged rights,</p>
<p>translating rights, reproducing rights, performing rights, communicating rights, adapting rights,</p>
<p>circulating rights, protected rights, joint rights, reciprocating rights, infringement rights and</p>
<p>further shall also include but not be limited to all text, graphics, user interfaces, visual interfaces,</p>
<p>sounds and music (if any), artwork and computer code in relation to the Website.</p>
<p>9.2 All IPR on the Website exclusively belong to either the Website or the third party sellers and</p>
<p>suppliers, as the case may be. Under no circumstance shall any User infringe in any way such</p>
<p>IPR of the Website, a third party supplier or seller during or pursuant to its use of the Website for</p>
<p>any purposes whatsoever.</p>
<p>9.3 All those IPR arising as a result of domain names, internet or any other right available under</p>
<p>applicable law shall vest in the domain of www.Sunshubhloans.com as the owner of such</p>
<p>domain name.</p>
<p>9.4 The Parties hereto agree and confirm that no part of any IPR mentioned hereinabove is</p>
<p>transferred in the name of User and any IPR arising as a result of these presents shall also be</p>
<p>in the absolute ownership, possession and our control or control of its owners/permitted</p>
<p>assigns, as the case may be.</p>
<p>9.5 Except as expressly provided herein, the User acknowledges and agrees that it shall not</p>
<p>copy, republish, post, display, translate, transmit, reproduce or distribute or in any other way</p>
<p>infringe any IPR through any medium without obtaining the necessary authorization from the</p>
<p>Website or the thirty party owner of such IPR.</p>
<p>10. Indemnity</p>
<p>10.1 The User hereby indemnifies, defends and holds harmless the entity owning and operating</p>
<p>the Website, its subsidiaries, affiliates, vendors, agents and their respective directors, officers,</p>
<p>employees, contractors and agents (herein after individually and collectively referred to as</p>
<p>&ldquo;indemnified parties&ldquo;) from and against any and all losses, liabilities, claims, suits, proceedings,</p>
<p>penalties, interests, damages, demands, costs and expenses (including legal and other</p>
<p>statutory fees and disbursements in connection therewith and interest chargeable thereon)</p>
<p>asserted against or incurred by the indemnified parties that arise out of, result from, or in</p>
<p>connection with:</p>
<p>(i) The User&rsquo;s breach of these Terms of Use; or</p>
<p>(ii) Any claims made by any third party due to, or arising out of, or in connection with, the User&rsquo;s</p>
<p>use of the Website; or</p>
<p>(iii) Any claim that any third party IPR, proprietary information, content or materials provided by</p>
<p>the User causes any damage to a third party; or</p>
<p>(iv) Violation of any rights of any third party by the User, including any IPR; each an &ldquo;Indemnity</p>
<p>Event&ldquo;.</p>
<p>10.2 Upon the occurrence of an Indemnity Event, the Website may notify the User of any claims</p>
<p>which the User shall be liable to indemnify the Website against. The User shall then be</p>
<p>obligated to consult with the Website regarding the course of action to be undertaken in</p>
<p>defending such a claim.</p>
<p>10.3 The User shall not compromise or settle any claim or admit any liability or wrongdoing on</p>
<p>the part of the Website without the express prior written consent of the Website which can be</p>
<p>withheld or denied or conditioned by the Website in its sole discretion.</p>
<p>10.4 Notwithstanding anything to contrary, in no event shall the Website, its subsidiaries or</p>
<p>affiliates and their respective officers, directors, employees, partners or suppliers be liable to the</p>
<p>User for any special, incidental, indirect, consequential, exemplary or punitive damages</p>
<p>whatsoever, including those resulting from loss of use, data or profits, whether or not</p>
<p>foreseeable or whether or not the Website has been advised of the possibility of such damages,</p>
<p>or based on any theory of liability, including breach of contract or warranty, negligence or other</p>
<p>tortious action, or any other claim arising out of or in connection with the User&rsquo;s use of or access</p>
<p>to the Website or the Product.</p>
<p>11. Report Abuse and Take Down Policy</p>
<p>11.1 In the event the Website or any User becomes aware of any objectionable content on the</p>
<p>Website including but not limited to violation of privacy, misuse of personal information or</p>
<p>violation of any IPR, in violation of these Terms of Use then, the Website, by itself or upon</p>
<p>receipt of a communication from any of its Users, without any prior notice take down such</p>
<p>objectionable content from the Website.</p>
<p>11.2 For any reporting in respect of the aforesaid, the User should contact the Website at</p>
<p>company@sunshubhloans.com</p>
<p>11.3 If a feedback comment or any communication made between Users and the Website or</p>
<p>email communication between Users in relation to transactions conducted on the Website</p>
<p>contain profanity, please submit a request for action/removal. Disciplinary action may result in</p>
<p>the indefinite suspension of a User&rsquo;s account, temporary suspension, or a formal warning.</p>
<p>11.4 The Website shall consider the circumstances of an alleged policy violation and the User&rsquo;s</p>
<p>trading records before taking action violations of this policy may result in a range of actions,</p>
<p>including: (a) limits placed on account privileges; (b) loss of special status; (c) account</p>
<p>suspension.</p>
<p>12. Feedback and Information</p>
<p>12.1 The Website reserves the right, at its sole discretion to use such information for</p>
<p>upgrading/enhancing the Website and such use shall be entirely unrestricted.</p>
<p>12.2 The Website may at its discretion, also make any modifications or changes to the Website</p>
<p>and its content on the basis of such feedback or information.</p>
<p>12.3 In the event that the Website makes any changes or modifications to the Website on the</p>
<p>basis of any such feedback, the User shall not have any rights or title (including any IPR) in</p>
<p>such changes or modifications to the Website or Products listed therein.</p>
<p>12.4 By submitting any feedback or any information, the User hereby warrants that (i) the</p>
<p>feedback does not contain confidential or proprietary information belonging to the User or any</p>
<p>other person; and (ii) shall not be entitled to any compensation or reimbursement of any kind</p>
<p>from the Website for the feedback under any circumstances.</p>
<p>13. Applicable Law</p>
<p>These Terms of Use shall be governed by and interpreted and construed in accordance with the</p>
<p>laws of India. The courts in Mumbai shall have exclusive jurisdiction in respect of any matters</p>
<p>arising here from.</p>
<p>14. Dispute Resolution</p>
<p>14.1 In the event any dispute arises out of or in connection with the Terms of Use herein,</p>
<p>including the validity hereof, the parties hereto shall endeavor to settle such dispute amicably in</p>
<p>the first instance. The attempt to bring about an amicable settlement shall be treated as having</p>
<p>failed as soon as one of the parties hereto, after reasonable attempts, which shall continue for</p>
<p>not less than 15 (Fifteen) calendar days, gives a notice to this effect, to the other party in writing.</p>
<p>14.2 In case of such failure, the dispute shall be referred to a sole arbitrator, who shall be</p>
<p>appointed by the Website. The arbitration proceedings shall be governed by the Indian</p>
<p>Arbitration and Conciliation Act, 1996 and shall be held in Mumbai. The language of arbitration</p>
<p>shall be English.</p>
<p>15. Jurisdictional Issues</p>
<p>Unless otherwise specified, the products/items listed on the Website are solely for the purpose</p>
<p>of delivery in India. The Website shall under no circumstance be held liable for use or availability</p>
<p>of Products for use in locations/countries other than India.</p>
<p>16. Buyer Protection Program</p>
<p>16.1 If you want to make a complaint about the Loan Agreement or Sunshubh Loans you can</p>
<p>email company@sunshubhloans.com with brief details of your complaint and your account</p>
<p>reference. Sunshubh Loans will acknowledge your complaint within one working day. Sunshubh</p>
<p>Loans will then investigate and send you an initial response within five working days.</p>
<p>16.2 If you are not satisfied with the response from the Customer Services Manager you can</p>
<p>email company@sunshubhloans.com, enclosing responses already given to you. Your email will</p>
<p>then be referred to the Chief Operating Officer, who will respond within a five working days.</p>
<p>17. General Provisions</p>
<p>17.1 Notice: All notices to be issued pursuant to these Terms of Use shall be served to the User</p>
<p>by email or by general notification on the Website. Any notice to be sent to the Website pursuant</p>
<p>to these Terms of Use shall be sent to the Website&rsquo;s grievance officer by e-mail at</p>
<p>company@sunshubhloans.com</p>
<p>17.2 Assignment: This Terms of Use shall not be assigned or otherwise transferred by the User.</p>
<p>However the Website&rsquo;s obligations under these Terms of Use are freely assignable or otherwise</p>
<p>transferable by the Website to any third parties without the requirement of seeking the Users</p>
<p>prior consent.</p>
<p>17.3 Severability: If any provision of these Terms of Use is void, or is so declared, such</p>
<p>provision shall be severed. The Terms of Use shall otherwise remain in full force and effect.</p>
<p>17.4 Waiver: Any failure or delay by a party to enforce or exercise any provision of these Terms</p>
<p>of Use, or any related right, shall not constitute a waiver by such party of that provision or right.</p>
<p>The exercise of one or more of a party&rsquo;s rights hereunder shall not be a waiver of, or preclude</p>
<p>the exercise of, any rights or remedies available to such party under these Terms of Use or in</p>
<p>law or at equity. Any waiver by a party shall only be made in writing and executed by a duly</p>
<p>authorized officer of such party.</p>
<p>17.5 Relationship and Exclusivity. Nothing in these Terms of Use shall constitute or be deemed</p>
<p>to constitute a partnership, joint venture, agency or the like between the parties hereto or confer</p>
<p>on any party any authority to bind the other party or to contract in the name of the other party or</p>
<p>to incur any liability or obligation on behalf of the other party.</p>
<p>17.6 Force Majeure: If performance of any service under these Terms of Use by the Website is</p>
<p>prevented, restricted, delayed or interfered with by reason of labor disputes, strikes, acts of</p>
<p>God, floods, lightning, severe weather, shortages of materials, rationing, inducement of any</p>
<p>virus, Trojan or other disruptive mechanisms, any event of hacking or illegal usage of the</p>
<p>website, utility or communication failures, earthquakes, war, revolution, acts of terrorism, civil</p>
<p>commotion, acts of public enemies, blockade, embargo or any law, order, proclamation,</p>
<p>regulation, ordinance, demand or requirement having legal effect of any government or any</p>
<p>judicial authority or representative of any such government, or any other act whatsoever,</p>
<p>whether similar or dissimilar to those referred to in this clause, which are beyond the reasonable</p>
<p>control of the Website and could not have been prevented by reasonable precautions then the</p>
<p>Website shall in toto be excused and discharged from such performance to the extent of and</p>
<p>during the period of such force majeure event, and such non-performance shall, in no manner</p>
<p>whosoever, amount to a breach by the Website of its obligations herein.</p>
<p>18. Insurance for Loan or Borrower</p>
<p>18.1 This feature is currently not available. However, Borrower authorizes website to work with</p>
<p>any insurance company registered with IRDA and ready to provide beneficiary product. If such</p>
<p>facility comes in existence on website in future, website need not take any permission from user.</p>
<p>18.2 For any such product Borrower choses to pay the premium amount to Sunshubh Loans</p>
<p>and then Sunshubh Loans will pay the same to the insurance company on your behalf.</p>
<p>18.3 Sunshubh Loans will be the nominee for this insurance policy and is authorized to manage</p>
<p>the claim settlement process on your behalf. Once the claim settlement is done, Sunshubh</p>
<p>Loans will distribute the settlement amount to Sunshubh Loans and then transfer available</p>
<p>amount to borrower&rsquo;s bank account if there is any.</p>
<p>18.4 In case your repayments are irregular or delayed on a consistent basis, Sunshubh Loans</p>
<p>reserves the right to cancel the insurance policy. The insurance policy will be co-terminus with</p>
<p>your loan tenure.</p>
<p>18.5 In case of any incident (permanent disability/accidental death of the Borrower), the</p>
<p>beneficiary/nominee will need to intimate Sunshubh Loans, who will then initiate the claims on</p>
<p>the beneficiary/nominee&rsquo;s behalf.</p>

        {/*  <Typography variant="h6" gutterBottom>BORROWER</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Borrower means the person who is registered as a borrower on the Website / Lending platform i.e.
www.sunshubhloans.com and has been accepted by us for membership post all legal due diligence.
        </Typography>
        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>AGREEMENT</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Agreement means an agreement among two parties 1) Borrower 2) Sunshubh solutions under certain agreed and
accepted Terms and Conditions.
        </Typography>


        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>SUNSHUBH SOLUTIONS</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Sunshubh Solutions means Sunshubh Solution is a Proprietary firm.
        </Typography>





        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>INFORMATION</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Information means any of the information that can be viewed or downloaded from the Website/ lending platform i.e.
"www.sunshubhloans.com" (herein after referred to as “lending platform/Website/Sunshubhloans") and includes,
but is not limited to, data, pricing information, Terms, images, reports, policies and software. It also includes our
copyright, Intellectual Property Rights, Information Technology or other commercially valuable property.
        </Typography>


        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>INFORMATION TECHNOLOGY</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Information Technology means all computer hardware including network and telecommunications equipment),
databases, and all computer software (including the lending platform or any software required for the lending
platform) owned, used, leased by us.
        </Typography>



        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>INTELLECTUAL PROPERTY RIGHTS</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Intellectual Property Rights means all patents, rights to inventions, copyrights and related rights, trademarks,
business names and domain names, rights in get up, goodwill and right to sue for passing off, database rights, right
to use, and protect the confidentiality of, confidential information, and all other intellectual property rights, whether
registered or unregistered, including all applications and rights to apply for and be granted, renewals or extensions
of, and rights to claim property form, such rights and all similar or equivalent rights or forms of protections which
subsist or will subsist now Or In The Future In Any Part Of The World.
        </Typography>




        
        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>SUNSHUBH LOANS</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      SUNSHUBH LOANS means the Lending platform i.e. "www.sunshubhloans.com" for people to borrow money from
our Company. The Sunshubh solutions like to loan money to creditworthy borrowers. Borrowers get the recognition
for their good credit behaviour on the online Lending Platforms
        </Typography>




        
        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>PARTICIPANT</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Participant means a person who has entered into an arrangement with Sunshubh Solutions to avail of loan
facilitation services provided by it.
        </Typography>




        
        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>PRIVACY POLICY</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Our privacy policy governing the use of your data under the provisions of law. which can be found at
website”www.sunshubhloans.com”
        </Typography>




        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>SERVICES</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      The services offered to you through the Website lending platform.
        </Typography>



        
        <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>WEBSITE</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Our main website which can be found at “www.sunshubhloans.com”        </Typography>






        
      <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>LOCATIONS</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Sunshubhloans servicing signed a consent order over their loan serving practices. The right to serve loan for a
particular location is with the company. Any given time company can stop serving the area or location without prior
        </Typography>



           
      <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>RIGHT TO REJECT</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Sunshubh loans has all the rights to cancel or hold or reject any given application at any given point of time.
        </Typography>



              
      <Box m={3}></Box>
        <Typography variant="h6" gutterBottom>INTERPRETATIONS</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Clause and paragraph headings shall not affect the interpretation of this terms and conditions:
• A reference to a person shall include a reference to an individual firm. company. corporation. partnership.
unincorporated to of persons. government. state or agency of a state or any association. trust joint venture or
consortium (whether or not having separate legal personality' and that person's personal representatives.successors
permitted assigns and permitted transferees,
• Unless the context them's. requires words in the singular shall include the plural and in the plural shall include the
singular:
• Unless the context them's. requires a reference to one gender shall include a reference to the other genders: and
• Participation in a Loan means having an interest in such Loan. whether because you made such Loan to the Borrow
pursuant to a Loan Contract or you were transferred such Loan by another Lender Member in accordance with the
relevant Loan Agreement and these Terms.


        </Typography>


                  
      <Box m={2}></Box>
        <Typography variant="h6" gutterBottom>SIGN UP AND REGISTRATION</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      • As indicated, any person may access the Website either by registering on the Website or using the Website as a
guest. However, a guest user may not have access to all sections of the Website which shall be reserved only for the
purpose of registered Users, and which may change from time to time at the sole discretion of the Website.
• All eligible Users who wish/have to register themselves with the Website shall be required to create an account by
filling in the details prescribed in the Website.
• By applying for registration, the User agrees and authorizes sunshubhloans to carry out such checks and
verification. As part of the registration process:
• We shall run identity, KYC (Know your customer policy as per Know-Your-Customers (KYC) Guidelines - Anti Money
Laundering Standards of RBI policy.Obtain a credit rating for Users from the Credit Information Bureau.

        </Typography>


                
        <Box m={2}></Box>
        <Typography variant="h6" gutterBottom>LIMITATION OF LIABILITY</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Notwithstanding anything to the contrary contained herein, neither Sunshubhloans nor its affiliated companies,
subsidiaries, officers, directors, employees or any related party shall have any liability to you or to any third party for
any indirect, incidental, special or consequential damages or any loss of revenue or profits arising under or relating
to these Terms and Conditions. To the maximum extent permitted by law, you waive, release, discharge and hold
harmless Sunshubhloans and its affiliated and subsidiary companies, and each of their directors, officers, employees,
and agents, from any and all claims, losses, damages, liabilities, expenses and causes of action arising out of your use
of the Website.
        </Typography>




                 
        <Box m={2}></Box>
        <Typography variant="h6" gutterBottom>DISCLAIMER OF WARRANTIES</Typography>
      <Box m={2}></Box>
      <Typography variant="body1" gutterBottom>
      Your use of the service and all information, products and other content (including that of third parties) included in or
accessible from the Service is at your sole risk. The Service is provided on an 'as is' and 'as available" basis
sunshubhloans expressly disclaim all warranties of any kind as to the Service and all information, products and other
content (including that of third parties) included in or accessible from the Service, whether express or implied,
including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and
non-infringement sunshubhloans make no warranty that the Service will meet your requirements and/or the Service
will be uninterrupted, timely, secure, or error-free and/or the results that may be obtained from the use of the
Service will be accurate or reliable and/or the quality of any products, services, information, or other material
purchased or obtained by you through the Service will meet your expectations and/or any errors in the technology
will be corrected. You shall solely be responsible for any damage to your computer system or loss of data that results
from the download of any such material. No advice or information, whether oral or written, obtained by you from
sunshubhloans through or from the Service will create any warranty not expressly stated in these Terms and
Conditions.
        </Typography>

     
 */}






        </div>



            </Container>
   
        );
    }
}

export default TermsandCondition;