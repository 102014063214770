import React, { Component } from 'react';


import {Container,FormControl,InputLabel,Select,MenuItem, 
    TextField,Typography, Button,Table,TableBody,TableCell,Card,TableHead,TableContainer,TableRow,Box} from '@material-ui/core'

import UserContext from '../ContextManage/UserContext'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import * as ApiFunctions from '../../util/apifunction'

class SearchLoan extends Component {
    static contextType =UserContext;
  
  constructor(props){
  
    super(props);
   
    this.state={
       
              ID:null,
            Email:null,
            Role:null,
            Token:null,
            isloggedin:false,
            SK:'ID',
            SQUERY:'',
            Data:[],
            isloading:true

    }
}



async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

   }



   async SetQuery(event){
       await this.setState({SQUERY:event.target.value})
    
   }

  async SetKeyword(event){
   
    await this.setState({SK:event.target.value})
  
}



CheckValiad(){
   let QUERY=this.state.SQUERY


   if(QUERY.length>=3){
     
    this.Search()

   }else{

    alert("At least Enter 3 Charcter or Value")

   }


}


async Search(){
    const header={
        'Content-Type': 'application/json',
            'x-access-token':this.state.Token
       };
       try{
        const Data = await ApiFunctions.GetApiRequest(`/loan/util/search?${this.state.SK}=${this.state.SQUERY}`,header)
  
       
        if(Data.Status===200){
        await  this.setState({Data:Data.data,isloading:false})
      console.log(this.state.Data)
       }else{
            this.setState({isError:true,isloading:true})   
        }
      }catch(err){
        this.setState({isError:true})
        }
}







    render() {
        return (
            <Container maxWidth="xl">
                <Typography variant="h3" color="initial" gutterBottom>Search Loan</Typography>


                 <div >
                  <FormControl style={{width:200}}  variant="filled">
        <InputLabel>Select Keyword</InputLabel>
        <Select
          labelId="keyword-selector"
          id="keyword selector"
        value={this.state.SK}
        onChange={(event)=>this.SetKeyword(event)}
        >
         
       
                <MenuItem  value='ID'>ID</MenuItem>
                <MenuItem value='User_Name'>Full_Name</MenuItem>
                <MenuItem value='User_Email'>Email</MenuItem>
                <MenuItem value='PhoneNo'>PhoneNo</MenuItem>
                <MenuItem value='Status'>Status</MenuItem>
              
         
         
        </Select>
      </FormControl>

        

      <TextField
        id="search-user"
        label=""
        variant="outlined"
        onChange={(event) => this.SetQuery(event)}

      />

      <Button onClick={()=>this.CheckValiad()} variant="contained" color="default" size="large">
        Search
      </Button>
      </div>
   <Box m={4}/>
      <Card>

<TableContainer >
 <Table  aria-label="simple table">
   <TableHead>
     <TableRow>
       
       <TableCell align="left">ID</TableCell>
     
       <TableCell align="left">Full Name</TableCell>
       <TableCell align="left">Email</TableCell>
       <TableCell align="left">Phone No.</TableCell>
       <TableCell align="left">Address</TableCell>

    
       <TableCell align="left">Married Status</TableCell>
   
       <TableCell align="left">Created_At</TableCell>
      





     </TableRow>
   </TableHead>
   <TableBody>
     {this.state.Data.map((data) => (
       <TableRow key={data.ID}>
             <TableCell align="left">{data.ID}</TableCell>
        
     <TableCell component="th" scope="row" align="left">
           {data.User_Name}
         </TableCell>
         <TableCell align="left">{data.User_Email}</TableCell>
         <TableCell align="left">{data.User_PhoneNo}</TableCell>
         <TableCell align="left">{data.User_Address}</TableCell>
      
         <TableCell align="left">{data.User_MarriedStatus}</TableCell>
         <TableCell align="left">{new Date(data.Created_At).toString()}</TableCell>
       

       
         <TableCell align="left">




   <IconButton  aria-label="edit" onClick={()=>this.props.history.push(`/app/edit-loan/${data.ID}`)}>
     <EditIcon />
   </IconButton>


         </TableCell>


       </TableRow>
     ))}
   </TableBody>
 </Table>
</TableContainer>


               </Card>





            </Container>
        );
    }
}

export default SearchLoan;