import React,{useState,useEffect} from 'react'

import { BottomNavigation,BottomNavigationAction,
  Menu,MenuItem} from '@material-ui/core'


//icons
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
//react-router-dom
import {useHistory,withRouter,Link} from 'react-router-dom';

//drawer slide refrence



function Botton_Navigation() {

  const history=useHistory();
  const [value, setValue] = React.useState("");
  /*for the category drawer*/ 
 
   //for the bottom navigation menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    if(newValue==="category" || newValue==="menu"){
     
    }else{
      history.push(`${newValue}`);
      setValue(newValue);
    }
  
  };



 


  return (

      <BottomNavigation
      value={value}
      onChange={handleChange}
       showLabels  boxShadow={3} style={style.stickToBottom}>
           <BottomNavigationAction label="Home"  value="/" icon={<HomeOutlinedIcon/>} /> 
      
         <BottomNavigationAction label="Login"  value="/login" icon={<VpnKeyOutlinedIcon/>}/>
        <BottomNavigationAction label="Join Now" value="/register" icon={<PersonAddOutlinedIcon/>} /> 
        <BottomNavigationAction label="More" value="menu" icon={<MoreHorizOutlinedIcon/>}  onClick={handleClick} />

        <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        value={value}
        onChange={handleChange}
        >
         <Link to="/contact" label="contact" onClick={window.preventDefault} color="inherit"   style={{textDecoration:'none',color:'black'}}
                 variant="body2">
              <MenuItem onClick={handleClose}>Contact</MenuItem>
           </Link>
           <Link to="/about" label="about" onClick={window.preventDefault} color="inherit"   style={{textDecoration:'none',color:'black'}}
                 variant="body2">
              <MenuItem onClick={handleClose}>About</MenuItem>
           </Link> 
      </Menu>


    
</BottomNavigation>

    )
  }


  export default withRouter(Botton_Navigation);

  


const style={

  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex:1200,
    borderTop:'1px solid'
  },
}

