import React from 'react'
import {Box,TextField,Dialog,
     DialogTitle,DialogContent,InputLabel,Select,
     DialogActions,Button,DialogContentText,Divider,FormControl,MenuItem} from '@material-ui/core'



     import { PostApiData } from '../../util/apifunction';


     import UserContext from '../ContextManage/UserContext';

export default function AddRef(props) {

    const User = React.useContext(UserContext);


    const { onClose, open } =props;
    const [Fields,SetFields]=React.useState({
    })
    const [Errors,SetErrors]=React.useState({})





    const handleValidation=()=>{

        let fields= Fields;
        let errors={};
        let FormIsValid=true;


     
         
    if (!Fields["Name"]) {
        FormIsValid = false;
        Errors["Name"] = "Name Cant be empty!";
      }   
  
               
      if(typeof Fields["Phone"] !== "undefined"){
        if(!fields["Phone"].match(/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/)){
          FormIsValid = false;
          Errors["Phone"] = "Enter A Valid Phone Number";
        }      	
      }
               
    if (!Fields["Relation"]) {
        FormIsValid = false;
        Errors["Relation"] = "Select a Relaton with Reference!";
      }   

        SetErrors(errors)
        return FormIsValid;
    }



   const SubmitUserData=(e)=>{
        e.preventDefault();

        if(handleValidation()){
            
AddRef()
            //make api call
        }else{
            alert('Please Fill the Form!');
        }


    }


    const AddRef=async()=>{

        const header={
            'Content-Type': 'application/json',
            'x-access-token': User.state.Token
           };

        try {
            
            const Data = await PostApiData(`/ref/customer`,header,Fields)

            if(Data.Status==200){
            window.location.href='/app/user-ref';
            }else{
                alert("Something Went Wrong Try Again After Some time");
            }



        } catch (error) {

            console.log(error)
             alert("INTERNAL SERVER ERROR! -- 500")
        }



    }


   const handleChange=async(field, e)=> {
            let InputFields=await Fields;
            InputFields[field]=e.target.value;
           await SetFields(InputFields)

           console.log(Fields)
      }

   

    

    const handleClose = () => {
      onClose(false);
      SetFields({})
    };
  

  return (
    <Dialog
    onClose={handleClose} open={open}
    maxWidth="sm"
    fullWidth="sm"
    aria-labelledby="add-ref"
    aria-describedby="add-references"
  >
      <form noValidate style={{ width: '100%' }} onSubmit={SubmitUserData}  >


    <DialogTitle id="add-ref">
        Add Reference
    </DialogTitle>

    <Divider/>
    <DialogContent>
      <DialogContentText id="add-ref-content">
        <Box m={2}/>
      
            <TextField
              id="Name"
              label="Name"
              fullWidth
              name="Name"
              variant='outlined'
              value={Fields["Name"]}
             onChange={(e) => handleChange("Name",e)}
            
            />


<Box m={4}/>
<TextField
type='number'
InputProps={{ inputProps: { min: 10, max: 10 } }}
              id="Phone"
              label="Phone"
              fullWidth
              name="Phone"
              variant='outlined'
              value={Fields["Phone"]}
             onChange={(e) => handleChange("Phone",e)}
        
            />


<Box m={4}/>
<FormControl fullWidth  variant="standard">
  <InputLabel id="demo-simple-select-label">Relaton</InputLabel>
  <Select
     defaultValue="choose"
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={Fields["Relation"]}
    label="Relation"
    onChange={(e) => handleChange("Relation",e)}

  >
    <MenuItem value="Friend">Friend</MenuItem>
    <MenuItem value="Family">Family</MenuItem>
    <MenuItem value="Colleague">Colleague</MenuItem>
  </Select>
</FormControl>





      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button  type="submit" id="submit">Save</Button>
      <Button variant='outlined' color="primary" onClick={handleClose}>
            Cancel
      </Button>
    </DialogActions>
    </form>

  </Dialog>
  )
}
