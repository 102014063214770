import React, { Component } from 'react';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import UserContext from '../ContextManage/UserContext'

import * as ApiFunctions from '../../util/apifunction'

class Notes extends Component {
    static contextType =UserContext;
 
constructor(props){
    super(props);
    this.state={
        Comment:'',
            Data:this.props.Data,
            ID:null,
            Email:null,
            Role:null,
            Token:null,
            isloggedin:false
    }

}



async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

   }






setComment(event,id){

  const CommentInput=event.target.value;


  const data=[...this.state.Data]
   
  data.map(item => {
        if(item.ID === id) {
          item.Comment = CommentInput
           /// console.log("ID",id,item.ID)
        }
        return item
      })
      
      this.setState({Data:data})
  
}



async DeleteNote(ID){
    const header={
      'Content-Type': 'application/json',
   'x-access-token':this.state.Token
     };

     try{
      const Data = await ApiFunctions.DeleteApiData(`/note/${ID}`,header)
    
    if(Data.Status===200){
        const items = this.state.Data.filter(item => item.ID !== ID);
        this.setState({ Data: items });
      alert(`Note ${ID} deleted Successfully!`)
    }

    if(Data.Status===404){
      alert(`Note ${ID} Not Found!`)
    }
      
    }catch(err){
      alert(`Internal Server Error`)
      console.log(err)
      }
    }



 
    async updatenote(id,comment){

        const header={
          'Content-Type': 'application/json',
       'x-access-token':this.state.Token
         };
    
    
         const data={
            Comment:comment
         }
  
  
         try{
          const Data = await ApiFunctions.UpdateApiData(`/note/${id}`,header,data)
  
         if( Data.Status===200){
             alert("Note Updated Successfully!")  
          
         }
         if( Data.Status!==200){
          alert("Cant Update Note Right Now!")      
     }
       
        }catch(err){
         alert("Something Went Wrong",err)
       }
    
   }
  
   
 

    render() {
        return (
            <React.Fragment>

            <Grid container spacing={2}>
              
            

            {this.state.Data.map((data)=>

                    <Grid item xs={12} lg={3} xl={3} md={3} sm={2} key={data.ID}>  
                         <Paper style={{padding:20}}>
                         <span>ID:-{data.ID}</span>
                         <IconButton aria-label="delete" style={{float:"right"}} onClick={()=>this.DeleteNote(data.ID)}>
                            <CloseIcon />
                            </IconButton>

                            <Box m={4}/>
                          <TextField
                          variant="filled"
                          multiline
                       
                          rows={6}

                          fullWidth
                            id="note-inuput"
                            label=""
                       value={data.Comment}
                            onChange={(event)=>this.setComment(event,data.ID)}
                            
                          />
            <Box m={2}/>
        
                        <label> <b>Created At:- </b>{new Date(data.Created_At).toString()}</label>
                       <Box m={2}/>
                       {
                            (data.Updated_At===null) ? (
                                    <span></span>
                            ) :(
                                <label> <b>Updated At:- </b>{new Date(data.Updated_At).toString()}</label>

                            )
                       }
            <Box m={2}/>
                      <Button variant="contained" color="primary" disableElevation onClick={()=>this.updatenote(data.ID,data.Comment)}>
                        UPDATE
                      </Button>


                             </Paper>       
                        </Grid>

            
            
            )}

</Grid>
        </React.Fragment>
        );
    }
}

export default Notes;