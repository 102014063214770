import React, { Component } from 'react'

import UserContext from '../ContextManage/UserContext'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as ApiFunctions from '../../util/apifunction'
import { TableCell,TableBody,TableRow ,Table,TableContainer,TableHead} from '@material-ui/core'

export default class GuarantorEdit extends Component {

  static contextType =UserContext;


  
  constructor(props){
    super(props);
    this.state={
            Name:'',
            Phone:0,
            Email:'',
            Relation:'',
            Age:21,
            Address:'',
            Data:this.props.Guarantor,
            Token:null,
            isloggedin:false
    }

}

async componentDidMount(){
  const UserContextData = this.context
  await this.setState({
    Token:UserContextData.state.Token,
    Role:UserContextData.state.Role
   })


   console.log(this.state.Data)

 }


  render() {
    return (
      <React.Fragment>
   <TableContainer component={Paper}>



   <Table  aria-label="simple table">
          <TableHead>
            <TableRow>
       
              <TableCell>Name</TableCell>
              <TableCell>Relation</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Agreement Signed</TableCell>
              <TableCell>Operation</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
   
          </TableBody>

          <GuarantorEdit Guarantor={this.state.Data.guarantors}/>

        </Table>

   </TableContainer>
        
      

      

  </React.Fragment>
    )
  }
}
