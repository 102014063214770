import React from 'react';
import { Route, Switch } from 'react-router-dom';


//pages and components

import NotFoundPage from '../Components/Pages/NotFoundPage';
import HomePage from  '../Components/Pages/HomePage'
import Register from '../Components/Pages/SignUpPage';
import AboutusPage from '../Components/Pages/AboutUsPage';
import ContactusPage from '../Components/Pages/ContactUsPage';
import LoginPage from '../Components/Pages/LoginPage';
import Calculator from '../Components/Pages/Calculatior'

import Terms from '../Components/Pages/TermsandCondition'
import Policy from '../Components/Pages/PrivacyPolicy'
import FAQ from '../Components/Pages/Faq'


import BusinessLona from '../Components/Pages/BusinessLoan'
import PersonalLoan from '../Components/Pages/PersonalLoan'



function PublicRoutes () {
	return(
	<React.Fragment>
		<Switch>
			<Route  path="/" exact component={HomePage} />
			<Route  path="/login"  exact component={LoginPage} />
			<Route  path="/register" exact  component={Register} />
			<Route  path="/about" exact component={AboutusPage}/>
			<Route  path="/contact"  exact component={ContactusPage}/>
			<Route  path="/calculator"  exact component={Calculator}/>
			<Route  path="/terms"  exact component={Terms}/>
			<Route  path="/privacy"  exact component={Policy}/>
			<Route  path="/faq"  exact component={FAQ}/>
			<Route  path="/business-loan"  exact component={BusinessLona}/>
			<Route  path="/personal-loan"  exact component={PersonalLoan}/>
			

	
			<Route component={NotFoundPage} />
		</Switch>	
		</React.Fragment>
)

};


//<BottomNavigation/>
export default  PublicRoutes;
