import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { CssBaseline } from "@material-ui/core";

import Fab from "@material-ui/core/Fab";

import Icon from "@material-ui/core/Icon";
import history from "./util/history";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import cookie from "react-cookies";
import jwtdecode from "jwt-decode";
import Popover from "@material-ui/core/Popover";

//import stalsl for public router
import BusinessLoan from "./Components/Pages/BusinessLoan";
import PersoonalLoan from "./Components/Pages/PersonalLoan";
import Box from "@material-ui/core/Box";
// //Page
import LoginPage from "./Components/Pages/LoginPage";
import Register from "./Components/Pages/SignUpPage";
import AboutusPage from "./Components/Pages/AboutUsPage";
import ContactUsPage from "./Components/Pages/ContactUsPage";
import PaymentPage from "./Components/CommonComponents/Payment";
//use context
import UserContext from "./Components/ContextManage/UserContext";
import ContactFrom from "./Components/Pages/ContactForm";

//Analytics and seo
import ReactGA from "react-ga";
import { HeadProvider, Title, Link, Meta } from "react-head";

//theme provide
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//loggedout menu

import LoggedOutMenu from "./Components/Menus/NormalMenu";

import "./App.css";

import BottomNavigation from "./Components/Menus/Botton_Navigation";

import Footer from "./Components/Pages/Footer";
import PersonalLoan from "./Components/Pages/PersonalLoan";
import { Container } from "material-ui-core";

// date picker 

//listent to whenever user move to new page

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const TOKEN = cookie.load("token");
const HOSTNAME = window.location.hostname;

const authtest = () => {
  if (!TOKEN) {
    return <PublicRoutes />;
  } else {
    try {
      jwtdecode(TOKEN);
      return <Redirect to="/app" />;
    } catch (err) {
      return <PublicRoutes />;
    }
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FullName: "",
      ID: "",
      Token: "",
      Email: "",
      Role: null,
      loggedin: false,
      Settings: [],
      settingfecthed: false,
      Theme: "#4caf50",
      CurrentTheme: "dark",
      URL: HOSTNAME,
      GSV: "",
      PADM: "",
      SitetTitle: null,
      GA: null,
      anchorEl: null,
    };
  }

  async componentDidMount() {
    this.fetchsettings();
    await this.checklogin();
    await this.SetTheme();
    //	await this.initgoogleanalytics()
    //UA-133750190-3

    ReactGA.initialize(this.state.GA);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }

  SetTheme = () => {
    var theme = localStorage.getItem("Theme");
    if (!theme) {
      localStorage.setItem("Theme", "light");
      this.setState({ CurrentTheme: "light" });
    } else {
      this.setState({ CurrentTheme: localStorage.getItem("Theme") });
    }
  };

  ToggleDarkMode = () => {
    var LocalTheme = localStorage.getItem("Theme");
    if (LocalTheme === "light") {
      localStorage.setItem("Theme", "dark");
      this.setState({ CurrentTheme: "dark" });
    } else {
      localStorage.setItem("Theme", "light");
      this.setState({ CurrentTheme: "light" });
    }
  };

  async logout() {
    await cookie.remove("token", { path: window.location.host });
    await window.location.reload();
  }

  async checklogin() {
    if (!TOKEN) {
      this.setState({ loggedin: false });
    } else {
      try {
        let data = jwtdecode(TOKEN);

        await this.setState({
          Email: data.Email,
          ID: data.ID,
          Role: data.Role,
          loggedin: true,
          Token: TOKEN,
          FullName: data.Full_Name,
        });
      } catch (err) {
        this.setState({ loggedin: false });
      }
    }
  }

  getTheme(theme) {
    return createMuiTheme({
      palette: {
        type: theme.paletteType,
        primary: {
          main: this.state.Theme,
        },
      },
    });
  }

  async fetchsettings() {
    await fetch("/adminsettings/publicsettings", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
      })
      .then((response) =>
        this.setState({ Settings: response.result, settingfecthed: true })
      )
      .catch((err) => {
        alert("Some Error!", err);
      });

    this.setState({
      Theme: this.state.Settings[2].Value,
      SitetTitle: this.state.Settings[0].Value,
      GSV: this.state.Settings[5].Value,
      PADM: this.state.Settings[6].Value,
      GA: this.state.Settings[7].Value,
    });
  }

  handleClick = (event) => {
    //	setAnchorEl(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    const theme = this.getTheme({ paletteType: this.state.CurrentTheme });
    const { Provider } = UserContext;
    return (
      <HeadProvider>
      
        <Meta name="propeller" content={`${this.state.PADM}`} />
        <Meta name="google-site-verification" content={`${this.state.GSV}`} />

        <Provider
          value={{
            state: this.state,
            ONTOGGLEDARKMODE: this.ToggleDarkMode,
            LOGOUT: this.logout,
          }}
        >
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ minHeight: "90vh" }}>
              {this.state.settingfecthed ? (
                <div>
                  {this.state.loggedin ? (
                    <span></span>
                  ) : (
                    <LoggedOutMenu BlogName="SUNSHUBH LOANS" />
                  )}

                  <Router history={history}>
                    <Switch>
                      <Route path="/app" component={PrivateRoutes} />
                      <Route path="/register" exact component={Register} />
                      <Route path="/about" exact component={AboutusPage} />
                      <Route path="/contact" exact component={ContactUsPage} />
                      <Route path="/login" exact component={LoginPage} />
                      <Route
                        path="/business-loan"
                        exact
                        component={BusinessLoan}
                      />
                      <Route
                        path="/personal-loan"
                        exact
                        component={PersonalLoan}
                      />
                      <Route path="/payment" exact component={PaymentPage} />
                      <Route path="" render={authtest} />
                    </Switch>

                    {this.state.loggedin ? <span></span> : <BottomNavigation />}
                  </Router>
                </div>
              ) : (
                <div style={styles.loadingcontainer}>
                  <img
                    src="/sunshubhlogo.png"
                    id="logoanimation"
                    style={{ width: "100px", height: "100px" }}
                    alt="logo"
                  />
                  <Typography align="center" variant="h6">
                    Loading...
                  </Typography>
                </div>
              )}

              <Fab
                style={{ position: "fixed", bottom: 100, right: 16 }}
                onClick={(event) => this.handleClick(event)}
                color="primary"
                aria-label="add"
              >
                <Icon fontSize="large">support_agent</Icon>
              </Fab>

              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={() => this.setState({ anchorEl: null })}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box m={4} />
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  fontWight="bold"
                >
                  Contact Us
                </Typography>
                <Container maxWidth="xs">
                  <ContactFrom />
                </Container>
              </Popover>
            </div>

            <Footer loggedin={this.state.loggedin} />
          </MuiThemeProvider>
        </Provider>
       
      </HeadProvider>
    );
  }
}

export default App;

const styles = {
  loadingcontainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    textAlign: "center",
  },
};
