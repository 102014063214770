import React, { Component } from 'react';

import {Grid,TextField,Button,Typography,Box,Container,Select,MenuItem,FormControl,InputLabel ,Checkbox} from '@material-ui/core'




import UserContext from '../ContextManage/UserContext'
import * as ApiFunctions from '../../util/apifunction'


const years=["1","2","3","4","5","6","7","8","9","10"]
const date=["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","30","31"]
const SRT = ['CASH','CHEQUE','BANK TRANSFER'];
const CEXP=["1","2","3","4","5","MORE THAN 5"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class PersonalLoanForm extends Component {


    static contextType =UserContext;
    constructor(props){
        super(props);
        this.state={
            fields: {},
            errors: {},  
          Certifictaed:[],
                    ID:null,
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                checked: false
              
               
    
        }
    }


    
    

async componentDidMount(){
   
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })
  
   }
  


   handleCheckboxChange = event =>{
    this.setState({ checked: event.target.checked })
  }
       

  async  handleCert(event){
    await  this.setState({Certifictaed:event.target.value})

     console.log(this.state.Certifictaed)
   }

   

   //handle validation for input fields

   handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
    // validation for differet fields
    if(!fields["Full_Name"]){formIsValid = false;errors["Full_Name"] = "Full Name Can not be empty";}

    if(typeof fields["Full_Name"] !== "undefined"){
      if(!fields["Full_Name"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)){
        formIsValid = false;
        errors["Full_Name"] = "Enter A Valid Name";
      }      	
    }


    if(!fields["Phone"]){formIsValid = false;errors["Phone"] = "Phone Number Can not be empty";}

    if(typeof fields["Phone"] !== "undefined"){
      if(!fields["Phone"].match(/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/)){
        formIsValid = false;
        errors["Phone"] = "Enter A Valid Phone Number";
      }      	
    }


    //email validation

  if(!fields["Email"]){
    formIsValid = false;
    errors["Email"] = "Email Can not be empty";
  }

  if(typeof fields["Email"] !== "undefined"){
    let lastAtPos = fields["Email"].lastIndexOf('@');
    let lastDotPos = fields["Email"].lastIndexOf('.');

    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["Email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["Email"].length - lastDotPos) > 2)) {
      formIsValid = false;
      errors["Email"] = "Email is not valid";
    }
  }


  //Pan

  
  if(!fields["PNo"]){
    formIsValid = false;
    errors["PNo"] = " Pan Card Number Can not be empty";
  }



        
if(typeof fields["PNo"] !== "undefined"){
  if(!fields["PNo"].match( new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/))){
    formIsValid = false;
    errors["PNo"] = "Enter A Valid  Pan Card Number";
  }      	
}


      //Gender

      if(!fields["Gender"]){
        formIsValid = false;
        errors["Gender"] = "Select Your Gender";
      }


      //

      
      if(!fields["BD"]){
        formIsValid = false;
        errors["BD"] = "Select Birth Date.";
      }



      //PinCode
      
      if(!fields["PinCode"]){
        formIsValid = false;
        errors["PinCode"] = "Pincode Can not be empty";
      }
   
      if(typeof fields["PinCode"] !== "undefined"){
        if(!fields["PinCode"].match( new RegExp(/^[1-9][0-9]{5}$/))){
          formIsValid = false;
          errors["PinCode"] = "Enter A Valid  PinCode Number";
        }      	
      }



      //CibilScore cibilscore

      if(!fields["cibilscore"]){
        formIsValid = false;
        errors["cibilscore"] = "Select Cibil Score.";
      }


      //RS


      if(!fields["RS"]){
        formIsValid = false;
        errors["RS"] = "Select Residential Status.";
      }


      //MS
      if(!fields["MS"]){
        formIsValid = false;
        errors["MS"] = "Select Maratial Status.";
      }


      //LoanAmount
      if(!fields["Amount"]){
        formIsValid = false;
        errors["Amount"] = "Enter Loan Amount!";
      }

    
      //Tenure

      if(!fields["Tenure"]){
        formIsValid = false;
        errors["Tenure"] = "Tenure Cant be Empty!";
      }


      //Typelona
      if(!fields["TypeLoan"]){
        formIsValid = false;
        errors["TypeLoan"] = "Select Type of Loan";
      }

        //Typelona
        if(!fields["TypeLoan"]){
          formIsValid = false;
          errors["TypeLoan"] = "Select Type of Loan";
        }


/* 
            //CLE
      if(this.state.Certifictaed.length<=0){
        formIsValid = false;
        errors["Cert"] = "Select one Business Certificate";
      }

 */

        
     /*      //CLE
      if(!fields["CLE"]){
        formIsValid = false;
        errors["CLE"] = "Enter A Value for Any Current Loan Emis! if no Emis Enter 0";
      }

          //CCE
          if(!fields["CCE"]){
            formIsValid = false;
            errors["CCE"] = "Enter A Value for Any Current Credit Card Emis! if no Emis Enter 0";
          }


             //ITR
             if(!fields["ITR"]){
              formIsValid = false;
              errors["ITR"] = "Select A Value for ITR";
            }
 */
               /*  //CA
                if(!fields["CA"]){
                  formIsValid = false;
                  errors["CA"] = "Select A Value for Current Account Status";
                }

 */
                //

                 // validation for differet fields
  /*   if(!fields["BNAME"]){formIsValid = false;errors["BNAME"] = "Bank Name Can not be empty";}

    if(typeof fields["BNAME"] !== "undefined"){
      if(!fields["BNAME"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)){
        formIsValid = false;
        errors["BNAME"] = "Enter A Valid Bank Name";
      }      	
    }
    

      //CA
      if(!fields["OS"]){
        formIsValid = false;
        errors["OS"] = "Select A Value for Office Status";
      }
 */
  

   //Typelona
   if(!fields["SRT"]){
    formIsValid = false;
    errors["SRT"] = "Select salary received Type";
  }

     //Typelona
     if(!fields["CEXP"]){
      formIsValid = false;
      errors["CEXP"] = "Select Current Workinng Experience";
    }


       //Typelona
       if(!fields["SRD"]){
        formIsValid = false;
        errors["SRD"] = "Select Salary received Date";
      }
      

      //Typelona
      if(!fields["SWF"]){
        formIsValid = false;
        errors["SWF"] = "Select Stay with Family Option";
      }
      

    this.setState({errors: errors});
    return formIsValid;
  }



  
  submituserdata(e){
    e.preventDefault();
    
  if(this.handleValidation()){
   
    this.addbusiness()
   
    }else{
      alert("Please Fill The Form")
   }
  
  }

   
   handleChange(field, e){    	
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   console.log(this.state.fields)


   let Certificates=this.state.Certifictaed;
   let Scertifcited=Certificates.toString();
 

   console.log("CERT",Scertifcited);

  }





  async addbusiness(){


   let Certificates=this.state.Certifictaed;
   let Scertifcited=Certificates.toString();
  
     const data={
      Full_Name:this.state.fields["Full_Name"],
      Phone:this.state.fields["Phone"],
      Email:this.state.fields["Email"],
      Pan:this.state.fields["PNo"],
      Pin:this.state.fields["PinCode"],

      Gender:this.state.fields["Gender"],
      DOB:this.state.fields["BD"],
      Cibil:this.state.fields["cibilscore"],
      RS:this.state.fields["RS"],
      MS:this.state.fields["MS"],
      SWF:this.state.fields["SWF"],
      CLE:"N/A",
      CCE:"N/A",
      LoanAmount:this.state.fields["Amount"],
      Tenure:this.state.fields["Tenure"],
      Type_Loan:this.state.fields["TypeLoan"],
      SRT:this.state.fields["SRT"],
      SRD:this.state.fields["SRD"],
      CEXP:this.state.fields["CEXP"]
      
     }
    await fetch('/loan/api/submitpl', {
      method: 'POST', // or 'PUT'
      headers: {
     'Content-Type': 'application/json',
     },
         body: JSON.stringify(data),
       })
       .then((response) => response.json())
       .then((data) => {
          alert("Thank You For Query , our Excutive will contact you Shortly.")
         setTimeout(() => {
            window.location.href="/"
          }, 2000);
       })
       .catch((error) => {
        alert("Something Went Wrong. Please Connect Wih Our Customer Care.")
       });

      }




    render() {
        return (
            <Container maxWidth="lg">
                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <Box m={2}/>
                        <Typography variant="Subtitle1" color="initial" align="center" style={{padding:3,borderRadius:4,border:"1px solid black"}}>I am a Salaried Person</Typography>
                        <Box m={2}/>
                        
              <Typography variant="h5" color="initial"  gutterBottom>Personal Information</Typography>
            
              <Typography variant="Subtitle1" color="initial" align="center" >Please fill all deatils in CAPITAL letter*</Typography>
                        
              <Box m={2}/>
                <form noValidate style={{width:'100%'}} onSubmit= {this.submituserdata.bind(this)} >
           
           
            <Grid container spacing={4}>
               
                    <Grid item xl={6} sm={6} xs={12}>
                
              <TextField
             
                      autoComplete="name"  name="Full_name"
                      variant="filled" required fullWidth
                      ref="Full_name" id="full_name"
                      label="Full Name" autoFocus
                     onChange={this.handleChange.bind(this, "Full_Name")} 
                      value={this.state.fields["Full_Name"] || ''}
                  />
                     <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Full_Name"]}</Typography>




                    </Grid>


                    <Grid item xl={6} sm={6} xs={12}>
                    <TextField
             
             autoComplete="phone"  name="Phone"
             variant="filled" required fullWidth
             ref="Phone" id="phone"
             label="Phone No." 
           onChange={this.handleChange.bind(this, "Phone")} 
            value={this.state.fields["Phone"] || ''}
         />
            <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Phone"]}</Typography>

                    </Grid>


  <Grid item xl={6} sm={6} xs={12}>
                    <TextField
             
             autoComplete="email"  name="Email"
             variant="filled" required fullWidth
             ref="Email" id="email"
             label="Email" 
           onChange={this.handleChange.bind(this, "Email")} 
             value={this.state.fields["Email"] || ''}
         />
            <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Email"]}</Typography>

                    </Grid>




                    <Grid item xl={6} sm={6} xs={12}>
                    <TextField
             
             autoComplete="Pancard"  name="PNo"
             variant="filled" required fullWidth
             ref="PNo" id="pancard"
             label="Pan Card"
           onChange={this.handleChange.bind(this, "PNo")} 
         value={this.state.fields["PNo"] || ''}
         />
            <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["PNo"]}</Typography>

                    </Grid>



                        {/*smaller fields DOB*/}

                        <Grid item lg={4} xl={4} sm={4} xs={12}>
 {/*Gender*/}
                    <FormControl style={{width:"100%"}} variant="filled">
                            <InputLabel id="gender-selector">Gender</InputLabel>
                            <Select
                            labelId="gender-selector"
                            id="gender-selector"
                          value={this.state.fields["Gender"] || ''}
                           onChange={this.handleChange.bind(this, "Gender")}
                            >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                            </Select>
                          </FormControl>
                  
              
                          <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Gender"]}</Typography>

                        </Grid>



                        <Grid item lg={4} xl={4} sm={4} xs={12}>
 {/*DOB*/}

                                 <FormControl style={{width:"100%"}} variant="filled">

                                

                               <TextField
                                            id="date"
                                            label="Birthday"
                                            type="date"
                                            defaultValue="2017-05-24"
                                            value={this.state.fields["BD"] || ''}
                                            onChange={this.handleChange.bind(this, "BD")}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />

                                 </FormControl>
                                 <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["BD"]}</Typography>




                        </Grid>







                        <Grid item lg={4} sm={4} xs={12}>
 {/*PINCODE*/}
 <TextField
             
             autoComplete="pin"  name="PinCode"
             variant="filled" required fullWidth
             ref="PinCode" id="pincode"
             label="Pin Code"
            onChange={this.handleChange.bind(this, "PinCode")} 
         value={this.state.fields["PinCode"] || ''}
         />
            <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["PinCode"]}</Typography>




                        </Grid>



                    {/**Cibil Score , Residential Staus Martial Staus*/}

                      
                      <Grid item sm={4} xs={12}>

                      <FormControl style={{width:"100%"}} variant="filled">
                            <InputLabel id="cibilscore-selector">Cibil Score</InputLabel>
                            <Select
                            labelId="cibilscore-selector"
                            id="cibilscore-selector"
                            onChange={this.handleChange.bind(this, "cibilscore")} 
                            value={this.state.fields["cibilscore"] || ''}  
                            >
                            <MenuItem value="ABOVE 750">ABOVE 750</MenuItem>
                            <MenuItem value="BELOW 650">BELOW 650</MenuItem>
                            <MenuItem value="BETWEEN 650-750">BETWEEN 650-750</MenuItem>
                            <MenuItem value="DON'T KNOW">DON'T KNOW</MenuItem>
                            </Select>
                          </FormControl>
                  
              
                          <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["cibilscore"]}</Typography>


                      </Grid>

                      
                      <Grid item sm={4} xs={12}>

                      <FormControl style={{width:"100%"}} variant="filled">
                            <InputLabel id="residentstatus-selector">Residential Status</InputLabel>
                            <Select
                            labelId="residentstatus-selector"
                            id="residentstatus-selector"
                            onChange={this.handleChange.bind(this, "RS")} 
                            value={this.state.fields["RS"] || ''} 
                            >
                            <MenuItem value="FAMILY OWNED">FAMILY OWNED</MenuItem>
                            <MenuItem value="SELF OWNED">SELF OWNED</MenuItem>
                            <MenuItem value="RENTED">RENTED</MenuItem>
                          
                            </Select>
                          </FormControl>
                  
              
                          <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["RS"]}</Typography>

                        
                      </Grid>

                      <Grid item sm={4} xs={12}>

                      <FormControl style={{width:"100%"}} variant="filled">
                            <InputLabel id="ms-selector">Maritial Status</InputLabel>
                            <Select
                            labelId="ms-selector"
                            id="ms-selectors"
                            onChange={this.handleChange.bind(this, "MS")} 
                            value={this.state.fields["MS"] || ''} 
                            >
                            <MenuItem value="SINGLE">SINGLE</MenuItem>
                            <MenuItem value="MARRIED">MARRIED</MenuItem>
                         
                            </Select>
                          </FormControl>
                  
              
                          <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["MS"]}</Typography>

                        
                    </Grid>


                    <Grid item sm={4} xs={12}>

<FormControl style={{width:"100%"}} variant="filled">
      <InputLabel id="ms-selector">Staying with Family ?</InputLabel>
      <Select
      labelId="ms-selector"
      id="ms-selectors"
      onChange={this.handleChange.bind(this, "SWF")} 
      value={this.state.fields["SWF"] || ''} 
      >
      <MenuItem value="YES">YES</MenuItem>
      <MenuItem value="NO">NO</MenuItem>
   
      </Select>
    </FormControl>


    <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["SWF"]}</Typography>

  
</Grid>

           </Grid>

           <Box m={6}/>

           <Typography variant="h5" color="initial"align="center">Loan Details</Typography>
           <Box m={6}/>

          

          <Grid container spacing={2}>
            

                    <Grid item  sm={6} xs={12}>
                    <TextField
             
             autoComplete="amount"  name="amount"
             variant="filled" required fullWidth
             ref="Amount" id="amount"
             label="Loan Amount"
           onChange={this.handleChange.bind(this, "Amount")} 
            value={this.state.fields["Amount"] || ''}
         />
            <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Amount"]}</Typography>
       </Grid>


                    <Grid item  sm={6} xs={12}>
                    <TextField id="select" label="Desired Tenure  ( in Years )" required 
                    
                    onChange={this.handleChange.bind(this, "Tenure")} 
                    value={this.state.fields["Tenure"] || ''}

                    select variant="filled" fullWidth>
                                {years.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                              ))}
                    </TextField>


                    <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Tenure"]}</Typography>


                    </Grid>

                    <Grid item  sm={6} xs={12}>
                 
                    <FormControl style={{width:"100%"}} variant="filled">
                            <InputLabel id="loan-selector">Type of Loan</InputLabel>
                            <Select
                            labelId="loan-selector"
                            id="loan-selectors"
                            onChange={this.handleChange.bind(this, "TypeLoan")} 
                            value={this.state.fields["TypeLoan"] || ''}
                            >

                              {/*
                              
                              MORTGAGE LOAN
                                    HOME LOAN
                                    WORKING CAPITAL LOAN
                                    INVOICE FINANCE
                                    TOP UPS
                         EDUCATION LOAN
                                    VEHICLE LOAN
                                    GOLD LOAN
                              */}


                              <MenuItem value="HOME LOAN">HOME LOAN</MenuItem>
                              <MenuItem value="PERSONAL LOAN">PERSONAL LOAN</MenuItem>
                              <MenuItem value="MEDICAL EMERGENCY">MORTGAGE LOAN</MenuItem>
                              <MenuItem value="EDUCATION LOAN">EDUCATION LOAN</MenuItem>
                              <MenuItem value="VEHICLE LOAN">VEHICLE LOAN</MenuItem>
                              <MenuItem value="GOLD LOAN">GOLD LOAN</MenuItem>
                              <MenuItem value="OTHERS">OTHERS</MenuItem>

                         
                         
                            </Select>
                          </FormControl>
                  
              
                          <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["TypeLoan"]}</Typography>

                        
                    </Grid>

                


          

          </Grid>

          <Box m={4}/>

<Typography variant="h5" color="initial"align="center">Salary Details</Typography>
<Box m={4}/>

              <Grid container spacing={2}>
                
                {/*GST , ITR ,CURRENT AC*/}

                <Grid item lg={4} xl={4} md={6} sm={6} xs={12}>


                  {/*salr recive dates */}
                  <TextField id="select" label="Salary received Date" required 
                    
                    onChange={this.handleChange.bind(this, "SRD")} 
                    value={this.state.fields["SRD"] || ''}

                    select variant="filled" fullWidth>
                                {date.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                              ))}
                    </TextField>


                    <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["SRD"]}</Typography>




                </Grid>


                
                <Grid item lg={4} xl={4} md={6} sm={6} xs={12}>

                {/*salr recive dates */}
                <TextField id="select" label="Salary received Type Current Working Experience" required 
                    
                    onChange={this.handleChange.bind(this, "SRT")} 
                    value={this.state.fields["SRT"] || ''}

                    select variant="filled" fullWidth>
                                {SRT.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                              ))}
                    </TextField>


                    <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["SRT"]}</Typography>


                </Grid>



                <Grid item lg={4} xl={4} md={6} sm={6} xs={12}>
                                      {/*company experience  */}
         
  {/*salr recive dates */}
  <TextField id="select" label="Current Working Experience" required 
                    
                    onChange={this.handleChange.bind(this, "CEXP")} 
                    value={this.state.fields["CEXP"] || ''}

                    select variant="filled" fullWidth>
                                {CEXP.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                              ))}
                    </TextField>


                    <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["CEXP"]}</Typography>

                </Grid>



              </Grid>

              <br/>
              <div style={{textAlign:"center",margin:0}}>
          <Checkbox
            checked={this.state.checked}
            onChange={this.handleCheckboxChange}
          />
          <span> I authorize SUNSHUBHLOANS and its partners to contact me. T & C Apply* </span>
           </div>
        
        
      <Box m={4} align="center">
              <Button
              align="center"
                  type="submit" 
                  size="large"
                  disabled={!this.state.checked} 
            
                  variant="contained" color="primary" value="submit" id="submit">
                  Submit
                </Button>
                </Box>
           </form>
                
        
        
           </div>
          </Container>
        
        );
    }
}

export default PersonalLoanForm;