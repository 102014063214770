import React from 'react';

import { Container } from '@material-ui/core'
import { Box, Paper, Typography } from 'material-ui-core'
import { LinearProgress, Button, Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { TableCell,TableBody,TableRow,Table,TableHead,TableContainer } from '@material-ui/core';


import UserContext from '../ContextManage/UserContext';




import * as ApiFunctions from '../../util/apifunction'

import AddRef from './AddRef';

export default function YourReference() {



    const User = React.useContext(UserContext);

    const [Ref, SetRef] = React.useState({});
    const [Loading, SetLoading] = React.useState(true);
    const [ShowAddRef,SetShowAddRef]=React.useState(false);


    React.useEffect(() => {
        GetUserRef();
    }, [])


    const handleClickOpen = () => {
            SetShowAddRef(true);
      };
    
      const handleClose = () => {
        SetShowAddRef(false);
      };


    const GetUserRef = async () => {

        const header = {
            'Content-Type': 'application/json',
            'x-access-token': User.state.Token

        };
        try {
            const Data = await ApiFunctions.GetApiRequest(`/ref/customer/ref`, header);
            if (Data.Status === 200) {
                SetRef(Data.Data);
                SetLoading(false);
            } else {
                alert('Cant Get User References --', Data.Status);
            }
        } catch (err) {
            alert('Internal Server Error - 500 Ok');
        }
    }

    return (
        <Container maxWidth="lg">
            <Box m={4} />



                <Grid container spacing={2}>

                    <Grid items lg={6} md={6}>
                    <Typography variant="h3" component="div" gutterBottom>
                Your Reference
            </Typography>
                    </Grid>

                            <Grid itesm lg={6} md={6}>

                                        <Button  onClick={handleClickOpen} disableElevation style={{float:'right'}} variant="contained" color="primary">
                                          Add Reference
                                        </Button>
                                
                                
                                </Grid>

                </Grid>


            <Box m={8} />
            {
                (Loading) ? (
                    <>
                        <Box m={6} />
                        <LinearProgress />
                    </>
                ) : (
                    <>
                        {
                            (Ref.count === 0) ? (
                                <Alert>
                                    <AlertTitle>info</AlertTitle>
                                         <Typography variant="h6" color="initial">

                                         You Have No References -- Add New References By Clicking 'Add Reference' Button.

                                         </Typography>

                                            <Box m={4}/>
                                         <Button onClick={handleClickOpen}  variant="outlined" color="default">
                                           Add Reference
                                         </Button>
                                </Alert>
                            ) : (
                                <>
                                     <TableContainer style={{ boxShadow:'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="left">Reference ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Relation</TableCell>
            <TableCell align="left">Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Ref.rows.map((row) => (
            <TableRow
              key={row.ID}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                              <TableCell align="lef">{row.ID}</TableCell>

              <TableCell align="lef">{row.Name}</TableCell>
              <TableCell align="left">{row.Phone}</TableCell>
              <TableCell align="left">{row.Relation}</TableCell>
              <TableCell align="left">{new Date(row.Created_At).toString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                                </>


                            )


                        }

                    </>

                )
            }


   <AddRef
        open={ShowAddRef}
        onClose={handleClose}
      />

        </Container>
    )
}
