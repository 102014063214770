import React, { Component } from 'react';
import { Container, Typography, Box, Paper } from '@material-ui/core'
class Payment extends Component {
    render() {
        return (



            <Container maxWidth="lg">
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 50
                }}>
                    <Typography variant="h4" alignCenter color="initial">Payment</Typography>
                </div>
            </Container>



        );
    }
}


/** 
 *   <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 50
                    }}>
                    
                        <img src='/IMG_20220708_124753.jpg' style={{ width: "60%", height: "60%" }} alt="barcode" />

                        <Box m={2}></Box>

                        <Typography variant="subtitle1" color="secodnary">
                            UPI ID
                        </Typography>

                        <Typography variant="subtitle1" color="secodnary">  
                            <a href="upi://pay?pa=sunshubhsolutions@icici&amp;pn=Sunshubh Loans Pvt. Ltd.&amp;cu=INR" class="upi-pay1">sunshubhsolutions@icici</a>
                        </Typography>

                        <Box m={2}></Box>

                        <Typography variant="h6" color="initial" fontWeight={700}><u>Step to Pay</u></Typography>

                        <Box m={1}></Box>

                        <Typography variant="subtitle1" color="initial">

                        Open UPI/BQR/Google pay/phone pay/ app > Select Scan to pay > Scan QR Code > Enter the Amount

                        </Typography>

                        <Box m={2}></Box>

                        <Typography variant="h6" color="initial" >

                            <span style={{color:'red'}}>Note:-</span> If the QR limit shows 2000/- only, please send the amount in parts
                        </Typography>

                        <Box m={2}></Box>
                        <Typography variant="h6" color="initial" align='left' fontWeight={700}>
                            Account Details
                        </Typography>


                        <table style={{fontSize:'18px',width:'100%',border:'1px solid'}}>
                            <tbody>
                                <tr style={{border:'1px solid'}}>
                                    <td style={{border:'1px solid'}} >Account Holder's Name</td>
                                    <td style={{border:'1px solid'}} >SUNSHUBH SOLUTIONS PRIVATE LIMITED</td>
                                </tr>
                                <tr>
                                    <td style={{border:'1px solid'}}>Account Number </td>
                                    <td style={{border:'1px solid'}}>768605000523</td>
                                </tr>
                                <tr>
                                    <td style={{border:'1px solid'}}>IFSC Code</td>
                                    <td style={{border:'1px solid'}}>ICIC0007686</td>
                                </tr>
                            </tbody>
                        </table>

                        <Box m={4}></Box>


                        <Typography variant="subtitle2" color="initial">


                            For any Query please contact us or mail us at company@sunshubhloans.com
                            Details related to your loan EMI please visit our App or Website My profile All Loans
                        </Typography>

                        <Box m={2}></Box>

                    </div>
 * 
 * 
*/

export default Payment;