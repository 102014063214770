import React, { Component } from 'react';
import {Card,TableHead,Table,TableContainer,TableRow,TableCell,TableBody,IconButton,} from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/DeleteOutline'

import * as ApiFunctions from '../../util/apifunction'

import UserContext from '../ContextManage/UserContext'

class LoanDealysCharges extends Component {
  static contextType =UserContext;


  
  constructor(props){
    super(props);
    this.state={
      ID:null,
      Email:null,
      Role:null,
      Token:null,
      isloggedin:false,
    }
  }


  async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

   }






  async deleteemi(eid){

    const header={
      'Content-Type': 'application/json',
      'x-access-token':this.state.Token
     };
  

  
     try{
      const Data = await ApiFunctions.DeleteApiData(`/emi/${eid}`,header)
   
     if( Data.Status===200){
         alert("Delay Charges Deleted Successfully!")  
          window.location.href=window.location
    
  
     }
     if( Data.Status!==200){
      alert("Can't Delete Emi Right Now!")      
  }
   
    }catch(err){
      console.log(err)
   }
  
  }

    render() {
        return (
           <React.Fragment>

       
      <Card style={{marginBottom:10}}>
  
          {(this.props.emis.length<=0)  ? (


              <Alert severity="info">
                <AlertTitle>Delay Charges</AlertTitle>
                No Dealy Charges — <strong>To Add Delay Charges Click on "Add Delay Charges " Button.</strong>
              </Alert>

      
            ) : (

              <React.Fragment>
<TableContainer >
              <Table  aria-label="simple table">
                <TableHead>
                  <TableRow>
                    
                    <TableCell align="left">Fine ID</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Paid At</TableCell>
       
                  </TableRow>
                </TableHead>
            <TableBody>
            {this.props.emis.map((data) => (

              <React.Fragment>
                {(data.Type==="DELAY")  ? (


<TableRow key={data.ID}>
<TableCell align="left">{data.ID}</TableCell>

<TableCell align="left">{data.Outstanding}</TableCell>
<TableCell align="left">{new Date(data.Created_At).toString()}</TableCell>




<TableCell align="left">
                    {(this.state.Role==="Admin") ? (  <IconButton aria-label="delete" 
                onClick={()=>this.deleteemi(data.ID)}
                >
                <EditIcon />
                </IconButton>
) : (<span></span>) }
                

              

</TableCell>



</TableRow>
              


                ) : (
        
                  <span></span>
        



                )   }

              </React.Fragment>


            ))}
          </TableBody>
          
       </Table>
     </TableContainer>
              </React.Fragment>

              
          )}
                     </Card>
                     </React.Fragment>
        );
    }
}


export default LoanDealysCharges;

