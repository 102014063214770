import React, { Component } from 'react';
import {Container,Card, Typography,Table,TableBody,TableCell,TableHead,TableContainer,TableRow,Box} from  '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton';

import IconButton from '@material-ui/core/IconButton';
import Description from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import * as ApiFunctions from '../../util/apifunction'
import UserContext from '../ContextManage/UserContext'

class YourLoans extends Component {
  static contextType =UserContext;

  constructor(props){

    super()
    this.state={
      isloading:true,
      Data:[],
      isError:false,
      PageNumber:1,
      TotalPage:0,
      TotalPost:0,
      PerPage:0,
      ID:null,
      Email:null,
      Role:null,
      Token:null,
      isloggedin:false,
      isError:false
  
    }
  }



  

  async componentDidMount(){
  
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

     if(this.state.isloading){
      this.FetchAllLoan()
     }else{
       window.location.href="/"
     }
     
   }
 
  async FetchAllLoan(){
    const header={
      'Content-Type': 'application/json',
     'x-access-token':this.state.Token
     };
     try{
      const Data = await ApiFunctions.GetApiRequest(`/loan/customer/allloans/`,header)
  
     
      if(Data.Status===200){
            this.setState({
              Data:Data.Data,
              isloading:false,
              TotalPost:Data.TotalPost,
              PerPage:Data.PerPage,
              TotalPage:Data.Totalpages
            })
    
          }else{
          this.setState({isError:true})   
      }
    }catch(err){
      this.setState({isError:true})
      }
    }

  async handlechange(event,pagenumber){
    
   await this.setState({PageNumber:pagenumber})
     await  this.FetchAllLoan() 
     
  }



    render() {
        return (

          <Container maxWidth="xl">
            <Box m={4}></Box>
            <Typography variant="h3" color="initial" gutterBottom>Your Loans</Typography>

     


        {(this.state.isloading) ? 
        
        (

      
          <Skeleton variant="rect" width="100%" height={600} />


        ) : (

            <Card>
            <TableContainer >
            <Table  aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell align="left">Oprations</TableCell>
                
                  <TableCell align="left">Loan ID</TableCell>
                
                 
                  <TableCell align="left">Occupation</TableCell>
                  <TableCell align="left">Residential Status</TableCell>
             
      
                  <TableCell align="left">Loan Amount</TableCell>
                  <TableCell align="left">Tenure</TableCell>
                  <TableCell align="left">Total Interest</TableCell>
                  <TableCell align="left">Agreement Signed</TableCell>
              
                  <TableCell align="left">Created At</TableCell>
      
                  <TableCell align="left">Status</TableCell>
              
      
                  
      
      
      
      
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.Data.map((data) => (
                  <TableRow key={data.ID}>
                           <TableCell align="left">
              <IconButton  aria-label="edit" onClick={()=>this.props.history.push(`/app/user-loan-details/${data.ID}`)}>
                <EditIcon />
              </IconButton>
              <IconButton  aria-label="edit" onClick={()=>this.props.history.push(`/app/agreement/${data.ID}`)}>
                <Description />
              </IconButton>
          
      
                    </TableCell>
                        <TableCell align="left">{data.ID}</TableCell>
                   
             
                    <TableCell align="left">{data.Occupation}</TableCell>
                    <TableCell align="left">{data.Resident_Status}</TableCell>
                  
                    <TableCell align="left">{data.Amount}</TableCell>
                    <TableCell align="left">{data.Tenure}</TableCell>
                    <TableCell align="left">{data.Total_Interest}</TableCell>
                    <TableCell align="left">{data.Agreement_Signed}</TableCell>
                    
                    
                   
                    <TableCell align="left">{data.Created_At}</TableCell>
                    <TableCell align="left">{data.Status}</TableCell>
                
                  
                  
             
                  
                    <TableCell align="left">{(data.Status==="Sanction") ?  (<span style={{width:15,height:15,borderRadius:"50%",backgroundColor:"#4cbb17",display:"block"}}></span>) : (<span style={{width:15,height:15,borderRadius:"50%",backgroundColor:"red",display:"block"}}></span> )}</TableCell>
      
      
      
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
     

      <Box m={4}></Box>
{/*
<Pagination  variant="outlined" color="primary"  count={this.state.TotalPage} page={this.state.PageNumber} onChange={this.handlechange.bind(this)} />


*/}



<Box m={4}></Box>
</Card>
        )
        
        
        
        }





              
                    </Container>
        );
    }
}

export default YourLoans;

