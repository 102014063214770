import React, { Component } from 'react'




//mui imports 
import {Button , Typography , TextField,Box,FormControl,
    InputLabel,Select,Paper,Avatar,DialogContent,DialogTitle,
    Dialog,DialogActions,MenuItem, IconButton,InputAdornment,OutlinedInput
    }  from '@material-ui/core'
    import { Done, SendOutlined } from '@material-ui/icons';

    import UserContext from '../ContextManage/UserContext'
//functional imports
import * as ApiFunctions from '../../util/apifunction'


export default class AddRefrence extends Component {
    static contextType =UserContext;
 
    constructor(props){
        super(props);
        this.state={ 
          ID:null,
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                RefFields:{},
                RefErrors:{},
                RefModelOpen:false   
        }
    }
  


    
  async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

   }
     
handleGurantorInputChange(field, e){    		
    let RefFields = this.state.RefFields;
    RefFields[field] = e.target.value;        
    this.setState({RefFields});
    console.log('GaurantoField',RefFields)
  }


   componentWillReceiveProps(props) {
   
    this.setState({ RefModelOpen: this.props.RefModelOpen })
  }

  
  redirect=(time,link)=>{
    setTimeout(function(){ window.location.href=`${link}` }, time);
  }

AddRefrence=async()=>{
    const header={
      'Content-Type': 'application/json',
      'x-access-token':this.state.Token
     };
  
     const data={
      "Name":this.state.RefFields["Name"],
      "Phone":this.state.RefFields["Phone"],
      "Relation":this.state.RefFields["Relation"],
      "User_ID":this.props.UID
  }
  
     try{
      const Data = await ApiFunctions.PostApiData(`/ref/admin`,header,data)
  
      if(Data.Status===200){
       this.redirect(1000,`/app/edit-user/${this.props.UID}`);
      }else{
        console.log(Data)
        alert('Cant Add Refrence');
      }
  
    }catch(err){
      console.log(err);
   }
  }
  
  toggleMenu() {
    this.setState({
      RefModelOpen: !this.state.RefModelOpen
    })
  }


  render() {
    return (
        <Dialog
        
        fullWidth
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={this.state.RefModelOpen}
      >
        <DialogTitle id="confirmation-dialog-title">Add Refrence </DialogTitle>
        <DialogContent dividers>

        <form noValidate style={{width:'100%',marginTop:0}}>

        <TextField label="Name" margin="normal" variant="outlined"
                                value={this.state.RefFields["Name"] || ''} 
                                style={{width:"100%"}}
                            
                                onChange={this.handleGurantorInputChange.bind(this, "Name")} 
                                />


<Box m={3}/>



<FormControl style={{width:'100%'}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Phone Number</InputLabel>
<OutlinedInput label="Phone" margin="normal" variant="outlined" type="number"
                                value={this.state.RefFields["Phone"] || ''} 
                                style={{width:"100%"}}
                                onChange={this.handleGurantorInputChange.bind(this, "Phone")} 
                                
                                endAdornment={
                                  <InputAdornment position="end">
                                        {
                                          (this.state.RefFields["PhoneVerified"]==="NO")?(
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>this.SendGuarantorOTPVerification(this.state.RefFields["Phone"])}
                                            edge="end"
                                          >
                                          <SendOutlined/>
                                          </IconButton>
                                          ):(
                                                <></>
                                          )
                                        }
                                  </InputAdornment>
                                }
                                />
                                </FormControl>

<Box m={3}/>



                    <Box m={3}/>

                  <FormControl style={{width:'100%'}}>
                    <InputLabel htmlFor="Relation">Relation</InputLabel>
                    <Select
                      
                      value={this.state.RefFields["Relation"] || ''}
                      onChange={this.handleGurantorInputChange.bind(this, "Relation")}
                      label="Relation"
                      inputProps={{
                        name: 'Relation',
                        id: 'select_Relation',
                      }}
                    >
                      <MenuItem  value="Friend">Friend</MenuItem >
                      <MenuItem  value="Family">Family</MenuItem >
                      <MenuItem  value="Colleague">Colleague</MenuItem>
                    </Select>
                  </FormControl>
                  <Box m={3}/>

        </form>
        </DialogContent>
        <DialogActions>

        <Button  color="primary" variant='outlined' onClick={()=>this.AddRefrence()}>
            SAVE
          </Button>
        <Button  onClick={()=>this.props.toggleRefMenu()}  color="primary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
