
export async function GetApiRequest(url,header){
    const options={
        method:"GET",
        headers:header
    };
    const response=await fetch(url,options);

    if(response.status===200){
   
        return response.json()
    }

    if (response.status !== 200) {
        return generateErrorResponse('The server responded with an unexpected status.',response.status);
      }
    
}


export async function PostApiData(url,header,data){
    const options={
        method:"POST",
        headers:header,
        body:JSON.stringify(data)
    };

    let response;
   

         response=await fetch(url,options);

        if(response.status===200){
        
            return response.json();
        }

        
        if(response.status!==200){
            return generateErrorResponse('The server responded with an unexpected status.',response.status,
            response);
          
        }
    
}


export async function UpdateApiData(url,header,data){
    const options={
        method:"PATCH",
        headers:header,
        body:JSON.stringify(data)
    };

    let response;
   

         response=await fetch(url,options);

        if(response.status===200){
        
            return response.json();
        }

        
        if(response.status!==200){
            return generateErrorResponse('The server responded with an unexpected status.',response.status,response);
          
        }
    
}





export async function DeleteApiData(url,header){
    const options={
        method:"DELETE",
        headers:header
    };

    let response;
   

         response=await fetch(url,options);

        if(response.status===200){
        
            return response.json();
        }

        
        if(response.status!==200){
            return generateErrorResponse('The server responded with an unexpected status.',response.status,response);
          
        }
    
}








function generateErrorResponse(message,scode,data) {
    return {
      status : 'error',
      message,
      statuscode:scode,
      Data:data.json()
    };
  }
