import React, { Component } from 'react';


import {Container,Button,Typography,Box,TextField,Grid} from '@material-ui/core'
import ScrollAnimation from 'react-animate-on-scroll'

import ContactForm from './ContactForm'
import UserContext from '../ContextManage/UserContext'


class ContactUsPage extends Component {

    static contextType =UserContext;


    constructor(props){
        super(props);
        this.state={
          fields: {},
          errors: {},    
        }
    }


    

        
handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
    // title
    if(!fields["fname"]){
      formIsValid = false;
      errors["fname"] = "FIrst Name Cannot be empty";
    }

    if(typeof fields["fname"] !== "undefined"){
      if(!fields["fname"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)){
        formIsValid = false;
        errors["fname"] = "Enter A Valid First Name";
      }      	
    }



      if(!fields["lname"]){
        formIsValid = false;
        errors["lname"] = "Last Name Cant Not Be Empty";
      }


      if(typeof fields["lname"] !== "undefined"){
        if(!fields["lname"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)){
          formIsValid = false;
          errors["lname"] = "Enter A Valid Last Name";
        }      	
      }

      if(!fields["email"]){
        formIsValid = false;
        errors["email"] = "Email Can not be empty";
      }

      if(typeof fields["email"] !== "undefined"){
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');
    
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
      }
    

      if(!fields["phone"]){
        formIsValid = false;
        errors["phone"] = "Phone No Can not be empty";
      }



      

      if(typeof fields["phone"] !== "undefined"){
        if(!fields["phone"].match(/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/)){
          formIsValid = false;
          errors["phone"] = "Enter A Valid Phone Number";
        }      	
      }
     


      
      if(!fields["describe"]){
        formIsValid = false;
        errors["describe"] = "Description Can't be Empty";
      }




   
   
  
    this.setState({errors: errors});
    return formIsValid;
  }


  
  submituserdata(e){
    e.preventDefault();
     

  
  if(this.handleValidation()){
    this.sendcontact()
    }else{
      alert("Please Fill The Form")
   }
  
  }


     
  handleChange(field, e){    		
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   
  }




  sendcontact(){
    const data = { 
      fname: this.state.fields["fname"] ,
      lname: this.state.fields["lname"],
      email: this.state.fields["email"],
      phoneno: this.state.fields["phone"],
        description: this.state.fields["describe"]
     };
     fetch('/user/cutomerquery', {
      method: 'POST', // or 'PUT'
      headers: {
     'Content-Type': 'application/json',
     },
         body: JSON.stringify(data),
       })
       .then((response) => response.json())
       .then((data) => {
          alert("Thank You For Query , our Excutive will contact you Shortly.")
          setTimeout(() => {
            window.location.href="/"
          }, 1000);
       })
       .catch((error) => {
        alert("Something Went Wrong. Please Connect Wih Our Customer Care.")
       });
 }


    render() {
        return (
            <Container maxWidth="sm">
            <div style={{ display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:50}}>
    <Typography  variant="subtitle1" align="center" color="textSecondary" gutterBottom>
         NEED HELP ?
            </Typography>
            <Box m={2} align="center"/>
            <Typography component="h5" variant="h5" align="center" color="textPrimary" gutterBottom>
         Contact Sunshubh Loans
            </Typography>
            <Box m={2} align="center"/>
<Iframe iframe={demos["soundcloud"]} allow="autoplay" />


<ContactForm/>
</div>
            </Container>
        );
    }
}

const demos = {
    soundcloud:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.8573099800406!2d72.85688086532534!3d19.41857023689226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a8da40ce2c0f%3A0xe2daeb8b31a076c9!2sCluster_vasai_21%2C%20Golani%20Naka%2C%20Vasai%20East%2C%20Vasai-Virar%2C%20Maharashtra%20401208!5e0!3m2!1sen!2sin!4v1613639434846!5m2!1sen!2sin" width="100% height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  
    plotly:
      '<iframe src="https://codesandbox.io/embed/q7jmjyplvq?fontsize=14" title="Plotly All Graph Types" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>'
  };
  

function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }


export default ContactUsPage;

