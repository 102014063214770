import React, { Component } from 'react';
import {Button , OutlinedInput,Typography ,Avatar,Link, TextField,Box,
  IconButton,Grid,Container,FormControl,InputLabel}  from '@material-ui/core'


import UserContext from '../ContextManage/UserContext'
import InputAdornment from '@material-ui/core/InputAdornment';

import * as ApiFunctions from '../../util/apifunction'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {withRouter} from "react-router-dom";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import cookie from 'react-cookies'





class LoginPage extends Component {
    static contextType =UserContext;


    constructor(props){
        super(props);
        this.state={
          fields: {},
          errors: {},    
          loginsuccess:null,
          ID:null,
     
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                showpassword:false
               

        }
    }



    async componentDidMount(){
      
        const UserContextData = this.context
        await this.setState({
    
          ID:UserContextData.state.ID,
          Email:UserContextData.state.Email,
          isloggedin:UserContextData.state.loggedin,
          Token:UserContextData.state.Token,
          Role:UserContextData.state.Role
         })
   
        if(this.state.isloggedin){
          window.location.href="/"
        }
         

       }
     
    



        
handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
    // title
    if(!fields["email"]){
      formIsValid = false;
      errors["email"] = "Email Can't be Empty!";
    }


      if(!fields["password"]){
        formIsValid = false;
        errors["password"] = "Password Cant Be Empty!";
      }

   
  
    this.setState({errors: errors});
    return formIsValid;
  }


  
  submituserdata(e){
   e.preventDefault();
  if(this.handleValidation()){
  
      this.login()
  
    
    }else{
      alert("Please Fill All Required Fields.")
   }
  
  }

   
  handleChange(field, e){    		
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   
  }






   async login(){

    const header={
      'Content-Type': 'application/json'
     // 'x-access-token':cookie.load("token")
     };
     const data={
      email:this.state.fields["email"],
      password:this.state.fields["password"],
     
     }
     try{
      const Data = await ApiFunctions.PostApiData(`/user/login`,header,data)
  
     if(Data.Status===200)
     {
      cookie.remove('token', { path: window.location.host })
     await  cookie.save('token', Data.data.Access_Token, { path: window.location.host })
      this.setState({loginsuccess:true})
      window.location.href="/"
     }



     if( Data.Status!==200){
          this.setState({loginsuccess:false})
          alert("Email Or Pasword is Missing!")
    }
  
    }catch(err){
      this.setState({isError:true})
      }
    }

    render() {
        return (
            <Container component="main" maxWidth="xs">
            <div style={styles.root}>
         
               <Avatar style={styles.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
              <Typography component="h1" variant="h5">
                    Sign in
            </Typography>
              <form noValidate style={{width:'100%',marginTop:24}}  onSubmit= {this.submituserdata.bind(this)}  >
                    {/* emails */}
                    <TextField
                      autoComplete="email"  name="email"
                      variant="outlined" required fullWidth
                      ref="email" id="email"
                      label="Email" 
                      onChange={this.handleChange.bind(this, "email")} 
                      value={this.state.fields["email"] || ''}
                  />
                     <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["email"]}</Typography>
               

                        <FormControl variant="outlined" style={{width:'100%'}} >
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <OutlinedInput
                            fullWidth
                                id="standard-adornment-password"
                                type={this.state.showpassword ? 'text' : 'password'}
                                onChange={this.handleChange.bind(this, "password")} 
                                value={this.state.fields["password"] || ''}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>{
                                    this.setState({showpassword:!this.state.showpassword})
                                        
                                    }}
                                    onMouseDown={(event)=>{
                                        event.preventDefault()
                                    }}
                                    >
                                    {this.state.showpassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                labelWidth={70}
                            />
                     <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["password"]}</Typography>

                                </FormControl>
               

               
               
               
               
                                <Box m={3} align="center">    </Box>

           <Grid container>
            <Grid item xs>
              <Link href="https://sunshubhloans.com/user/auth/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link onClick={()=>this.props.history.push('/register')} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>



              {/* ending of module 5 */}



      <Box m={3} align="center">    </Box>
              <Button
              fullWidth
              align="center"
                  type="submit" 
                  
                  variant="contained" color="primary" value="submit" id="submit">
                  SIGN IN
                </Button>
            
              </form>
              <br/>
           
    
              
     
             </div>

             </Container>
            
        );
    }
}

export default withRouter(LoginPage);



const styles={
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop:100
    },
    avatar:{
        margin: 8,
        backgroundColor:"skublue"
    }
  }