import React, { Component } from 'react';
import {
  Button, Typography, TextField, Box, Grid, Container, InputLabel,

  FormControl, MenuItem, Select, Paper, Dialog, IconButton, DialogContent, DialogContentText, DialogTitle, Slide
} from '@material-ui/core'



import { Alert, AlertTitle } from '@material-ui/lab';

import SaveIcon from '@material-ui/icons/Save'

import UserContext from '../ContextManage/UserContext'
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


import * as ApiFunctions from '../../util/apifunction'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddNewLoan extends Component {
  static contextType = UserContext;


  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      ID: null,
      Email: null,
      Role: null,
      Token: null,
      isloggedin: false,
      Geoon: false,
      Geocord: '',
      ErrorDialog: false,
      inputFields: [{ Name: "", Phone: "", Email: "", Relation: "",Age:21,Address:"" }],
      LoanAmount: [],
      LoanTenure: [],
      LoanRoi:4,


    }

  }

  async componentDidMount() {
    await this.geocheck()
    const UserContextData = this.context
    await this.setState({
      ID: UserContextData.state.ID,
      Email: UserContextData.state.Email,
      isloggedin: UserContextData.state.loggedin,
      Token: UserContextData.state.Token,
      Role: UserContextData.state.Role
    })

    await this.checkprofile()

    await this.GetLoanProfile()
  }




  async GetLoanProfile() {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };


    try {
      const Data = await ApiFunctions.GetApiRequest(`/loan/customer/loan-profile`, header)
      this.setState({
        LoanAmount: JSON.parse(Data.profile["LoanAmount"]),

        LoanTenure: JSON.parse(Data.profile["LoanTenure"]),

        LoanRoi:Data.profile["LoanRoi"]
      });

    } catch (err) {
      this.setState({ isError: true })
    }

    console.log(this.state.LoanRoi);

  }

  async checkprofile() {




    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };

    try {
      const Data = await ApiFunctions.GetApiRequest(`/user/customer/profile`, header)

      if (Data.Status === 200) {

        let completeprofile = true;

        if (Data.Data.Alt_No === "") {
          completeprofile = false
        }


        if (Data.Data.Address === "") {
          completeprofile = false
        }


        if (Data.Data.BankIfsc === "") {
          completeprofile = false
        }


        if (Data.Data.Father_Name === "") {
          completeprofile = false
        }


        if (Data.Data.Ofc_Name === "") {
          completeprofile = false
        }

        if (Data.Data.PNo === "") {
          completeprofile = false
        }

        if (Data.Data.ANo === "") {
          completeprofile = false
        }


        if (Data.Data.PinCode === "") {
          completeprofile = false
        }

        if (!completeprofile) {
          this.setState({ ErrorDialog: true })

          setTimeout(() => {
            this.props.history.push('/app/users-profile')
          }, 3000);

        }


      }


      else {
        alert("Cant find profile ")
      }


    } catch (err) {
      this.setState({ isError: true })
    }

  }




  geocheck() {
    if (!navigator.geolocation) {
      console.log("errror in accesssing the geolocation")

    } else {

      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      navigator.geolocation.getCurrentPosition(this.success, this.error, options);

    }
  }


  success = (pos) => {
    var crd = pos.coords;
    this.setState({ Geoon: true, Geocord: `Latitude:-${crd.latitude} ,Longitude:- ${crd.longitude}` })
  }

  error = (err) => {
    alert("Enable Location to fill the Form!")

  }



  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;



    if (!fields["Due_AAL"]) {
      formIsValid = false;
      errors["Due_AAL"] = "Due Amount Any Loan Can not be empty";
    }


    if (!fields["Loan_Purpose"]) {
      formIsValid = false;
      errors["Loan_Purpose"] = "Please Select Loan Purpose";
    }

    if (!fields["DCC"]) {
      formIsValid = false;
      errors["DCC"] = "Enter Amount for Any Credit Card Due";
    }

    if (!fields["Amount_Loan"]) {
      formIsValid = false;
      errors["Amount_Loan"] = "Select Loan Amount";
    }


    if (!fields["Occupation"]) {
      formIsValid = false;
      errors["Occupation"] = "Occupation Can not be empty";
    }

    if (!fields["Tenure"]) {
      formIsValid = false;
      errors["Tenure"] = "Tenure of Loan Can not be empty";
    }


    if (!fields["Pay_Day"]) {
      formIsValid = false;
      errors["Pay_Day"] = "Select Emi Date";
    }


    if (!fields["Resident_Status"]) {
      formIsValid = false;
      errors["Resident_Status"] = "Resident Status Can not be empty";
    }

    if (!fields["Stay_With_Family"]) {
      formIsValid = false;
      errors["Stay_With_Family"] = " Stay With Family Can not be empty";
    }

    if (!fields["Income"]) {
      formIsValid = false;
      errors["Income"] = "Select Your Income";
    }


    if (!fields["Numemembers"]) {
      formIsValid = false;
      errors["Numemembers"] = "Select Number of Earning Members";
    }


    if (!fields["Numfmembers"]) {
      formIsValid = false;
      errors["Numfmembers"] = "Select Number of Family Members";
    }


    if (!fields["D_14"]) {
      formIsValid = false;
      errors["D_14"] = "Default Loan After 2014 ?";
    }

    if (!fields["Cibil_Score"]) {
      formIsValid = false;
      errors["Cibil_score"] = "Select Your Cibil Score";
    }

    if (!fields["Loan_Purpose"]) {
      formIsValid = false;
      errors["Loan_Purpose"] = "Loan Purpose Can not be empty";
    }



    this.setState({ errors: errors });
    return formIsValid;
  }



  submituserdata(e) {
    e.preventDefault();



    if (this.handleValidation()) {

      if (this.checkgdetails()) {
        this.adduserloan()
      }else{
        alert('Please Enter Gaurantor Details')
      }


    } else {
      alert("Please Complete Loan Application")
    }

  }


  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });


  }






  async adduserloan() {

    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };

    const data = {

      amount: this.state.fields["Amount_Loan"],
      tenure: this.state.fields["Tenure"],
      EmiDate: this.state.fields["Pay_Day"],
      rs: this.state.fields["Resident_Status"],
      swf: this.state.fields["Stay_With_Family"],
      occupation: this.state.fields["Occupation"],
      income: `${this.state.fields["Income"]}`,
      ne: this.state.fields["Numemembers"],
      nf: this.state.fields["Numfmembers"],
      daloan: this.state.fields["Due_AAL"],
      dcc: this.state.fields["DCC"],
      d14: this.state.fields["D_14"],
      Purpose: this.state.fields["Loan_Purpose"],
      location: this.state.Geocord,
      Status: "InProcess",
      Cibil_Score: this.state.fields["Cibil_Score"],
      //  guarantor: this.state.inputFields,
      Gurantor: this.state.inputFields,
      LoanRoi:this.state.LoanRoi
    }


    try {

      const Data = await ApiFunctions.PostApiData(`/loan/customer/applyloan`, header, data)
        console.log('Loan Success Data',Data)
      if (Data.Status === 200) {
        alert("Successfully Applied for Loan")
         this.props.history.push(`/app/agreement/${Data.Loan.Loan.ID}`)

         // window.location.href = `/app/agreement/${Data.Loan.ID}`

      }


      if (Data.statuscode === 424) {
        alert("Cant Apply for new Loan , One Loan Found Active")

        window.location.href = `/app/user-loans`

      }
      if (Data.statuscode === 422) {

        this.setState({ ErrorDialog: true })
        setTimeout(() => {
          this.setState({ ErrorDialog: false })

          window.location.href = "/app/users-profile"
        }, 5000);

      }




    } catch (err) {
      console.log('Loan Error',err)
      alert("Internal Server Error")
      this.setState({ isError: true })
    }

  }



  addguarantee() {
    const values = [...this.state.inputFields];
    values.push({ Name: '', Phone: '', Email: '', Relation: '',Age:21,Address:'' });
    this.setState({ inputFields: values });

  }

  handleRemoveFields(index) {
    if (index === 0) {
      alert("Please Add At Least One Gaurantee Name and Phone")
    } else {
      const values = [...this.state.inputFields];
      values.splice(index, 1);
      this.setState({ inputFields: values })
    }

  };


  handleInputChange(index, event) {
    const values = [...this.state.inputFields];
    if (event.target.name === "Name") {
      values[index].Name = event.target.value;
    } else if (event.target.name === "Phone") {
      values[index].Phone = event.target.value;
    }
    else if (event.target.name === "Email") {
      values[index].Email = event.target.value;
    }
    else if (event.target.name === "Relation") {
      values[index].Relation = event.target.value;
    }
    else if (event.target.name === "Age") {
      values[index].Age = event.target.value;
    }
    else if (event.target.name === "Address") {
      values[index].Address = event.target.value;
    }





    this.setState({ inputFields: values })

    //console.log(this.state.inputFields)

  };

  checkgdetails() {

    let GlistIsValid = true;

    this.state.inputFields.forEach(element => {

      if (element.Name === "") {
        alert("Name Cant be empty")
        GlistIsValid = false;
      }

      if (element.Phone === "") {
        alert("Phone Number Cant be Empty")
        GlistIsValid = false;
      }

      
    if (element.Age === "") {
        alert("Age Cant be Empty")
        GlistIsValid = false;
      }
      if (element.Email === "") {
        alert("Email Cant be Empty")
        GlistIsValid = false;
      }

         
      if (element.Relation === "") {
        alert("Select Relation with Gaurantor")
        GlistIsValid = false;
      }

      
      if (element.Address === "") {
        alert("Address Cant be Empty")
        GlistIsValid = false;
      }


    });


    return GlistIsValid
  }



  render() {
    return (

      <div style={styles.root}>




        <Container component="main" maxWidth="lg">

          {(this.state.Geoon) ? (
            <span></span>
          ) : (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Location Must Be on To Apply For Loan — <strong>Enable Location and Refresh The Page!</strong>
            </Alert>
          )}




          <br />
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Loan Application Form
          </Typography>

          <form noValidate style={{ width: '100%' }} onSubmit={this.submituserdata.bind(this)}  >

            {/* for percentage criteria allowed branches salary */}

            <Paper className="loan-box">

              <Typography variant="h6" color="textPrimary">Loan Information</Typography>

              <Box m={4} />


              <Grid container spacing={3} align="center">

                <Grid item xs={12} sm={4} align="center">



                  <Box m={2}>
                    <Typography variant="body2" >
                      {`Select Loan Amount`}
                    </Typography>
                  </Box>
                  <br />

                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Amount Loan"
                      disabled={!this.state.Geoon}
                      id="Amount Loan"

                      onChange={this.handleChange.bind(this, "Amount_Loan")}
                      value={this.state.fields["Amount_Loan"] || ''}>


                      {
                        this.state.LoanAmount.map((amount) => {
                          return <MenuItem value={amount}>{amount}</MenuItem>
                        })
                      }

                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Amount_Loan"]}</Typography>


                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry">Tenure of Loan</Typography>
                  </Box>

                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Tenure"
                      disabled={!this.state.Geoon}
                      id="Tenure"
                      onChange={this.handleChange.bind(this, "Tenure")}
                      value={this.state.fields["Tenure"] || ''}>



                      {
                        this.state.LoanTenure.map((amount) => {
                          return <MenuItem value={amount}>{amount}</MenuItem>
                        })
                      }


                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Tenure"]}</Typography>

                </Grid>


                <Grid item xs={12} sm={4}>
                  <Box m={2}>

                    <Typography variant="secondry">
                      {`EMI Date`}
                    </Typography>
                  </Box>
                  <br />

                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Pay_Day"
                      disabled={!this.state.Geoon}
                      id="Pay_Day"
                      onChange={this.handleChange.bind(this, "Pay_Day")}
                      value={this.state.fields["Pay_Day"] || ''}
                    >
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="10">10</MenuItem>

                    </Select>
                  </FormControl>
                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Pay_Day"]}</Typography>
                </Grid>
              </Grid>

            </Paper>
            {  /* for tenur and other things  - 3 module */}



            <Paper className="loan-box">

              <Typography variant="h6" color="textPrimary">Personal Information</Typography>

              <Box m={4} />



              <Grid container spacing={3} align="center">
                <Grid item xs={12} sm={4}>
                  <Box m={2}>





                    <Typography variant="secondry">Resident Status</Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Resident_Status"
                      disabled={!this.state.Geoon}
                      id="Resident_Status"
                      onChange={this.handleChange.bind(this, "Resident_Status")}
                      value={this.state.fields["Resident_Status"] || ''}
                    >
                      <MenuItem value="Family_Owned">Family Owned</MenuItem>
                      <MenuItem value="Self_Owned">Self Owned</MenuItem>
                      <MenuItem value="Rented">Rented</MenuItem>
                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Resident_Status"]}</Typography>



                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry"> Occupation </Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Occupation"
                      disabled={!this.state.Geoon}
                      id="Occupation"
                      onChange={this.handleChange.bind(this, "Occupation")}
                      value={this.state.fields["Occupation"] || ''}
                    >
                      <MenuItem value="Salaried">Salaried</MenuItem>
                      <MenuItem value="Self_Employed">Self Employed</MenuItem>

                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Occupation"]}</Typography>

                </Grid>


                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry">Stay With Family</Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      disabled={!this.state.Geoon}
                      labelId="Stay_With_Family"
                      id="Stay_With_Family"
                      onChange={this.handleChange.bind(this, "Stay_With_Family")}
                      value={this.state.fields["Stay_With_Family"] || ''}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Stay_With_Family"]}</Typography>

                </Grid>


                <Grid item xs={12} sm={4}>
                  <Box m={2}>





                    <Typography variant="secondry">Income</Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

              


                    <TextField
                    id="Income"
                    disabled={!this.state.Geoon}
                    type="number"
                    onChange={this.handleChange.bind(this, "Income")}
                    value={this.state.fields["Income"]}
                    InputLabelProps={{
                      shrink: false
                    }}
                    style={{
                      width: '200px'
                    }}
                    InputProps={{
                      inputProps: { max: 100, min: 10 },
                      endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
                    }}

                  />


                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Income"]}</Typography>



                </Grid>

                {/*Numfmembers*/}

                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry"> Number of Family Members </Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Numfmembers"
                      id="Numfmembers"
                      disabled={!this.state.Geoon}
                      onChange={this.handleChange.bind(this, "Numfmembers")}
                      value={this.state.fields["Numfmembers"] || ''}
                    >
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4-6">4-6</MenuItem>
                      <MenuItem value="More than 6">More than 6</MenuItem>

                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Numfmembers"]}</Typography>




                </Grid>


                <Grid item xs={12} sm={4}>
                  {/** Numemembers */}
                  <Box m={2}>
                    <Typography variant="secondry">Number of Earning Members</Typography>
                  </Box>
                  <br />

                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Numemembers"
                      id="Numemembers"
                      disabled={!this.state.Geoon}
                      onChange={this.handleChange.bind(this, "Numemembers")}
                      value={this.state.fields["Numemembers"] || ''}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4-6">4-6</MenuItem>
                      <MenuItem value="More than 6">More than 6</MenuItem>

                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Numemembers"]}</Typography>

                </Grid>











              </Grid>

            </Paper>



            {/*ending of the module 4 */}



            {/* starting of module 5 */}




            <Paper className="loan-box">
              <Typography variant="h6" color="textPrimary">Credit Information</Typography>

              <Box m={4} />

              <Grid container spacing={3} align="center">
                <Grid item xs={12} sm={4}>
                  <Box m={2}>




                    {/* integer input only */}
                    <Typography variant="caption">
                      Due Amount Any Loan
                    </Typography>
                  </Box>
                  <br />
                  <TextField
                    id="Due_AAL"
                    disabled={!this.state.Geoon}
                    type="number"
                    onChange={this.handleChange.bind(this, "Due_AAL")}
                    value={this.state.fields["Due_AAL"]}
                    InputLabelProps={{
                      shrink: false
                    }}
                    style={{
                      width: '200px'
                    }}
                    InputProps={{
                      inputProps: { max: 100, min: 10 },
                      endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
                    }}

                  />







                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Due_AAL"]}</Typography>



                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry"> Due Amount Credit Card</Typography>
                  </Box>
                  <br />


                  <TextField
                    disabled={!this.state.Geoon}
                    id="DCC"
                    onChange={this.handleChange.bind(this, "DCC")}
                    value={this.state.fields["DCC"]}
                    style={{
                      width: '200px'
                    }}
                    type="number"
                    InputLabelProps={{
                      shrink: false,

                    }}
                    InputProps={{
                      inputProps: { max: 100, min: 10 },
                      endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
                    }}


                  />


                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["DCC"]}</Typography>

                </Grid>


                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry">Default in Loan After 2014</Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="D_14"
                      id="D_14"
                      disabled={!this.state.Geoon}
                      onChange={this.handleChange.bind(this, "D_14")}
                      value={this.state.fields["D_14"] || ''}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["D_14"]}</Typography>

                </Grid>





                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry">Purpose of Loan</Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Loan_Purpose"
                      id="Loan_Purpose"
                      disabled={!this.state.Geoon}
                      onChange={this.handleChange.bind(this, "Loan_Purpose")}
                      value={this.state.fields["Loan_Purpose"] || ''}
                    >
                      <MenuItem value="HOME RENOVATION">HOME RENOVATION</MenuItem>
                      <MenuItem value="ADVANCE SALARY">ADVANCE SALARY</MenuItem>
                      <MenuItem value="MEDICAL EMERGENCY">MEDICAL EMERGENCY</MenuItem>
                      <MenuItem value="DEBT CONSOLIDATION">DEBT CONSOLIDATION</MenuItem>
                      <MenuItem value="CONSUMER LOAN">CONSUMER LOAN</MenuItem>
                      <MenuItem value="SCHOOL FEES LOAN">SCHOOL FEES LOAN</MenuItem>
                      <MenuItem value="WEDDING LOAN">WEDDING LOAN</MenuItem>
                      <MenuItem value="CASH CRUNCH">CASH CRUNCH</MenuItem>
                      <MenuItem value="WORKING CAPITAL">WORKING CAPITAL</MenuItem>
                      <MenuItem value="PROVIDING GIFT">PROVIDING GIFT</MenuItem>

                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Loan_Purpose"]}</Typography>

                </Grid>



                <Grid item xs={12} sm={4}>
                  <Box m={2}>
                    <Typography variant="secondry">CIBIL Score</Typography>
                  </Box>
                  <br />
                  <FormControl style={{ minWidth: 200 }}>

                    <Select
                      labelId="Cibil_Score"
                      id="Cibil_Score"
                      disabled={!this.state.Geoon}
                      onChange={this.handleChange.bind(this, "Cibil_Score")}
                      value={this.state.fields["Cibil_Score"] || ''}
                    >
                      <MenuItem value="ABOVE 750">ABOVE 750</MenuItem>
                      <MenuItem value="BELOW 650">BELOW 650</MenuItem>
                      <MenuItem value="BETWEEN 650-750">BETWEEN 650-750</MenuItem>
                      <MenuItem value="DONT KNOW">DON'T KNOW</MenuItem>


                    </Select>
                  </FormControl>

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Cibil_Score"]}</Typography>

                </Grid>

              </Grid>








            </Paper>




            {/* ending of module 5 */}



            <React.Fragment>
              <Typography variant="h6" color="initial">Add guarantor ( Required )</Typography>

              <Button variant="contained" color="default" onClick={() => this.addguarantee()}>
                Add
              </Button>
              {this.state.inputFields.map((inputField, index) => (

                <React.Fragment key={`${inputField}~${index}`}>
                  <Box m={4}></Box>

                  <Grid container spacing={2}>

                    <Grid item lg={3}>
                      <TextField
                        id="Gname"
                        fullWidth
                        required
                        name="Name"
                        label="Name"
                        variant="filled"
                        value={inputField.Name}
                        onChange={(event) => this.handleInputChange(index, event)}
                      />
                    </Grid>


                    <Grid item lg={3}>
                      <TextField
                        id="GPhone"
                        required
                        name="Phone"
                        fullWidth
                        type="number"
                        variant="filled"
                        label="Phone Number"
                        value={inputField.Phone}
                        onChange={(event) => this.handleInputChange(index, event)}
                      />

                    </Grid>

                    <Grid item lg={3}>
                      <TextField
                        id="Email"
                        name="Email"
                        fullWidth
                        type="email"
                        variant="filled"
                        label="Email"
                        value={inputField.Email}
                        onChange={(event) => this.handleInputChange(index, event)}
                      />

                    </Grid>


                    <Grid item lg={3}>
                      <FormControl variant="filled" fullWidth>
                        <InputLabel id="select-relation">Relation</InputLabel>
                        <Select
                          required
                          id="select-realtion"
                          name='Relation'
                          defaultValue="Friend"
                          label="Relation"
                          value={inputField.Relation}
                          onChange={(event) => this.handleInputChange(index, event)}
                        >
                          <MenuItem value="Friend">Friend</MenuItem>
                          <MenuItem value="Family">Family</MenuItem>
                          <MenuItem value="Colleague">Colleague</MenuItem>
                        </Select>

                      </FormControl>

                    </Grid>



                    <Grid item lg={3}>
                      <TextField
                        id="Age"
                        name="Age"
                        fullWidth
  
                        type="number"
                        variant="filled"
                        label="Age"
                        value={inputField.Age}
                        onChange={(event) => this.handleInputChange(index, event)}
                      />

                    </Grid>



                    
                    <Grid item lg={3}>
                      <TextField
                        id="Address"
                        name="Address"
                        fullWidth
                        type="text"
                        variant="filled"
                        label="Address"
                        multiline
                        rows={3}
                        value={inputField.Address}
                        onChange={(event) => this.handleInputChange(index, event)}
                      />

                    </Grid>
                    

                    <Grid item lg={3}>


                      <IconButton color="primary" onClick={() => this.addguarantee()}>

                        <AddIcon />

                      </IconButton>

                      <IconButton color="secondary" onClick={() => this.handleRemoveFields(index)}>
                        <RemoveIcon />

                      </IconButton>





                    </Grid>





                  </Grid>



                </React.Fragment>
              ))}
            </React.Fragment>


            <Box m={8} align="center">
              <Button
                align="center"
                type="submit"
                size="large"
                disabled={!this.state.Geoon}
                startIcon={<SaveIcon />}
                variant="contained" color="primary" value="submit" id="submit">
                Submit
              </Button>
            </Box>
          </form>
          <br />
        </Container>

        {/* user profile dialog */}
        <Dialog
          open={this.state.ErrorDialog}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">InComplete Profile</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please Complete Your Profile Before Applying for Loan.

              You Will be Rediretced to Your Porfile Page.
            </DialogContentText>
          </DialogContent>

        </Dialog>

      </div>

    );
  }
}

export default AddNewLoan;



const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto'
  },
}


