import React, {Component} from 'react';

import {
    Container,
    Button,
    Typography,
    Grid,
    Box,
    IconButton,
    Link
} from '@material-ui/core'

import UserContext from '../ContextManage/UserContext'

//icons for social media
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailIcon from '@material-ui/icons/Mail';

class Footer extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            Test:false
        }
    }

  

    async componentDidMount() {
        const UserContextData = this.context
        await this.setState({loggedIn: UserContextData.state.loggedin})
       
    }

    render() {
        return (

            <React.Fragment>
                <div
                    style={{
                    backgroundColor: "#00000033",
                    padding: 50,
                    marginTop: 0,
                    borderTop:"2px solid #03A9F4"
                }}>
                    <Container>

                        <Box m={8}></Box>
                        <Grid container spacing={9}>

                            <Grid item lg={6}>

                                <Typography variant="h6" color="primary">ABOUT</Typography>
                                <Box m={2}></Box>
                                <Typography variant="body1" color="initial">Our automated, trustworthy,
                                    transparent, simple to use, and hassle free platform ensures that the borrowing
                                    needs of individuals are met quickly and efficiently.
                                </Typography>
                                <Box m={2}></Box>
                                <Typography variant="h6" color="primary">Follow Us On</Typography>

                                <IconButton href="mailto:company@sunshubhloans.com">
                                    <MailIcon/>
                                </IconButton>

                                <IconButton href="https://www.linkedin.com/company/sunshubh-loans">
                                    <LinkedInIcon/>
                                </IconButton>

                                <IconButton href="https://twitter.com/sunshubh?s=08">
                                    <TwitterIcon/>
                                </IconButton>

                                <IconButton href="https://www.facebook.com/sunshubhloans">
                                    <FacebookIcon/>
                                </IconButton>

                                <IconButton href="https://www.instagram.com/sunshubh_loans?r=nametag">
                                    <InstagramIcon/>
                                </IconButton>

                            </Grid>

                            <Grid item lg={3}>
                                <Typography variant="h6" color="initial">Legal</Typography>
                                <Box m={2}></Box>

                                {(this.state.loggedIn)
                                    ? (
                                       

<React.Fragment>
                                              
                                              <Link
                                               
                                                  variant="body1"
                                                  className="footer-link"
                                                  href="https://sunshubhloans.com/app/privacy">Privacy Policy</Link>
                                              <br/>
                                              <br/>
                                              <Link
                                                
                                                  variant="body1"
                                                  className="footer-link"
                                                  href="https://sunshubhloans.com/app/terms">{`Terms&Condition`}</Link>
                                              <br/>
                                              <br/>
                                              <Link
                                              
                                                  variant="body1"
                                                  className="footer-link"
                                                  href="https://sunshubhloans.com/app/faq">FAQs</Link>
                                              
                                              </React.Fragment>

                                    )
                                    : (

                                        <React.Fragment>
 
                                        <Link
                                         
                                            variant="body1"
                                            className="footer-link"
                                            href="https://sunshubhloans.com/privacy">Privacy</Link>
                                        <br/>
                                        <br/>
                                        <Link
                                           
                                            variant="body1"
                                            className="footer-link"
                                            href="https://sunshubhloans.com/terms">{`Terms&Condition`}</Link>
                                        <br/>
                                        <br/>
                                        <Link
                                         
                                            variant="body1"
                                            className="footer-link"
                                            href="https://sunshubhloans.com/faq">FAQs</Link>

                                    </React.Fragment>       


                                    )}

                            </Grid>

                            <Grid item lg={3}>
                                <Typography variant="h6" color="initial">Quick Links</Typography>
                                <Box m={2}></Box>

                                {(this.state.loggedIn)
                                    ? (
                                     
<React.Fragment>

<Link
 
    variant="body1"
    className="footer-link"
    href="https://sunshubhloans.com">Home</Link>
<br/>
<br/>

<Link
 
    variant="body1"
    className="footer-link"
    href="https://sunshubhloans.com/payment">Payment</Link>

<br/>
<br/>

<Link

    variant="body1"
    className="footer-link"
    href="https://sunshubhloans.com/app/about">About Us</Link>
<br/>
<br/>
<Link
  
    variant="body1"
    className="footer-link"
    href="https://sunshubhloans.com/app/contact">Contact Us</Link>
<br/>
<br/>
<Link

    variant="body1"
    className="footer-link"
    href="https://sunshubhloans.com/app/calculator">EMI Calculator</Link>



</React.Fragment>  
                                      
                                    )
                                    : (

                                       

                                        <React.Fragment>

                                        <Link
                                          
                                            variant="body1"
                                            className="footer-link"
                                            href="https://sunshubhloans.com">Home</Link>
                                        <br/>
                                        <br/>
                                        
<Link
 
 variant="body1"
 className="footer-link"
 href="https://sunshubhloans.com/payment">Payment</Link>

<br/>
<br/>
                                        <Link
                                         
                                            variant="body1"
                                            className="footer-link"
                                            href="https://sunshubhloans.com/about">About Us</Link>
                                        <br/>
                                        <br/>
                                        <Link
                                          
                                            variant="body1"
                                            className="footer-link"
                                            href="https://sunshubhloans.com/contact">Contact Us</Link>
                                        <br/>
                                        <br/>
                                        <Link
                                        
                                            variant="body1"
                                            className="footer-link"
                                            href="https://sunshubhloans.com/calculator">EMI Calculator</Link>

                                    </React.Fragment>



                                    )}

                            </Grid>

                        </Grid>

                    </Container>

                </div>
                <div style={{
                    backgroundColor: "black"
                }}>
                    <br/>
                    <Typography variant="body1" color="primary" align="center">Copyright © Sunshubh Solutions 2021.</Typography>
                    <br/>
                </div>
            </React.Fragment>
        );
    }
}

export default Footer;