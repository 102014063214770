import React, { Component } from 'react';

import {Typography,Container,Accordion,AccordionSummary ,AccordionDetails,Box} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Faq extends Component {
    render() {
        return (
         <Container maxWidth="md">
        
        
         <div style={{ display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
     marginTop:50}}>
             <Typography variant="h3" alignCenter color="initial">FAQ'S</Typography>

<Box m={5}></Box>
<div style={{width:'100%',display:"block",flexDirection:"none"}}>
             <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >What Is Sunshubh Loans?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sunshubh Loan Is a Fintech Startup.We Hold License From The Government Of
Maharashtra (Sahukari Licence).
          </Typography>
        </AccordionDetails>
      </Accordion>
    


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Why Sunshubh Loans?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Here's Why Our Loan is The Perfect Match for you.
          </Typography>
        </AccordionDetails>
        <AccordionDetails>
        <ul>

                <li>We Don&#39;t Ask For Any Security Or Guarantor For Our Loan.</li>
                <li>You Get The Flexibility Of Choosing Loan Repayment Tenure.</li>
                <li>For Our Existing Customers, We Regularly Provide Exclusive Pre-Approved Offers
On Loans.</li>
                <li>We Offer Unsecured Business Loans Up To ₹ 10,000.</li>
                <li>We Seek Minimum Documentation.</li>
                <li>We Give You Quick Online Approval Once You Share The Minimum Documents
We Need..</li>

        </ul>

        </AccordionDetails>
      </Accordion>





      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >How Does The Borrower Loan Get Rejected?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          If The Your Profile Is Not Suitable As Per The Credit Criteria Risk Policy Then Your Application Is Rejected.
          </Typography>
        </AccordionDetails>
      </Accordion>
    
     



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >What Are The Documents Required For a Loan?</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ul>

<li>Pan Card.</li>
<li>Aadhar Card</li>
<li>Electricity Bill</li>
<li>Bank Passbook</li>
<li>Post Dated Cheques</li>
</ul>


        </AccordionDetails>
      </Accordion>
    



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Who Can Get Loan?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Any Person Who Have Income (Business Income More Then 12000) (Salary Income
More Then 10000) And Suitable As Per The Credit Criteria Risk Policy.
          </Typography>
        </AccordionDetails>
      </Accordion>



      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >What Are My Options To Pay EMIs ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You Can Repay Through Post Dated Cheques, Electronic Clearance Service (Ecs) Or
Direct Debit.
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >I Am Self Employed Can I Apply For Loan?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Yes You Can Apply For The Loan.
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Can I Get Loan Without Collateral ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Yes You Can Avail Loan Without Collateral.
          </Typography>
        </AccordionDetails>
      </Accordion>



      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Am I Eligible For The Loan ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You Are Eligible For a Loan If You:
          </Typography>
        </AccordionDetails>

        <AccordionDetails>
         <ol>
            <li>I)Are Between 21-45 Years Of Age</li>
            <li>II)Have Received Salary In The Bank Account For 2 Consecutive Months.</li>
            <li>III)Have Business That Shows a Profit For The Last Financial Years.</li>
            <li>IV)Have Business That Shows An Upward Turnover Trend</li>

         </ol>
        </AccordionDetails>
      </Accordion>




      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >How Long Can Be The Loan Tenure?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Minimum Loan Tenure Is 1 Month And Maximum Loan Tenure Is 3 Months.
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >How My Repayment Is Calculated?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Your Repayment Is Calculated On Flat Rate Method As Per Tenure Selected By You.
The Monthly Installments Or Emis Will Be The Same.
          </Typography>
        </AccordionDetails>
      </Accordion>




      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Is There Any Penalty On Delay Or Non-Payment Of EMIs.</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ol>
            <li>If The Borrower Has Delayed His Repayment Upto a 5 Days From Emi Date, He Will Be Charged Rs.
250 /-</li>
<li>If The Borrower Has Delayed His Repayment Upto 10 Days From Emi Date, He Will Be Charged Rs.
500 /-</li>
<li>
If The Borrower Has Delayed His Repayment Upto 15 Days From Emi Date, He Will Be Charged Rs.
750 /-
</li>
<li>If The Borrower Has Delayed His Repayment Upto 20 Days, He Will Be Charged Rs. 1000 /-</li>
<li>If The Borrower Has Delayed His Repayment Upto 1 Month (30 Days From Emi Date) ,He Will Be
Charged Rs. 1500 /-</li>
<li>For Delays Of An Emi Beyond a Month, The Borrower Will Be Charged An Additional 2% Penal
Interest Rate; On The Overdue Amount.</li>
        </ol>
        </AccordionDetails>
      </Accordion>




      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Why I Have To Submit The Cheques?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          As Per The Polciy Of SUNSHUBHLOANS The Cheques Are Used As Security
Cheques.
          </Typography>
        </AccordionDetails>
      </Accordion>




      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >How Many Days It Takes For Disbursement?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Disbursement Takes Place In t+2 Working Days.
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Why Should We Pay Registration Fees?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Registration Fees Are Use To Complete All The Formalities, Credit Assessment Cost.
(Which Is Debited From The Disbursal Amount)
          </Typography>
        </AccordionDetails>
      </Accordion>



      </div>
</div>

<Box m={4}></Box>
         </Container>
        );
    }
}

export default Faq;