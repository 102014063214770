import React, { Component } from 'react';



import  {Grid,Typography,Container,Box,ButtonBase, Card,Paper} from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import MoneyIcon from '@material-ui/icons/Money';

import BusinessLoanForm from './BusinessLoanForm';



//new loan
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BusinessIcon from '@material-ui/icons/Business';
import TimelineIcon from '@material-ui/icons/Timeline';



class BusinessLoan extends Component {
   




    render() {
        return (
          <Container maxWidth="xl">
            <Box m={4} />
            <Typography variant="h2" color="initial" align="center">
              Business Loan
            </Typography>
            <Box m={8} />
            <Grid container spacing={2}>
              {/**left bar */}
              <Grid item xl={6}  lg={6} md={6} sm={12}>
                <Typography
                  variant="h4"
                  color="primary"
                  align="center"
                  gutterBottom
                >
                  One Application Multiple Lenders.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="initial"
                  align="center"
                  gutterBottom
                >
                  Get upto 20 Lakhs , We Send Your Application to Multiple Lender{" "}
                 
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="initial"
                  align="center"
                  gutterBottom
                >
               
                   so you can get best Lender for your Application.
                </Typography>
                <Box m={8} />
                <Typography variant="h4" color="initial" align="center">
                  Why Us ?
                </Typography>

                <Box m={8} />
                <Grid
                  container
                  spacing={2}
                  justify="center"
                  style={{ textAlign: "center" }}
                >
                  <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                    <ButtonBase>
                      <TimerIcon
                        color="primary"
                        style={{ fontSize: 60, marginBottom: 20 }}
                      />
                    </ButtonBase>

                    <br />
                    <Typography gutterBottom variant="subtitle2">
                      Within Minutes
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                    <ButtonBase>
                      <SupervisedUserCircleIcon
                        color="primary"
                        style={{ fontSize: 60, marginBottom: 20 }}
                      />
                    </ButtonBase>

                    <br />
                    <Typography gutterBottom variant="subtitle2">
                      Multiple Lenders
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                    <ButtonBase>
                      <MoneyIcon
                        color="primary"
                        style={{ fontSize: 60, marginBottom: 20 }}
                      />
                    </ButtonBase>

                    <br />
                    <Typography gutterBottom variant="subtitle2">
                      Upto 20 Lacs
                    </Typography>
                  </Grid>
                </Grid>

                <Box m={8} />
                <Typography variant="h4" color="initial" align="center">
                  What Our Loan Covers?
                </Typography>
                <Box m={8} />

                <Grid
                  container
                  spacing={2}
                  justify="center"
                  style={{ textAlign: "center" }}
                >
                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <TrendingDownIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                       Low Interest rates
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <HourglassFullIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                       Fast Process
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <AssignmentIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                       Minimum Documentation
                      </Typography>
                    </Paper>
                  </Grid>

              

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <ReceiptIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                     Invoice Finance
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <BusinessIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                      Working Capital
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={4} md={4}>
                    <Paper style={{ padding: 20 }} className="feature-box">
                      <TimelineIcon
                        color="primary"
                        style={{ fontSize: 50, marginBottom: 20 }}
                      />

                      <Typography
                        variant="h6"
                        color="textSecondary"
                        gutterBottom
                      >
                      Flexible Loans
                      </Typography>
                    </Paper>
                  </Grid>




                 
                </Grid>
              </Grid>

              {/**left bar */}

              <Grid item xl={6} lg={6} md={6} sm={12}>
                <Card variant="outlined">
                  {/*Business Loan form*/}
                  <BusinessLoanForm />
                </Card>
              </Grid>
            </Grid>
          </Container>
        );
    }
}

export default BusinessLoan;