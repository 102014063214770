import React, { Component } from 'react';
import { Container, Card, Typography, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Box } from '@material-ui/core'

import Pagination from '@material-ui/lab/Pagination';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as ApiFunctions from '../../util/apifunction'
import UserContext from '../ContextManage/UserContext'

import SearchUI from '../AdminComponents/SearchUI'


class AllLoans extends Component {
  static contextType = UserContext;

  constructor(props) {

    super()
    this.state = {
      isloading: true,
      Data: [],
      isError: false,
      PageNumber: 1,
      TotalPage: 0,
      TotalPost: 0,
      PerPage: 0,
      ID: null,
      Email: null,
      Role: null,
      Token: null,
      isloggedin: false,

    }
  }


  getBackgroundColor(status) {
    if (status === 'Sanction') {
      return 'green';
    }
    if (status === 'Closed') {
      return 'blue';
    }
    if (status === 'InProcess') {
      return '#ffef00';
    }
    if (status === 'Rejected') {
      return 'red';
    }
    return 'red';
  }


  async componentDidMount() {

    const UserContextData = this.context
    await this.setState({
      ID: UserContextData.state.ID,
      Email: UserContextData.state.Email,
      isloggedin: UserContextData.state.loggedin,
      Token: UserContextData.state.Token,
      Role: UserContextData.state.Role
    })

    if (this.state.isloading) {
      this.FetchUsers()
    } else {
      window.location.href = "/"
    }

  }




  async DeleteLoan(ID) {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };

    try {
      const Data = await ApiFunctions.DeleteApiData(`/loan/${ID}`, header)

      if (Data.Status === 200) {
        alert(`Loan ${ID} deleted Successfully!`)
        window.location = window.location.href;
      }

      if (Data.Status === 404) {
        alert(`Loan ${ID} Not Found!`)
      }

    } catch (err) {
      alert(`Internal Server Error`)
    }
  }










  async FetchUsers() {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    try {
      const Data = await ApiFunctions.GetApiRequest(`/loan/allloan/${this.state.PageNumber}`, header)

      if (Data) {
        this.setState({
          Data: Data.Data,
          isloading: false,
          TotalPost: Data.TotalPost,
          PerPage: Data.PerPage,
          TotalPage: Data.Totalpages
        })

        //   console.log("data",Data)
      } else {
        this.setState({ isError: true })
      }
    } catch (err) {
      this.setState({ isError: true })
    }
  }

  async handlechange(event, pagenumber) {

    await this.setState({ PageNumber: pagenumber })
    await this.FetchUsers()

  }



  render() {
    return (

      <Container maxWidth="xl">
        <Box m={4}></Box>
        <Typography variant="h3" color="initial" gutterBottom>All Loans</Typography>


        <Card>

          <TableContainer >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>

                  <TableCell align="left">Loan ID</TableCell>
                  <TableCell align="left">Agreement Signed</TableCell>
                  <TableCell align="left">User Name</TableCell>
                  <TableCell align="left">Phone Number</TableCell>
                  <TableCell align="left">Occupation</TableCell>
                  <TableCell align="left">Residential Status</TableCell>


                  <TableCell align="left">Loan Amount</TableCell>
                  <TableCell align="left">Tenure</TableCell>
                  <TableCell align="left">Total Interest</TableCell>

                  <TableCell align="left">Created At</TableCell>

                  <TableCell align="left">Status</TableCell>

                  <TableCell align="left">Oprations</TableCell>






                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.Data.map((data) => (
                  <TableRow key={data.ID}>
                    <TableCell align="left">{data.ID}</TableCell>
                    <TableCell align="left">{data.Agreement_Signed}</TableCell>

                    <TableCell component="th" scope="row" align="left">
                      {data.User_Name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {data.User_PhoneNo}
                    </TableCell>
                    <TableCell align="left">{data.Occupation}</TableCell>
                    <TableCell align="left">{data.Resident_Status}</TableCell>

                    <TableCell align="left">{data.Amount}</TableCell>
                    <TableCell align="left">{data.Tenure}</TableCell>
                    <TableCell align="left">{data.Total_Interest}</TableCell>



                    <TableCell align="left">{new Date(data.Created_At).toString()}</TableCell>
                    <TableCell align="left">{data.Status}</TableCell>



                    <TableCell align="left">


                      <IconButton aria-label="delete" onClick={() => this.DeleteLoan(data.ID)}>
                        <DeleteIcon />
                      </IconButton>



                      <IconButton aria-label="edit" onClick={() => this.props.history.push(`/app/edit-loan/${data.ID}`)}>
                        <EditIcon />
                      </IconButton>


                    </TableCell>

                    <TableCell align="left">

                      <span style={{ 'width': 15, 'height': 15, 'borderRadius': "50%", 'backgroundColor': this.getBackgroundColor(data.Status), 'display': "block" }}> </span>

                    </TableCell>



                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>


        </Card>

        <Box m={4}></Box>
        <Pagination variant="outlined" color="primary" count={this.state.TotalPage} page={this.state.PageNumber} onChange={this.handlechange.bind(this)} />
        <Box m={4}></Box>
      </Container>
    );
  }
}

export default AllLoans;

