import React, { Component } from 'react';
import {Container,Card, Typography,Table,TableBody,TableCell,TableHead,TableContainer,TableRow,Box} from  '@material-ui/core'

import Pagination from '@material-ui/lab/Pagination';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as ApiFunctions from '../../util/apifunction'
import UserContext from '../ContextManage/UserContext'

import SearchUI from './SearchUI'


class AllBusinessApp extends Component {
  static contextType =UserContext;

  constructor(props){

    super()
    this.state={
      isloading:true,
      Data:[],

      isError:false,
      PageNumber:1,
      TotalPage:0,
      TotalPost:0,
      PerPage:0,
      ID:null,
      Email:null,
      Role:null,
      Token:null,
      isloggedin:false,
  
    }
  }



  

  async componentDidMount(){
  
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

     if(this.state.isloading){
      this.FecthApplications()
     }else{
       window.location.href="/"
     }
     
   }
 



   async DeleteLoan(ID){
    const header={
      'Content-Type': 'application/json',
    'x-access-token':this.state.Token
     };

     try{
      const Data = await ApiFunctions.DeleteApiData(`/loan/api/deletebl/${ID}`,header)
    
    if(Data.Status===200){
      alert(`Loan ${ID} deleted Successfully!`)
     window.location=window.location.href;
    }

    if(Data.Status===404){
      alert(`Loan ${ID} Not Found!`)
    }
      
    }catch(err){
      alert(`Internal Server Error`)
      console.log(err)
      }
    }










  async FecthApplications(){

    const header={
      'Content-Type': 'application/json',
   'x-access-token':this.state.Token
     };
     try{
      const Data = await ApiFunctions.GetApiRequest(`/loan/api/submitbl/${this.state.PageNumber}`,header)
  
      if(Data){


            this.setState({
              Data:Data.DATA,
              isloading:false,
              TotalPost:Data.TotalPost,
              PerPage:Data.PerPage,
              TotalPage:Data.Totalpages
            })
           
      //   console.log("data",Data)
          }else{
          this.setState({isError:true})   
      }
    }catch(err){
      this.setState({isError:true})
      }
      
    }

  async handlechange(event,pagenumber){
    
   await this.setState({PageNumber:pagenumber})
     await  this.FecthApplications() 
     
  }



    render() {
        return (

          <Container maxWidth="xl">
            <Box m={4}></Box>
            <Typography variant="h3" color="initial" gutterBottom>Business Applications</Typography>


      <Card>

     <TableContainer >
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Full_Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone Number</TableCell>
            <TableCell align="left">Loan Amount</TableCell>
            <TableCell align="left">Tenure</TableCell>
       

            <TableCell align="left">Residential Status</TableCell>
       

            <TableCell align="left">Martial Status</TableCell>
            <TableCell align="left">Gender</TableCell>
            <TableCell align="left">Pan</TableCell>
            <TableCell align="left">Pin Code</TableCell>
          
        
            <TableCell align="left">Created At</TableCell>

      

            




          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.Data.map((data) => (
            <TableRow key={data.ID}>
                  <TableCell align="left">{data.ID}</TableCell>
                  <TableCell align="left">{data.Full_Name}</TableCell>
          
          <TableCell component="th" scope="row" align="left">
                {data.Email}
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                {data.Phone}
              </TableCell>
              <TableCell align="left">{data.LoanAmount}</TableCell>
              <TableCell align="left">{data.Tenure} Years </TableCell>
            
              <TableCell align="left">{data.RS}</TableCell>
              <TableCell align="left">{data.MS}</TableCell>
              <TableCell align="left">{data.Gender}</TableCell>
              <TableCell align="left">{data.Pan}</TableCell>
              
              <TableCell align="left">{data.Pin}</TableCell>
            
              
             
              <TableCell align="left">{new Date(data.Created_At).toString()}</TableCell>
             
            
            
              <TableCell align="left">


              <IconButton aria-label="delete" onClick={()=>this.DeleteLoan(data.ID)}>
          <DeleteIcon />
        </IconButton>
    


    
        <IconButton  aria-label="edit" onClick={()=>this.props.history.push(`/app/bl-application/${data.ID}`)}>
          <EditIcon />
        </IconButton>
    

              </TableCell>
          

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


                    </Card>

                    <Box m={4}></Box>
    <Pagination  variant="outlined" color="primary"  count={this.state.TotalPage} page={this.state.PageNumber} onChange={this.handlechange.bind(this)} />
    <Box m={4}></Box>
                    </Container>
        );
    }
}

export default AllBusinessApp;

