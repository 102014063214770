// component's config object.

//pages //media //comment //contact //blog //profile //category //tag //users //settings

const components = {
  admindashbaord: {
    component: "AdminDashboard",
    url: "/maindashboard",
    title: "Dashboard",
    icon: "add_circle",
    module: 1,
  },
  allloans: {
    component: "AllLoans",
    url: "/all-loans",
    title: "All Loans",
    icon: "books",
    module: 1,
  },
  YourReference: {
    component: "YourReference",
    url: "/user-ref",
    title: "Your Reference",
    icon: "book",
    module: 1,
    child: false,
  },
  yourloans: {
    component: "YourLoans",
    url: "/user-loans",
    title: "My Loans",
    icon: "books",
    module: 1,
  },

  allusers: {
    component: "AllUser",
    url: "/all-user",
    title: "Users",
    icon: "people",
    module: 1,
  },
  addloanadmin: {
    component: "AddLoanAdmin",
    url: "/add-loan",
    title: "Add New Loan",
    icon: "books",
    module: 0,
  },
  editloan: {
    component: "EditLoan",
    url: "/edit-loan/:ID",
    title: "Edit Loan",
    icon: "menu",
    module: 1,
    child: true,
  },
  edituser: {
    component: "EditUser",
    url: "/edit-user/:ID",
    title: "Edit User",
    icon: "menu",
    module: 1,
    child: true,
  },
  profile: {
    component: "Profile",
    url: "/users-profile",
    title: "My Profile",
    icon: "account_circle",
    module: 0,
  },
  addnewloan: {
    component: "AddNewLoan",
    url: "/create-loan/",
    title: "Apply Now",
    icon: "people",
    module: 1,
  },
  loandetails: {
    component: "LoanDetails",
    url: "/user-loan-details/:ID",
    title: "Loan Details",
    icon: "Dashboard",
    module: 1,
    child: true,
  },
  settings: {
    component: "Settings",
    url: "/settings",
    title: "Settings",
    icon: "settings",
    module: 1,
  },
  payment: {
    component: "Payment",
    url: "/payment",
    title: "Pay EMI",
    icon: "payment",
    module: 1,
    child: false,
  },
  loanagreement: {
    component: "LoanAgreement",
    url: "/agreement/:LID",
    title: "Loan Agreement",
    icon: "document",
    module: 1,
    child: true,
  },
  searchloan: {
    component: "SearchLoan",
    url: "/search-loan/",
    title: "SeachLoan",
    icon: "search",
    module: 1,
    child: false,
  },
  searchuser: {
    component: "SearchUser",
    url: "/search-user/",
    title: "SeachUser",
    icon: "search",
    module: 1,
    child: false,
  },
  allbusinessapp: {
    component: "AllBusinessApp",
    url: "/app-bl/",
    title: "Business Applications",
    icon: "book",
    module: 1,
    child: false,
  },
  singlebi: {
    component: "SingleBI",
    url: "/bl-application/:LID",
    title: "Single Business Application",
    icon: "book",
    module: 1,
    child: true,
  },
  allpersonalapp: {
    component: "AllPersonalApp",
    url: "/app-pl/",
    title: "Personal Applications",
    icon: "book",
    module: 1,
    child: false,
  },
  singlepl: {
    component: "SinglePL",
    url: "/pl-application/:LID",
    title: "Single Personal Application",
    icon: "book",
    module: 1,
    child: true,
  },
  contactprofile: {
    component: "UserContact",
    url: "/user-contact-profile/:UID",
    title: "User Profile",
    icon: "book",
    module: 1,
    child: true,
  },
  locationprofile: {
    component: "UserLocation",
    url: "/user-location-profile/:UID",
    title: "User Locations",
    icon: "book",
    module: 1,
    child: true,
  },
};

// modules for grouping.
const modules = {
  0: {
    title: "Dashboard",
    icon: "add_circle",
    isExpendable: true,
  },
};

// component's access to roles.
const rolesConfig = {
  Admin: {
    routes: [
      components.addloanadmin,
      components.allloans,
      components.allusers,
      components.searchloan,
      components.searchuser,
      components.editloan,
      components.profile,
      components.settings,
      components.edituser,
      components.payment,
      components.allbusinessapp,
      components.singlebi,
      components.allpersonalapp,
      components.singlepl,
      components.contactprofile,
      components.locationprofile
    ],
  },
  Customer: {
    routes: [
      components.profile,
      components.addnewloan,
      components.loandetails,
      components.yourloans,
      components.payment,
      components.loanagreement,
    ],
  },
  common: {
    routes: [
      {
        component: "HomePage",
        url: "/",
        title: "Home",
        icon: "home",
        module: 1,
      },

	  components.YourReference,
      {
        component: "YourLoans",
        url: "/user-loans",
        title: "My Loans",
        icon: "books",
        module: 1,
      },

      components.addnewloan,
      {
        component: "Calculator",
        url: "/calculator",
        title: "EMI calculator",
        icon: "calculate",
        module: 1,
      },
      components.payment,
      {
        component: "AboutUsPage",
        url: "/about",
        title: "About Us",
        icon: "feed",
        module: 1,
      },
      {
        component: "Faq",
        url: "/faq",
        title: "FAQ'S",
        icon: "question_answer",
        module: 1,
      },

      {
        component: "PrivacyPolicy",
        url: "/privacy",
        title: "Privcy Policy",
        icon: "policy",
        module: 1,
      },
      {
        component: "TermsandCondition",
        url: "/terms",
        title: "Terms & Conditions",
        icon: "border_color",
        module: 1,
      },
      {
        component: "ContactusPage",
        url: "/contact",
        title: "Contatc Us",
        icon: "history_edu",
        module: 1,
      },
    ],
  },
};

export { modules, rolesConfig };
