import React, { Component } from 'react';
import {Button , Typography ,Box,Grid,Container,
  Paper,Avatar,TableCell,TableContainer,TableRow,TableHead,Table,TableBody
  }  from '@material-ui/core'



import LoanEmis from '../AdminComponents/LoanEmis'
import UserContext from '../ContextManage/UserContext'
import axios from 'axios';
import * as ApiFunctions from '../../util/apifunction'

import Skeleton from '@material-ui/lab/Skeleton';

import LoanDelayCharges from '../AdminComponents/LoanDealysCharges'

import {saveAs} from 'file-saver';

class LoanDetails extends Component {
  static contextType =UserContext;
 
  constructor(props){
      super(props);
      let match = props.match;
      this.state={
        fields: {},
        errors: {},    
        ID:null,
              Email:null,
              Role:null,
              Token:null,
              isloggedin:false,
              LoanID:match.params.ID,
              Data:{},
              isloading:true,
              gabutton:false,
              guarantor:[]
   
      }
  }






  async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })



     await this.GetloanDetails()
  
     if(this.state.Data.guarantor!=""){
      await this.setState({guarantor: JSON.parse(this.state.Data.guarantor)  })
  
     }


   }


  async GetloanDetails(){
    const header={
      'Content-Type': 'application/json',
          'x-access-token':this.state.Token
     };
     try{
      const Data = await ApiFunctions.GetApiRequest(`/loan/customer/singleloan/${this.state.LoanID}`,header)

     
      if(Data.Status===200){
      await  this.setState({Data:Data.Data,isloading:false})
//      console.log(Data)
     }else{
          this.setState({isError:true,isloading:true})   
      }
    }catch(err){
      this.setState({isError:true})
      }
    }


    createAndDownloadPdf = () => {
       

     
      this.setState({gabutton:true})
      const data={
        userid:this.state.Data.User_ID,
        married_status:this.state.Data.User_MarriedStatus,
        loanid:this.state.Data.ID,   
        gender:this.state.Data.User_Gender,
        loan_amount:this.state.Data.Amount,
        fullname:this.state.Data.User_Ofc_Name,
        fathername:this.state.Data.User_Father_Name,
        phoneno:this.state.Data.User_PhoneNo,
        address:this.state.Data.User_Address,
        pancard:this.state.Data.User_PNo,
        aadharcard:this.state.Data.User_ANo,
        location:this.state.Data.location,
        Purpose:this.state.Data.Purpose,

        bankname:this.state.Data.User_BankName,
        bankifsc:this.state.Data.User_BankIfsc,
        ba_type:this.state.Data.USER_BA_Type,
        ba_holder_name:this.state.Data.User_Ofc_Name,
        ba_ac_no:this.state.Data.User_Ba_Ac_No,
        EmiDate:this.state.Data.EmiDate,
        Created_At:this.state.Data.Created_At,
        tenure:this.state.Data.Tenure,
        total_interest:this.state.Data.Total_Interest,
        ROI:this.state.Data.ROI,
        UserEmail:this.state.Data.User_Email


      }
      axios.post('/loan/agreement/create-pdf', data)

        .then(() => axios.get('/loan/agreement/fetch-pdf', { responseType: 'blob' }))

        .then((res) => {
                           const pdfBlob = new Blob([res.data], { type: 'application/pdf'});
  
                            saveAs(pdfBlob, `${this.state.Data.ID}-Loan Agreement.pdf`);

                            this.setState({gabutton:false})
        })
    }


    

   

 



    

    render() {

      const {Data}=this.state;
        return (

            <Container component="main" maxWidth="lg" >
                        <Box m={2}></Box>
                        <Typography variant="h5" color="initial">User Details</Typography>
                        <Box m={2}></Box>
                { (this.state.isloading) ? ( 

<Skeleton animation="wave" variant="rect"   width="100%" height={300}/>
                ) : (
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    {/* UI FOR THE BASIC DATA */}
                    <Paper  variant="outlined" style={{padding:20}}>
                      <div  style={{ 
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',}}>
                      <Avatar alt="Remy Sharp" src="/broken-image.jpg">B</Avatar>
                      <Box m={1}></Box>
                      <Typography variant="subtitle1" color="primary">{this.state.Data.User_Name}</Typography>
                      <Typography variant="caption" color="initial" >{this.state.Data.User_ID}</Typography>
                        

                      
                      </div>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Email :-   { <span style={{color:"black"}}>{this.state.Data.User_Email}</span>  }</Typography>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Phone No. :-   { <span style={{color:"black"}}>{this.state.Data.User_PhoneNo}</span>  }</Typography>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Married Status :-   { <span style={{color:"black"}}>{this.state.Data.User_MarriedStatus}</span>  }</Typography>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Father Name :-   { <span style={{color:"black"}}>{this.state.Data.User_Father_Name}</span>  }</Typography>
                      


                    </Paper>
                  </Grid>






                  <Grid item xs={12} sm={7} md={7} lg={7}>

                  {/* for the additional user data */}
                  <Paper  variant="outlined" style={{padding:20}}>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Address</Typography>
                      { <span style={{color:"black"}}>{this.state.Data.User_Address}</span>  }
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Pan Card No. :-   { <span style={{color:"black"}}>{this.state.Data.User_PNo}</span>  }</Typography>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Aadhar No. :-   { <span style={{color:"black"}}>{this.state.Data.User_ANo}</span>  }</Typography>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Bank Name:-   { <span style={{color:"black"}}>{this.state.Data.User_BankName}</span>  }</Typography>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Bank IFSC:-   { <span style={{color:"black"}}>{this.state.Data.User_BankIfsc}</span>  }</Typography>    
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Bank Account No.:-   { <span style={{color:"black"}}>{this.state.Data.User_Ba_Ac_No}</span>  }</Typography>
                      <Box m={2}></Box>
                      <Typography variant="subtitle1" color="textSecondary">Bank Account Type:-   { <span style={{color:"black"}}>{this.state.Data.USER_BA_Type}</span>  }</Typography>        
                  </Paper>
                  </Grid>
              </Grid>
                )


                }

                   

                    <Box m={2}></Box>
                    <Typography variant="h5" color="initial">Loan Details</Typography>
                    <Box m={2}></Box>
               
               
                {/* loan details */}


                {(this.state.isloading) ? (
               <Skeleton animation="wave" variant="rect"   width="100%" height={300}/>


                ) : (

                  <Paper  variant="outlined" style={{padding:20}}>

                  <Typography variant="subtitle1" color="primary">Loan ID :-   { <span style={{color:"black"}}>{this.state.Data.ID}</span>  }</Typography>
                  <Box m={3}></Box>
                  <Grid container spacing={2}>
                 
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Amount :-   { <span style={{color:"black"}}>{this.state.Data.Amount}</span>  }</Typography>

                  </Grid>   
  
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                  <Typography variant="subtitle1" color="textSecondary">Tenure :-   { <span style={{color:"black"}}>{this.state.Data.Tenure}</span>  }</Typography>

                  </Grid>   
  
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Principal :-   { <span style={{color:"black"}}>{Math.ceil((this.state.Data.Amount)/(this.state.Data.Tenure)) } {`Rs.`}</span>  }</Typography>
                  </Grid>   
  
  

                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Monthly EMI :-   { <span style={{color:"black"}}>{Math.ceil((this.state.Data.Amount)/(this.state.Data.Tenure) +   this.state.Data.Total_Interest) } {`Rs.`}</span>  }</Typography>
                  </Grid>   
  
  



  
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Interest :-   { <span style={{color:"black"}}>{this.state.Data.Total_Interest} {`Rs.`}</span>  }</Typography>
                  </Grid>   
  
  
  
  
                  
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Occupation :-   { <span style={{color:"black"}}>{this.state.Data.Occupation}</span>  }</Typography>
                  </Grid>   
  
                  <Grid item xs={6} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Residential Status:-   { <span style={{color:"black"}}> {this.state.Data.Resident_Status} </span>  }</Typography>
                  </Grid>   
  
  
  
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Net Icome:-   { <span style={{color:"black"}}>{this.state.Data.Income}</span>  }</Typography>
                  </Grid>   
  
  
  
  
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Stay With Family :-   { <span style={{color:"black"}}>{this.state.Data.Stay_with_Family}</span>  }</Typography>
                  </Grid>   
  
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Family Members:-   { <span style={{color:"black"}}>{this.state.Data.No_of_Fmembers}</span>  }</Typography>
                  </Grid>   
  
  
  
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Earning Members:-   { <span style={{color:"black"}}>{this.state.Data.No_of_Emember}</span>  }</Typography>
                  </Grid>
  


  
            
  
  
  
  
  
    
              
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Due Loan Any Amount :-   { <span style={{color:"black"}}>{this.state.Data.Due_Amount_Any_Loan} {`Rs.`}</span>  }</Typography>
                  </Grid>  
  
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Due Amount Credit Card:-   { <span style={{color:"black"}}>{this.state.Data.Due_Amount_CC} {`Rs.`}</span>  }</Typography>
                  </Grid>   
  
  
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Default Loan 2014 :-   { <span style={{color:"black"}}>{this.state.Data.Default_Loan14}</span>  }</Typography>
                  </Grid>    
  
  
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Emi Paying Date:-   { <span style={{color:"black"}}>Every Month  {this.state.Data.EmiDate}</span>  }</Typography>
                  </Grid>   
  
          
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="subtitle1" color="textSecondary">Applied For Loan :-   { <span style={{color:"black"}}>{  new Date(this.state.Data.Created_At).toString()}</span>  }</Typography>
                  </Grid>    
               
                  <Box m={5}></Box>
  
                     
               
                 {/* loan operations */}
                 <Grid item xs={6} sm={6}>
            
                 <Typography variant="subtitle1" color="textSecondary">Status :-   { <span style={{color:"black"}}>{this.state.Data.Status}</span>  }</Typography>



                      
               
  
                  </Grid>    
  
  
  
                  <Grid item xs={12} sm={12}>
                  
                  {(this.state.Data.Status==="Sanction")? (


<Button variant="outlined" color="primary" size="large"
onClick={this.createAndDownloadPdf}
style={{flow:"right"}}  disabled={this.state.gabutton}>
    Download Loan Agreement
  </Button>    


                  ) : (
              <span></span>

                )}


                  </Grid>    
                  </Grid>
          </Paper>
     
  
                )}
                
                <Box m={2}></Box>
        <Typography variant="h6" color="initial">Guarantor</Typography>
        <Box m={2}></Box>
        <TableContainer component={Paper}>
      {
        (this.state.Data.guarantor!="") ? (

          <Table  aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
           
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.guarantor.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.Name}
                </TableCell>
                <TableCell>{row.Phone}</TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>

        ) :(
          <Table  aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Relation</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Agreement Signed</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.Data.guarantors.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.Name}
                </TableCell>
                <TableCell>{row.Relation}</TableCell>
                <TableCell>{row.Email}</TableCell>
                <TableCell>{row.PhoneNumber}</TableCell>
                <TableCell>{row.AgreementSigned}</TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>

        )
      }
    </TableContainer>




        <Box m={2}></Box>
                
                  {(this.state.isloading)   ?  (
                           <Skeleton animation="wave" variant="rect"   width="100%" height={300}/>
                  ) : (
                        <React.Fragment>


                          {   (this.state.Data.Status=="InProcess" || this.state.Data.Status=="Rejected") ? (

                          <span></span>

                          ) :(
                            
                            <React.Fragment>

<Typography variant="h5" color="initial"  >Emis Details</Typography>
          <Box m={3}></Box>
             
                <LoanEmis emis={this.state.Data.emis}/>

                <Box m={3}></Box>

                <Typography variant="h5" color="initial"  >Delay Charges</Typography>

                <Box m={3}></Box>

          
                <LoanDelayCharges emis={this.state.Data.emis}/>
        
           </React.Fragment>

                          )}

                        </React.Fragment>
                  ) 

                  }

        </Container>
        );
    }
}

export default LoanDetails;




const styles={
    root: {
      display: 'flex',
      flexDirection: 'column',
      height:'auto'
    },
  }


