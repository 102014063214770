import React,{Component} from 'react';

import UserContext from '../ContextManage/UserContext'
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import {Typography,IconButton,AppBar,Toolbar,Button} from '@material-ui/core'



import history from '../../util/history';



class NormalMenu extends Component{
  static contextType =UserContext;
  constructor(props){
      super(props);
      this.state={
        Theme:null,
        Settings:null
      }
  }


  


  componentDidMount(){
    const UserContextData = this.context
    this.setState({
      Theme:UserContextData.state.CurrentTheme
    })
  }
 

  ChnageTheme=()=>{
    const UserContextData = this.context
    UserContextData.ONTOGGLEDARKMODE()
    this.setState({
      Theme:UserContextData.state.CurrentTheme
    })
  }  




  render(){

    return (
  
       
      <AppBar position="initial">
        <Toolbar>
          <Typography variant="h6" style={{flexGrow:1}} align="left" onClick={()=>history.push('/')}>
           {this.props.BlogName}
          </Typography>
         
         
          <Button color="inherit" variant="outlined"
         onClick={()=>history.push('/login')}
          >LOGIN</Button>



          <IconButton onClick={()=>this.ChnageTheme()} edge="end" style={{marginLeft:{spacing:2}}} color="inherit" aria-label="theme-option">
        {
          (this.state.Theme==='light') ? (
            <Brightness4Icon/>
          ):(
          
            <Brightness7Icon/>
          )
        }
        </IconButton>
        </Toolbar>
      </AppBar>
    
    )
  }
  }



  export default NormalMenu;

