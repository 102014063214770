import React,{useState} from 'react'
import {Card,TableHead,Table,TableContainer,TableRow,TableCell,TableBody,CircularProgress,IconButton,Dialog,DialogTitle,DialogContent } from '@material-ui/core'


import GetAppIcon from '@material-ui/icons/GetApp';


export default function Docs(props) {


  const[DialogOpen,SetDialogOpen]=React.useState(false)
  const[DialogMessage,SetDialogMssage]=React.useState('')

//upload a single file only jpg,png,jpeg,bmp



const  uploadimage = async (event,DID)=>{

  var file, formData;
  file = event.target.files[0];
  let size=await file.size


  if(size>250000){
    
alert("Current File Size is Gretaer Than 250KB , File Size Needs to be Under 250KB")
window.location.reload();

  }else{

  
  formData = new FormData();

 
  await formData.append('mediadata', file);

  SetDialogOpen(true)


 await fetch(`/document/${DID}`, {
          method: 'PUT',
          body: formData
          
      }).then(res => {
          if(res.ok) {
            
            SetDialogMssage('Document Upload Complete!')
           
              setInterval(() => {
                SetDialogOpen(false)
                window.location=window.location.href;
              }, 2000);

          }else{
        
         
            alert("Something Went Wrong Can't Upload File Right Now! Please Try Again")
          }
      })




  }

}
  




function downloadfile(DID) {
  
        fetch(`/document/${DID}`, {
            method: 'GET'
        }).then(res => {
            if(res.ok) {

              
      //window.open(`http://localhost:4040/document/${DID}`,"__blank")

         window.open(`https://sunshubhloans.com/document/${DID}`,"__blank")
            
            }else{
         
              alert("Something Went Wrong Can't Download File Right Now.")
            }
        })
}

        return (
           <React.Fragment>

       
      <Card style={{marginBottom:10}}>
      <TableContainer >
       <Table  aria-label="simple table">
         <TableHead>
           <TableRow>
           <TableCell align="left">Operations</TableCell>
         
             <TableCell align="left">Document Type</TableCell>
             <TableCell align="left">File Type</TableCell>
             <TableCell align="left">File Name</TableCell>

             <TableCell align="left">Created At</TableCell>
           
 
             
 
 
 
 
           </TableRow>
         </TableHead>
         <TableBody>
           {props.docs.map((data) => (
             <TableRow key={data.ID}>
                 
                 <TableCell align="left">
     <input type="file" className="form-control" name="file" onChange={(event)=>uploadimage(event,data.ID)}/>
    
      <IconButton onClick={() => downloadfile(data.ID)}>
      <GetAppIcon/>
      </IconButton>
   
    </TableCell>
             
                
               <TableCell align="left">{data.Type}</TableCell>
               <TableCell align="left">{data.Data_Type}</TableCell>
               <TableCell align="left">{data.Name}</TableCell>
            
               <TableCell align="left">{data.Created_At}</TableCell>
 
 
 
 
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </TableContainer>
 
 
                     </Card>


        <Dialog
        open={DialogOpen}
        aria-labelledby="dialog-upload-Progress"
        aria-describedby="dialog-upload-Progress"
      >
        <DialogTitle id="dialog-upload-Progress">{DialogMessage}</DialogTitle>
        <DialogContent style={{display:"flex"}}>
        <CircularProgress />
        </DialogContent>

      </Dialog>
                     </React.Fragment>
        );

}



