import React, { Component } from 'react';
import {Button , Typography , TextField,Box,Grid,Container,
  
  FormControl,MenuItem,Select,
IconButton}  from '@material-ui/core'



import { Alert, AlertTitle } from '@material-ui/lab';


import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


import SaveIcon from '@material-ui/icons/Save'

import UserContext from '../ContextManage/UserContext'
import InputAdornment from '@material-ui/core/InputAdornment';


import * as ApiFunctions from '../../util/apifunction'


class AddLoanAdmin extends Component {
    static contextType =UserContext;


    constructor(props){
        super(props);
        this.state={
          fields: {},
          errors: {},    
                ID:null,
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                Geoon:false,
                Geocord:'',
                inputFields:[ { Name :""  , Phone:""}]
        }


      
    }


   



    async componentDidMount(){
        await this.geocheck()
        const UserContextData = this.context
        await this.setState({
          ID:UserContextData.state.ID,
          Email:UserContextData.state.Email,
          isloggedin:UserContextData.state.loggedin,
          Token:UserContextData.state.Token,
          Role:UserContextData.state.Role
         })
   
       }
     
    


geocheck(){
    if(!navigator.geolocation) {
      console.log("errror in accesssing the geolocation")

      } else {
           
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };
          navigator.geolocation.getCurrentPosition(this.success, this.error, options);
    
      }
}


success=(pos)=> {
    var crd = pos.coords;
    //console.log('Your current position is:');
   // console.log(`Latitude : ${crd.latitude}`);
   // console.log(`Longitude: ${crd.longitude}`);
   // console.log(`More or less ${crd.accuracy} meters.`);
   this.setState({Geoon:true,Geocord:`${crd.latitude} , ${crd.longitude}`})


  }
  
  error=(err)=> {
   alert("Enable Location to fill the Form!")
   
  }




        
handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
    // title
    if(!fields["User_ID"]){
      formIsValid = false;
      errors["User_ID"] = "User_ID Cannot be empty";
    }


      if(!fields["Due_AAL"]){
        formIsValid = false;
        errors["Due_AAL"] = "Due Amount Any Loan Can not be empty";
      }

      if(!fields["DCC"]){
        formIsValid = false;
        errors["DCC"] = "DUE CC Can not be empty";
      }

      if(!fields["Amount_Loan"]){
        formIsValid = false;
        errors["Amount_Loan"] = "Amount Loan Can not be empty";
      }

   
      if(!fields["Occupation"]){
        formIsValid = false;
        errors["Occupation"] = "Occupation Can not be empty";
      }

      if(!fields["Tenure"]){
        formIsValid = false;
        errors["Tenure"] = "Tenure Loan Can not be empty";
      }


      if(!fields["Pay_Day"]){
        formIsValid = false;
        errors["Pay_Day"] = "Pay Day Loan Can not be empty";
      }


      if(!fields["Resident_Status"]){
        formIsValid = false;
        errors["Resident_Status"] = "Resident Status Loan Can not be empty";
      }

      if(!fields["Stay_With_Family"]){
        formIsValid = false;
        errors["Stay_With_Family"] = " Stay_With_Family Can not be empty";
      }

      if(!fields["Income"]){
        formIsValid = false;
        errors["Income"] = "Income Can not be empty";
      }


      if(!fields["Numemembers"]){
        formIsValid = false;
        errors["Numemembers"] = "Earning Member Can not be Empty";
      }


      if(!fields["Numfmembers"]){
        formIsValid = false;
        errors["Numfmembers"] = "Family Members Can not be Empty";
      }


      if(!fields["D_14"]){
        formIsValid = false;
        errors["D_14"] = "Deafult Loan 2014 Can not be empty";
      }
   
      
      if(!fields["Cibil_Score"]){
        formIsValid = false;
        errors["Cibil_Score"] = "Cibil_Score Can not be empty";
      }
   
      
      if(!fields["Loan_Purpose"]){
        formIsValid = false;
        errors["Loan_Purpose"] = "Loan Purpose Can not be empty";
      }
   
   
  
    this.setState({errors: errors});
    return formIsValid;
  }


  
  submituserdata(e){
    e.preventDefault();
     

  
  if(this.handleValidation()){
   
    if(this.checkgdetails()){
      this.adduserloan()
    }
  
   
  
    }else{
      alert("Please Fill The Form")
   }
  
  }

   
  handleChange(field, e){    		
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   // console.log(this.state.fields)
  }






   async adduserloan(){

    const header={
      'Content-Type': 'application/json',
    'x-access-token':this.state.Token
     };

     const data={
      uid:this.state.fields["User_ID"],
      amount:this.state.fields["Amount_Loan"],
      tenure:this.state.fields["Tenure"],
      EmiDate:this.state.fields["Pay_Day"],
      rs:this.state.fields["Resident_Status"],
      swf:this.state.fields["Stay_With_Family"],
      occupation:this.state.fields["Occupation"],
      income:this.state.fields["Income"],
      ne:this.state.fields["Numemembers"],
      nf:this.state.fields["Numfmembers"],
      daloan:this.state.fields["Due_AAL"],
      dcc:this.state.fields["DCC"],
      d14:this.state.fields["D_14"],
      Purpose:this.state.fields["Loan_Purpose"],
      location:this.state.Geocord,
      Status:"Deactive",
      Cibil_Score:this.state.fields["Cibil_Score"],
      guarantor:this.state.inputFields
     }
     try{
      const Data = await ApiFunctions.PostApiData(`/loan/`,header,data)
      
 if( Data.Status===200){
      alert("Successfully Applied for Loan")
   
  }
  if( Data.statuscode===422){
   alert("Please Complete Your Profile!")

}
  if( Data.statuscode===500){
   alert("Internal Server Error!")
 }
      
         
    }catch(err){
      this.setState({isError:true})
      }

    }


    addguarantee(){
      const values = [...this.state.inputFields];
      values.push({ Name: '', Phone: '' });
      this.setState({inputFields:values});
    
    }

    handleRemoveFields (index) {
      if(index===0){
          alert("Please Add At Least One Gaurantee Name and Phone")
      }else{
        const values = [...this.state.inputFields];
        values.splice(index, 1);
        this.setState({inputFields:values})
      }
     
    };


    handleInputChange(index, event){
      const values = [...this.state.inputFields];
      if (event.target.name === "Name") {
        values[index].Name = event.target.value;
      } else {
        values[index].Phone = event.target.value;
      }

      this.setState({inputFields:values})

    };

    checkgdetails(){
      
      let GlistIsValid = true;

      this.state.inputFields.forEach(element => {
     
        if(element.Name===""){
          alert("Name Cant be empty")
          GlistIsValid = false;
        }

        if(element.Phone===""){
          alert("Phone Number Cant be Empty")
          GlistIsValid = false;
        }

      
      });

        
         return GlistIsValid
    }
  
  


    render() {
        return (
     
            <div style={styles.root}>

           


             <Container component="main" maxWidth="md">



             {(this.state.Geoon) ? (
            <span></span>
              ) : (
                <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Location Must Be on To Apply For Loan — <strong>Enable Location and Refresh The Page!</strong>
              </Alert>
              )}




             <br/>
             <Typography variant="h4" component="h2" gutterBottom>
                Add New Loan
              </Typography>
              <br/>
              <form noValidate style={{width:'100%'}}  onSubmit= {this.submituserdata.bind(this)}  >
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                  <Box m={2}>
                            <Typography variant="secondry">User ID ( Enter A Valid ID ) </Typography>
                            </Box>
              <TextField
                 disabled={!this.state.Geoon}
                      autoComplete="password"  name="User_ID"
                      variant="filled" required fullWidth
                      ref="User_ID" id="User_ID"
                      label="User ID" autoFocus
                      onChange={this.handleChange.bind(this, "User_ID")} 
                      value={this.state.fields["User_ID"] || ''}
                  />
                     <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["User_ID"]}</Typography>
                     </Grid>
                   
                </Grid>
               
               
               
               
               
               
               
                {/* for percentage criteria allowed branches salary */}
      
                  <Grid container spacing={3} align="center">
                  <Grid item xs={12} sm={4}>
                        <Box m={2}>
                            <Typography variant="secondry">
                                {`Select Loan Amount`}
                            </Typography>
                            </Box>
                            <br/>
                                                                     
                            <FormControl style={{minWidth:200}}>
                                
                                <Select
                                labelId="Amount Loan"
                                disabled={!this.state.Geoon}
                                id="Amount Loan"
                               
                                onChange={this.handleChange.bind(this, "Amount_Loan")} 
                                value={this.state.fields["Amount_Loan"] || ''}



                                >
                                <MenuItem value="3000">3000</MenuItem>
                                <MenuItem value="5000">5000</MenuItem>
                                <MenuItem value="6000">6000</MenuItem>
                                <MenuItem value="7000">7000</MenuItem>
                                <MenuItem value="8000">8000</MenuItem>
                                <MenuItem value="9000">9000</MenuItem>
                                <MenuItem value="10000">10000</MenuItem>

                                </Select>
                                </FormControl>

                                <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Amount_Loan"]}</Typography>


                          </Grid>
      
                          <Grid item xs={12} sm={4}>
                            <Box m={2}>
                            <Typography variant="secondry">For How Many Month You Need this Loan</Typography>
                            </Box>
                                                                   
                            <FormControl style={{minWidth:200}}>
                                
                                <Select
                                labelId="Tenure"
                                disabled={!this.state.Geoon}
                                id="Tenure"
                                onChange={this.handleChange.bind(this, "Tenure")} 
                                value={this.state.fields["Tenure"] || ''}
                                >
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                </Select>
                                </FormControl>

                                <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Tenure"]}</Typography>

                          </Grid>


                          <Grid item xs={12} sm={4}>
                            <Box m={2}>

                            <Typography variant="secondry">
                                {`Date At Which You want to Pay Emi `}
                            </Typography>
                            </Box>
                            <br/>
                                                                     
                            <FormControl style={{minWidth:200}}>
                                
                                <Select
                                labelId="Pay_Day"
                                disabled={!this.state.Geoon}
                                id="Pay_Day"
                                onChange={this.handleChange.bind(this, "Pay_Day")} 
                                value={this.state.fields["Pay_Day"] || ''}
                                >
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                
                                </Select>
                                </FormControl>

                                <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Pay_Day"]}</Typography>




                            
                            
                          

                          </Grid>



                          

                          



                        </Grid>


                        {  /* for tenur and other things  - 3 module */}


                        <Grid container spacing={3} align="center">
                  <Grid item xs={12} sm={4}>
                        <Box m={2}>
                           



  
                        <Typography variant="secondry">Resident Status</Typography>
                            </Box>
                            <br/>                              
                            <FormControl style={{minWidth:200}}>
                                
                                <Select
                                labelId="Resident_Status"
                                disabled={!this.state.Geoon}
                                id="Resident_Status"
                                onChange={this.handleChange.bind(this, "Resident_Status")} 
                                value={this.state.fields["Resident_Status"] || ''}
                                >
                                  <MenuItem value="Family_Owned">Family Owned</MenuItem>
                                <MenuItem value="Self_Owned">Self Owned</MenuItem>
                                <MenuItem value="Rented">Rented</MenuItem>
                                </Select>
                                </FormControl>

                                <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Resident_Status"]}</Typography>



                          </Grid>
      
                          <Grid item xs={12} sm={4}>
                            <Box m={2}>
                            <Typography variant="secondry"> Occupation </Typography>
                            </Box>
                                  <br/>                                 
                            <FormControl style={{minWidth:200}}>
                                
                                <Select
                                labelId="Occupation"
                                disabled={!this.state.Geoon}
                                id="Occupation"
                                onChange={this.handleChange.bind(this, "Occupation")} 
                                value={this.state.fields["Occupation"] || ''}
                                >
                                 <MenuItem value="Salaried">Salaried</MenuItem>
                                <MenuItem value="Self_Employed">Self Employed</MenuItem>
                            
                                </Select>
                                </FormControl>

                                <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Occupation"]}</Typography>

                          </Grid>


                          <Grid item xs={12} sm={4}>
                            <Box m={2}>
                            <Typography variant="secondry">Stay With Family</Typography>
                            </Box>
                            <br/>                              
                            <FormControl style={{minWidth:200}}>
                                
                                <Select
                                   disabled={!this.state.Geoon}
                                labelId="Stay_With_Family"
                                id="Stay_With_Family"
                                onChange={this.handleChange.bind(this, "Stay_With_Family")} 
                                value={this.state.fields["Stay_With_Family"] || ''}
                                >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                                </Select>
                                </FormControl>

                                <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Stay_With_Family"]}</Typography>

                          </Grid>



                          

                          



                        </Grid>


                        {/*ending of the module 3 */}






      {  /*  starting of  module 4 */}


                      <Grid container spacing={3} align="center">
                      <Grid item xs={12} sm={4}>
                      <Box m={2}>
                        




                      <Typography variant="secondry">Income</Typography>
                          </Box>
                          <br/>                              
                          <FormControl style={{minWidth:200}}>
                              
                              <Select
                              labelId="Income"
                              id="Income"
                              disabled={!this.state.Geoon}
                              onChange={this.handleChange.bind(this, "Income")} 
                              value={this.state.fields["Income"] || ''}
                              >
                              <MenuItem value="Yes Above 7000">Yes Above 7000</MenuItem>
                              <MenuItem value="Yes Above 10000">Yes Above 10000</MenuItem>
                        
                              </Select>
                              </FormControl>

                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Income"]}</Typography>



                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box m={2}>
                          <Typography variant="secondry"> Number of Family Members </Typography>
                          </Box>
                                <br/>                                 
                                <FormControl style={{minWidth:200}}>
                              
                              <Select
                              labelId="Numfmembers"
                              id="Numfmembers"
                              disabled={!this.state.Geoon}
                              onChange={this.handleChange.bind(this, "Numfmembers")} 
                              value={this.state.fields["Numfmembers"] || ''}
                              >
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4-6">4-6</MenuItem>
                              <MenuItem value="More than 6">More than 6</MenuItem>

                              </Select>
                              </FormControl>

                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Numfmembers"]}</Typography>

                  
                        </Grid>


                        <Grid item xs={12} sm={4}>
                          <Box m={2}>
                          <Typography variant="secondry">Number of Earning Members</Typography>
                          </Box>
                          <br/>                              
                        
                          <FormControl style={{minWidth:200}}>
                              
                              <Select
                              labelId="Numemembers"
                              id="Numemembers"
                              disabled={!this.state.Geoon}
                              onChange={this.handleChange.bind(this, "Numemembers")} 
                              value={this.state.fields["Numemembers"] || ''}
                              >
                                      <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4-6">4-6</MenuItem>
                              <MenuItem value="More than 6">More than 6</MenuItem>

                              </Select>
                              </FormControl>

                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Numemembers"]}</Typography>


                        </Grid>



                        

                        



                      </Grid>


            {/*ending of the module 4 */}



            {/* starting of module 5 */}




                      

            <Grid container spacing={3} align="center">
                      <Grid item xs={12} sm={4}>
                      <Box m={2}>
                        



                  {/* integer input only */}
                      <Typography variant="secondry">
                        Due Amount Any Loan    
                      </Typography>
                          </Box>
                          <br/>                              
              <TextField
                        id="Due_AAL"
                        disabled={!this.state.Geoon}
                        type="number"
                        onChange={this.handleChange.bind(this, "Due_AAL")} 
                        value={this.state.fields["Due_AAL"]}
                        InputLabelProps={{
                          shrink: false
                        }}
                        style={{
                          width:'200px'
                        }}
                        InputProps={{
                          inputProps: { max: 100, min: 10 },
                          endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
                        }}
                   
                        />
                  

                          




                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Due_AAL"]}</Typography>



                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box m={2}>
                          <Typography variant="secondry"> Due Amount Credit Card</Typography>
                          </Box>
                                <br/>                                 
                         

                                <TextField
                                   disabled={!this.state.Geoon}
                        id="DCC"
                        onChange={this.handleChange.bind(this, "DCC")} 
                        value={this.state.fields["DCC"]}
                     style={{
                       width:'200px'
                     }}
                        type="number"
                        InputLabelProps={{
                          shrink: false,
                      
                        }}
                        InputProps={{
                          inputProps: { max: 100, min: 10 },
                          endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
                        }}
                     
                   
                        />
                  

                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["DCC"]}</Typography>

                        </Grid>


                        <Grid item xs={12} sm={4}>
                          <Box m={2}>
                          <Typography variant="secondry">Default in Loan Before 2014</Typography>
                          </Box>
                          <br/>                              
                          <FormControl style={{minWidth:200}}>
                              
                              <Select
                              labelId="D_14"
                              id="D_14"
                              disabled={!this.state.Geoon}
                              onChange={this.handleChange.bind(this, "D_14")} 
                              value={this.state.fields["D_14"] || ''}
                              >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                              </Select>
                              </FormControl>

                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["D_14"]}</Typography>

                        </Grid>



                        <Grid item xs={12} sm={4}>
                          <Box m={2}>
                          <Typography variant="secondry">Purpose of Loan</Typography>
                          </Box>
                          <br/>                              
                          <FormControl style={{minWidth:200}}>
                              
                              <Select
                              labelId="Loan_Purpose"
                              id="Loan_Purpose"
                              disabled={!this.state.Geoon}
                              onChange={this.handleChange.bind(this, "Loan_Purpose")} 
                              value={this.state.fields["Loan_Purpose"] || ''}
                              >
                              <MenuItem value="HOME RENOVATION">HOME RENOVATION</MenuItem>
                              <MenuItem value="ADVANCE SALARY">ADVANCE SALARY</MenuItem>
                              <MenuItem value="MEDICAL EMERGENCY">MEDICAL EMERGENCY</MenuItem>
                              <MenuItem value="DEBT CONSOLIDATION">DEBT CONSOLIDATION</MenuItem>
                              <MenuItem value="CONSUMER LOAN">CONSUMER LOAN</MenuItem>
                              <MenuItem value="SCHOOL FEES LOAN">SCHOOL FEES LOAN</MenuItem>
                              <MenuItem value="WEDDING LOAN">WEDDING LOAN</MenuItem>
                              <MenuItem value="CASH CRUNCH">CASH CRUNCH</MenuItem>
                              <MenuItem value="WORKING CAPITAL">WORKING CAPITAL</MenuItem>
                              <MenuItem value="PROVIDING GIFT">PROVIDING GIFT</MenuItem>

                              </Select>
                              </FormControl>

                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Loan_Purpose"]}</Typography>

                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box m={2}>
                          <Typography variant="secondry">CIBIL Score</Typography>
                          </Box>
                          <br/>                              
                          <FormControl style={{minWidth:200}}>
                              
                              <Select
                              labelId="Cibil_Score"
                              id="Cibil_Score"
                              disabled={!this.state.Geoon}
                              onChange={this.handleChange.bind(this, "Cibil_Score")} 
                              value={this.state.fields["Cibil_Score"] || ''}
                              >
                              <MenuItem value="ABOVE 750">ABOVE 750</MenuItem>
                              <MenuItem value="BELOW 650">BELOW 650</MenuItem>
                              <MenuItem value="BETWEEN 650-750">BETWEEN 650-750</MenuItem>
                              <MenuItem value="DONT KNOW">DON'T KNOW</MenuItem>
                          

                              </Select>
                              </FormControl>

                              <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Cibil_Score"]}</Typography>

                        </Grid>


                      </Grid>


<Box m={4}></Box>
                      <Typography variant="h6" color="initial">Add guarantor ( Required )</Typography>
                      
                      <Button variant="contained" color="default" onClick={()=>this.addguarantee()}>
                        Add
                      </Button>
                      {this.state.inputFields.map((inputField, index) => (
                      
            <React.Fragment key={`${inputField}~${index}`}>
            <Box m={4}></Box>
                        
                      <Grid container spacing={2}>

                        <Grid item lg={5}>
                        <TextField
                        id="Gname"
                        fullWidth
                        required
                        name="Name"
                        label="Name"
                        variant="filled"
                        value={inputField.Name}
                        onChange={(event) => this.handleInputChange(index, event)}
                      />
                        </Grid>


                          <Grid item lg={5}>


    
                          <TextField
                        id="GPhone"
                        required
                        name="Phone"
                        fullWidth
                        type="number"
                        variant="filled"
                        label="Phone Number"
                        value={inputField.Phone}
                        onChange={ (event) => this.handleInputChange(index, event)}
                      />

                          </Grid>

                        <Grid item lg={2}>


                        <IconButton  color="primary"  onClick={() => this.addguarantee()}>

                                <AddIcon/>
                                
                        </IconButton>

                        <IconButton    color="secondary"  onClick={() => this.handleRemoveFields(index)}>
                         <RemoveIcon/>

                        </IconButton>



            

                        </Grid>

 



                      </Grid>
         
                      
                     
         
          

           
          
          
            </React.Fragment>
          ))}



              {/* ending of module 5 */}



      <Box m={8} align="center">
              <Button
              align="center"
                  type="submit" 
                  size="large"
                  disabled={!this.state.Geoon}
                  startIcon={<SaveIcon />}
                  variant="contained" color="primary" value="submit" id="submit">
                  Submit
                </Button>
                </Box>
              </form>
              <br/>
              </Container>
              
           
   
             </div>
            
        );
    }
}

export default AddLoanAdmin;



const styles={
    root: {
      display: 'flex',
      flexDirection: 'column',
      height:'auto'
    },
  }


