import React, { Component } from 'react';

class NotFoundPage extends Component {
    render() {
        return (
            <div>
                Not Found Page
            </div>
        );
        
    }
}

export default NotFoundPage;