import React, { Component } from 'react';
import {Button , Typography , TextField,Box,Grid,Container,FormControl,
  InputLabel,Select,Paper,Avatar,Icon}  from '@material-ui/core'
  import Rating from '@material-ui/lab/Rating';

    import UserContext from '../ContextManage/UserContext'

import LoanList from '../AdminComponents/LoanList'
import LoanProfile from './LoanProfile';
import Slider from '@material-ui/core/Slider';

import * as ApiFunctions from '../../util/apifunction'

import Docs from '../CommonComponents/Docs'

import EditRef from './EditRef';

import Autocomplete from '@material-ui/lab/Autocomplete';

import AddRefrence from './AddRefrence';

class EditUser extends Component {
    static contextType =UserContext;
    constructor(props){
        super(props);
        let match = props.match;
        this.state={
          UID:match.params.ID,
          fields: {},
          errors: {},    
          ID:null,
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                Data:{}
          
     
        }
    }


    async componentDidMount(){
      const UserContextData = this.context
      await this.setState({
        ID:UserContextData.state.ID,
        Email:UserContextData.state.Email,
        isloggedin:UserContextData.state.loggedin,
        Token:UserContextData.state.Token,
        Role:UserContextData.state.Role
       })



       await this.GetUserProfile()
 

      let fields=this.state.fields;
      fields["Address"]=this.state.Data.Address
      fields["BankName"]=this.state.Data.BankName
      fields["BankIfsc"]=this.state.Data.BankIfsc
      fields["ANo"]=this.state.Data.ANo
      fields["PNo"]=this.state.Data.PNo
      fields["Mstatus"]=this.state.Data.Married_Status
      fields["BA_Type"]=this.state.Data.BA_Type
      fields["Father_Name"]=this.state.Data.Father_Name
      fields["Bank_Ac_No"]=this.state.Data.Bank_Ac_No
      fields["Alt_No"]=this.state.Data.Alt_No
      fields["Ofc_Name"]=this.state.Data.Ofc_Name
      fields["PinCode"]=this.state.Data.PinCode
      fields["Account_Verified"]=this.state.Data.Account_Verified
      fields["Email_Verified"]=this.state.Data.EmailVerified
      fields["Gender"]=this.state.Data.Gender
      fields["Email"]=this.state.Data.Email
      fields["PhoneNo"]=this.state.Data.PhoneNo
      fields["Full_Name"]=this.state.Data.Full_Name
      fields["BD"]=this.state.Data.DOB
      fields["CNAME"]=this.state.Data.CNAME
      fields["RATE"]=this.state.Data.Rate
      fields["Pan_Verified"]=this.state.Data.Pan_Verified

      await this.setState({fields})
     }



     //profiledata
     
  async GetUserProfile(){
    const header={
      'Content-Type': 'application/json',
      'x-access-token':this.state.Token
   
    };
     try{
      const Data = await ApiFunctions.GetApiRequest(`/user/${this.state.UID}`,header)

     
      if(Data.Status===200){       
              await  this.setState({Data:Data.Data})
              
      
      }else{
          this.setState({isError:true})   
      }
    }catch(err){
      this.setState({isError:true})
      }
    }


  

    async updateownprofile(){

      const header={
        'Content-Type': 'application/json',
        'x-access-token':this.state.Token
       };
  

       const data={
        Bank_Ac_No:this.state.fields["Bank_Ac_No"],
     BankName:this.state.fields["BankName"],
        BankIfsc:this.state.fields["BankIfsc"],
        ANo:this.state.fields["ANo"],
        PNo:this.state.fields["PNo"],
        DOB:this.state.fields["BD"],
        Address:this.state.fields["Address"],
        mstatus:this.state.fields["Mstatus"],
        BA_Type:this.state.fields["BA_Type"],
        fname:this.state.fields["Father_Name"],
        Ofc_Name:this.state.fields["Ofc_Name"],
        Alt_No:this.state.fields["Alt_No"],
        Account_Verified:this.state.fields["Account_Verified"],
        EmailVerified:this.state.fields["Email_Verified"],
        PanVerified:this.state.fields["Pan_Verified"],
        Email:this.state.fields["Email"],
        PhoneNo:this.state.fields["PhoneNo"],
        Gender:this.state.fields["Gender"],
        Full_Name:this.state.fields["Full_Name"],
        PinCode:this.state.fields["PinCode"],
        CNAME:this.state.fields["CNAME"],
        RATE:this.state.fields["RATE"]
        //Married_Status
      
       }


       try{
        const Data = await ApiFunctions.UpdateApiData(`/user/${this.state.UID}`,header,data)

      
       if( Data.Status===200){
           alert("Profile Updated Successfully!")  
        
       }
       if( Data.Status!==200){
        alert("Cant Update Porifle Right Now!")      
   }
     
      }catch(err){
        this.setState({isError:true})
     }
  
 }

 

  toggleRefMenu = () => {
   this.setState({ RefModelOpen: !this.state.RefModelOpen})
}




  handleChange(field, e){    		
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});

   
  }



   
  submituserdata(e){
    e.preventDefault();
    
  if(this.handleValidation()){
     this.updateownprofile()
    }else{
      alert("Please Fill The Form")
   }
  
  }


       
handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  


    //father name

    if(!fields["Father_Name"]){
      formIsValid = false;
      errors["Father_Name"] = "Bank_Name Cannot be empty";
    }



    if(typeof fields["Father_Name"] !== "undefined"){
      if(!fields["Father_Name"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)){
        formIsValid = false;
        errors["Father_Name"] = "Enter a Valid Father Name";
      }      	
    }



   
    if(!fields["BankName"]){
      formIsValid = false;
      errors["BankName"] = "Bank_Name Cannot be empty";
    }



    if(typeof fields["BankName"] !== "undefined"){
      if(!fields["BankName"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)){
        formIsValid = false;
        errors["BankName"] = "Enter A Valid Bank Name";
      }      	
    }


      if(!fields["BankIfsc"]){
        formIsValid = false;
        errors["BankIfsc"] = "Bank_IFSC  Any Loan Can not be empty";
      
      }


      
    if(typeof fields["BankIfsc"] !== "undefined"){
      if(!fields["BankIfsc"].match(/^[A-Z]{4}0[A-Z0-9]{6}$/)){
        formIsValid = false;
        errors["BankIfsc"] = "Enter A Valid IFSC CODE";
      }      	
    }


    //Bank_Ac_No


    if(!fields["Bank_Ac_No"]){
      formIsValid = false;
      errors["Bank_Ac_No"] = "Bank Account Number Cant Be Empty";
    
    }


    
  if(typeof fields["Bank_Ac_No"] !== "undefined"){
    if(String(!fields["Bank_Ac_No"]).match(/^\d{9,18}$/)){
      formIsValid = false;
      errors["Bank_Ac_No"] = "Enter A Valid Bank Account Number";
    }      	
  }



      if(!fields["ANo"]){
        formIsValid = false;
        errors["ANo"] = "Aadhar Card Number Can Not Be Empty";
      }




      if(!fields["PNo"]){
        formIsValid = false;
        errors["PNo"] = " Pan Card Number Can not be empty";
      }



            
    if(typeof fields["PNo"] !== "undefined"){
      if(!fields["PNo"].match(/[A-Z]{5}[0-9]{4}[A-Z]{1}/)){
        formIsValid = false;
        errors["PNo"] = "Enter A Valid  Pan Card Number";
      }      	
    }


    if(typeof fields["PinCode"] !== "undefined"){
      if(!fields["PinCode"].match( new RegExp(/^[1-9][0-9]{5}$/))){
        formIsValid = false;
        errors["PinCode"] = "Enter A Valid  PinCode Number";
      }      	
    }
    
    if(typeof fields["ANo"] !== "undefined"){
      if(!fields["ANo"].match(new RegExp(/^\d{4}\s\d{4}\s\d{4}$/))){
        formIsValid = false;
      errors["ANo"] = "Enter A Valid Aadhar Card Number";
      }      	
   }


    if(!fields["PinCode"]){
      formIsValid = false;
      errors["PinCode"] = "Pincode Can not be empty";
    }
 



      
      if(!fields["Address"]){
        formIsValid = false;
        errors["Address"] = "Address Can not be empty";
      }

      if(!fields["Mstatus"]){
        formIsValid = false;
        errors["Mstatus"] = "Married Status Can not be empty";
      }
   
   
   
  
    this.setState({errors: errors});
    return formIsValid;
  }




 




    render() {

      const {Data}=this.state;
    
   
        return (
            <Container component="main" maxWidth="lg" >

          <Box m={2}></Box>
                        <Typography variant="h5" color="initial">Edit User</Typography>
                        <Box m={2}></Box>
                        <form noValidate style={{width:'100%',marginTop:24}}  onSubmit= {this.submituserdata.bind(this)}  >
                    <Grid container spacing={2}>
                      
       
      
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          {/* UI FOR THE BASIC DATA */}
                          <Paper  variant="outlined" style={{padding:20}}>
                            <div  style={{ 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',}}>
                            <Avatar alt="Remy Sharp" src="/broken-image.jpg">B</Avatar>
                            <Box m={1}></Box>
                            <Typography variant="caption" color="initial">{Data.ID}</Typography>
                            

                           
                            <Box m={2}/>


                              <div>
                            <Typography variant="subtitle1" color="initial">{this.state.fields["RATE"]}/5 {<Icon style={{display:"inline-block",fontSize:16,color:"#faaf00"}}>star</Icon>}</Typography>
                                </div>


                            <FormControl variant="standard">
                                      <InputLabel htmlFor="select_married_status"></InputLabel>
                                      <Select
                                        native
                                        value={this.state.fields["RATE"]}
                                        onChange={this.handleChange.bind(this, "RATE")} 
                                      



                                        label="Risk"
                                        inputProps={{
                                          name: 'Risk',
                                          id: 'Risk',
                                        }}
                                      >
                                  
                                        <option value={1}>Default</option>
                                        <option value={2}>High Risk</option>
                                        <option value={3}>Moderate Risk</option>
                                        <option value={4}>Moderately Low Risk</option>
                                        <option value={5}>Low Risk</option>

                                     
                                      </Select>
                          </FormControl>
                          

                            </div>

                            <Box m={2}/>

                            <TextField label="Full Name" fullWidth margin="normal" variant="outlined" ref="Full_Name"
                    value={this.state.fields["Full_Name"]|| ''}
                    onChange={this.handleChange.bind(this, "Full_Name")} 
                    />

<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Full_Name"]}</Typography>




                            <Box m={2}></Box>
                            <FormControl variant="outlined">
                                      <InputLabel htmlFor="select_gender">Gender</InputLabel>
                                      <Select
                                        native
                                        value={this.state.fields["Gender"]}
                                        onChange={this.handleChange.bind(this, "Gender")} 
                                      



                                        label="Gender"
                                        inputProps={{
                                          name: 'Gender',
                                          id: 'select_gender',
                                        }}
                                      >
                                  
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      
                                     
                                      </Select>
                          </FormControl>
                           

                           <Box m={2}/>




                          <FormControl style={{width:"100%"}} variant="filled">                                                     

<TextField
            id="date"
            label="Birthday"
            type="date"
            variant="outlined"
            defaultValue="2017-05-24"
            value={this.state.fields["BD"] || ''}
            onChange={this.handleChange.bind(this, "BD")}
            InputLabelProps={{
            shrink: true,
            }}
        />

  </FormControl>
  <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["BD"]}</Typography>

                           
                           
                            <Box m={2}></Box>
                         
                            <TextField label="Email" fullWidth margin="normal" variant="outlined" ref="Email"
                    value={this.state.fields["Email"]|| ''}
                    onChange={this.handleChange.bind(this, "Email")} 
                    />

<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Email"]}</Typography>

                         
                           
                           
                            <Box m={2}></Box>
                           
                            <TextField label="PhoneNo" fullWidth margin="normal" variant="outlined" ref="PhoneNo"
                    value={this.state.fields["PhoneNo"]|| ''}
                    onChange={this.handleChange.bind(this, "PhoneNo")} 
                    />

<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["PhoneNo"]}</Typography>

                         
                          
                          
                          
                          <Box m={2}></Box>
                        
                            
                            
                     
                             
                            <FormControl variant="outlined">
                                      <InputLabel htmlFor="select_married_status">Married Status</InputLabel>
                                      <Select
                                        native
                                        value={this.state.fields["Mstatus"]}
                                        onChange={this.handleChange.bind(this, "Mstatus")} 
                                      



                                        label="Married Status"
                                        inputProps={{
                                          name: 'Married Status',
                                          id: 'select_married_status',
                                        }}
                                      >
                                  
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Widow">Widow</option>
                                        <option value="Widower">Widower</option>
                                        <option value="Divorcee">Divorcee</option>
                                     
                                      </Select>
                          </FormControl>
                          <br/>
                             
                                        <br/>
                          <TextField label="Father Name" fullWidth margin="normal" variant="outlined" ref="Father_Name"
                    value={this.state.fields["Father_Name"]|| ''}
                    onChange={this.handleChange.bind(this, "Father_Name")} 
                    />

<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Father_Name"]}</Typography>


                                          <Box m={2}/>
                                          <TextField label="Company Name" fullWidth margin="normal" variant="outlined" ref="CNAME"
                    value={this.state.fields["CNAME"]|| ''}
                    onChange={this.handleChange.bind(this, "CNAME")} 
                    />

<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["CNAME"]}</Typography>



                                          <Box m={2}/>
     
                             
                             
                  <TextField label="Alternate Number" fullWidth margin="normal" variant="outlined" ref="Alt_No"
                    value={this.state.fields["Alt_No"] || ''}
                    onChange={this.handleChange.bind(this, "Alt_No")} 
                    />

<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Alt_No"]}</Typography>



<Grid container spacing={2}>

{/* for the additional user data */}
<Grid item xs={12} lg={8} xl={8} md={8}>
<TextField label="Address" margin="normal" variant="outlined" multiline rows={4} fullWidth
                    value={this.state.fields["Address"] || ''}
                    onChange={this.handleChange.bind(this, "Address")} 
                    />
                               <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Address"]}</Typography>

          
</Grid>

<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
<TextField label="Pin Code" margin="normal" variant="outlined" 
                    value={this.state.fields["PinCode"] || ''}
                    onChange={this.handleChange.bind(this, "PinCode")} 


                    



                    />
                               <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["PinCode"]}</Typography>

          
  </Grid>


  </Grid>

                   
                          </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12}  md={7} lg={7} xl={7}>
                        <Paper  variant="outlined" style={{padding:20}}>
    

    <Box m={1}></Box>

        <Typography variant="h6" color="primary">Banking Details</Typography>

    <TextField label="Name As Per Bank Account" margin="normal" fullWidth variant="outlined" ref="Ofc_Name" style={{textTransform:"uppercase"}}
                    value={this.state.fields["Ofc_Name"] || ''}
                    
                    onChange={this.handleChange.bind(this, "Ofc_Name")} 
                    />

<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Ofc_Name"]}</Typography>



    <TextField label="Bank Account Number" margin="normal" fullWidth variant="outlined" type="number"
                    value={this.state.fields["Bank_Ac_No"] || ''}
                    onChange={this.handleChange.bind(this, "Bank_Ac_No")} 
                    
                    />
                               <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Bank_Ac_No"]}</Typography>

     
    <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            {/* Bank Name */}
                            <Autocomplete
                               value={this.state.fields["BankName"] || ''}
                             
                               onInputChange={(event, newInputValue) => {
                              const fields=this.state.fields;
                               fields["BankName"]=newInputValue;
                                this.setState({fields})
          

                              }}
                                    id="free-solo-demo"
                                    freeSolo
                                    options={top100Films.map((option) => option.title)}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Bank Name" margin="normal" variant="outlined" />
                                    )}
                                  />
                                                                    <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["BankName"]}</Typography>
     
                        </Grid>


      {/* Bank Name Ending */}


      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

            {/* Bank IFSC starting */}

            <TextField label="Bank IFSC Code" margin="normal" variant="outlined"
                    value={this.state.fields["BankIfsc"] || ''}
                    onChange={this.handleChange.bind(this, "BankIfsc")} 
                    />
                           <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["BankIfsc"]}</Typography>



                    {/* BANK IFSC ENDING */}

        </Grid>



        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                             
        <FormControl variant="outlined" style={{marginTop:15}}>
      
                                      <InputLabel htmlFor="select_married_status">Account Type</InputLabel>
                                      <Select
                                        native
                                        value={this.state.fields["BA_Type"]}
                                        onChange={this.handleChange.bind(this, "BA_Type")} 
                                      



                                        label="Account Type"
                                        inputProps={{
                                          name: 'Account Type',
                                          id: 'select_account_type',
                                        }}
                                      >
                                  
                                        <option value="SAVINGS">SAVING</option>
                                        <option value="CURRENT">CURRENT</option>
                                     
                                      </Select>
                          </FormControl>
                             

                          </Grid>


        </Grid>

        <Typography variant="h6" color="primary">Identification</Typography>

        <Grid container spacing={2}>


                 <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                    {/* Adhar Crad starting */}


                    <label>Give Space After 4 digit For Aadhar Card</label>
                    <TextField label="Aadhar Card Number" margin="normal" variant="outlined"
                                value={this.state.fields["ANo"] || ''} style={{width:"100%"}}
                                onChange={this.handleChange.bind(this, "ANo")} 
                                />
<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["ANo"]}</Typography>


                          {/* Adhar Crad Ending */}

                </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                          {/* Pan card Starting */}
<br/>

                                    <TextField label="Pan Card Number" margin="normal" variant="outlined"
                                value={this.state.fields["PNo"] || ''} style={{width:"100%"}}
                                onChange={this.handleChange.bind(this, "PNo")} 
                                />
<Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["PNo"]}</Typography>

                    
                    {/* Pan Card Ending */}
                    </Grid>

            </Grid>
           
            <Box m={2}></Box>
    <Typography variant="subtitle1" color="textSecondary">Created At :-   { <span style={{color:"black"}}>{new Date(Data.Created_At).toString()}</span>  }</Typography>
    <Box m={2}></Box>
                  <FormControl variant="outlined" style={{marginTop:15,width:200}}>
                    
                    <InputLabel htmlFor="Account_verified">Account Verified</InputLabel>
                    <Select
                      native
                      value={this.state.fields["Account_Verified"]}
                      onChange={this.handleChange.bind(this, "Account_Verified")} 
                    



                      label="Account Verified"
                      inputProps={{
                        name: 'Account Verified',
                        id: 'select_account_type',
                      }}
                    >
                
                      <option value="True">True</option>
                      <option value="False">False</option>
                  
                    </Select>
              </FormControl>



              <Box m={2}></Box>
                  <FormControl variant="outlined" style={{marginTop:15,width:200}}>
                    
                    <InputLabel htmlFor="Email_Verified">Email Verified</InputLabel>
                    <Select
                      native
                      value={this.state.fields["Email_Verified"]}
                      onChange={this.handleChange.bind(this, "Email_Verified")} 
               

                      label="Email_Verified"
                      inputProps={{
                        name: 'Email_Verified',
                        id: 'Email_Verified',
                      }}
                    >
                
                      <option value={1}>True</option>
                      <option value={0}>False</option>
                  
                    </Select>
              </FormControl>


              <Box m={2}></Box>
                  <FormControl variant="outlined" style={{marginTop:15,width:200}}>
                    
                    <InputLabel htmlFor="Kyc_Verified">Kyc Completion</InputLabel>
                    <Select
                      native
                      value={this.state.fields["Pan_Verified"]}
                      onChange={this.handleChange.bind(this, "Pan_Verified")} 
               

                      label="Kyc Complete"
                      inputProps={{
                        name: 'Pan_Verified',
                        id: 'Pan_Verified',
                      }}
                    >
                
                      <option value='YES'>YES</option>
                      <option value='NO'>NO</option>
                  
                    </Select>
              </FormControl>
                            


            <Box m={2}></Box>
                    <Button 
               align="center"
               type="submit" 
               
               variant="contained" color="primary" value="submit" id="submit"
              
                    >
                      Update
                    </Button>
      </Paper>


                      <Box m={2}/>
                          <Paper style={{padding:25}}>
                       
                          {(this.state.Data.loans) ? ( 
        <>
  <LoanProfile UID={Data.ID}/>

        </>

  

      ) : (
        <span>loading....</span>
      )}

                          </Paper>

                      
      </Grid>
    
      </Grid>

      </form>

                      <Box m={2}/>
    
      <Box m={2}></Box>

      {(this.state.Data.loans) ? ( 
        <>

  <LoanList loans={this.state.Data.loans} role={this.state.Role}  />


   <Box m={8}>
     
     
     </Box>  


     


        </>

  

      ) : (
        <span>loading....</span>
      )}



<Grid container spacing={2}>
  

          <Grid item>
  
          <Typography variant="h6" color="initial">User References</Typography>


          </Grid>

          <Grid item>


          <Button variant="contained" color="default"  onClick={this.toggleRefMenu}>
            Add Refrence
          </Button>


          </Grid>

</Grid>

<AddRefrence UID={Data.ID}  RefModelOpen={this.state.RefModelOpen} toggleRefMenu={this.toggleRefMenu} />

{(this.state.Data.user_refrs) ? ( 
  <EditRef REF={this.state.Data.user_refrs}  />

      ) : (
        <span>Loading  ....</span>
      )}


  



<Box m={4}></Box>




{(this.state.Data.documents) ? ( 
  <Docs docs={this.state.Data.documents}  />

      ) : (
        <span>loading  Documents....</span>
      )}


  

         </Container>
        );
    }
}

export default EditUser;




const top100Films = [
    { title: 'Allahabad Bank', year: 1994 },
    { title: 'Andhra Bank', year: 1972 },
    { title: 'Bank of Baroda', year: 1974 },
    { title: 'Bank of India', year: 2008 },
    { title: 'Bank of Maharashtra', year: 1957 },
    { title: "Canara Bank", year: 1993 },
    { title: 'Central Bank of India', year: 1994 },
    { title: 'Corporation Bank', year: 2003 },
    { title: 'Dena Bank', year: 1966 },
    { title: 'ICICI Bank', year: 1950 },
    { title: 'Indian Bank', year: 1999 },
 
    { title: 'Indian Overseas Bank', year: 2001 },
    { title: 'Oriental Bank of Commerce', year: 1980 },
    { title: 'Punjab & Sind Bank', year: 1994 },
    { title: 'Punjab National Bank', year: 2010 },
    { title: 'Syndicate Bank', year: 2002 },
    { title: "UCO Bank", year: 1975 },
    { title: 'Union Bank of India', year: 1990 },
    { title: 'United Bank Of India (UBI)', year: 1999 },
    { title: 'Vijaya Bank', year: 1954 },
    { title: 'IDBI Bank Limited', year: 1977 },
    { title: 'State Bank of India', year: 2002 },
    { title: 'State Bank Of Bikaner & Jaipur', year: 1995 },
    { title: 'State Bank of Hyderabad', year: 1991 },
    { title: "State Bank of Mysore", year: 1946 },
    { title: 'State Bank of Patiala', year: 1997 },
    { title: 'State Bank of Travancore', year: 1995 },
    { title: 'City Union Bank', year: 1994 },
    { title: 'Dhanalakshmi Bank', year: 2001 },
    { title: 'Federal Bank', year: 1998 },
    { title: 'ING Vysya Bank Ltd', year: 1968 },



    { title: 'Jammu & Kashmir Bank', year: 1998 },
    { title: 'Karnataka Bank', year: 2014 },
    { title: 'Karur Vysya Bank', year: 1942 },
    { title: 'Lakshmi Vilas Bank', year: 1931 },
    { title: 'Nanital Bank', year: 1960 },
    { title: 'The Ratnakar Bank', year: 1999 },
    { title: 'SBI Commercial & International Bank', year: 2011 },
    { title: 'South India Bank', year: 1936 },
    { title: 'Tamilnadu Mercantile Bank', year: 1981 },
    { title: 'United Western Bank', year: 1954 },
    { title: 'IndusInd Bank', year: 2002 },
    { title: 'HDFC Bank', year: 2006 },
    { title: 'ICICI Bank', year: 1991 },
    { title: 'Axis Bank', year: 1985 },
    { title: 'Kotak Mahindra Bank', year: 2014 },
    { title: 'Yes Bank', year: 2000 },
    { title: 'Development Credit Bank (DCB Bank)', year: 2000 },
    { title: 'Citi Bank', year: 2006 },
    { title: 'HSBC', year: 1994 },
    { title: 'Abu Dhabi Commercial Bank', year: 1979 },
    { title: 'Amercian Express Bank', year: 1979 },
    { title: 'Standard Chartered Bank', year: 1950 }
   
 
  ];

  
  const options = top100Films.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });