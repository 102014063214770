import React, { Component } from "react";

import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "@material-ui/core/Container";
import CountUp, { useCountUp } from 'react-countup';

import Avatar from "@material-ui/core/Avatar";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import ScrollAnimation from "react-animate-on-scroll";

import Paper from "@material-ui/core/Paper";
import Count from '../../util/Count'
import Counntdata from '../../util/countdata.json'
//icons
import TimerIcon from "@material-ui/icons/Timer";
import { Android,ShopOutlined} from "@material-ui/icons";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import StarsIcon from "@material-ui/icons/Stars";

import UserContext from "../ContextManage/UserContext";

//loan reasons
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import PaymentIcon from "@material-ui/icons/Payment"; //advance salary
import LocalHospitalIcon from "@material-ui/icons/LocalHospital"; //medical
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"; //CASH
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"; //bank emi
import HomeIcon from "@material-ui/icons/Home";
import CategoryIcon from "@material-ui/icons/Category"; //working capital

import CheckIcon from "@material-ui/icons/Check";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import TextMobilestepper from "../CommonComponents/TextMobileStepper";
import TestiMonials from "../CommonComponents/Testimonials";

import ProductCarosuel from "./ProductCarsouel";
import DowloadApp from "./DowloadApp";



class HomePage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      Email: null,
      Role: null,
      Token: null,
      isloggedin: false,
    };
  }

  async componentDidMount() {
    this.typingEffect();
    const UserContextData = this.context;
    await this.setState({
      ID: UserContextData.state.ID,
      Email: UserContextData.state.Email,
      isloggedin: UserContextData.state.loggedin,
      Token: UserContextData.state.Token,
      Role: UserContextData.state.Role,
    });
  }

  typingEffect() {
    const contactTexts = this.shuffleArray([
      "Start Your Business Now",
      "Instant Loan Approval",
      "Loan For Your Needs",
    ]);
    const typedtext = document.getElementsByClassName("typedtext")[0];
    let removing = false;
    let idx = 0;
    let char = 0;

    setInterval(() => {
      // We define the interval of the typing speed

      // If we do not reach the limit, we insert characters in the html
      if (char < contactTexts[idx].length)
        typedtext.innerHTML += contactTexts[idx][char];

      // 15*150ms = time before starting to remove characters
      if (char == contactTexts[idx].length + 15) removing = true;

      // Removing characters, the last one always
      if (removing)
        typedtext.innerHTML = typedtext.innerHTML.substring(
          0,
          typedtext.innerHTML.length - 1
        );

      char++; // Next character

      // When there is nothing else to remove
      if (typedtext.innerHTML.length === 0) {
        // If we get to the end of the texts we start over
        if (idx === contactTexts.length - 1) idx = 0;
        else idx++;

        char = 0; // Start the next text by the first character
        removing = false; // No more removing characters
      }
    }, 150); // Typing speed, 150 ms
  }

  shuffleArray(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }



  

  render() {
    return (
      <React.Fragment>
        <Container maxWidth="lg">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 50,
            }}
          >
          
            <Box m={4}></Box>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              delay={500}
            >
              <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <img
                    src="/homepagefirstgif.gif"
                    style={{ width: "100%", height: "100%" }}
                    alt="logo"
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography
                  style={{height:90}}
                    component="h1"
                    variant="h3"
                    align="center"
                    color="primary"
                    gutterBottom
                    className="typedtext"
                  ></Typography>
                  <Typography
                  
                    variant="body1"
                    align="center"
                    color="textSecondary"
                  >
                    Need immediate cash?
                  </Typography>

                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    Now get the money you need and whenever you need it with
                    Sunshubh Loans.
                  </Typography>

                  <Grid container spacing={2} justify="center">
                    {this.state.isloggedin ? (
                      <React.Fragment>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.props.history.push("/app/create-loan/")
                            }
                          >
                            APPLY NOW
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.props.history.push("/app/calculator")
                            }
                          >
                            EMI Calculator
                          </Button>
                        </Grid>

                        <div style={{width:'80%',marginTop:20}}>
                            <Button startIcon={<Android />} fullWidth size="large"  target="_blank" href="https://sunshubhloans.com/download/content/apk" variant="contained" color="primary">
                    Download our android app
                  </Button>
                            </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.props.history.push("/register")}
                          >
                            Get Started
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.props.history.push("/calculator")
                            }
                          >
                            Calculator
                          </Button>
                        </Grid>
                            <div style={{width:'80%',marginTop:20}}>
                            <Button startIcon={<Android />} fullWidth size="large"  target="_blank" href="https://sunshubhloans.com/download/content/apk" variant="contained" color="primary">
                    Download our android app
                  </Button>
                            </div>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </ScrollAnimation>
          </div>
        </Container>

        <Box m={8}></Box>

        <div style={{ backgroundColor: "rgba(116,185,255,0.2)", padding: 50 }}>

                    
        
                  


          <Container maxWidth="lg">
            <Grid
              container
              spacing={2}
              justify="center"
              style={{ textAlign: "center" }}
            >
                {
          
          Counntdata.counts.map(count => <Count key={count.id} data={count} extension={count.extension}/>)
          
          
          }

            </Grid>
          </Container>
        </div>

        <div style={{ backgroundColor: "#f4f6f8" }}>
          <br />
          <br />
          <br />
          <br />
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
            <ScrollAnimation
              delay={1000}
              animateIn="tada"
              initiallyVisible={true}
            >
              <Typography
                variant="h4"
                color="initial"
                align="Center"
                gutterBottom
              >
                Why choose Us ?
              </Typography>
            </ScrollAnimation>

            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
              paragraph
            >
              We believe your journey should continue without any speed bumps.{" "}
              <br />
              Avail our most attractive loans with best interest rates and
              comfortable repayment tenures.
            </Typography>

            <br />
            <br />
    
            <Container maxWidth="lg">
              <Grid
                container
                spacing={2}
                justify="center"
                style={{ textAlign: "center" }}
              >
                <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                  <ButtonBase>
                    <TimerIcon
                      color="primary"
                      style={{ fontSize: 60, marginBottom: 20 }}
                    />
                  </ButtonBase>

                  <br />
                  <Typography gutterBottom variant="subtitle2">
                    Within Minutes
                  </Typography>
                  <Typography variant="button" gutterBottom color="primary">
                    Fast and Easy
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} xs={6} sm={3}>
                  <ButtonBase>
                    <StarsIcon
                      color="primary"
                      style={{ fontSize: 60, marginBottom: 20 }}
                    />
                  </ButtonBase>
                  <br />
                  <Typography gutterBottom variant="subtitle2">
                    Best Rate
                  </Typography>
                  <Typography variant="button" color="primary" gutterBottom>
                    Competitive Rate
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} xs={6} sm={3}>
                  <ButtonBase>
                    <LinearScaleIcon
                      color="primary"
                      style={{ fontSize: 60, marginBottom: 20 }}
                    />
                  </ButtonBase>

                  <Typography gutterBottom variant="subtitle2">
                    Upto 10000 Rs.
                  </Typography>
                  <Typography variant="button" gutterBottom color="primary">
                    Wide Range Loans
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                  <ButtonBase>
                    <CheckIcon
                      color="primary"
                      style={{ fontSize: 60, marginBottom: 20 }}
                    />
                  </ButtonBase>

                  <br />
                  <Typography gutterBottom variant="subtitle2">
                    Government Approved
                  </Typography>
                  <Typography variant="button" gutterBottom color="primary">
                    Sahukari Licence
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} xs={6} sm={3} justify="center">
                  <ButtonBase>
                    <GroupAddIcon
                      color="primary"
                      style={{ fontSize: 60, marginBottom: 20 }}
                    />
                  </ButtonBase>

                  <br />
                  <Typography gutterBottom variant="subtitle2">
                    Customers
                  </Typography>
                  <Typography variant="button" gutterBottom color="primary">
                    More Than 100 Customers
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <br />
            <br />
            <br />
            <br />
          </ScrollAnimation>
        </div>

        <Box m={12}></Box>
       
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">


        
        <Container className="LOAN-SEECTOR">

        <ScrollAnimation
                delay={1000}
                animateIn="tada"
                initiallyVisible={true}
              >
          <Typography
            component="h4"
            variant="h4"
            id="title"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            OUR PRODUCTS
          </Typography>
          </ScrollAnimation>
                              <Box m={4}/>
          <Typography variant="h6" gutterBottom align="center">
            <b> We have best loan products for your needs</b>
          </Typography>

          <Typography variant="subtitle2" gutterBottom align="center">
          We are a registered DSA. Partnered with all the leading lending houses.
          </Typography>

          <Box m={8}/>
          <ProductCarosuel />

          <Box m={2}/>
          
          
          <Typography variant="h6" gutterBottom align="center">
            <b>APPLY NOW</b>
          </Typography>

          <Box m={2}/>
          
          <Grid
            container
            spacing={1}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            
          >
              
      
              <Grid item>
              <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.props.history.push("/personal-loan/")
                            }
                          >
                            I am salaried Person
                          </Button>
              </Grid>

              <Grid item>
              <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              this.props.history.push("/business-loan/")
                            }
                          >
                              I am Business Person
                          </Button>
                </Grid>


          </Grid>

        </Container>
        

</ScrollAnimation>

        <Box m={16}></Box>
        <div style={{ backgroundColor: "#f4f6f8",padding:"64px 0 64px 0"}}>
        
          <Container maxWidth="lg">
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <ScrollAnimation
                delay={1000}
                animateIn="tada"
                initiallyVisible={true}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  id="title"
                  align="center"
                  color="textPrimary"
                  gutterBottom
                >
                  What Our Loan Can Cover
                </Typography>
              </ScrollAnimation>

              <Typography variant="subtitle1" gutterBottom align="center">
                We will match your with our loan product that best suits your
                financial need in short term liquidity.
              </Typography>
            </ScrollAnimation>
            <br />
            <br />
            <Grid
              container
              spacing={2}
              justify="center"
              style={{ textAlign: "center" }}
            >
              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <FlightTakeoffIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Travel Support
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <PaymentIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Advance Salary
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <CardGiftcardIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Providing Gift
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <LocalHospitalIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Medical Emergency
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <AttachMoneyIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Cash Crunch
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <HomeIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Home Renovation
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <AccountBalanceIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Debt Consolidation
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={3} sm={4} md={4}>
                <Paper style={{ padding: 20 }} className="feature-box">
                  <CategoryIcon
                    color="primary"
                    style={{ fontSize: 50, marginBottom: 20 }}
                  />

                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    Working Capital
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Box m={12}></Box>
    
       {/*    <div tyle={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
         
            }}>
            <DowloadApp/>
      
            </div> */}
        
        <Box m={12}></Box>
        <div>
         
          <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
            <ScrollAnimation
              delay={1000}
              animateIn="tada"
              initiallyVisible={true}
            >
              <Typography
                variant="h4"
                color="initial"
                align="Center"
                gutterBottom
              >
                How it Works
              </Typography>
            </ScrollAnimation>

            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
              paragraph
            >
              Ensure Hussle Free Transparent Quick and Easy Process
            </Typography>

            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                  <img
                    src="/howitworks.png"
                    style={{ width: "90%", height: "90%" }}
                    alt="how it works"
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="1"
                          style={{ backgroundColor: "#3f51b5" }}
                          src="/broken-image.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="button"
                              display="block"
                              color="primary"
                              style={{ marginTop: 12 }}
                              gutterBottom
                              className="hover-text"
                            >
                              REGISTER BORROWER
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="2"
                          style={{ backgroundColor: "#3f51b5" }}
                          src="/broken-image.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="button"
                              display="block"
                              color="primary"
                              style={{ marginTop: 12 }}
                              gutterBottom
                              className="hover-text"
                            >
                              UPLOADING DOCUMENTS FOR VERIFICATION
                            </Typography>
                          </React.Fragment>
                        }
                        gutterBottom
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="3"
                          style={{ backgroundColor: "#3f51b5" }}
                          src="/broken-image.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="button"
                              display="block"
                              style={{ marginTop: 10 }}
                              color="primary"
                              gutterBottom
                              className="hover-text"
                            >
                              EVALUATION OF LOAN APPLICATION
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="4"
                          style={{ backgroundColor: "#3f51b5" }}
                          src="/broken-image.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="button"
                              display="block"
                              color="primary"
                              style={{ marginTop: 12 }}
                              gutterBottom
                              className="hover-text"
                            >
                              CREDIT EVALUATION
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="5"
                          style={{ backgroundColor: "#3f51b5" }}
                          src="/broken-image.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="button"
                              display="block"
                              color="primary"
                              style={{ marginTop: 12 }}
                              gutterBottom
                              className="hover-text"
                            >
                              APPROVAL OF LOAN
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="6"
                          style={{ backgroundColor: "#3f51b5" }}
                          src="/broken-image.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="button"
                              display="block"
                              color="primary"
                              style={{ marginTop: 12 }}
                              gutterBottom
                              className="hover-text"
                            >
                              DIBURSAL OF LOAN
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="7"
                          style={{ backgroundColor: "#3f51b5" }}
                          src="/broken-image.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant="button"
                              display="block"
                              color="primary"
                              style={{ marginTop: 12 }}
                              gutterBottom
                              className="hover-text"
                            >
                              REPAYMENT OF LOAN
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </ScrollAnimation>
          <br />
          <br />
        </div>

        <TestiMonials />
      </React.Fragment>
    );
  }
}

export default HomePage;
