import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { rolesConfig } from '../config/roles';
import * as Routes from './index';
import LoggedInMenu from '../Components/Menus/LoggedInMenu'


import cookie from 'react-cookies'
import jwtdecode from 'jwt-decode';
import NoPageFound from '../Components/Pages/NotFoundPage';
	
const token=cookie.load("token")



class PrivateRoutes extends Component {

	constructor(props){
		super(props);
	this.state = { allowedRoutes: [] };
	}



async componentDidMount() {
		if(!token){
			this.props.history.push('/');
		}else{
			try{
				jwtdecode(token)
				const data=jwtdecode(token);
					let roles=[data.Role]
					roles = ['common', ...roles];
					let allowedRoutes = roles.reduce((acc, role) => {
						return [...acc, ...rolesConfig[role].routes];
					}, []);
					
					// For removing duplicate entries, compare with 'url'.
					allowedRoutes = uniqBy(allowedRoutes, 'url');
					this.setState({ allowedRoutes });

			}catch(err){
				this.props.history.push('/');
			}
		}	

	}



	render() {
		return (
			<Fragment>
				<LoggedInMenu
				routes={this.state.allowedRoutes}
				path={this.props.match.path}
				/>
				<Switch>
					{this.state.allowedRoutes.map((route) => (
						<Route
							exact
							key={route.url}
							component={Routes[route.component]}
							path={`${this.props.match.path}${route.url}`}
						/>
					))}
					<Route component={NoPageFound} />
				</Switch>
			
			</Fragment>
		);
	}
}

export default PrivateRoutes;


