import React, { Component } from 'react';
import {Card,TableHead,Typography,
  Table,TableContainer,Button,TableRow,TableCell,TableBody,IconButton,Box,Dialog,DialogTitle} from '@material-ui/core'

import UserContext from '../ContextManage/UserContext'

import * as ApiFunctions from '../../util/apifunction'

class UserLocation extends Component {
    static contextType =UserContext;

    constructor(props){

        super()
        let match = props.match;
        this.state={
          UID:match.params.UID,
          Loading:true,
          Data:[],
          Contacts:[],
          isError:false,
          ID:null,
          Email:null,
          Role:null,
          Token:null,
          isloggedin:false,
      
        }
      }
    


      async componentDidMount(){
  
        console.log('.......')
        const UserContextData = this.context
        await this.setState({
          ID:UserContextData.state.ID,
          Email:UserContextData.state.Email,
          isloggedin:UserContextData.state.loggedin,
          Token:UserContextData.state.Token,
          Role:UserContextData.state.Role
         })
    
     
         await this.GetUserLocationProfile()
       
         
       }


    
    async GetUserLocationProfile(){
   

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", this.state.Token);
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
      await  fetch(`https://sunshubhloans.com/api/location/${this.state.UID}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result.Data)
            this.setState({Data:result.Data,Loading:false})
          })
          .catch(error => console.log('error', error));

      }
  



    render() {
        return (
            <div>

                {
                  (this.state.Loading) ? (
                      <h1>Loading...</h1>
                  ) :(

       
                    <Card style={{marginBottom:10}}>

            

                    <TableContainer >
                     <Table  aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           
                           <TableCell align="left">ID</TableCell>
                           <TableCell align="left">User_ID</TableCell>
                           <TableCell align="left">IP</TableCell>
                           <TableCell align="left">Location</TableCell>
                           <TableCell align="left">Created_At</TableCell>
                         
               
               
               
                         </TableRow>
                       </TableHead>
                       <TableBody>
                       {this.state.Data.map((data) => (
            <TableRow key={data.ID}>
                  <TableCell align="left">{data.ID}</TableCell>
                  <TableCell align="left">{data.User_ID}</TableCell>
                  <TableCell align="left">{data.IP_Data}</TableCell>
                 
                  <TableCell align="left">{data.Location}</TableCell>
                
                  <TableCell align="left"> {new Date(data.Created_At).toString()}</TableCell>
                 
             

            </TableRow>
          ))}
                       </TableBody>
                     </Table>
                   </TableContainer>
               
               
                                   </Card>

                   )
                }

                           </div>
        );
    }
}

export default UserLocation;

