import React, { Component } from 'react'




//mui imports 
import {Button , Typography , TextField,Box,FormControl,
    InputLabel,Select,Paper,Avatar,DialogContent,DialogTitle,
    Dialog,DialogActions,MenuItem, IconButton,InputAdornment,OutlinedInput
    }  from '@material-ui/core'
    import { Done, SendOutlined } from '@material-ui/icons';

    import UserContext from '../ContextManage/UserContext'
//functional imports
import * as ApiFunctions from '../../util/apifunction'


export default class AddGaurantor extends Component {
    static contextType =UserContext;
 
    constructor(props){
        super(props);
        this.state={ 
          ID:null,
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                GurantorFields:{},
                GurantorErrors:{},
                GaurantorModalOpen:false   
        }
    }
  

    componentWillReceiveProps(props) {
   
      this.setState({ GaurantorModalOpen: this.props.AddGaurantorModelOpen })
    }
  
    
  async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })

   }
     
handleGurantorInputChange(field, e){    		
    let GurantorFields = this.state.GurantorFields;
    GurantorFields[field] = e.target.value;        
    this.setState({GurantorFields});
    console.log('GaurantoField',GurantorFields)
  }



  redirect=(time,link)=>{
    setTimeout(function(){ window.location.href=`${link}` }, time);
  }
  
  

AddGuarantor=async()=>{
    const header={
      'Content-Type': 'application/json',
      'x-access-token':this.state.Token
     };
  
     const data={
      "Name":this.state.GurantorFields["Name"],
      "Email":this.state.GurantorFields["Email"],
      "PhoneNumber":this.state.GurantorFields["Phone"],
      "Age":this.state.GurantorFields["Age"],
      "Address":this.state.GurantorFields["Address"],
      "Relation":this.state.GurantorFields["Relation"],
      "EmailVerified":this.state.GurantorFields["EmailVerified"],
      "PhoneVerified":this.state.GurantorFields["PhoneVerified"],
      "Loan_ID":this.props.LoanID,
      "User_ID":this.state.ID
  }
  
     try{
      const Data = await ApiFunctions.PostApiData(`/gurantor/add`,header,data)
  
      if(Data.Status===200){
        alert('Guarantor Added');
       this.redirect(1000,`/app/edit-loan/${this.props.LoanID}`);
      }else{
        console.log(Data)
        alert('Cant Add Refrence');
      }
  
    }catch(err){
     alert('Inernal Server Error');
   }
  }
  

  
redirect=(time,link)=>{
    setTimeout(function(){ window.location.href=`${link}` }, time);
  }
  
  

  render() {
    return (
        <Dialog
        
        fullWidth
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={this.state.GaurantorModalOpen}
      >
        <DialogTitle id="confirmation-dialog-title">Add Gaurantor</DialogTitle>
        <DialogContent dividers>

        <form noValidate style={{width:'100%',marginTop:0}}>

        <TextField label="Name" margin="normal" variant="outlined"
                                value={this.state.GurantorFields["Name"] || ''} 
                                style={{width:"100%"}}
                            
                                onChange={this.handleGurantorInputChange.bind(this, "Name")} 
                                />


<TextField label="Age" margin="normal" variant="outlined" type="number"
                                value={this.state.GurantorFields["Age"] || ''} 
                                style={{width:"100%"}}
                                onChange={this.handleGurantorInputChange.bind(this, "Age")} 
                                />


<FormControl style={{width:'100%'}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
<OutlinedInput label="Email" margin="normal" variant="outlined" type="email"
                                value={this.state.GurantorFields["Email"] || ''} 
                                style={{width:"100%"}}
                                onChange={this.handleGurantorInputChange.bind(this, "Email")} 
                          
                              
                              />
</FormControl>
<Box m={2}/>
<FormControl style={{width:'100%'}}>
                    <InputLabel htmlFor="Relation">Email Verified</InputLabel>
                    <Select
                    
                      value={this.state.GurantorFields["EmailVerified"] || ''}
                      onChange={this.handleGurantorInputChange.bind(this, "EmailVerified")}
                      label="Email Verified"
                
                      inputProps={{
                        name: 'EmailVerified'
                      }}
                    >
                      <MenuItem value="YES">YES</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
<Box m={3}/>



<FormControl style={{width:'100%'}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Phone Number</InputLabel>
<OutlinedInput label="Phone" margin="normal" variant="outlined" type="number"
                                value={this.state.GurantorFields["Phone"] || ''} 
                                style={{width:"100%"}}
                                onChange={this.handleGurantorInputChange.bind(this, "Phone")} 
                                
                                endAdornment={
                                  <InputAdornment position="end">
                                        {
                                          (this.state.GurantorFields["PhoneVerified"]==="NO")?(
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>this.SendGuarantorOTPVerification(this.state.GurantorFields["Phone"])}
                                            edge="end"
                                          >
                                          <SendOutlined/>
                                          </IconButton>
                                          ):(
                                                <></>
                                          )
                                        }
                                  </InputAdornment>
                                }
                                />
                                </FormControl>

<Box m={3}/>

{
  (this.state.GurantorFields["PhoneVerified"]==="NO")?(

<FormControl style={{width:'100%'}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">OTP</InputLabel>
<OutlinedInput label="OTP" margin="normal" variant="outlined" type="number"
                                value={this.state.GurantorFields["OTP"] || ''} 
                                style={{width:"100%"}}
                                onChange={this.handleGurantorInputChange.bind(this, "OTP")} 
                                
                                />
                                </FormControl>
  ):(
<></>
  )
}


<Box m={3}/>

<FormControl style={{width:'100%'}}>
                    <InputLabel htmlFor="Relation">Phone Verified</InputLabel>
                    <Select
                      
                      value={this.state.GurantorFields["PhoneVerified"]}
                      onChange={this.handleGurantorInputChange.bind(this, "PhoneVerified")}
             
                      label="Phone Verified"
                      inputProps={{
                        name: 'PhoneVerified'
                      }}
                    >
                      <MenuItem value="YES">YES</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>



                    <Box m={3}/>

                  <FormControl style={{width:'100%'}}>
                    <InputLabel htmlFor="Relation">Relation</InputLabel>
                    <Select
                      
                      value={this.state.GurantorFields["Relation"] || ''}
                      onChange={this.handleGurantorInputChange.bind(this, "Relation")}
                      label="Relation"
                      inputProps={{
                        name: 'Relation',
                        id: 'select_Relation',
                      }}
                    >
                      <MenuItem  value="Friend">Friend</MenuItem >
                      <MenuItem  value="Family">Family</MenuItem >
                      <MenuItem  value="Colleague">Colleague</MenuItem>
                    </Select>
                  </FormControl>
                  <Box m={3}/>
<TextField label="Address" margin="normal" variant="outlined"
                                value={this.state.GurantorFields["Address"] || ''} 
                                style={{width:"100%"}}
                                multiline
                                rows={4}
                                onChange={this.handleGurantorInputChange.bind(this, "Address")} 
       />
        </form>
        </DialogContent>
        <DialogActions>

        <Button  color="primary" variant='outlined' onClick={()=>this.AddGuarantor()}>
            SAVE
          </Button>
        <Button  onClick={()=>this.props.toggleAddGaurantorModel()}  color="primary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
