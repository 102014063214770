import React, { Component } from "react";
import Slider from "react-slick";
import {Container,Typography,Avatar,Paper,Box ,Card,CardContent} from '@material-ui/core'
import ScrollAnimation from 'react-animate-on-scroll'

export default class Testimonials extends Component {
  render() {
    const settings = {

      infinite: true,
    
      autoplay: true,
      speed: 10000,
      autoplaySpeed: 10000,
      slidesToShow: 3,
      slidesToScroll: 3,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div  style={{ backgroundColor: "#f4f6f8",padding:"64px 0 64px 0" }}>
      <Container maxWidth="lg">
    
    <ScrollAnimation delay={1000}
          animateIn='tada'
          initiallyVisible={true}>
     <Typography variant="h4" color="initial" align="center">Testimonials</Typography>
     <Box m={2}></Box>
       </ScrollAnimation>
     <Typography variant="body1" align="center" color="textSecondary" paragraph>
     Our customers choose us because they believe in the value of the platform. <br/>
     They are an integral part of our Shubhparivar. See what they have to say about us!
        </Typography>

     <Box m={8}/>
        <Slider {...settings}>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        Its really an excellent site where people can get avail of loans with a easy and transparent process.
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        REKHA SINGH
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
       Home Renovation
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
          <div>
          <Card>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        I had always found Sunshubhloans a very trustworthy, easy and fast to take loan.
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        KRISHNA YADAV
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
       Advance Salary
        
        </Typography>
      </CardContent>
     
    </Card>



          </div>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        Sunshubhloans provide loans to me, when other companies refused me for the loan.
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        SHRIKUMAR KORI
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
       Working Capital Loan
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        Wanted to gift my mom and dad on there anniversary and needed quick funds. Sunshubhloans have very quick and smooth process.
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        NILAM DAVE
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
    Providing  Gift 
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        Sunshubhloans help me to reviev my busniess with there loan which was badly affected due to covid-19 Pandemic.
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        SHIV PRITHVIPAL CHAUHAN
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
       Working Capital Loan
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        I am very happy with the process.Its easy to apply and very convenient to pay the EMIs.

        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        MANOJKUMAR RAULA
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
       Debt Consolidation
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        Help me in the case of emergency time and made the loan as fast as possible.
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        IRSHAD AFAZ ALI
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
    Medical Emergency
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
        Best thing about the Sunshubhloans is the process of applying, its convenient, fast, easy, and very user friendly. I recommend !
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
        SNEHA THAKURPRASAD GUPTA
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
         Cash Crunch
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
          <div>
          <Card style={{margin:10}}>
          <Avatar src="/broken-image.jpg" style={{margin:10}}/>

      <CardContent>
        <Typography  color="textPrimary" gutterBottom>
      I am Very Happy About the Process it's Easy to Apply and very Convenient to pay the EMIS.
      Helped me in my emergency time and made the loan as fasy as possile i recommend!
        </Typography>
        <br/>
        
        <Typography  color="textSecondary">
      MANOJKUMAR RAVALA
        </Typography>
        <br/>
       
        <Typography variant="body2" component="p">
      School Fees
        
        </Typography>
      </CardContent>
     
    </Card>
          </div>
        </Slider>
      
        </Container>
        </div>
    );
  }
}

