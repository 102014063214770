import React, { Component } from 'react'
import {Select,CssBaseline, Container, Typography,Button,TextField,Box,FormControl,InputLabel } from '@material-ui/core'


import UserContext from '../ContextManage/UserContext'


import SaveIcon from '@material-ui/icons/Save'; 


export default class UserProfile extends Component {

  static contextType =UserContext;
  constructor(props){
      super(props);
      this.state={
              fields: {},
              errors: {},   
              Data:[],
              Settings:[],
              ID:null,
              Email:null,
              Role:null,
              Token:null,
              isloggedin:false,
              isloading:true,
      }
  }


  async componentDidMount(){
    const UserContextData = this.context.state
   await this.setState({
      ID:UserContextData.ID,
      Email:UserContextData.Email,
      isloggedin:UserContextData.loggedin,
      Token:UserContextData.Token,
      Role:UserContextData.Role,
     })

     await this.fetchsettings()
  }
  


async fetchsettings(){
  await fetch('/adminsettings', {
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
        'x-access-token':this.state.Token
     }
   }).then(function(response) {
     if (response.status >= 400) {
       throw new Error("Bad response from server");
     }
     return response.json();
   }).then(response=>this.setState({ Settings:response.result,settingfecthed:true}))
   .catch(err => {
      alert("Some Error ,Cant Load The Setting Right Now!",err)
      this.props.history.push('/')
   })

  this.setdata()
}

async setdata(){

  let fields = this.state.fields;
  const {Settings}=this.state
  let inputfield=["SiteTitle","SiteUrl","AdminEmail","Theme","TagLine","SiteLogo","GSV","PADM","GA"]
  let x;
  for(x in inputfield){
  
    fields[inputfield[x]] = Settings[x].Value    
  }
     
 await this.setState({fields});
}
    
handleValidation(){
  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;


  if(!fields["SiteTitle"]){
      formIsValid = false;
      errors["SiteTitle"] = "Site Title Cant Be Empty";
    }
    
  
    if(!fields["Theme"]){
      formIsValid = false;
      errors["Theme"] = "Theme Can not be empty";
    }
 


  this.setState({errors: errors});
  return formIsValid;
}




submituserdata(e){
  e.preventDefault();
  if(this.handleValidation()){
    this.setState({snackbaropen:true})
  
    this.UpdateSettingsDetail()
  }else{
    alert("Please Fill The Form.")
  }

}

handleChange(field, e){    		
  let fields = this.state.fields;
  fields[field] = e.target.value;        
  this.setState({fields});
}


async UpdateSettingsDetail(){
  const data={
      SiteTitle:this.state.fields["SiteTitle"],
      TagLine:this.state.fields["TagLine"],
      AdminEmail:this.state.fields["AdminEmail"],
      Theme: this.state.fields["Theme"],
      GSV:this.state.fields["GSV"],
      PADM:this.state.fields["PADM"]
  }

  await fetch(`/adminsettings/update`, {
    method: 'PATCH', // or 'PUT'
    headers: {
   'Content-Type': 'application/json',
   'x-access-token':this.state.Token
   },
       body: JSON.stringify(data),
     })
     .then(
      function(response) {
        if (response.status === 200) {
           window.location.reload()
          return;
      }  
      if (response.status >= 500) {
          alert('Something Wrong!' +
            response.status)
          return;
      }
  
      }
    )
    .catch((error)=>{
      console.log(error)
    })
}




 


  render() {
    return (
     <Container maxWidth="xs" align="left">
       <CssBaseline/>
      <Typography variant="h3" gutterBottom>Settings</Typography>


      <form noValidate style={{width:'100%'}}  onSubmit= {this.submituserdata.bind(this)}  > 

          <TextField
          id="SiteTitle"
          label="Site Title"
          fullWidth
          refs="SiteTitle"
          required
          onChange={this.handleChange.bind(this, "SiteTitle")} value={this.state.fields["SiteTitle"]}
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
         <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>
             {this.state.errors["SiteTitle"]}
             </Typography>
        <Box m={2}></Box>
    
          <TextField
          id="SiteUrl"
          disabled
          fullWidth
          label="Site Url"
          refs="SiteUrl"
          required
         value={this.state.fields["SiteUrl"]}
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
          <Box m={2}></Box>

        <TextField
          id="AdminEmail"
          fullWidth
          label="Admin Email"
          refs="AdminEmail"
          onChange={this.handleChange.bind(this, "AdminEmail")} value={this.state.fields["AdminEmail"]}
          required
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>
             {this.state.errors["AdminEmail"]}
             </Typography>
   <Box m={2}></Box>


   <FormControl style={{width:'100%'}}>
        <InputLabel htmlFor="theme-selector">Theme</InputLabel>
        <Select
          native
          onChange={this.handleChange.bind(this, "Theme")} 
          value={this.state.fields["Theme"]}
          ref="Theme"
          //onChange={handleChange}
          inputProps={{
            name: 'theme',
            id: 'theme-selector',
          }}
        >
          <option value='#f44336'>Red</option>
          <option value='#e91e63'>Pink</option>
          <option value='#9c27b0'>Purple</option>
          <option value='#673ab7'>Deep Purple</option>

          <option value='#3f51b5'>Indigo</option>
          <option value='#2196f3'>Blue</option>
          <option value='#03a9f4'>Light Blue</option>
          <option value='#00bcd4'>Cyan</option>

          <option value='#009688'>Teal</option>
          <option value='#4caf50'>Green</option>
          <option value='#8bc34a'>Light Green</option>
          <option value='#cddc39'>Lime</option>

          <option value='#ffeb3b'>Yellow</option>
          <option value='#ffc107'>Amber</option>
          <option value='#ff9800'>Orange</option>
          <option value='#ff5722'>Deep Orange</option>
        </Select>
        </FormControl>
    <br/>


    <Box m={2}></Box>


        <TextField
          id="TagLine"
          fullWidth
          label="TagLine"
          refs="TagLine"
          onChange={this.handleChange.bind(this, "TagLine")} value={this.state.fields["TagLine"]}
          required
          maxWidth="20px"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
         <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>
             {this.state.errors["TagLine"]}
             </Typography>
        <Box m={2}></Box>

        <TextField
          id="SiteLogo"
          fullWidth
          label="SiteLogo"
          refs="SiteLogo"
          onChange={this.handleChange.bind(this, "SiteLogo")} value={this.state.fields["SiteLogo"]}
          required
          maxWidth="20px"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
         <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>
             {this.state.errors["SiteLogo"]}
             </Typography>
        <Box m={2}></Box>

        <TextField
          id="GSV"
          fullWidth
          label="Google Site Verification"
          refs="GSV"
          onChange={this.handleChange.bind(this, "GSV")} value={this.state.fields["GSV"]}
          required
          maxWidth="20px"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
         <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>
             {this.state.errors["GSV"]}
             </Typography>
        <Box m={2}></Box>


        <TextField
          id="PADM"
          fullWidth
          label="Propeller Ads Meta Code"
          refs="PADM"
          onChange={this.handleChange.bind(this, "PADM")} value={this.state.fields["PADM"]}
          required
          maxWidth="20px"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
        />
         <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>
             {this.state.errors["PADM"]}
             </Typography>
        <Box m={2}></Box>


            <TextField
              id="GA"
              fullWidth
              label="Google Analtics Code"
              refs="GA"
              onChange={this.handleChange.bind(this, "GA")} value={this.state.fields["GA"]}
              required
              maxWidth="20px"
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>
                {this.state.errors["GA"]}
                </Typography>
            <Box m={2}></Box>



               <Button
        align="center"
        fullWidth
            type="submit" 
            size="large"
            startIcon={<SaveIcon />}
            variant="contained" color="primary" value="submit" id="submit">
            Submit
          </Button>
          </form>
     </Container>
    )
  }
}
