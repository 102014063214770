import React, { Component } from 'react';

import * as ApiFunctions from '../../util/apifunction'
import UserContext from '../ContextManage/UserContext'
import Checkbox from '@material-ui/core/Checkbox'

import '../../App.css'


import { Container, Typography, Box, Button } from '@material-ui/core'

let list = [];
class LoanAgreement extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    let match = props.match;
    this.state = {
      fields: {},
      errors: {},
      ID: null,
      Email: null,
      Role: null,
      Token: null,
      isloggedin: false,
      LoanID: match.params.LID,
      Data: {},
      isloading: true,
      gabutton: false,
      Gender_Prefix: '',
      checked: false

    }
  }




  async componentDidMount() {
    const UserContextData = this.context
    await this.setState({
      ID: UserContextData.state.ID,
      Email: UserContextData.state.Email,
      isloggedin: UserContextData.state.loggedin,
      Token: UserContextData.state.Token,
      Role: UserContextData.state.Role
    })

    await this.GetloanDetails()
     this.genlist()
     this.set_gender_prefix()

    if (this.state.Data.Agreement_Signed === 'NO') {
      this.setState({ checked: false })
    } else {
      this.setState({ checked: true })
    }

  }


  set_gender_prefix() {

    if (this.state.Data.User_Gender === "Male") {
      this.setState({ Gender_Prefix: "Mr." })
    }
    if (this.state.Data.User_Gender === "Female" && this.state.Data.User_Gender === "Single") {
      this.setState({ Gender_Prefix: "Ms." })
    }

    if (this.state.Data.User_Gender === "Female") {
      this.setState({ Gender_Prefix: "Mrs." })
    }
  }




  genlist() {


    for (var i = 1; i <= this.state.Data.Tenure; ++i) {

      list.push(<tr><td>{i}</td><td>{Math.ceil((this.state.Data.Amount) / (this.state.Data.Tenure))}</td><td>{this.state.Data.Total_Interest}</td><td>{Math.ceil((this.state.Data.Amount) / (this.state.Data.Tenure)) + this.state.Data.Total_Interest}</td></tr>);


    }
  }


  inWords(num) {

    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];


    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';

    return str;
  }



  handleCheckboxChange = event => {
    this.setState({ checked: event.target.checked })
  }

  async GetloanDetails() {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    try {
      const Data = await ApiFunctions.GetApiRequest(`/loan/customer/singleloan/${this.state.LoanID}`, header)


      if (Data.Status === 200) {
        await this.setState({ Data: Data.Data, isloading: false })

      } else {
        this.setState({ isError: true, isloading: true })
      }
    } catch (err) {
      this.setState({ isError: true })
    }
  }





  async signdoc() {

    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };

    const data = {
      Agreement_Signed: 'YES'
    }

    try {

      const Data = await ApiFunctions.PostApiData(`/loan/customer/signloan/${this.state.LoanID}`, header, data)


      if (Data.Status === 200) {
        alert("Document Signed")
        // this.props.history.push(`/app/agreement/${this.state.LoanID}`)
        window.location.reload()
      }
      if (Data.statuscode === 422) {

        this.setState({ ErrorDialog: true })
        setTimeout(() => {
          this.setState({ ErrorDialog: false })

          //      window.location.href="/app/users-profile"
        }, 5000);

      }
      if (Data.statuscode === 500) {
        alert("Internal Server Error!")
        window.location.href = window.location

      }



    } catch (err) {
      this.setState({ isError: true })
    }

  }

  render() {
    return (
      <Container>
        <Box m={4} />
        <Typography variant="h4" color="initial">Loan Agreement</Typography>
        <Box m={4} />

        {(this.state.isloading) ? (<React.Fragment>

          <h1>Loading.....</h1>

        </React.Fragment>) : (


          <div style={{ width: '100%', height: '650px', display: 'block', overflowY: 'scroll', padding: '10px', border: '2px solid blue', marginBottom: '20px' }}>
            <h5 style={{textAlign:'center',fontSize:20}}>LOAN AGREEMENT AND PROMISSORY NOTE</h5>
            <label>
              <div>
                THIS LOAN AGREEMENT AND PROMISSORY NOTE (the “Note”), is made this {new Date(this.state.Data.Created_At).toString()} by and among SUNSHUBH SOLUTIONS PVT LTD through its Director Mr. SHUBHAM KAMLESH SINGH HAVING PAN NO: - GOYPS1947J, an adult Indian inhabitant, DOB-25-09-1996, having commercial address at SHOP NO 11 APEKSHA APT, WALIV VASAI EAST- 401208., with a mailing address of COMPANY@SUNSHUBHLOANS.COM (hereinafter, known as “LENDER”).</div>
            </label>

            <h4 style={{textAlign:'center',fontSize:20}}>AND</h4>


            <span>  {this.state.Gender_Prefix} {this.state.Data.User_Ofc_Name.toUpperCase()} having   PAN Number :- {this.state.Data.User_PNo}
              <br /></span>
            <br />
            {(this.state.Data.User_Gender === "Male") ? (`S/O : ${this.state.Data.User_Father_Name.toUpperCase()}`) : ((`D/O :${this.state.Data.User_Father_Name}`))}
            an adult Indian inhabitant,DOB: - "user bitrhtdate" residing at R/O:- {this.state.Data.User_Address}.,with a mailing address of  <b>{this.state.Data.User_Email}</b> (hereinafter, known as “BORROWER”).

            <br /><br />
            BORROWER and LENDER shall collectively be known herein as “the Parties”.
            In determining the rights and duties of the Parties under this Loan Agreement, the entire document must be read as a whole.
            <br />


            <p className="title"><b>1.Definitions: -</b></p>

            <span>Capitalized terms, unless defined elsewhere in this Agreement, shall have the meaning assigned to such terms in Appendix 1 of this Agreement, as the case may be.</span>
            <br />
            <br />
            <b>1.1.Disbursement Details</b>

            <table className="demo">
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td>Bank Account</td>
                  <td>{this.state.Data.User_Ba_Ac_No}</td>
                </tr>
                <tr>
                  <td>Account Holder Name</td>
                  <td>{this.state.Data.User_Ofc_Name.toUpperCase()}</td>
                </tr>
                <tr>
                  <td>Type of Account</td>
                  <td>{this.state.Data.USER_BA_Type}</td>
                </tr>
                <tr>
                  <td>Bank Name</td>
                  <td>{this.state.Data.User_BankName.toUpperCase()}</td>
                </tr>
                <tr>
                  <td>IFSC Code</td>
                  <td>{this.state.Data.User_BankIfsc.toUpperCase()}</td>
                </tr>
              </tbody>
            </table>
            <br /><br /><br /><b><p className="title">2. Loan Disbursement Terms: - </p></b><br />
            The BORROWER and LENDER, hereby further set forth their rights and obligations to one another under this Loan Agreement and Promissory Note and agree to be legal bound as follows:
            <br />
            <label><u>A. Principal Loan Amount</u> Rs.{this.state.Data.Amount}/- {this.inWords(this.state.Data.Amount).toUpperCase()} RUPEES ONLY)</label>
            <br />
            <label><u>B. Tenure of Loan: -</u><br />
              The Borrower shall repay the loan in <b>{this.state.Data.Tenure} </b> equal monthly installments amounting to Rs. {this.state.Data.ROI}, along with the interest at Rs.{this.state.Data.ROI} per every hundred per month, total amounting to Rs. (TOTAL VALUE).</label>
            <br />
            <label><u>C. Purpose of Loan: - </u></label><br />The purpose of this loan is solely for <b><u>{this.state.Data.Purpose}.</u></b>, and the Borrower hereby declares that he shall not use this said loan amount for any other purpose then the purpose stated herein above.
            <br />
            <label><u>D. Due Date of First & Subsequent Installments: -</u></label><br />
            The Parties do hereby agree and confirms that: <br />
            a) In case, if the Loan Amount is received into the Borrower’s Bank Account on or before the 20th day of that particular month, in that case the First Installment shall become due and payable by the Borrower to the Lender on {this.state.Data.EmiDate}th day of the first succeeding month of the Loan Date and thereafter all the subsequent installments shall become due and payable on the same day of the subsequent months;
            <br />b) In case, if the Loan Amount is received into the Borrower’s Bank Account after 20th day of that particular month, in that case the First Installment shall become due and payable by the Borrower to the Lender on day of the second {this.state.Data.EmiDate}th succeeding month of the Loan Date and thereafter all the subsequent installments shall become due and payable on the same day of the subsequent months.
            <br /><br />
            <label><u>E.Loan Repayment Schedule.: -</u></label><br />
            The Borrower do hereby covenants with the Lender to repay the Loan Amount together with interest payable in following manner:
            <br /><br /><br /> <table className="demo">
              <thead>
                <tr>
                  <th>Installment</th>
                  <th>Principal (Rs.)</th>
                  <th>Interest (Rs.)</th>
                  <th>Total amount (Rs.)</th>
                </tr>
              </thead>
              <tbody>
                {list}
              </tbody>

            </table>

            <br />
            <br />
            <br />
            <br />
            <br /><b><p className="title">3.Payment of Interest:- </p></b>

            <b>3.1 Interest:</b>     The Borrower shall pay to the Lenders, Interest on the Loan at the rate at Rs.{4} per every hundred per month, in the manner and on the dates specified in clause 2.1(C) hereof. Such interest will be computed from the respective Due Dates and shall become payable with daily/weekly/monthly rests, or such other rests as may be prescribed by the Lenders from time to time, and shall be payable on the dates specified in this Clause 2.1 (C)above.

            <br />         <b>3.2 Delay Charges and Default Interest:</b> In the event any monies remain due and payable by the Borrower to the Lenders, which have not been paid by the Borrower on the respective Due Dates such due and unpaid amounts shall carry further “Delay charges” as mentioned in Annexure 1.
            <br />     <b> 3.3</b> In case of Borrower fails to repay two consecutive monthly installment amount on the due dates, Default Interest will be payable by the Borrower to the Lenders at the Default Interest rate 2% per month on outstanding amount as on the date of default and such Default Interest will accrue on a monthly basis from the date of the occurrence of the Event of Default until the cure of the Event of Default to the satisfaction of the Lenders.
            <br /> <b>3.4</b> The Borrower shall bear all Taxes, costs, charges and expenses (including, without limitation, stamp duty and relevant registration and filing charges in connection with this Agreement and / or the other Loan Documents) as may be levied from time to time in respect of or in connection with the Loan, this Agreement and / or the other Loan Documents.

            <br /><br /><b><p className="title">4. Method of Loan Payment.</p></b><br />
            a)The Borrower do hereby covenants with the Lender that for the purpose of repayment of the Loan Amount together with Interest and if applicable, Default Interest and Delay Charges, thereon by way of monthly Installment Simultaneously upon signing of this Loan Agreement, the Borrower shall either facilitate the same in advance through National Automated Clearing House Mandate (“NACH Mandate”) for the tenure covering all the installments, in favour of the Lender or any other person or by handing over post-dated duly signed cheques for repayment of a loan Installment to the Lender.
            <br />  b)The Borrower do hereby further covenants that without prior written consent of the Lender, the Borrower shall not close that bank account from which the NACH Mandate has been facilitated or from which the cheques has been issued, without prior intimation to Lender and making alternate arrangement for the payment of the balance Installments. Any instruction for closure of account without the consent of the Lender shall be deemed to be an event of default and consequence as set out in Clause 7 shall ensure.


            <br />

            <br /><b>
              <p className="title">5.Appropriation of Payments:- </p></b> <br />
            The Borrower hereby agrees and confirms that any payment made by him/her to the Lender under and/or in terms of this Loan Agreement shall be appropriated in the following manner:- Firstly, towards the costs, fees, expenses and other charges, if any, which the Lender may have to incur for the recovery of amounts payable by the Borrower under this Loan Agreement; Secondly, towards the payment of Default Interest and Delay Charges, due and payable under this Loan Agreement; Thirdly, towards the payment of Interest, due and payable on the Loan Amount; Lastly, towards the payment of Installment of the Principal Loan Amount.
            <br />
            <br /><b><p className="title">6. Prepayment of Loan: - </p></b>
            <br />
            <label>The Lender hereby agrees and confirms that at any time after repayment of the installments, the Borrower shall have the option to repay before the due dates any or all of the installments, which may be outstanding at that point of time, provided that the Borrower shall remain liable to pay the interest on the Loan Amount outstanding till the date of payment.</label>
            <br />
            <br />
            <b><p className="title">7. Promissory Note:- </p></b><br />
            <label>FOR VALUE RECEIVED, BORROWER promises to repay to the order of LENDER, the sum of Rs.{this.state.Data.Amount}/- ({this.inWords(this.state.Data.Amount)} RUPEES ONLY) together with interest thereon at the rate at Rs.{4} per every hundred per month</label>

            <br /><br /><b><p className="title">8. Additional Provisions Regarding Default: -</p></b><br />

            <b>a. Change of Address</b> <br />
            <label>The LENDER shall in all times notify the BORROWER about his change in address for any communication purpose and/or sending out default notices and/or any other purpose called for herein. The LENDER shall also provide an alternative contact detail where he/she shall be available to communicate with. Failing in provide the change of address details in case of missed payment shall result in default and shall be liable to the legal consequences as stated below in this agreement.</label>
            <br /><b>b. Cure of Default</b><br />
            <label>Upon default, LENDER shall give BORROWER written notice of default.  Mailing of written notice by LENDER to BORROWER via Postal Service Certified Mail, or on the Borrowers registered Email Address stated herein above shall constitute prima facie evidence of delivery.  BORROWER shall have 15 days after receipt of receiving the notice of default from LENDER to cure said default.  In the case of default due solely to BORROWER’S failure to make timely payment as called for in this loan agreement, BORROWER may cure the default by either:
              <br />(i) making full payment of any principal and accrued interest (including default interest on these amounts) whose payment to LENDER is overdue under the loan agreement and, also, the late-payment penalty described below.  </label>
            <br />
            <b>c. Indemnification of Legal Fee’s and Out-of-Pocket Cost’s</b><br />
            <label>The Borrower agrees to pay to the Lender the fees as set out in table penal below. The Borrower hereby authorities the Lender to deduct these amounts together with applicable taxes from the Loan Amount and to pay to the Borrower only the balance amount. The Borrower hereby confirms that irrespective of deduction of such fees, the obligation of the Borrower to repay to the Lender shall be of the entire Loan Amount along with Interest, and other charges together with applicable taxes in terms of this Agreement. The fees paid / deducted by the Lender is to meet the out-of-pocket expenses and the same is non- refundable / non-adjustable. The Borrower hereby undertakes to reimburse / pay charges amounting to Rs 200 to the Lender for every visit by the representative of the Lender to the premises of the Borrower for collection of the dues outstanding from time to time. </label>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br /><b>GAP INTEREST WILL BE CALCULATED ON THE BASIS OF:- </b><br />
            <label>
              1) SANCTIONED DATE /AMOUNT DISBURSEMENT DATE </label><br />
            <br />
            <br />
            <label> 2) EMI DATE </label><br />
            <br />
            <br />
            <label>3) AS PER THE CONDITION PROVIDED clause C.</label> <br />

            <p><b>d. Applicable fees:-</b></p>
            <table className="demo">
              <tbody>
                <tr>
                  <td>Processing Charges</td>
                  <td>Rs. {this.state.Data.ROI}%</td>
                </tr>
                <tr>
                  <td>Legal Charges</td>
                  <td>Rs. 1%</td>
                </tr>
                <tr>
                  <td>Documentation Charges :</td>
                  <td>Rs. 1%</td>
                </tr>
              </tbody>
            </table>

            <br /><b>f. Additional Default Methods: - </b><br />
            <label><b>a.Misrepresentation:</b> Any representations, information, statements or particulars provided by the Borrower or the Guarantor or on behalf of the Borrower or the Guarantor under any Loan Document or other certificates, undertakings and/or declarations are found to be incorrect or misleading when made or provided or deemed to be made or provided.  </label>
            <br /><label> <b>b.Breach of Other Obligations:</b> The Borrower or the Guarantor commits or threatens to commit any breach or default in performance or observance of this Agreement or any Loan Document or fails to keep or perform any of the terms or provisions of any other agreement or other writings between the Lenders and the Borrower in respect of the Loan. </label>
            <br />


            <br /><b><p className="title">9.Powers Conferred by the Borrower In favour of the Lender:-</p></b><br />

            <label>The Borrower hereby irrevocably empowers and appoints the Lender to exercise any rights mentioned in this Loan Agreement and/or exercisable by the Lender on behalf of the Borrower and also to act and represent the Borrower in such of the acts expressed to be necessary for the purpose of carrying out the terms and conditions of this Loan Agreement and to represent the Borrower before all authorities including the Borrower’s employer, banker, etc. and the Borrower undertakes to furnish all such information, statements, documents and the papers to be submitted or furnished or to be filed before any authority.</label>
            <br />
            <br />
            <b><p className="title">10. Covenants and Undertakings of the Borrower: -</p></b><br />
            <label>The Borrower do hereby agree, covenants and undertakes as under </label>
            <br /><label>a) That the Borrower shall pay and bear all expenses including stamp Duty and registration charges on actual basis and other charges and expenses which may be incurred in preparation of this Loan Agreement and/or any other related or incidental documents as may be required to be executed in future in connection with the disbursal of the loan to the Borrower by the Lender.</label>
            <br /><label>b) That in case, if the Lender incurs any legal or other charges or expenses for the recovery of any part of the Loan Amount together with Interest, Default Interest and Delay Charges, then in that event the Borrower shall also be liable to pay the amounts paid by the Lender for such purposes along with interest on such amounts at the rate of Default Interest mentioned hereinabove, from the date of receipt of demand notice by the Borrower. </label>
            <br /><label>c) That the statement of account forward by the Lender or by any other person or entity duly authorized by the Lender in that behalf to the Borrower, shall be accepted by the Borrower as the conclusive proof of the correctness of the Lender’s claim due and payable by the Borrower on that particular date. </label>
            <br /><label>d) That the Lender shall be entitled to disclose and furnish to any of the Credit Information Agencies duly authorized by the RBI in that behalf, all such data and information describing the manner in which the Borrower is performing his/her obligations arising under this Loan Agreement and the Borrower shall not be entitled to raise any objection/s in respect thereof. </label>
            <br /><label>e) That the data and the information so disclosed and furnished by the Lender to any of the Credit Information Agencies may be used or processed by such Agencies in the manner as they may deem fit and proper to test the creditworthiness of the Borrower; </label>
            <br /><label>f) That the rights, powers and remedies given to the Lender by this Loan Agreement shall be in addition to all rights, powers and remedies given to the Lender by virtue of any other statute or rule of law.</label>
            <br /><label>g) That the Lender at his/her absolute discretion may at any point of time set-off any of the obligation/s and/or the part of the obligation/s of the Borrower arising under this Loan Agreement. </label>
            <br /><label>h) That at the absolute discretion of the Lender, the Lender shall be entitled to enforce this Loan Agreement himself/herself personally or through any other person or entity duly authorized in writing by the Lender in that behalf and in that event all the covenants and undertakings given by the Borrower to the Lender shall be deemed to have been duly given by the Borrower to such other person or entity who is duly authorized in writing by the Lender;</label>
            <br /><label>i) That the Borrower shall comply with all covenants, terms, conditions stipulated in this Loan Agreement and shall fully indemnify and keep indemnified the Lender from and against all actions, proceedings, liabilities, claims, demands, loses, damages, costs, charges and expenses whatsoever in respect of or in relation to or arising out all obligations and liabilities of the Borrower under this Loan Agreement.</label>
            <br />
            <br />

            <br />

            <b><p className="title">11. Representation and Warrenties of the Borrower :-</p></b>
            <label>Each of the Borrower and the Guarantor(s) (to the extent applicable) makes the following representations and warranties and states that the same are true, correct, valid and subsisting in every respect as of the date of this Agreement, as on each day until the Final Settlement Date:</label>
            <br /><label>(a) The Borrower is an eligible Borrower and has full capacity, power and authority to execute, deliver and perform its obligations under this Agreement.</label>
            <br /><label>(b) This Agreement and all Loan Documents when executed by the Borrower and/or the Guarantor will be valid and binding obligations of the Borrower and/or the Guarantor and enforceable in accordance with their respective terms and the person/persons executing this Agreement (on behalf of the Borrower) and the Loan Documents to be executed pursuant hereto, is/are duly and properly authorized to execute the same.</label>
            <br /><label>(c) No agreement entered by the Borrower or the Guarantor with any third party, or any Applicable Law, will be breached, by the availing of the Borrower of the Loan from the Lenders, and the Borrower and the Guarantor entering the Loan Documents and performing their obligations hereunder. </label>
            <br /><label>(d) Each of the Borrower and the Guarantor has the power and authority to enter, perform and deliver, and has taken all necessary action to authorize its entry into, and performance and delivery, of the Loan Documents to which it is or will be a party, and the transactions contemplated by those Loan Documents.</label>
            <br /><label>(e) That there is no action, suit, proceeding or investigation pending or threatened against the Borrower and/or the Guarantor or the property of the Borrower and/or the Guarantor before any courts/tribunals or any governmental authority which might have Material Adverse Effect on the financial and other affairs of the Borrower and/or the Guarantor or which might put into question the validity, enforceability or performance of the Loan Documents or any of its terms and conditions. </label>
            <br /><label>(f) All factual and other information provided to the Lenders is true and accurate, including the information provided in the Schedules to this Agreement. </label>
            <br /><label>(g) No default or an Event of Default has occurred and is continuing or might reasonably be expected to result from the making of any drawing under the Loan, no event or circumstance is outstanding which has or might reasonably be expected to have a material adverse effect, and no event or circumstance is outstanding which constitutes a default under any other agreement binding upon it including any payment or other contractual obligations. </label>
            <br /><label>(h) The Borrower and/or the Guarantor(s) have disclosed to the Lenders all information (financial or otherwise) relating to it and all other relevant parties which is material to be known to the Lenders in view of the provisions of the Loan Documents and which is true, complete and accurate in all material respects as at the date it was given and is not misleading in any respect. </label>
            <br /><label>(i) The Borrower shall be responsible for obtaining all the requisite permissions and consents with respect to the Loan and other transactions contemplated in this Agreement, as may be necessary under applicable laws; and </label>
            <br /><label>(j) The Borrower shall, at all times abide by the laws in India, and in particular, provisions of the Prevention of Money Laundering Act, 2002.</label>
            <br /><label>(k) The Borrower and/or the Guarantor(s) hereby accept and confirm that it has no objection to the Lenders administering the Loan through third parties. The Borrower and/or the Guarantor(s) confirm that the Lenders may either partly or in full delegate such of those activities to any third party as it may think fit in the circumstances. Such delegation of work would include the right and authority to collect the outstanding obligations on behalf of the Lender, the dues and unpaid instalments and other amount due under the Agreement and to perform and execute all lawful acts, deeds and matters and things connected therewith and incidental thereto including sending notices to the Borrower and/ or the Guarantor(s), receiving cash against issue of the receipts. For the purpose aforesaid as for any other purpose at the sole discretion of the Lender, the Lenders shall be entitled to disclose such the third-party entities the details of the Borrower and/or the Guarantor(s), the Loan, the outstanding amount and other information for effectively discharging the work assigned to the third Party and the Borrower hereby consents to such disclosure by the Lenders.</label>

            <b><p className="title">12.Parties That Are Not Individuals: -</p></b>
            <br /><label>If any Party to this agreement is other than an individual (i.e., a corporation, a Limited Liability Company, a Partnership, or a Trust), said Party, and the individual signing on behalf of said Party, hereby represents and warrants that all steps and actions have been taken under the entity’s governing instruments to authorize the entry into this Loan Agreement. Breach of any representation contained in this paragraph is considered a material breach of the Loan Agreement and the individual signing on behalf of the company shall be responsible to pay the Loan amount under this Loan Agreement.</label>

            <br /><br /><b><p className="title">13.Integration: -</p></b>
            <br /><label>This Agreement, including the attachments mentioned in the body as incorporated by reference, sets forth the entire agreement between the Parties with regard to the subject matter hereof.  All prior agreements, representations and warranties, express or implied, oral or written, with respect to the subject matter hereof, are superseded by this agreement. This is an integrated agreement.</label>

            <br /><b><p className="title">14.Severability: -</p></b>
            <br /><label>In the event any provision of this Agreement is deemed to be void, invalid, or unenforceable, that provision shall be severed from the remainder of this Agreement so as not to cause the invalidity or unenforceability of the remainder of this Agreement. All remaining provisions of this Agreement shall then continue in full force and effect. If any provision shall be deemed invalid due to its scope or breadth, such provision shall be deemed valid to the extent of the scope and breadth permitted by law.</label>

            <br /><b><p className="title">15.Modification: -</p></b>
            <br /><label>Except as otherwise provided in this document, this agreement may be modified, superseded, or voided only upon the written and signed agreement of the Parties.  Further, the physical destruction or loss of this document shall not be construed as a modification or termination of the agreement contained herein.</label>

            <br /><b><p className="title">16. Time is The Essence: -</p></b>
            <label>The Parties hereby agree that time is the essence with respect to all dates and periods mentioned in this Loan Agreement (as may be modified/extended wherever permitted under this Loan Agreement), for performance of their respective obligations under this Loan Agreement.</label>

            <b><p className="title"> 17. Exclusive Jurisdiction for Suit in Case of Breach: -</p></b>
            <br /><label>This Loan Agreement shall be governed by and construed in accordance with the Laws of Maharashtra, India and any dispute between the Parties relating to or arising out of this Loan Agreement shall be subject to the exclusive jurisdiction over any disputes between the parties relative to this agreement, whether said disputes sounds in contract, tort, or other areas of the law shall be governed in the courts of Mumbai, India only.</label>
            <br />
            <br /><b><p className="title">18. Acceptance The parties hereby declares as follows: -</p></b>
            <br /><label>a. They have read the entire agreement and shall be bound by all the conditions. </label>
            <br /><label>b. This agreement and other documents have been explained to them in the language understood by them and they have understood the entire meaning of all the clauses.</label>
            <br /><label>c. They agree that this agreement shall be concluded and become legally binding on the date when it is signed by the parties</label>

            <br />
            <label>Borrower</label>
            <br />
            <br />
            {this.state.Gender_Prefix}{this.state.Data.User_Ofc_Name.toUpperCase()}
            <br />
            <br />

            <label>User ID :- {` `} {this.state.Data.User_ID}</label>
            <br />
            <br />

            <lable>Location :-{``} {this.state.Data.location}</lable>
            <br />
            <br />
            Loan ID:-{` `} {this.state.Data.ID}
            <br />
            <br /><br />

            <br /><br /> *THIS IS ELECTRONICS GENRATED AGREEMENT<br /><br />
            <br /><br />


            <label style={{textAlign:'center'}}><u><b>Annexure 1:</b></u></label>
            <br /><br />
            <p>Delay charges Details of the charges applicable in case <strong>    {this.state.Gender_Prefix}{this.state.Data.User_Ofc_Name.toUpperCase()}</strong> delays any EMI.&nbsp;</p>
<p><br/></p>
<p>Based on your loan amount of Rs. <strong>{this.state.Data.Amount}</strong>, applicable delay charges will be as below :&nbsp;</p>
<p><br/></p>
<p><strong>Delay charges levied for loan amount from 3000/- to 10000/-&nbsp;</strong></p>
<p>1. If the Borrower has delayed his repayment upto a 5 days from EMI date, he will be charged Rs. 250 /-</p>
<p>&nbsp;2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged Rs. 500 /-</p>
<p>&nbsp;3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged Rs. 750 /-</p>
<p>&nbsp;4. If the Borrower has delayed his repayment upto 20 days, he will be charged Rs. 1000/-&nbsp;</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month (30 days from EMI date) ,he will be charged Rs. 1500 /-</p>
<p>6. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal interest rate; on the total overdue amount.&nbsp;</p>
<p><br/></p>
<p><strong>Delay charges levied for loan amount from 11000/- to 15000/-</strong></p>
<p>1. If the Borrower has delayed his repayment upto a 5 days from EMI date, he will be charged Rs. 300 /-&nbsp;</p>
<p>2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged Rs. 600 /-</p>
<p>3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged Rs. 900 /-&nbsp;</p>
<p>4. If the Borrower has delayed his repayment upto 20 days, he will be charged Rs. 1200/-&nbsp;</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month (30 days from EMI date) ,he will be charged Rs. 1800 /-&nbsp;</p>
<p>6. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal interest rate; on the total overdue amount.&nbsp;</p>
<p><br/></p>
<p><strong>Delay charges levied for loan amount from 16000/- and above.&nbsp;</strong></p>
<p>1. If the Borrower has delayed his repayment upto a 5 days from EMI date, he will be charged Rs. 400 /-&nbsp;</p>
<p>2. If the Borrower has delayed his repayment upto 10 days from EMI date, he will be charged Rs. 800 /-&nbsp;</p>
<p>3. If the Borrower has delayed his repayment upto 15 days from EMI date, he will be charged Rs. 1200 /-&nbsp;</p>
<p>4. If the Borrower has delayed his repayment upto 20 days, he will be charged Rs. 1800/-&nbsp;</p>
<p>5. If the Borrower has delayed his repayment upto 1 Month (30 days from EMI date) ,he will be charged Rs. 2500 /-&nbsp;</p>
<p>6. For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal interest rate; on the total overdue amount.</p>
<p><strong>For delays of an EMI beyond a month, the Borrower will be charged an additional 2% penal interest rate; on the total overdue amount.</strong></p>
<p>Charges conveyed &amp; accepted by:&nbsp;</p>
<p><strong>    {this.state.Gender_Prefix}{this.state.Data.User_Ofc_Name.toUpperCase()}</strong></p>
<p>&nbsp;I accept all terms &amp; conditions of Annexure 1&nbsp;: Delay charges</p>
<p><strong>    {this.state.Gender_Prefix}{this.state.Data.User_Ofc_Name.toUpperCase()}</strong></p>

              <br/><br/>
            
            {
              (this.state.Data.Agreement_Signed === 'YES') ? (
                <React.Fragment>
                  <Box m={2} />
                  <Typography variant="h6" color="error" align="center">*This Document is Singed</Typography>
                </React.Fragment>
              ) : (
                <>
                  <Checkbox
                    checked={this.state.checked}
                    onChange={this.handleCheckboxChange}
                  />
                  <span> I read and agree with the terms and conditions. (*मैं पढ़ता हूं और नियमों और शर्तों से सहमत हूं । ) </span>

                  <br />
                  <br />
                  <br />
                  <Button variant="contained" align="center" color="primary" disabled={!this.state.checked} onClick={() => this.signdoc()}>
                    I ACCEPT
                  </Button>


                </>


              )
            }


          </div>



        )}


      </Container>
    );

  }

}






export default LoanAgreement;
