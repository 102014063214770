import React, { Component } from 'react';
import {Card,TableHead,Table,TableContainer,Button,TableRow,TableCell,TableBody,IconButton,} from '@material-ui/core'

import DetailIcon from '@material-ui/icons/Details'


class LoanList extends Component {

    render() {
        return (
            <React.Fragment>

       
            <Card style={{marginBottom:10}}>
         
            <TableContainer >
             <Table  aria-label="simple table">
               <TableHead>
                 <TableRow>
                   
                   <TableCell align="left">Loan ID</TableCell>
                   <TableCell align="left">Amount</TableCell>
                   <TableCell align="left">Tenure</TableCell>
                   <TableCell align="left">Status</TableCell>
                   <TableCell align="left">Created At</TableCell>

                   
       
       
       
       
                 </TableRow>
               </TableHead>
               <TableBody>
                 {this.props.loans.map((data) => (
                   <TableRow key={data.ID}>
                         <TableCell align="left">{data.ID}</TableCell>
                    
                      
                     <TableCell align="left">{data.Amount}</TableCell>
                     <TableCell align="left">{data.Tenure}</TableCell>
                     <TableCell align="left">{data.Status}</TableCell>
                     <TableCell align="left">{new Date(data.Created_At).toString()}</TableCell>

       
                   
                   
           <TableCell align="left">



                
           
                      {(this.props.role==="Admin") ? (
                      
                      <IconButton aria-label="edit loan admin"  onClick={()=>window.location.href=`/app/edit-loan/${data.ID}`} >
                 <DetailIcon />
               </IconButton>
                      
                      ) : (
                      
                        <IconButton aria-label="delete" onClick={()=>window.location.href=`/app/user-loan-details/${data.ID}`}>
                        <DetailIcon />
                      </IconButton>
                      
                      
                      ) }
       
          </TableCell>
                   
       
       
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
       
       
                           </Card>
                           </React.Fragment>
        );
    }
}

export default LoanList;

