import React, { Component } from 'react';
import {
  Button, Typography, TextField, Box, Grid, Container, FormControl,
  InputLabel, Select, Paper, Avatar, DialogContent, DialogTitle,
  Dialog, DialogActions, TableBody, TableCell,
  TableContainer, TableHead, Table, TableRow, MenuItem, IconButton, Divider, InputAdornment, OutlinedInput
} from '@material-ui/core'

import dayjs, { Dayjs } from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AddGaurantor from './AddGaurantor';

import LoanEmis from './LoanEmis'
import UserContext from '../ContextManage/UserContext'
import axios from 'axios';
import * as ApiFunctions from '../../util/apifunction'

import Skeleton from '@material-ui/lab/Skeleton';

import LoanDelayCharges from '../AdminComponents/LoanDealysCharges'

import { saveAs } from 'file-saver';

import AddNotes from '../AdminComponents/AddNotes'
import Notes from '../AdminComponents/Notes'
import GuarantorEdit from './GuarantorEdit';
import { Done, SendOutlined } from '@material-ui/icons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { nextmonths } from '../../util/dateutil';
import { uuid } from 'uuidv4';

const LoanAmounts = [3000, 5000, 7000, 8000, 9000, 10000, 11000, 12000,
   13000, 14000, 15000, 16000, 17000, 18000, 19000,
   20000,21000,22000,23000,24000,25000,26000,27000,28000,29000,30000,
   31000,32000,33000,34000,35000,36000,37000,38000,39000,40000,
   41000,42000,43000,44000,45000,46000,47000,48000,49000,50000,
   51000,52000,53000,54000,55000,56000,57000,58000,59000,60000]
const LoanTenures = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]
class EditLoan extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    let match = props.match;
    this.state = {
      fields: {},
      errors: {},
      ID: null,
      Email: null,
      Role: null,
      Token: null,
      isloggedin: false,
      LoanID: match.params.ID,
      Data: {},
      isloading: true,
      emidialogopen: false,
      emivalue: 0,
      delaydialogoepn: false,
      guarantor: [],
      Notes: [],
      GurantorFields: {},
      GurantorErrors: {},
      GaurantorModalOpen: false,
      GurantorOtpSession: '',
      AddGaurantorModelOpen: false,
      EmiMonth:dayjs(),
      UserGenreatedEmis:[]


    }
  }


  openemidialog = () => {
    this.setState({ emidialogopen: true })

  }




  closeemidialog = (newemivalue) => {
    this.setState({ emidialogopen: false })
    if (newemivalue) {
      this.setState({ emivalue: newemivalue })
    }

  }


  closedelaydialog = (newemivalue) => {
    this.setState({ delaydialogoepn: false })

  }




  toggleAddGaurantorModel = () => {
    this.setState({ AddGaurantorModelOpen: !this.state.AddGaurantorModelOpen })
  }


  async componentDidMount() {



    const UserContextData = this.context
    await this.setState({
      ID: UserContextData.state.ID,
      Email: UserContextData.state.Email,
      isloggedin: UserContextData.state.loggedin,
      Token: UserContextData.state.Token,
      Role: UserContextData.state.Role
    })



    await this.GetloanDetails()
    if (this.state.Data.guarantor != "") {
      await this.setState({ guarantor: JSON.parse(this.state.Data.guarantor) })

    }

    let fields = this.state.fields;

    fields["Tenure"] = this.state.Data.Tenure
    fields["Amount"] = this.state.Data.Amount
    fields["Status"] = this.state.Data.Status
    fields["User_Father_Name"] = this.state.Data.User_Father_Name
    fields["User_Address"] = this.state.Data.User_Address
    fields["User_PNo"] = this.state.Data.User_PNo
    fields["User_ANo"] = this.state.Data.User_ANo
    fields["User_BankName"] = this.state.Data.User_BankName
    fields["USER_BA_Type"] = this.state.Data.USER_BA_Type
    fields["User_Ba_Ac_No"] = this.state.Data.User_Ba_Ac_No
    fields["User_PinCode"] = this.state.Data.User_PinCode
    fields["User_Ofc_Name"] = this.state.Data.User_Ofc_Name
    fields["User_BankIfsc"] = this.state.Data.User_BankIfsc
    fields["User_MarriedStatus"] = this.state.Data.User_MarriedStatus
    fields["EmiDate"] = this.state.Data.EmiDate
    fields["ROI"] = parseFloat(this.state.Data.ROI)

    console.log("Next EMI MONTH FOR EMIS", nextmonths(Number(this.state.Data.Tenure)));

    await this.setState({ fields })



  }


  async GetloanDetails() {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    try {
      const Data = await ApiFunctions.GetApiRequest(`/loan/${this.state.LoanID}`, header)


      if (Data.Status === 200) {
        await this.setState({ Data: Data.Data, isloading: false })
        console.log("NOTES", this.state.Data.notes)
      } else {
        this.setState({ isError: true, isloading: true })
      }
    } catch (err) {
      this.setState({ isError: true })
    }
  }


  createAndDownloadPdf = () => {



    const data = {
      userid: this.state.Data.User_ID,
      married_status: this.state.Data.User_MarriedStatus,
      loanid: this.state.Data.ID,
      gender: this.state.Data.User_Gender,
      loan_amount: this.state.Data.Amount,
      fullname: this.state.Data.User_Ofc_Name,
      fathername: this.state.Data.User_Father_Name,
      phoneno: this.state.Data.User_PhoneNo,
      address: this.state.Data.User_Address,
      pancard: this.state.Data.User_PNo,
      aadharcard: this.state.Data.User_ANo,
      location: this.state.Data.location,
      Purpose: this.state.Data.Purpose,

      bankname: this.state.Data.User_BankName,
      bankifsc: this.state.Data.User_BankIfsc,
      ba_type: this.state.Data.USER_BA_Type,
      ba_holder_name: this.state.Data.User_Ofc_Name,
      ba_ac_no: this.state.Data.User_Ba_Ac_No,
      EmiDate: this.state.Data.EmiDate,
      Created_At: this.state.Data.Created_At,
      tenure: this.state.Data.Tenure,
      total_interest: this.state.Data.Total_Interest,
      ROI: parseFloat(this.state.Data.ROI),
      UserEmail: this.state.Data.User_Email

    }
    axios.post('/loan/agreement/create-pdf', data)

      .then(() => axios.get('/loan/agreement/fetch-pdf', { responseType: 'blob' }))

      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

        saveAs(pdfBlob, `${this.state.Data.ID}-Loan Agreement.pdf`);

      })
  }


  async updateanyloan() {

    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };


    const data = {
      status: this.state.fields["Status"],
      tenure: this.state.fields["Tenure"],
      amount: this.state.fields["Amount"],
      User_Father_Name: this.state.fields["User_Father_Name"],
      User_Address: this.state.fields["User_Address"],
      User_PNo: this.state.fields["User_PNo"],
      User_ANo: this.state.fields["User_ANo"],
      User_BankName: this.state.fields["User_BankName"],
      USER_BA_Type: this.state.fields["USER_BA_Type"],
      User_Ba_Ac_No: this.state.fields["User_Ba_Ac_No"],
      User_PinCode: this.state.fields["User_PinCode"],
      User_Ofc_Name: this.state.fields["User_Ofc_Name"],
      User_BankIfsc: this.state.fields["User_BankIfsc"],
      User_MarriedStatus: this.state.fields["User_MarriedStatus"],
      EmiDate: this.state.fields["EmiDate"],
      ROI: this.state.fields["ROI"],






    }


    try {
      const Data = await ApiFunctions.UpdateApiData(`/loan/${this.state.LoanID}`, header, data)

      // console.log(data)
      if (Data.Status === 200) {
        alert("Loan Updated Successfully!")
        this.redirect(1000, `/app/edit-loan/${this.state.LoanID}`)



      }
      if (Data.Status !== 200) {
        alert("Can't Update Loan Right Now!")
      }

    } catch (err) {
      this.setState({ isError: true })
    }

  }




  async addemi() {

    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };


    const data = {
      uid: this.state.Data.User_ID,
      lid: this.state.LoanID,
      outstanding: this.state.fields["EmiAccount"],
      Type: "EMI"
    }


    try {
      const Data = await ApiFunctions.PostApiData(`/emi/`, header, data)

      // console.log(Data)
      if (Data.Status === 200) {
        alert("Loan Updated Successfully!")

        this.redirect(1000, `/app/edit-loan/${this.state.LoanID}`)


      }
      if (Data.Status !== 200) {
        alert("Can't Add Emi Right Now!")
      }

    } catch (err) {
      this.setState({ isError: true })
    }

  }

  SendGuarantorAgreement = async (GID) => {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };

    //B_Name , B_Address , B_Phone , B_Email , B_Amount , B_ROI , B_Date
    const data = {
      ID: GID
    }
    try {
      const Data = await ApiFunctions.PostApiData(`/gurantor/gurantor-verfication`, header, data)

      console.log("statuscode", Data)
      if (Data.Status === 200) {
        alert("Agreement Sent!")
        this.redirect(1000, `/app/edit-loan/${this.state.LoanID}`);
      }
      else {
        alert("Can't Send Agreement Right Now", Data.statuscode);
      }

    } catch (err) {
      alert('Inernal Server Error');
    }
  }




  VerifyGuarantorOTP = async () => {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    try {
      const Data = await ApiFunctions.GetApiRequest(`/gurantor/gurantor-phone-verify-otp/${this.state.GurantorOtpSession}/${this.state.GurantorFields["OTP"]}/${this.state.GurantorFields["ID"]}`, header)
      if (Data.Status === 200) {
        alert("Guaranot Phone Verified!")
        this.redirect(1000, `/app/edit-loan/${this.state.LoanID}`);
      }
      else {
        alert("Can't Verify Guarantor Phone");
      }
    } catch (err) {
      console.log(err);
      alert('Inernal Server Error');
    }
  }




  SendGuarantorOTPVerification = async (PhoneNumber) => {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    try {
      const Data = await ApiFunctions.GetApiRequest(`/gurantor/gurantor-phone-verification/${PhoneNumber}`, header)

      const parsedData = JSON.parse(Data.body)
      console.log(parsedData.Status, parsedData.Details)

      this.setState({ GurantorOtpSession: parsedData.Details })

      alert('OTP SENT');
      // this.redirect(1000,`/app/edit-loan/${this.state.LoanID}`);
    } catch (err) {

      alert('Inernal Server Error');
    }
  }


  UpdateGuarantor = async () => {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };

    const data = {
      "Name": this.state.GurantorFields["Name"],
      "Email": this.state.GurantorFields["Email"],
      "PhoneNumber": this.state.GurantorFields["Phone"],
      "Age": this.state.GurantorFields["Age"],
      "Address": this.state.GurantorFields["Address"],
      "Relation": this.state.GurantorFields["Relation"],
      "EmailVerified": this.state.GurantorFields["EmailVerified"],
      "PhoneVerified": this.state.GurantorFields["PhoneVerified"]
    }

    try {
      const Data = await ApiFunctions.UpdateApiData(`/gurantor/gurantor-update/${this.state.GurantorFields["ID"]}`, header, data)

      if (Data.Status === 200) {
        alert('Guarantor Updated');
        this.redirect(1000, `/app/edit-loan/${this.state.LoanID}`);
      }

    } catch (err) {
      alert('Inernal Server Error');
    }
  }


  DeleteGuarantor = async (ID) => {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    try {
      const Data = await ApiFunctions.DeleteApiData(`/gurantor/gurantor-remove/${ID}`, header)

      if (Data.Status === 200) {
        alert('Guarantor Deleted');
        this.redirect(1000, `/app/edit-loan/${this.state.LoanID}`);
      }

    } catch (err) {
      alert('Inernal Server Error');
    }
  }






  async adddelaycharges() {

    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    const data = {
      uid: this.state.Data.User_ID,
      lid: this.state.LoanID,
      outstanding: this.state.fields["DAmount"],
      Type: "DELAY"
    }
    try {
      const Data = await ApiFunctions.PostApiData(`/emi/`, header, data)

      console.log(Data)
      if (Data.Status === 200) {
        alert("Loan Updated Successfully!")

        this.redirect(1000, `/app/edit-loan/${this.state.LoanID}`)


      }
      if (Data.Status !== 200) {
        alert("Can't Add Emi Right Now!")
      }

    } catch (err) {
      this.setState({ isError: true })
    }

  }






  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // title
    if (!fields["Tenure"]) {
      formIsValid = false;
      errors["Tenure"] = "Email Can't be Empty!";
    }


    if (!fields["Amount"]) {
      formIsValid = false;
      errors["Amount"] = "Password Cant Be Empty!";
    }




    if (typeof fields["PinCode"] !== "undefined") {
      if (!fields["PinCode"].match(new RegExp(/^[1-9][0-9]{5}$/))) {
        formIsValid = false;
        errors["PinCode"] = "Enter A Valid  PinCode Number";
      }
    }

    if (typeof fields["ANo"] !== "undefined") {
      if (!fields["ANo"].match(new RegExp(/^\d{4}\s\d{4}\s\d{4}$/))) {
        formIsValid = false;
        errors["ANo"] = "Enter A Valid Aadhar Card Number";
      }
    }


    if (!fields["PinCode"]) {
      formIsValid = false;
      errors["PinCode"] = "Pincode Can not be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }



  submituserdata(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      //console.log(this.state.fields)

    } else {
      alert("Please Fill All Required Fields.")
    }

  }


  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    // console.log(this.state.fields)
  }



  redirect = (time, link) => {
    setTimeout(function () { window.location.href = `${link}` }, time);
  }



  handleGurantorInputChange(field, e) {
    let GurantorFields = this.state.GurantorFields;
    GurantorFields[field] = e.target.value;
    this.setState({ GurantorFields });
    console.log('GaurantoField', GurantorFields)
  }

  EditGaurantor = async (data) => {
    console.log('Gurantor Data', data);
    this.setState({ GaurantorModalOpen: true });

    let GurantorField = this.state.GurantorFields;

    GurantorField["Name"] = data.Name
    GurantorField["Email"] = data.Email
    GurantorField["Phone"] = data.PhoneNumber
    GurantorField["Age"] = data.Age
    GurantorField["Relation"] = data.Relation
    GurantorField["Address"] = data.Address
    GurantorField["EmailVerified"] = data.EmailVerified
    GurantorField["PhoneVerified"] = data.PhoneVerified
    GurantorField["ID"] = data.ID


    await this.setState({ GurantorFields: GurantorField })

  }

  ViewGagreement = async (Name, Email, Phone, Address, Relation) => {

    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };
    const data = {
      Name: Name,
      Email: Email,
      Phone: Phone,
      Address: Address,
      Relation
    }
    try {
      const Data = await ApiFunctions.PostApiData(`/loan/agreement/gaurantor-agreement`, header, data)

      console.log(Data)
      if (Data.Status === 200) {





      }
      if (Data.Status !== 200) {
        alert("Cant View Agreement Now")
      }

    } catch (err) {
      this.setState({ isError: true })
    }



  }

  createAndDownloadGagreement = async (Name, Email, Phone, Address, Relation, Age) => {


    const data = {
      Name: Name,
      Email: Email,
      Phone: Phone,
      Address: Address,
      Relation: Relation,
      Age: Age,
      //B_Name,B_Email,B_Phone,B_Address,B_Pan,B_Amount,B_ROI,B_Date,
      B_Name: this.state.Data.User_Ofc_Name,
      B_Email: this.state.Data.User_Email,
      B_Phone: this.state.Data.User_PhoneNo,
      B_Address: this.state.Data.User_Address,
      B_Pan: this.state.Data.User_PNo,
      B_Amount: this.state.Data.Amount,
      B_Date: this.state.Data.Created_At
    }

    await axios.post('/loan/agreement/create-gaurantor-agreement', data)

      .then(() => axios.get('/loan/agreement/fetch-gaurantor-agreement-pdf', { responseType: 'blob' }))

      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

        saveAs(pdfBlob, `${Name} -Gaurantor Agreement.pdf`);

      })
  }


   GenearteEMIData=async()=>{


    
   const EmisData=nextmonths(this.state.EmiMonth,  Number(this.state.fields["Tenure"]),this.state.fields["EmiDate"]);
  

   if (window.confirm("Are you sure , you want to generate Emi Data!") == true) {
   
    const values = [];
    const MonthlyEmi=Math.ceil((this.state.Data.Amount) / (this.state.Data.Tenure) + this.state.Data.Total_Interest);
    EmisData.map((date,index)=>{
      values.push({User_ID:this.state.ID , Loan_ID:this.state.LoanID ,Outstanding:MonthlyEmi,Delay_Amount:0,EmiDate:new Date(date[0],date[1],date[2])});
    })
   // this.setState({ UserGenreatedEmis: values });
   console.log(values)


 
    const header = {
      "Content-Type": "application/json",
      "x-access-token": this.state.Token,
    };

    try {
      const Data = await ApiFunctions.PostApiData(`/emi`, header,values);

      if (Data.Status === 200) {
        alert("Emis Added Successfully!");
        this.redirect(500, `/app/edit-loan/${this.state.LoanID}`)

      }
      if (Data.Status !== 200) {
        alert("Can't Add Emis Right Now!");
      }
    } catch (err) {
      console.log(err);
    } 



     

  }




      
   //function to generate emi tables
/* 
    ID: uuidv4(),
    User_ID: req.body.uid,
    Loan_ID: req.body.lid,
    Outstanding: req.body.outstanding,
    Delay_Amount:req.body.Delay_Amount,
    Created_At: new Date(), */




  }


  render() {

    const { Data } = this.state;

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container component="main" maxWidth="lg" >
        <form noValidate style={{ width: '100%', marginTop: 24 }} onSubmit={this.submituserdata.bind(this)}  >

          <Box m={2}></Box>
          <Typography variant="h5" color="initial">User Details</Typography>
          <Box m={2}></Box>
          {(this.state.isloading) ? (

            <Skeleton animation="wave" variant="rect" width="100%" height={300} />
          ) : (

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                {/* UI FOR THE BASIC DATA */}
                <Paper variant="outlined" style={{ padding: 20 }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                    <Avatar alt="Remy Sharp" src="/broken-image.jpg">B</Avatar>
                    <Box m={1}></Box>
                    <Typography variant="subtitle1" color="primary">{this.state.Data.User_Name}</Typography>
                    <Typography variant="caption" color="initial" onClick={() => this.props.history.push(`/app/edit-user/${this.state.Data.User_ID}`)}>{this.state.Data.User_ID}</Typography>
                    <Box m={2}></Box>
                    <Button variant="contained" color="primary" onClick={() => this.props.history.push(`/app/edit-user/${this.state.Data.User_ID}`)}>
                      View Profile
                    </Button>
                    <Box m={2}></Box>


                  </div>
                  <Box m={2}></Box>
                  <Typography variant="subtitle1" color="textSecondary">Email :-   {<span style={{ color: "black" }}>{this.state.Data.User_Email}</span>}</Typography>
                  <Box m={2}></Box>
                  <Typography variant="subtitle1" color="textSecondary">Phone No. :-   {<span style={{ color: "black" }}>{this.state.Data.User_PhoneNo}</span>}</Typography>
                  <Box m={2}></Box>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="select_married_status">Married Status</InputLabel>
                    <Select
                      native
                      value={this.state.fields["User_MarriedStatus"]}
                      onChange={this.handleChange.bind(this, "User_MarriedStatus")}




                      label="Married Status"
                      inputProps={{
                        name: 'Married Status',
                        id: 'select_married_status',
                      }}
                    >

                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Widow">Widow</option>
                      <option value="Widower">Widower</option>
                      <option value="Divorcee">Divorcee</option>

                    </Select>
                  </FormControl>
                  <br />

                  <br />
                  <TextField label="Father Name" margin="normal" variant="outlined" ref="User_Father_Name" fullWidth
                    value={this.state.fields["User_Father_Name"] || ''}
                    onChange={this.handleChange.bind(this, "User_Father_Name")}
                  />

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_Father_Name"]}</Typography>


                  <TextField label="Name As Per Document" margin="normal" variant="outlined" ref="Ofc_Name" fullWidth
                    value={this.state.fields["User_Ofc_Name"] || ''}

                    onChange={this.handleChange.bind(this, "User_Ofc_Name")}
                  />

                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_Ofc_Name"]}</Typography>

                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>

                {/* for the additional user data */}
                <Paper variant="outlined" style={{ padding: 20 }}>


                  {/* address and pincode */}
                  <Grid Container spacing={2}>




                    <Grid item xs={12} lg={8} xl={8} md={8}>
                      <TextField label="Address" margin="normal" variant="outlined" multiline rows={4} fullWidth
                        value={this.state.fields["User_Address"] || ''}
                        onChange={this.handleChange.bind(this, "User_Address")}
                      />
                      <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_Address"]}</Typography>


                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField label="Pin Code" margin="normal" variant="outlined"
                        value={this.state.fields["User_PinCode"] || ''}
                        onChange={this.handleChange.bind(this, "User_PinCode")}
                      />
                      <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_PinCode"]}</Typography>


                    </Grid>
                  </Grid>



                  <Box m={1}></Box>
                  <TextField label="Bank Account Number" margin="normal" variant="outlined" type="number"
                    value={this.state.fields["User_Ba_Ac_No"] || ''}
                    onChange={this.handleChange.bind(this, "User_Ba_Ac_No")}

                  />
                  <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_Ba_Ac_No"]}</Typography>


                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      {/* Bank Name */}
                      <Autocomplete
                        value={this.state.fields["User_BankName"] || ''}

                        onInputChange={(event, newInputValue) => {
                          const fields = this.state.fields;
                          fields["User_BankName"] = newInputValue;
                          this.setState({ fields })


                        }}
                        id="free-solo-demo"
                        freeSolo
                        options={top100Films.map((option) => option.title)}
                        renderInput={(params) => (
                          <TextField {...params} label="Bank Name" margin="normal" variant="outlined" />
                        )}
                      />
                      <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_BankName"]}</Typography>

                    </Grid>


                    {/* Bank Name Ending */}


                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

                      {/* Bank IFSC starting */}

                      <TextField label="Bank IFSC Code" margin="normal" variant="outlined"
                        value={this.state.fields["User_BankIfsc"] || ''}
                        onChange={this.handleChange.bind(this, "User_BankIfsc")}
                      />
                      <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_BankIfsc"]}</Typography>



                      {/* BANK IFSC ENDING */}

                    </Grid>



                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

                      <FormControl variant="outlined" style={{ marginTop: 15 }}>

                        <InputLabel htmlFor="select_married_status">Account Type</InputLabel>
                        <Select
                          native
                          value={this.state.fields["USER_BA_Type"]}
                          onChange={this.handleChange.bind(this, "USER_BA_Type")}




                          label="Account Type"
                          inputProps={{
                            name: 'Account Type',
                            id: 'select_account_type',
                          }}
                        >

                          <option value="SAVINGS">SAVING</option>
                          <option value="CURRENT">CURRENT</option>

                        </Select>
                      </FormControl>


                    </Grid>


                  </Grid>

                  <Grid container spacing={2}>


                    <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                      {/* Adhar Crad starting */}


                      <label>Give Space After 4 digit For Aadhar Card</label>
                      <TextField label="Aadhar Card Number" margin="normal" variant="outlined"
                        value={this.state.fields["User_ANo"] || ''} style={{ width: "100%" }}
                        onChange={this.handleChange.bind(this, "User_ANo")}
                      />
                      <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_ANo"]}</Typography>


                      {/* Adhar Crad Ending */}

                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                      {/* Pan card Starting */}

                      <br />
                      <TextField label="Pan Card Number" margin="normal" variant="outlined"
                        value={this.state.fields["User_PNo"] || ''} style={{ width: "100%" }}
                        onChange={this.handleChange.bind(this, "User_PNo")}
                      />
                      <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["User_PNo"]}</Typography>


                      {/* Pan Card Ending */}
                    </Grid>

                  </Grid>



                </Paper>

              </Grid>

            </Grid>
          )


          }



          <Box m={2}></Box>
          <Typography variant="h5" color="initial">Loan Details</Typography>
          <Box m={2}></Box>


          {/* loan details */}


          {(this.state.isloading) ? (
            <Skeleton animation="wave" variant="rect" width="100%" height={300} />


          ) : (

            <Paper variant="outlined" style={{ padding: 20 }}>

              <Typography variant="subtitle1" color="primary">Loan ID :-   {<span style={{ color: "black" }}>{this.state.Data.ID}</span>}</Typography>
              <Box m={3}></Box>
              <Grid container spacing={2}>

                <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>

                  <FormControl>
                    <InputLabel htmlFor="select_married_status">Amount</InputLabel>
                    <Select
                      native
                      value={this.state.fields["Amount"]}
                      onChange={this.handleChange.bind(this, "Amount")}


                      label="Amount"
                      inputProps={{
                        name: 'Amount',
                        id: 'select_loan_amount',
                      }}
                    >

                      {LoanAmounts.map((name) => (
                        <option
                          key={name}
                          value={name}
                        >
                          {name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                </Grid>

                <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>

                  <FormControl>
                    <InputLabel htmlFor="select_married_status">Tenure</InputLabel>
                    <Select
                      native
                      value={this.state.fields["Tenure"]}
                      onChange={this.handleChange.bind(this, "Tenure")}


                      label="Tenure"
                      inputProps={{
                        name: 'Tenure',
                        id: 'select_tenure',
                      }}
                    >

                      {LoanTenures.map((name) => (
                        <option
                          key={name}
                          value={name}
                        >
                          {name}
                        </option>
                      ))}


                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Principal :-   {<span style={{ color: "black" }}>{Math.ceil((this.state.Data.Amount) / (this.state.Data.Tenure))} {`Rs.`}</span>}</Typography>
                </Grid>


                <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>

                  <FormControl style={{ width: 100 }}>
                    <InputLabel htmlFor="ROI">ROI</InputLabel>
                    <Select
                      native
                      value={this.state.fields["ROI"]}
                      onChange={this.handleChange.bind(this, "ROI")}


                      label="ROI"
                      inputProps={{
                        name: 'ROI',
                        id: 'select_ROI',
                      }}
                    >

                      <option value={1.00}>1.00</option>
                      <option value={1.50}>1.5</option>
                      <option value={2.00}>2</option>
                      <option value={2.50}>2.5</option>
                      <option value={3.00}>3</option>

                      <option value={3.50}>3.5</option>
                      <option value={4.00}>4</option>
                      <option value={4.50}>4.5</option>
                      <option value={5.00}>5</option>
                      <option value={5.50}>5.5</option>
                      <option value={6.00}>6</option>
                      <option value={6.50}>6.5</option>



                    </Select>
                  </FormControl>

                </Grid>



                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Monthly EMI :-   {<span style={{ color: "black" }}>{Math.ceil((this.state.Data.Amount) / (this.state.Data.Tenure) + this.state.Data.Total_Interest)} {`Rs.`}</span>}</Typography>
                </Grid>



                <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Interest :-   {<span style={{ color: "black" }}>{this.state.Data.Total_Interest} {`Rs.`}</span>}</Typography>
                </Grid>





                <Grid item xs={6} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Occupation :-   {<span style={{ color: "black" }}>{this.state.Data.Occupation}</span>}</Typography>
                </Grid>


                <Grid item xs={4} sm={6} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Purpose :-   {<span style={{ color: "black" }}>{this.state.Data.Purpose}</span>}</Typography>
                </Grid>



                <Grid item xs={6} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Residential Status:-   {<span style={{ color: "black" }}> {this.state.Data.Resident_Status} </span>}</Typography>
                </Grid>



                <Grid item xs={6} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Net Icome:-   {<span style={{ color: "black" }}>{this.state.Data.Income}</span>}</Typography>
                </Grid>




                <Grid item xs={6} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" color="textSecondary">Stay With Family :-   {<span style={{ color: "black" }}>{this.state.Data.Stay_with_Family}</span>}</Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" color="textSecondary">Family Members:-   {<span style={{ color: "black" }}>{this.state.Data.No_of_Fmembers}</span>}</Typography>
                </Grid>



                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" color="textSecondary">Earning Members:-   {<span style={{ color: "black" }}>{this.state.Data.No_of_Emember}</span>}</Typography>
                </Grid>


                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" color="textSecondary">Cibil:-   {<span style={{ color: "black" }}>{this.state.Data.Cibil_Score}</span>}</Typography>
                </Grid>













                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" color="textSecondary">Due Loan Any Amount :-   {<span style={{ color: "black" }}>{this.state.Data.Due_Amount_Any_Loan} {`Rs.`}</span>}</Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" color="textSecondary">Due Amount Credit Card:-   {<span style={{ color: "black" }}>{this.state.Data.Due_Amount_CC} {`Rs.`}</span>}</Typography>
                </Grid>


                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" color="textSecondary">Default Loan 2014 :-   {<span style={{ color: "black" }}>{this.state.Data.Default_Loan14}</span>}</Typography>
                </Grid>


                <Grid item xs={12} sm={12}>




                  <FormControl style={{ width: 100 }}>
                    <InputLabel htmlFor="select_married_status">Emi Date</InputLabel>
                    <Select
                      native
                      value={this.state.fields["EmiDate"]}
                      onChange={this.handleChange.bind(this, "EmiDate")}


                      label="EmiDate"
                      inputProps={{
                        name: 'EmiDate',
                        id: 'select_EmiDate',
                      }}
                    >

                      <option value={5}>5</option>
                      <option value={10}>10</option>



                    </Select>
                  </FormControl>




                </Grid>


                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" color="textSecondary">Applied For Loan :-   {<span style={{ color: "black" }}>{new Date(this.state.Data.Created_At).toString()}</span>}</Typography>
                </Grid>

                <Box m={5}></Box>



                {/* loan operations */}
                <Grid item xs={6} sm={6}>





                  <FormControl>
                    <InputLabel htmlFor="select_married_status">Status</InputLabel>
                    <Select
                      native
                      value={this.state.fields["Status"]}
                      onChange={this.handleChange.bind(this, "Status")}


                      label="Status"
                      inputProps={{
                        name: 'Status',
                        id: 'select_married_status',
                      }}
                    >

                      <option value="InProcess">InProcess</option>
                      <option value="Sanction">Sanction</option>
                      <option value="Closed">Closed</option>
                      <option value="Rejected">Rejected</option>



                    </Select>
                  </FormControl>

                  {/* select month options */}
                

                </Grid>


                <Grid item xs={4} sm={4}>
                  <Button variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => this.updateanyloan()}
                    size="large" style={{ flow: "right" }}>
                    UPDATE
                  </Button>
                </Grid>

                <Grid item xs={4} sm={4}>

            <DatePicker
              views={['month','year']}
              disablePast
          label="Select EMI Starting Month"
          value={this.state.EmiMonth}
          onChange={(newValue) => {
            console.log(newValue);
            this.setState({EmiMonth:newValue})
          }}
        />
                  <Button variant="outlined"
                    color="primary"
                    onClick={this.GenearteEMIData}
                    size="large" style={{ flow: "right" }}>
                    Genrate EMI
                  </Button>
                </Grid>

                <Grid item xs={12} sm={12}>

                  <Button variant="outlined" color="primary" size="large"
                    onClick={this.createAndDownloadPdf}
                    style={{ flow: "right" }}>
                    GENRATE AGREEMENT
                  </Button>


                </Grid>
              </Grid>
              <Box m={2} />

              {(Data.Agreement_Signed === 'YES') ? (<Typography variant="h6" color="initial">* Document is Signed</Typography>) : (<Typography variant="h6" color="error">Document is Not Signed</Typography>)}

              <Box m={3} />
            </Paper>


          )}



          <Box m={2}></Box>
          <Grid container spacing={2}>


            <Grid item>

              <Typography variant="h6" color="initial">Gaurantor</Typography>


            </Grid>

            <Grid item>


              <Button variant="contained" color="default" onClick={this.toggleAddGaurantorModel}>
                Add Gaurantor
              </Button>


            </Grid>

          </Grid>
          <Box m={2}></Box>
          <TableContainer component={Paper}>
            {
              (this.state.Data.guarantor != "") ? (

                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.guarantor.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.Name}
                        </TableCell>
                        <TableCell>{row.Phone}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>

                      <TableCell>Name</TableCell>
                      <TableCell>Relation</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Age</TableCell>
                      <TableCell>Email verified</TableCell>
                      <TableCell>Phone verified</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Agreement Signed</TableCell>
                      <TableCell>Operation</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Data.guarantors.map((row) => (
                      <TableRow key={row.ID}>
                        <TableCell component="th" scope="row">
                          {row.Name}
                        </TableCell>
                        <TableCell>{row.Relation}</TableCell>
                        <TableCell>{row.Email}</TableCell>
                        <TableCell>{row.PhoneNumber}</TableCell>
                        <TableCell>{row.Age}</TableCell>
                        <TableCell>{row.EmailVerified}</TableCell>
                        <TableCell>{row.PhoneVerified}</TableCell>
                        <TableCell>{row.Address}</TableCell>
                        <TableCell>{row.AgreementSigned}</TableCell>
                        <TableCell>


                          <Button variant="outlined" color="primary" onClick={() => this.EditGaurantor(row)}>
                            Edit
                          </Button>

                          <br />
                          {/*       
                 <Button variant="contained"  color="secondary" onClick={()=>this.DeleteGuarantor(row.ID)}>
                   Delete
                 </Button> 
                 
                 */}
                          <br />

                          <Button variant="contained" color="primary" onClick={() => this.createAndDownloadGagreement(row.Name, row.Email, row.PhoneNumber, row.Address, row.Relation, row.Age)}>
                            Downalod Agreement
                          </Button>

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>


                </Table>

              )
            }
          </TableContainer>


          {(this.state.isloading) ? (
            <Skeleton animation="wave" variant="rect" width="100%" height={300} />
          ) : (
            <React.Fragment>


              {(this.state.Data.Status === "InProcess" || this.state.Data.Status === "Rejected") ? (

                <span></span>

              ) : (

                <React.Fragment>
<Box m={3}></Box>
                  <Typography variant="h5" color="initial"  >Emis Details</Typography>
                {/*   <Box m={3}></Box>
                  <Button
                    variant="contained" color="secondry"
                    onClick={() => this.setState({ emidialogopen: true })}>Add Emi</Button>
                  <Box m={3}></Box>
 */}<Box m={3}></Box>
                  <LoanEmis emis={this.state.Data.emis} />

                  <Box m={3}></Box>
{/* 
                  <Typography variant="h5" color="initial"  >Delay Charges</Typography>

                  <Box m={3}></Box>

                  <Button
                    variant="contained" color="secondry"
                    onClick={() => this.setState({ delaydialogoepn: true })}>Add Delay Charges</Button>


                  <Box m={3}></Box>
                  <LoanDelayCharges emis={this.state.Data.emis} />

 */}

                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="sm"
                    onClose={this.closeemidialog}
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.emidialogopen}

                  >
                    <DialogTitle id="confirmation-dialog-title">NEW EMI</DialogTitle>
                    <DialogContent dividers>


                      <TextField
                        id="emivalue"
                        fullWidth
                        label="Amount"
                        value={this.state.fields["EmiAccount"]}
                        onChange={this.handleChange.bind(this, "EmiAccount")}
                      />


                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeemidialog} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.addemi("EMI")}
                        color="primary">
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>



                  {/* delay charges */}




                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="sm"
                    onClose={this.closedelaydialog}
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.delaydialogoepn}

                  >
                    <DialogTitle id="confirmation-dialog-title">Add Delay Charges</DialogTitle>
                    <DialogContent dividers>

                      <TextField
                        id="emivalue"
                        label="Delay Amount"
                        value={this.state.fields["DAmount"]}
                        onChange={this.handleChange.bind(this, "DAmount")}
                      />

                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeemidialog} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.adddelaycharges()}
                        color="primary">
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>

                </React.Fragment>

              )}



              <Typography variant="h6" color="initial">Notes</Typography>
              <AddNotes ID={this.state.LoanID} />
              <Notes Data={this.state.Data.notes} />

            </React.Fragment>
          )

          }
        </form>

        <AddGaurantor LoanID={this.state.LoanID} AddGaurantorModelOpen={this.state.AddGaurantorModelOpen} toggleAddGaurantorModel={this.toggleAddGaurantorModel} />
        <Dialog

          fullWidth
          maxWidth="sm"
          aria-labelledby="confirmation-dialog-title"
          open={this.state.GaurantorModalOpen}
        >
          <DialogTitle id="confirmation-dialog-title">Edit Gaurantor</DialogTitle>
          <DialogContent dividers>


            <form noValidate style={{ width: '100%', marginTop: 0 }}>

              <TextField label="Name" margin="normal" variant="outlined"
                value={this.state.GurantorFields["Name"] || ''}
                style={{ width: "100%" }}

                onChange={this.handleGurantorInputChange.bind(this, "Name")}
              />


              <TextField label="Age" margin="normal" variant="outlined" type="number"
                value={this.state.GurantorFields["Age"] || ''}
                style={{ width: "100%" }}
                onChange={this.handleGurantorInputChange.bind(this, "Age")}
              />


              <FormControl style={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
                <OutlinedInput label="Email" margin="normal" variant="outlined" type="email"
                  value={this.state.GurantorFields["Email"] || ''}
                  style={{ width: "100%" }}
                  onChange={this.handleGurantorInputChange.bind(this, "Email")}

                  endAdornment={
                    <InputAdornment position="end">
                      {
                        (this.state.GurantorFields["EmailVerified"] === "NO") ? (
                          <IconButton
                            aria-label=""
                            onClick={() => this.SendGuarantorAgreement(this.state.GurantorFields["ID"])}
                            edge="end"
                          >
                            <SendOutlined />
                          </IconButton>
                        ) : (
                          <></>
                        )
                      }
                    </InputAdornment>
                  }

                />
              </FormControl>
              <Box m={2} />
              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="Relation">Email Verified</InputLabel>
                <Select

                  value={this.state.GurantorFields["EmailVerified"] || ''}
                  onChange={this.handleGurantorInputChange.bind(this, "EmailVerified")}
                  label="Email Verified"

                  inputProps={{
                    name: 'EmailVerified'
                  }}
                >
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </Select>
              </FormControl>
              <Box m={3} />



              <FormControl style={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Phone Number</InputLabel>
                <OutlinedInput label="Phone" margin="normal" variant="outlined" type="number"
                  value={this.state.GurantorFields["Phone"] || ''}
                  style={{ width: "100%" }}
                  onChange={this.handleGurantorInputChange.bind(this, "Phone")}

                  endAdornment={
                    <InputAdornment position="end">
                      {
                        (this.state.GurantorFields["PhoneVerified"] === "NO") ? (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.SendGuarantorOTPVerification(this.state.GurantorFields["Phone"])}
                            edge="end"
                          >
                            <SendOutlined />
                          </IconButton>
                        ) : (
                          <></>
                        )
                      }
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Box m={3} />

              {
                (this.state.GurantorFields["PhoneVerified"] === "NO") ? (

                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">OTP</InputLabel>
                    <OutlinedInput label="OTP" margin="normal" variant="outlined" type="number"
                      value={this.state.GurantorFields["OTP"] || ''}
                      style={{ width: "100%" }}
                      onChange={this.handleGurantorInputChange.bind(this, "OTP")}

                      endAdornment={
                        <InputAdornment position="end">

                          <IconButton
                            aria-label="CLICK-VERIFY-OTP"
                            onClick={() => this.VerifyGuarantorOTP()}
                            edge="end"
                          >
                            <Done />
                          </IconButton>

                        </InputAdornment>
                      }
                    />
                  </FormControl>
                ) : (
                  <></>
                )
              }


              <Box m={3} />

              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="Relation">Phone Verified</InputLabel>
                <Select

                  value={this.state.GurantorFields["PhoneVerified"]}
                  onChange={this.handleGurantorInputChange.bind(this, "PhoneVerified")}

                  label="Phone Verified"
                  inputProps={{
                    name: 'PhoneVerified'
                  }}
                >
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </Select>
              </FormControl>



              <Box m={3} />







              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="Relation">Relation</InputLabel>
                <Select

                  value={this.state.GurantorFields["Relation"] || ''}
                  onChange={this.handleGurantorInputChange.bind(this, "Relation")}
                  label="Relation"
                  inputProps={{
                    name: 'Relation',
                    id: 'select_Relation',
                  }}
                >
                  <MenuItem value="Friend">Friend</MenuItem >
                  <MenuItem value="Family">Family</MenuItem >
                  <MenuItem value="Colleague">Colleague</MenuItem>
                </Select>
              </FormControl>
              <Box m={3} />
              <TextField label="Address" margin="normal" variant="outlined"
                value={this.state.GurantorFields["Address"] || ''}
                style={{ width: "100%" }}
                multiline
                rows={4}
                onChange={this.handleGurantorInputChange.bind(this, "Address")}
              />
            </form>
          </DialogContent>
          <DialogActions>

            <Button color="primary" variant='outlined' onClick={() => this.UpdateGuarantor()}>
              SAVE
            </Button>
            <Button onClick={() => this.setState({ GaurantorModalOpen: false })} color="primary">
              close
            </Button>
          </DialogActions>
        </Dialog>




      </Container>
      </LocalizationProvider>
    );
  }
}

export default EditLoan;


const top100Films = [
  { title: 'Allahabad Bank', year: 1994 },
  { title: 'Andhra Bank', year: 1972 },
  { title: 'Bank of Baroda', year: 1974 },
  { title: 'Bank of India', year: 2008 },
  { title: 'Bank of Maharashtra', year: 1957 },
  { title: "Canara Bank", year: 1993 },
  { title: 'Central Bank of India', year: 1994 },
  { title: 'Corporation Bank', year: 2003 },
  { title: 'Dena Bank', year: 1966 },
  { title: 'ICICI Bank', year: 1950 },
  { title: 'Indian Bank', year: 1999 },

  { title: 'Indian Overseas Bank', year: 2001 },
  { title: 'Oriental Bank of Commerce', year: 1980 },
  { title: 'Punjab & Sind Bank', year: 1994 },
  { title: 'Punjab National Bank', year: 2010 },
  { title: 'Syndicate Bank', year: 2002 },
  { title: "UCO Bank", year: 1975 },
  { title: 'Union Bank of India', year: 1990 },
  { title: 'United Bank Of India (UBI)', year: 1999 },
  { title: 'Vijaya Bank', year: 1954 },
  { title: 'IDBI Bank Limited', year: 1977 },
  { title: 'State Bank of India', year: 2002 },
  { title: 'State Bank Of Bikaner & Jaipur', year: 1995 },
  { title: 'State Bank of Hyderabad', year: 1991 },
  { title: "State Bank of Mysore", year: 1946 },
  { title: 'State Bank of Patiala', year: 1997 },
  { title: 'State Bank of Travancore', year: 1995 },
  { title: 'City Union Bank', year: 1994 },
  { title: 'Dhanalakshmi Bank', year: 2001 },
  { title: 'Federal Bank', year: 1998 },
  { title: 'ING Vysya Bank Ltd', year: 1968 },



  { title: 'Jammu & Kashmir Bank', year: 1998 },
  { title: 'Karnataka Bank', year: 2014 },
  { title: 'Karur Vysya Bank', year: 1942 },
  { title: 'Lakshmi Vilas Bank', year: 1931 },
  { title: 'Nanital Bank', year: 1960 },
  { title: 'The Ratnakar Bank', year: 1999 },
  { title: 'SBI Commercial & International Bank', year: 2011 },
  { title: 'South India Bank', year: 1936 },
  { title: 'Tamilnadu Mercantile Bank', year: 1981 },
  { title: 'United Western Bank', year: 1954 },
  { title: 'IndusInd Bank', year: 2002 },
  { title: 'HDFC Bank', year: 2006 },
  { title: 'ICICI Bank', year: 1991 },
  { title: 'Axis Bank', year: 1985 },
  { title: 'Kotak Mahindra Bank', year: 2014 },
  { title: 'Yes Bank', year: 2000 },
  { title: 'Development Credit Bank (DCB Bank)', year: 2000 },
  { title: 'Citi Bank', year: 2006 },
  { title: 'HSBC', year: 1994 },
  { title: 'Abu Dhabi Commercial Bank', year: 1979 },
  { title: 'Amercian Express Bank', year: 1979 },
  { title: 'Standard Chartered Bank', year: 1950 }


];



const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto'
  },
}


