import React, { Component } from 'react';
import {Card,TableHead,Typography,
  Table,TableContainer,Button,TableRow,TableCell,TableBody,IconButton,} from '@material-ui/core'

import UserContext from '../ContextManage/UserContext'

import * as ApiFunctions from '../../util/apifunction'

class UserContact extends Component {
    static contextType =UserContext;

    constructor(props){

        super()
        let match = props.match;
        this.state={
          UID:match.params.UID,
          isloading:true,
          Data:[],
          Contacts:[],
          isError:false,
          ID:null,
          Email:null,
          Role:null,
          Token:null,
          isloggedin:false,
      
        }
      }
    


      async componentDidMount(){
  
        const UserContextData = this.context
        await this.setState({
          ID:UserContextData.state.ID,
          Email:UserContextData.state.Email,
          isloggedin:UserContextData.state.loggedin,
          Token:UserContextData.state.Token,
          Role:UserContextData.state.Role
         })
    
         if(this.state.isloading){
         await this.GetUserContactProfile()
         }else{
           window.location.href="/"
         }
         
       }


    
    async GetUserContactProfile(){
      const header={
        'Content-Type': 'application/json',
        'x-access-token':this.state.Token
     
      };
       try{
        const Data = await ApiFunctions.GetApiRequest(`/api/contacts/${this.state.UID}`,header)
    
        if(Data.Status===200){       
          this.setState({Data:Data.result})
          this.setState({ Contacts: JSON.parse(Data.result.Contacts)})
          this.setState({isloading:false})
        }
      }catch(err){
        this.setState({isError:true})
        }
      }
  



    render() {
        return (
            <React.Fragment>

                {
                  (this.state.isloading) ? (
                      <h1>Loading......</h1>
                  ) :(

       
                    <Card style={{marginBottom:10}}>

            

                    <TableContainer >
                     <Table  aria-label="simple table">
                       <TableHead>
                         <TableRow>
                           
                           <TableCell align="left">ID</TableCell>
                           <TableCell align="left">give Name</TableCell>
                           <TableCell align="left">display Name</TableCell>
                           <TableCell align="left">department Name</TableCell>
                           <TableCell align="left">Family Name</TableCell>
                           <TableCell align="left">Phone Numbers</TableCell>
                           <TableCell align="left">Email Address</TableCell>
                          
               
               
               
               
                         </TableRow>
                       </TableHead>
                       <TableBody>
                       {this.state.Contacts.map((data) => (
            <TableRow key={data.rawContactId}>
                  <TableCell align="left">{data.rawContactId}</TableCell>
                  <TableCell align="left">{data.givenName}</TableCell>
                 
                  <TableCell align="left">{data.displayName}</TableCell>
                 
                  <TableCell align="left">{data.department}</TableCell>
                 
                  <TableCell align="left">{data.familyName}</TableCell>

                  <TableCell align="left">{
                   data.phoneNumbers.map((phone)=>{
                    return (
                      phone.number
                    )
                   })
                  }</TableCell>

                  <TableCell align="left">{
                      data.emailAddresses.map((email)=>{
                        return (
                          email.email
                        )
                       })
                  }</TableCell>

                


            </TableRow>
          ))}
                       </TableBody>
                     </Table>
                   </TableContainer>
               
               
                                   </Card>

                   )
                }

                           </React.Fragment>
        );
    }
}

export default UserContact;

