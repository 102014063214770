import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box'
const images = [
  {
    heading:"PERSONAL LOAN",
    icon:"savings"
  },
  {
    heading:"BUSINESS LOAN",
    icon:"add_business"
  },
  {
    heading:"MORTGAGE LOAN",
    icon:"other_houses"
  },
  {
    heading:"HOME LOAN",
    icon:"real_estate_agent"
  },
  {
    heading:"WORKING CAPITAL LOAN",
    icon:"engineering"
  },
  {
    heading:"INVOICE FINANCE",
    icon:"receipt"
  },
  {
    heading:"TOP UPS",
    icon:"credit_score"
  },
  {
    heading:"EDUCATION LOAN",
    icon:"school"
  },
  {
   
    heading:"VEHICLE LOAN",
    icon:"directions_car"
  }
];

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



class ProductCarsouel extends Component {
    render() {
        return (
            <Carousel responsive={responsive}>
             {images.map(image => {
        return (
          <div style={{ position: "relative" }}>
         <Paper style={{margin:10,padding:10,textAlign:"center",borderRadius:"5px 20px 5px"}}>
         <Icon  style={{ fontSize: 48 }} color="primary">{image.icon} </Icon>

              <Box m={4}/>
            <Typography variant="subtitle1" align="center" color="initial">{image.heading}</Typography>
            </Paper>

          </div>
        );
      })}
          </Carousel>
        );
    }
}

export default ProductCarsouel;