import React, { Component } from 'react'
import {AppBar,IconButton,
    Toolbar,Button,Box,ListItemText,Avatar,ListItemAvatar,Typography,Divider,List,ListItem,ListItemIcon,SwipeableDrawer } from '@material-ui/core'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';

import UserContext from '../ContextManage/UserContext'
import { withRouter } from 'react-router';


import history from '../../util/history';



//iconss
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon'


class LoggedInMenu extends Component {
  static contextType =UserContext;
  constructor(props){
    super(props)
    this.state={
      ismenuopen:false,
      Settings:null,
      Theme:null,
      ID:null,
      Email:null,
      Role:null,
      Token:null,
      isloggedin:false,
      FullName:null
    }
  }

  openmenu(check) {
    this.setState({ ismenuopen: check });
  }


 async componentDidMount(){
    const UserContextData = this.context
  const SettingsContext=UserContextData.state.Settings
 
  await  this.setState({
        Settings:SettingsContext,
        ID:UserContextData.state.ID,
        Email:UserContextData.state.Email,
        isloggedin:UserContextData.state.loggedin,
        Token:UserContextData.state.Token,
        Role:UserContextData.state.Role,
        FullName:UserContextData.state.FullName
        })
  }


  logout=()=>{
    const UserContextData = this.context
    UserContextData.LOGOUT()
  }


  
  ChnageTheme=()=>{
    const UserContextData = this.context
    UserContextData.ONTOGGLEDARKMODE()
    this.setState({
      Theme:UserContextData.state.CurrentTheme
    })
  }  





  render() {
  
    return (
      <div style={{flexGrow:1,zIndex:999,top:0,position:"sticky",marginBottom:10}}>
        <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={()=>this.openmenu(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="body1" style={{flexGrow:1}} onClick={()=>history.push('/')}>
          {/* {this.state.Settings[0].Value} */}
        SUNSHUBH LOANS
          </Typography>
          <Button color="inherit" variant="outlined" 
          onClick={()=>this.logout()}
          >Logout</Button>
            <IconButton onClick={()=>this.ChnageTheme()} edge="end" style={{marginLeft:{spacing:2}}} color="inherit" aria-label="theme-option">
        {
          (this.state.Theme==='light') ? (
            <Brightness4Icon/>
          ):(
          
            <Brightness7Icon/>
          )
        }
         
        </IconButton>
        </Toolbar>
      </AppBar>



{/* the drawer section*/}

<SwipeableDrawer
          open={this.state.ismenuopen}
          onClose={() => this.openmenu(false)}
          onOpen={() => this.openmenu(true)}>

<div role="presentation"
          style={{width:'auto'}}
          onClick={()=>this.openmenu(false)}
          onKeyDown={()=>this.openmenu(false)}
          >





         
            <List style={{width:250}}>
          <div  style={{
                      display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',marginTop:25}}>
                  <Avatar
                    style={{
                      width: 40,
                      height:40,
                      transition: "0.3s",
                     
      
                    }}
                  />
                 
                  <Typography variant={"h6"} noWrap>
               {this.state.FullName}
                  </Typography>
                  <Typography color={"textSecondary"} noWrap gutterBottom>
                  {this.state.Email}

                  </Typography>

                  <Box m={1}/>


                    

                  <Button variant="contained" color="primary"
                      onClick={() =>
                        this.props.history.push("/app/users-profile/")
                      }
                  >My Profile</Button>

                        <Box m={1}/>

                  </div>

                <Divider />


              {this.props.routes.map((route) => (    
                <Link
                key={route.url}
                to={`${this.props.path}${route.url}`}
                style={{textDecoration:'none',color:'black'}}
                 variant="body2"
                >{
                  route.child===true ? console.log("child element") : <ListItem button key={route}>
                  <ListItemIcon>
                  <Icon>
                    {route.icon}
                  </Icon>
                  </ListItemIcon>
                  <ListItemText color="secondry" primary={route.title} />
               
                </ListItem>
          
                }
 
                 </Link>
                  

                )
              )}
            
            </List>
          </div>
        </SwipeableDrawer>
        </div>
    )
  }
}

  


LoggedInMenu.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired
		})
	).isRequired,
	path: PropTypes.string.isRequired
};



export default  withRouter(LoggedInMenu)
