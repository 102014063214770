import React from 'react'
import {Typography,Button,TextField,Select,InputLabel,Box,Paper} from '@material-ui/core'


import {Dialog,
    DialogTitle,DialogContent,
    DialogActions,DialogContentText,Divider,FormControl,MenuItem} from '@material-ui/core'


    import { Alert, AlertTitle } from '@material-ui/lab';

import { TableCell,TableBody,TableRow,Table,TableHead,TableContainer } from '@material-ui/core';



import UserContext from '../ContextManage/UserContext';


import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteApiData } from '../../util/apifunction';


export default function EditRef(props) {
  
       const User=React.useContext(UserContext);

    const [ShowAddRef,SetShowAddRef]=React.useState(false);
    const [RefData,SetRefData]=React.useState({})

      
         const handleClickOpen = () => {
            SetShowAddRef(true);
          
      };
    
      const handleClose = () => {
        SetShowAddRef(false);
      };

      const SendRefData=async(row)=>{
         await SetRefData(row)
      await  SetShowAddRef(true);
      }



      const DeleteRef=async(ID)=>{
        const header={
          'Content-Type': 'application/json',
        'x-access-token':User.state.Token
         };
    
         try{
          const Data = await DeleteApiData(`/ref/customer/${ID}`,header)
        
        if(Data.Status===200){
          alert(`Reference ${ID} deleted Successfully!`)
         window.location=window.location.href;
        }
    
        if(Data.Status===404){
          alert(`Reference ${ID} Not Found!`)
        }
          
        }catch(err){
          alert(`Internal Server Error`)
         
          }
        }
    

    return (
        <>

        <Box m={4}/>
        {
            (props.REF.length===0) ? (
                <>

<Alert>
                                    <AlertTitle>info</AlertTitle>
                                         <Typography variant="h6" color="initial">

                                         User Have No References.
                                         </Typography>
                                      </Alert>


                </>
            ) :(
                    <>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="left">Reference ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Relation</TableCell>
            <TableCell align="left">Created At</TableCell>
            <TableCell align="left">Operatons</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {props.REF.map((row) => (
            <TableRow
              key={row.ID}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                              <TableCell align="lef">{row.ID}</TableCell>

              <TableCell align="lef">{row.Name}</TableCell>
              <TableCell align="left">{row.Phone}</TableCell>
              <TableCell align="left">{row.Relation}</TableCell>
              <TableCell align="left">{new Date(row.Created_At).toString()}</TableCell>
              <TableCell align="left">
              <IconButton onClick={()=>DeleteRef(row.ID)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
    

    {/* 
        <IconButton onClick={()=>SendRefData(row)}  aria-label="edit">
          <EditIcon />
        </IconButton> */}
      
       
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

                    </>
            )
        }
        
        <UpdateRef
   REFDATA={RefData}
        open={ShowAddRef}
        onClose={handleClose}
      />

        </>

  )
}






function UpdateRef(props) {

    


    const { onClose, open ,REFDATA} =props;

    const [Name,SetName]=React.useState(REFDATA.Name)
    const [Phone,SetPhone]=React.useState(REFDATA.Phone)
    const [Relation,SetRelation]=React.useState(REFDATA.Relation)

    

    const HandleName=(e)=>{
        SetName(e.target.value)
    }

    
    const HandlePhone=(e)=>{
        SetPhone(e.target.value)
    }

    
    const HandleRelaion=(e)=>{
        SetRelation(e.target.value)
    }



/* 
    const MakeApiCall=async()=>{

        const header={
            'Content-Type': 'application/json',
            'x-access-token': User.state.Token
           };

        try {
            
            const Data = await GetApiRequest.PostApiData(`/ref/customer`,header)

            if(Data.Status==200){
            window.location.href='/app/user-ref';
            }else{
                alert("Something Went Wrong Try Again After Some time");
            }



        } catch (error) {

            console.log(error)
             alert("INTERNAL SERVER ERROR! -- 500")
        }



    } */


   

   

    

    const handleClose = () => {
      onClose(false);
    
    };
  

  return (

    
    <Dialog
    onClose={handleClose} open={open}
    maxWidth="sm"
    fullWidth="sm"
    aria-labelledby="add-ref"
    aria-describedby="add-references"
  >

      <h2>{REFDATA.ID}</h2>
      <form noValidate style={{ width: '100%' }}   >


    <DialogTitle id="add-ref">
        Edit Reference
    </DialogTitle>

    <Divider/>
    <DialogContent>
      <DialogContentText id="add-ref-content">
        <Box m={2}/>
      
            <TextField
              id="Name"
              label="Name"
              fullWidth
              name="Name"
              variant='outlined'
              value={Name}
             onChange={(e) => HandleName(e)}
            
            />


<Box m={4}/>
<TextField
type='number'
InputProps={{ inputProps: { min: 10, max: 10 } }}
              id="Phone"
              label="Phone"
              fullWidth
              name="Phone"
              variant='outlined'
              value={Phone}
             onChange={(e) => HandlePhone(e)}
        
            />


<Box m={4}/>
<FormControl fullWidth  variant="standard">
  <InputLabel id="demo-simple-select-label">Relaton</InputLabel>
  <Select
     defaultValue="choose"
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={Relation}
    label="Relation"
    onChange={(e) => HandleRelaion(e)}

  >
    <MenuItem value="Friend">Friend</MenuItem>
    <MenuItem value="Family">Family</MenuItem>
    <MenuItem value="Colleague">Colleague</MenuItem>
  </Select>
</FormControl>





      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button  type="submit" id="submit">Save</Button>
      <Button variant='outlined' color="primary" onClick={handleClose}>
            Cancel
      </Button>
    </DialogActions>
    </form>

  </Dialog>
  )
}
