import React, { Component } from 'react';
import {  Typography ,Box,Grid,Container,

  Paper
  }  from '@material-ui/core'



import UserContext from '../ContextManage/UserContext'

import * as ApiFunctions from '../../util/apifunction'

import Skeleton from '@material-ui/lab/Skeleton';

import AddNotes from './AddNotes.jsx'


import Notes from './Notes';



class SinglePL extends Component {
  static contextType =UserContext;
 
  constructor(props){
      super(props);
      let match = props.match;
      this.state={
        fields: {},
        errors: {},    
        ID:null,
              Email:null,
              Role:null,
              Token:null,
              isloggedin:false,
              LoanID:match.params.LID,
              Data:{},
              Notes:[],
              isloading:true
      }
  }






  async componentDidMount(){
    const UserContextData = this.context
    await this.setState({
      ID:UserContextData.state.ID,
      Email:UserContextData.state.Email,
      isloggedin:UserContextData.state.loggedin,
      Token:UserContextData.state.Token,
      Role:UserContextData.state.Role
     })



     await this.GetloanDetails()
    // await this.setState({guarantor: JSON.parse(this.state.Data.guarantor)})
  

   }


  async GetloanDetails(){
    const header={
      'Content-Type': 'application/json',
      'x-access-token':this.state.Token
     };
     try{
      const Data = await ApiFunctions.GetApiRequest(`/loan/api/pa/application/${this.state.LoanID}`,header)

     
      if(Data.Status===200){
      await  this.setState({Data:Data.Data,Notes:Data.Notes,isloading:false})
      console.log(Data)
     }else{
          this.setState({isError:true,isloading:true})   
      }
    }catch(err){
      this.setState({isError:true})
      }
    }


    


    

    render() {

      const {Data}=this.state;
        return (

            <Container component="main" maxWidth="lg" >
                     
                { (this.state.isloading) ? ( 

<Skeleton animation="wave" variant="rect"   width="100%" height={600}/>
                ) : (
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={5}>

                  <Box m={2}>  <Typography variant="h5" color="initial">Personal Details</Typography></Box>
                      
                    {/* UI FOR THE BASIC DATA */}
                    <Paper  variant="outlined" style={{padding:20}}>

                   

                    <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Full Name :-   { <span style={{color:"black"}}>{Data.Full_Name}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Email :-   { <span style={{color:"black"}}>{Data.Email}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Phone No. :-   { <span style={{color:"black"}}>{Data.Phone}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Married Status :-   { <span style={{color:"black"}}>{Data.MS}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">DOB :-   { <span style={{color:"black"}}>{Data.DOB}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Gender :-   { <span style={{color:"black"}}>{Data.Gender}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">PAN :-   { <span style={{color:"black"}}>{Data.Pan}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Pin Code :-   { <span style={{color:"black"}}>{Data.Pin}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Cibil :-   { <span style={{color:"black"}}>{Data.Cibil}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Residential Status :-   { <span style={{color:"black"}}>{Data.RS}</span>  }</Typography>
                    


                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={7} md={7} lg={7}>
                  <Box m={2}>  <Typography variant="h5" color="initial">Salary Details</Typography></Box>
                      
                  {/* for the additional user data */}
                  <Paper  variant="outlined" style={{padding:20}}>
                
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Salary Recive Date :-   { <span style={{color:"black"}}>{Data.SRD}</span>  }</Typography>
                      <Box m={2}/>
                    
                      <Typography variant="subtitle1" color="textSecondary">Salary Recive Type:-   { <span style={{color:"black"}}>{Data.SRT}</span>  }</Typography>
                      <Box m={2}/>
                      <Typography variant="subtitle1" color="textSecondary">Current Working Expeience :-   { <span style={{color:"black"}}>{Data.CEXP}</span>  }</Typography>        
                   
                  </Paper>

                  <Box m={2}>  <Typography variant="h5" color="initial">Loan Details</Typography></Box>
                  {(this.state.isloading) ? (



<Skeleton animation="wave" variant="rect"   width="100%" height={300}/>


 ) : (

   <Paper  variant="outlined" style={{padding:20}}>

  
   <Typography variant="subtitle1"color="textSecondary">Amount:-   { <span style={{color:"black"}}>{Data.LoanAmount}</span>  }</Typography>
   <Box m={2}/>
  
   <Typography variant="subtitle1" color="textSecondary">Tenure :-   { <span style={{color:"black"}}>{Data.Tenure} Years</span>  }</Typography>
   <Box m={2}/>
   <Typography variant="subtitle1" color="textSecondary">CLE :-   { <span style={{color:"black"}}>{Data.CLE}</span>  }</Typography>
   <Box m={2}/>

<Typography variant="subtitle1" color="textSecondary">CCE :-   { <span style={{color:"black"}}>{Data.CCE}</span>  }</Typography>
<Box m={2}/>

<Typography variant="subtitle1" color="textSecondary">Type of Loan :-   { <span style={{color:"black"}}>{Data.Type_Loan}</span>  }</Typography>



</Paper>


 )}

     </Grid>

           </Grid>
                )

                }

                   

                  
               
<Box m={2}>  <Typography variant="h5" color="initial">Notes</Typography>

<AddNotes ID={this.state.LoanID}/>
</Box>


                {/* Notes details */}
                {(this.state.isloading) ? (

<Skeleton animation="wave" variant="rect"   width="100%" height={300}/>


 ) : (
    <React.Fragment>

    <Notes Data={this.state.Notes}/>

    </React.Fragment>

 )}

              
          </Container>
        );
    }
}

export default SinglePL;





