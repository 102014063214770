import React, { Component } from 'react';


import  { Typography,Container,Box} from '@material-ui/core'

class PrivacyPolicy extends Component {
    render() {
        return (
            <Container maxWidth="lg">
      
      <div style={{ display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:50}}>
     <Typography variant="h3" alignCenter color="primary">PRIVACY POLICY</Typography>
   <Box m={5}></Box>
   </div>

   <p>This Privacy Policy, as amended from time to time, (&ldquo;Privacy Policy) governs the data in relation</p>
<p>to the onboarded customer&rsquo;s (&ldquo;You&rdquo;/&rdquo;Users&rdquo;) relationship with &ldquo;SUNSHUBH SOLUTIONS PVT</p>
<p>LTD&rdquo; (&ldquo;SUNSHUBH LOANS&rdquo;) accessible at Our Website, Google Play and Apple&rsquo;s App Store</p>
<p>operated by Sunshubh loans through which it offers You the services, including bringing</p>
<p>individual borrowers who intend to borrow money in India (&ldquo;Services&rdquo;). Please read these terms</p>
<p>carefully. By using this Platform (as defined below), you agree to be bound by all of the terms</p>
<p>and conditions of this Privacy Policy.</p>
<p>The statements included herein disclose the privacy practices of Sunshubh Loans for the</p>
<p>purposes of its Platform (as defined below) and govern Users&rsquo; access to the same. This Privacy</p>
<p>Policy is intended to help Users of Sunshubh Loans understand what information Sunshubh</p>
<p>Loans collects, how is it, and what rights Users have on their information when sharing it with</p>
<p>Sunshubh Loans. The Sunshubh Loans website and mobile application and any other platform</p>
<p>or website authorized by Sunshubh Loans from time to time for the purpose of validly accessing</p>
<p>Sunshubh Loans are hereinafter collectively referred to as &ldquo;Platform&rdquo;.</p>
<p>Sunshubh Loans is committed to ensuring that your privacy is protected. Should we ask you to</p>
<p>provide certain information by which you can be identified when using this website, then you can</p>
<p>be assured that it will only be used in accordance with this Privacy Policy.</p>
<p>Sunshubh Loans may change this policy from time to time by updating this page. You should</p>
<p>check this page from time to time to ensure that you are happy with any changes. This policy is</p>
<p>effective from 1st October 2020.</p>
<p>This Privacy Policy governs the following:</p>
<p>a) The type of information collected from the Users, including personal information.</p>
<p>b) The purpose means and modes of collection, usage, processing, retention, storage and</p>
<p>destruction of such information.</p>
<p>c) How and to whom Sunshubh Loans will disclose such information.</p>
<p>d) Who retains this information and who all can access it.</p>
<p>Sunshubh Loans may disclose or transfer your personal information after retrieving it from</p>
<p>Sunshubh Loans&rsquo;s database to individual companies, affiliated companies, or third parties in</p>
<p>locations around the world for the purposes described in this Privacy Policy. Wherever your</p>
<p>personal information is transferred, stored, or processed by us, we will take reasonable steps in</p>
<p>accordance with prevailing technology to safeguard the privacy of your personal information. A</p>
<p>User may request to cease such disclosure by writing to us subject to the protocol described in</p>
<p>Clause 7 above.</p>
<p>PERSONAL INFORMATION COLLECTED BY SUNSHUBH LOANS AND ITS</p>
<p>PURPOSES.</p>
<p>1.1 Sunshubh Loans respects each User&rsquo;s privacy and recognizes the need to protect the</p>
<p>personal data or information shared with Sunshubh Loans.</p>
<p>1.2 The words &ldquo;you&rdquo; or &ldquo;your&rdquo; or &ldquo;User&rdquo; as used herein, refer to all individuals and/or entities</p>
<p>accessing or using the Platform for any reason.</p>
<p>1.3 By accessing, browsing, registering, logging in the Platform, the User acknowledges to have</p>
<p>read, understood, and agreed to be bound by this Privacy Policy and affirm assent to the</p>
<p>collection, use, storage, processing and disclosure as set out in this Privacy Policy.</p>
<p>1.4 To avail the Services on our Platform, Users voluntarily provide us certain information, i.e.</p>
<p>name, occupation and other related professional details, contact information including phone</p>
<p>number and email address, demographic information including postcode, preferences and</p>
<p>interests, gender, age, other information related to creating the profile of the User and the like,</p>
<p>as part of the registration process whether as a borrower on the Platform. This information helps</p>
<p>us enhance our services by allowing us to tailor our Platform to cater to your personal interests/</p>
<p>needs and make them more useful and relevant to you. Further, your information is required to</p>
<p>create and maintain your accounts with us and to contact you in case you need support.</p>
<p>1.5 Such information will be stored and processed by Sunshubh Loans. The sole purpose for</p>
<p>Sunshubh Loans to obtain such information is to maintain internal records, improve the products</p>
<p>and services offered on the Platform, to customize the website according to the User&rsquo;s interests</p>
<p>and periodically providing promotional information to Users by emails about new products,</p>
<p>special offers or other information. Further, from time to time, Sunshubh Loans may also use</p>
<p>your information to contact you for market research purposes. We may contact you by email,</p>
<p>phone, fax or mail.</p>
<p>1.6 Sunshubh Loans shall obtain documents, details and information with respect to the</p>
<p>&lsquo;account holders&rsquo; of Sunshubh Loans for carrying out know-your-customer verification of such</p>
<p>account holders.</p>
<p>1.7 We would collect the following information about you when you use the Platform and without</p>
<p>such information we may not be able to provide you with all the requested Services. The</p>
<p>information collected is classified into following heads:</p>
<p>a) Personal Data:</p>
<p>i) User-Provided Data: The following categories of information may be sought directly from the</p>
<p>User. This information is essential for availing certain features on the Platform, and extends to</p>
<p>basic contact information including but not limited to the following:</p>
<p>Name</p>
<p>Gender</p>
<p>Phone</p>
<p>Aadhaar Card</p>
<p>Pan Card</p>
<p>Email</p>
<p>Date of Birth</p>
<p>Address</p>
<p>Age</p>
<p>Interests</p>
<p>ii) Mobile Device Permissions: We may further collect the following personal information, either</p>
<p>through the mobile application or other relevant Platforms. This information will further be</p>
<p>processed and stored with Sunshubh Loans (as mentioned above). Permissions to these</p>
<p>settings may be essential for the good running of the relevant Platform and can be managed</p>
<p>through the respective User device&rsquo;s controls. These include:</p>
<p>Contacts</p>
<p>Phone</p>
<p>Camera</p>
<p>Gallery</p>
<p>Microphone</p>
<p>SMS</p>
<p>iii) Information you provide us voluntarily: This extends to personal correspondence, such as</p>
<p>emails or letters sent by the User. We may also collect additional information at other times, for</p>
<p>example, when you provide feedback(s), or post on the Platform, respond to surveys, or</p>
<p>communicate with us. This information may include your name, e-mail ID, mobile number,</p>
<p>location, etc. This information is typically used for either personalizing the User&rsquo;s experience on</p>
<p>the) or other reasonably related purposes</p>
<p>iv) Third Party Information: Sunshubh Loans may also receive information about you through</p>
<p>other Users or by third parties with whom the User has consented to sharing correspondence</p>
<p>about their activities (&ldquo;TPI&rdquo;). It is clarified that Sunshubh Loans does not undertake any</p>
<p>obligation to verify if the User has consented to third parties sharing TPI with Sunshubh Loans</p>
<p>and any damage arising to the User thereto shall be a matter solely and exclusively between the</p>
<p>User and the concerned third party.</p>
<p>v) Information we collect automatically: We collect information about you and your use of our</p>
<p>services, your interactions with us, as well as information regarding your devices, used to</p>
<p>access our services (such as mobile devices, tablets, and other viewing devices). This</p>
<p>information includes, but is not limited to:</p>
<p>Your interactions with our email, customer care, and messaging Platform;</p>
<p>Information collected/stored via installed mobile applications;</p>
<p>Device information like device ID, device model etc. and other unique identifiers;</p>
<p>Advertising identifiers, such as those found on mobile devices and tablets, devices that include</p>
<p>identifiers; and</p>
<p>Information is collected via the use of cookies and other technologies.</p>
<p>vi) User Experience and Support: We identify and use your internet protocol (&ldquo;IP&rdquo;) address to</p>
<p>help diagnose any problems with our server and to administer our application. Your IP address</p>
<p>is also used to help identify you and to gather broad demographic information to customize your</p>
<p>experience on our Platform. You will occasionally be asked to complete optional online surveys.</p>
<p>These surveys may ask you for information such as contact information and demographic</p>
<p>information (like Pincode, age, etc.). Such data is used to tailor your experience on the Platform,</p>
<p>providing you with content and campaigns that we think you might be interested in and to</p>
<p>display content and campaigns according to your preferences.</p>
<p>b) Sensitive Personal Data: In addition to the above, Sunshubh Loans may collect the following</p>
<p>information that may qualify as sensitive personal information. A User providing the information</p>
<p>falling under this category expressly consents to its collection and use by Sunshubh Loans and</p>
<p>its affiliates for the purposes mentioned herein and in accordance with the other provisions of</p>
<p>this Privacy Policy.</p>
<p>i) Payment information: We do not collect any payment information from the Users except for</p>
<p>details relating to bank accounts and payment wallets. Such information shall be dealt in</p>
<p>accordance with the Terms of Use and Privacy Policy of the Sunshubh Loans Platform.</p>
<p>ii) Tax Details: All details associated with the above payment modalities for the purposes of</p>
<p>paying and depositing tax with the requisite authorities.</p>
<p>iii) Information Use Cases: Without prejudice to any use cases of information detailed in above,</p>
<p>Sunshubh Loans and Sunshubh Loans retains the right to use the User&rsquo;s information for the</p>
<p>following purposes:</p>
<p>Making available relevant content based on your interest for an improved experience;</p>
<p>Assessing and determining the creditworthiness of Users;</p>
<p>Respond to your questions or comments;</p>
<p>Analyze and understand our audience, improve our services (including our User interface</p>
<p>experiences), and optimize content selection, recommendation algorithms and delivery;</p>
<p>Conduct analysis and research to improve our Platform;</p>
<p>Advertise Users to participate in interactive features offered through the services;</p>
<p>Notify Users about the change in terms of service; and</p>
<p>Send you updates on relevant services.</p>
<p>However, any personal information provided by you will not be considered as sensitive if it is</p>
<p>freely available and/or accessible in the public domain like any comments, messages, blog</p>
<p>posts or scribbles, etc. available on social media Platform like Facebook, Twitter etc. Any</p>
<p>information posted/ uploaded/ conveyed/ communicated by Users on the public sections of the</p>
<p>Platform becomes published content. In addition, Sunshubh Loans and We may collect</p>
<p>information regarding the domain and host from which the visitor accesses the internet, the</p>
<p>internet protocol address of the computer or internet service provider, browsing history, and</p>
<p>anonymous website or application statistical data. The Platform uses cookie and tracking</p>
<p>technology depending on the features offered which are text files collected by a User&rsquo;s web</p>
<p>browser.</p>
<p>SECURITY</p>
<p>2.1 We are committed to ensuring that your information is secure. In order to prevent</p>
<p>unauthorized access or disclosure, we have put in place suitable physical, electronic, and</p>
<p>managerial procedures to safeguard and secure the information we collect online.</p>
<p>2.2 We, Sunshubh Loans understand how you care about the privacy and the security of your</p>
<p>personal information, which is why we are committed to respect and protect your privacy and</p>
<p>your information shared with us from any unauthorized access or loss. All information gathered</p>
<p>on the Platform is securely stored within our database. The database is stored on the servers</p>
<p>secured behind a firewall and access to these servers is password protected and is strictly</p>
<p>limited. If you know or have reason to believe that your account credentials have been lost,</p>
<p>stolen, altered, or otherwise compromised, or in case of any actual or suspected unauthorized</p>
<p>use of your account, please contact our grievance officer at company@sunshubhloans.com</p>
<p>2.3 In order to make every effort to ensure that the User&apos;s experience on the Platform is secure,</p>
<p>Sunshubh Loans uses encryption technology to protect the Users against the loss, misuse or</p>
<p>alteration of Personal Information.</p>
<p>2.4 However, in this digital era, the transmission of information via the internet is never</p>
<p>completely impenetrable. Hence, Users should also take the requisite steps to protect their</p>
<p>personal information against any unauthorized access. Such steps include choosing a strong</p>
<p>password, signing off after using a shared device, and keeping your log-in details, especially</p>
<p>your password, private. Sunshubh Loans shall not be held responsible for any lost, stolen, or</p>
<p>compromised information or for any activity on your account via unauthorized password usage.</p>
<p>Further, Sunshubh Loans disclaims all liability for the breach of any of the User&rsquo;s data in the</p>
<p>event the said User fails to follow or knowingly breaches the below-mentioned guidelines for</p>
<p>access.</p>
<p>2.5 The User may review, correct, update or change their account information at any time. To</p>
<p>protect the User&rsquo;s privacy and security, We will verify the User&rsquo;s identity before granting access</p>
<p>or making changes to the personal information.</p>
<p>ACCESS GUIDELINES FOR USERS</p>
<p>3.1 Don&rsquo;t break our security measures or test the vulnerability of our systems or networks.</p>
<p>3.2 Don&rsquo;t access or use the Platform by means of any mechanism or technology which conceals</p>
<p>your actual geo-location or provides incorrect details of your location (for example, use a virtual</p>
<p>private network (VPN) to access the services).</p>
<p>3.3 Don&rsquo;t try to interfere with our networks, like sending a virus, overloading, spamming.</p>
<p>3.4 Don&rsquo;t collect or store personally identifiable information of people on the Platform.</p>
<p>3.5 Don&rsquo;t provide any unauthorized personal information of third parties while providing or</p>
<p>contributing any User generated content on the Platform.</p>
<p>3.6 Don&rsquo;t share your password, allow anyone to access your account or do anything that might</p>
<p>put your account at risk.</p>
<p>3.7 Don&rsquo;t attempt to buy or sell access to your account, or Usernames, or otherwise transfer</p>
<p>account features for compensation.</p>
<p>3.8 Don&rsquo;t create or operate accounts that aren&rsquo;t authentic.</p>
<p>3.9 Don&rsquo;t create new accounts for the purpose of violating these guidelines.</p>
<p>3.10 Don&rsquo;t link your web browser to websites that are unsafe, deceptive, untrustworthy,</p>
<p>unoriginal, or that facilitate or encourage spam.</p>
<p>3.11 Don&rsquo;t send unsolicited messages or make irrelevant comments on the content of the</p>
<p>Platform.</p>
<p>3.12 To maintain the quality of our Platform, we remove spam when we find it. We ask that you</p>
<p>be considerate of any spamming experience you come across, and if any, report to us</p>
<p>proactively at company@sunshubhloans.com</p>
<p>CONTROLLING YOUR PERSONAL INFORMATION</p>
<p>You may choose to restrict the collection or use of your personal information in the following</p>
<p>ways :</p>
<p>Whenever you are asked to fill in a form on the website, look for the box that you can click to</p>
<p>indicate that you do not want the information to be used by anybody for direct marketing</p>
<p>purposes. If you have previously agreed to us using your personal information for direct</p>
<p>marketing purposes, you may change your mind at any time by writing to or emailing us at</p>
<p>company@sunshubhloans.com</p>
<p>DATA RETENTION AND ERASURE</p>
<p>Sunshubh Loans may retain your personal information as long as necessary for the legitimate</p>
<p>business interests to provide the user&apos;s appropriate services and to comply with our legal</p>
<p>obligations. If you no longer want us to use your information, then you can request that we</p>
<p>erase your personal information and close your account. Please note that if you request for the</p>
<p>erasure of your personal information, we may:</p>
<p>Still retain some of your personal information as necessary for our legitimate business interests,</p>
<p>such as fraud detection, prevention, and enhancing safety. For example, if we suspend an</p>
<p>account for fraud or safety reasons, we may retain certain information from that account to</p>
<p>prevent that user from opening a new account in the future.</p>
<p>Still retain and use your personal information to the extent necessary to comply with our legal</p>
<p>obligations. For example, we may keep some of your information for taxation, legal reporting,</p>
<p>and auditing obligations.</p>
<p>GENERAL DISCLOSURE</p>
<p>Sunshubh Loans may disclose or transfer your personal information after retrieving it from</p>
<p>Sunshubh Loans&rsquo;s database to individual companies, affiliated companies, or third parties in</p>
<p>locations around the world for the purposes described in this Privacy Policy. Wherever your</p>
<p>personal information is transferred, stored, or processed by us, we will take reasonable steps in</p>
<p>accordance with prevailing technology to safeguard the privacy of your personal information. A</p>
<p>User may request to cease such disclosure by writing to us subject to the protocol described in</p>
<p>Clause 7 above.</p>
<p>SERVICE PROVIDERS</p>
<p>At times, Sunshubh Loans may make certain personal information available to its third party</p>
<p>strategic partners, or other third parties that help Sunshubh Loans in marketing and research.</p>
<p>When you register with our Platform, you authorize Sunshubh Loans to exchange the</p>
<p>information you provide for the purpose of provision of the services as well as to improve the</p>
<p>services and for our marketing aspects. Such exchange of information by Sunshubh Loans shall</p>
<p>be governed by our Privacy Policy.</p>
<p>Sunshubh Loans may also carefully select other companies to send you information about their</p>
<p>products or services which are related to the services but are not necessary to its operation.</p>
<p>ADVERTISING</p>
<p>When we present information to our advertisers to help them understand our audience and</p>
<p>confirm the value of advertising on our Platform, it is usually in the form of aggregated statistics</p>
<p>on traffic to various pages/ content within our Platform. We use third-party advertising</p>
<p>companies to serve ads when you visit our Platform. These companies may use information</p>
<p>about your visits to this and other websites or applications, in order to provide advertisements</p>
<p>about goods and services of interest to you.</p>
<p>LEGAL</p>
<p>Sunshubh Loans may disclose personally identifiable information if required to do so by law or</p>
<p>in good faith belief that such action is necessary to:</p>
<p>Comply with legal process or proceedings or judicial order served on the Platform or its owners.</p>
<p>Protect and defend the rights or property of the Platform owners or of the users of the Platform,</p>
<p>or</p>
<p>Act under exigent circumstances to protect the personal safety of users of the Platform, its</p>
<p>owners, or the public.</p>
<p>AMENDMENT TO THE PRIVACY POLICY</p>
<p>Sunshubh Loans may amend this Privacy Policy from time to time without requiring any consent</p>
<p>from anyone. If Sunshubh Loans changes this Privacy Policy, the same will be posted on this</p>
<p>page so that the user is aware of the Personal Information collected, used and disclosed by</p>
<p>Sunshubh Loans. Any changes to the Privacy Policy will be communicated through the</p>
<p>Platform. Users are bound by any changes to the Privacy Policy when they use Sunshubh</p>
<p>Loans after such changes have been first posted.</p>
<p>CONTACT INFORMATION AND MISCELLANEOUS</p>
<p>11.1 Support</p>
<p>If you require any information or clarification regarding the use of your personal information or</p>
<p>this Privacy Policy or grievances with respect to use of your personal information, please email</p>
<p>our grievance officer at:</p>
<p>Name: [&sdot;]</p>
<p>Contact: [&sdot;]</p>
<p>Email: company@sunshubhloans.com</p>
<p>11.2 Indemnity</p>
<p>You agree to defend, indemnify and hold harmless Sunshubh Loans, its subsidiaries, affiliates,</p>
<p>licensors, employees, agents, third party information providers and independent contractors</p>
<p>against any loss, claims, damages, costs, liabilities and expenses (including, but not limited to,</p>
<p>reasonable attorneys&rsquo; fees) arising out of or related to any content that you store or otherwise</p>
<p>transmit on or through the Platform, your conduct, your use or inability to use the Platform, your</p>
<p>breach or alleged breach of the Privacy Policy and the Terms of Use of Sunshubh Loans or of</p>
<p>any representation or warranty contained herein, your unauthorized use of the Platform and the</p>
<p>User Content, or your violation of any rights of another.</p>
<p>11.3 Conditions of Use</p>
<p>Please note that if you decide to visit our Platform, your visit and any possible dispute over</p>
<p>privacy is subject to the Platform&rsquo; Privacy Policy, and the Terms of Use of Sunshubh Loans.</p>
<p>11.4 Termination</p>
<p>Notwithstanding anything contained herein, Sunshubh Loans reserves the right, without notice</p>
<p>and in its sole discretion, to terminate your account and/or to block your use of the Platform.</p>
<p>11.5 Severance and Waiver</p>
<p>No action of Sunshubh Loans, other than an express written waiver or amendment, may be</p>
<p>construed as a waiver or amendment of any of this Privacy Policy. If any clause in this Privacy</p>
<p>Policy is found to be unenforceable, wherever possible, it will not affect any other clause and</p>
<p>each will remain in full force and effect. this Privacy Policy set out the entire agreement between</p>
<p>Sunshubh Loans and you relating to your use of this Platform and supersedes and replaces any</p>
<p>prior agreements we might have between us regarding the Platform. Any rights not expressly</p>
<p>granted herein are reserved.</p>
<p>11.6 Disclaimers</p>
<p>The Platform and its user content are provided &ldquo;As is&rdquo; and Sunshubh Loans and its directors,</p>
<p>employees, content providers, agents and affiliates exclude, to the fullest extent permitted by</p>
<p>applicable law, any warranty, express or implied, including, without limitation, any implied</p>
<p>warranties of merchantability, satisfactory quality or fitness for a particular purpose. Sunshubh</p>
<p>Loans will not be liable for any damages of any kind arising from the use of the Platform. The</p>
<p>functions embodied on or in the materials of this website are not warranted to be uninterrupted</p>
<p>or without error. Sunshubh Loans makes no warranty that the website or the user content is free</p>
<p>from infection by viruses or anything else that has contaminating or destructive properties.</p>
<p>11.7 Governing Law</p>
<p>This Privacy Policy shall be governed by, and construed in accordance with, the laws of India.</p>
<p>The Parties agree that if the process of the courts is required to be invoked for enforcement of</p>
<p>the clause below, including for seeking of any interim relief prior, during or after the invocation of</p>
<p>the clause below, the competent Courts at Mumbai, India shall have exclusive jurisdiction and</p>
<p>both the parties hereto submit to the same.</p>

  {/*  <Typography variant="body1" gutterBottom>
         This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as
applicable and the provisions pertaining to electronic records in various statutes as amended by the Information
Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or
digital signatures to make the terms of this policy binding. 
<Box m={2}></Box>
This website; Online Lending platform www.sunshubhloans.com (herein after referred to as
Website/Sunshubhloans ) is owned and operated by Sunshubh Solutions ( We or we or Sunshubhloans . We, take
privacy protection very seriously. We value the trust you place in us. Please read this document carefully regarding
our privacy policy.
<Box m={2}></Box>
 This privacy policy (herein after referred to as Private Policy describes how we may use and disclose personally
identifiable information that we may collect about you, through the Website (herein after referred to as Personal
Information We insist upon the highest standards for secure transactions and customer information privacy. Please
read the following statement to learn about our information gathering and dissemination practices. Our privacy
policy is subject to change from time to time without notice. To make sure you are aware of the changes, please
review this policy periodically. 
 <Box m={2}></Box>
Also this privacy statement does not apply to our business partners, affiliates or other third parties. Please review
the privacy statements of the other parties with whom you may interact. By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree
please do not use or access our Website. By mere use of the Website, you expressly consent to our use and disclosure of your personal information in
accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.
      </Typography>
      <Box m={2}></Box>
      <Typography variant="h6" gutterBottom>COLLECTIONS AND USES OF PERSONAL INFORMATION</Typography>
      <Box m={2}></Box>

      <Typography variant="body1" gutterBottom>1.1 When you use or register with the website, we may ask for certain Personal Information. The categories of Personal
Information we may request, include but not limited to, are your name, your job title, your companys name and
industry sector, your companys location (country, state and zip code) and your contact information (email and
phone number). Depending on the nature of your inquiry or activities on the website, we may also obtain
information about your interests and likings of styles themes, mood boards, budget etc. and professional interests
through white papers or newsletters you request, as well as professional interests that you specifically describe to
us.
<Box m={2}></Box>
Providing Personal Information is necessary for performance of the services and functionalities offered by the
website. If you decline to provide the requisite Personal Information, we may not be able to process your Inquiry or
other requests.


</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>COOKIES</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>2.1 The Site may also utilize cookies to track your online activities, including your registration, submissions, and information requests, in accordance with applicable law. Cookies are files placed on your computer by a website
server. Cookies on this website do not collect Personal Information, but we do track your online activities and may
link that information with Personal Information you have provided us through online registration. Your web browser
may be set to accept cookies automatically, but can be changed to decline them. We offer certain features that are
only available through the use of a cookie . Most cookies are session cookies, meaning that they are automatically
deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser
permits, although in that case you may not be able to use certain features on the Website and you may be required
to re-enter your password more frequently during a session.</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>ADVERTISEMENTS/MARKETING</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>3.1 We use Personal Information and other data collected through the website to register you with the website and
to otherwise provide you with requested information or services. We may also from time to time use your Personal
Information to send you automated email messages or marketing materials regarding our services. These email messages may contain web beacons and other features that help us make sure that you received and were able to
open the message. You may opt out of receiving such marketing email messages at any time by clicking on the
Unsubscribe link in the email.</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>DISCLOSURES OF PERSONAL INFORMATION</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>4.1 As described in the Terms of Use, Sunshubhloans may disclose Personal Information to third parties. The
disclosure to third parties would occur in the following circumstances:</Typography>
<Typography variant="body1" gutterBottom>4.1.1 We may disclose information to companies and individuals we employ to perform business functions and
services on our behalf. Such functions may include hosting our Web servers, analyzing data, providing legal,
accounting and marketing services, and providing other support services. All such parties will be under obligation to
maintain the security and confidentiality of the Personal Information, and to process the data in accordance with our
instructions.</Typography>
<Typography variant="body1" gutterBottom>4.1.2 We may disclose Personal Information to our affiliates in your local jurisdiction or other locations as necessary
or appropriate to provide the product or service you requested, to contact you in connection with product or service
offerings, or for other legitimate business purposes. The names and addresses of our affiliates are published along
with our design, products and services on online peer to peer digital marketplace/lending platform
www.sunshubhloans.com portal.</Typography>
<Typography variant="body1" gutterBottom>4.1.3 We may disclose Personal Information to the extent necessary or appropriate to government agencies,
advisors, and other third parties in order to comply with applicable laws, or if such action is necessary to protect the
rights or property of sunshubhloans or its affiliated companies.</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>PERSONAL DATA ACCESS AND CORRECTION</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>5.1 Sunshubhloans will provide access to, update and amend, and/or delete the Personal Information in accordance
with applicable laws. You can request access to, and have the opportunity to update and amend your Personal
Information, and, you can exercise the other rights acknowledged by applicable data protection legislation, including,
for example, objection to and blocking of the processing of your Personal Information, by contacting us at
<br/>
<br/>
company@sunshubhloans.com
<br/>
<br/>
For security reasons, Sunshubhloans reserves the right to take steps to authenticate the identity of the requesting
party before providing access to Personal Information in accordance with this section of the Privacy Policy.</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>PERSONAL DATA SECURITY AND CONFIDENTIALITY:</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>6.1 We maintain adequate technical and organizational security measures to protect Personal Information from loss,
misuse, and unauthorized access, disclosure, alteration, or destruction..</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>VISITORS NOTE</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>7.1 No Sensitive Personal Data or Information is automatically collected by Sunshubhloans from any visitors of the
Websites who are merely perusing or browsing the Website Visitors.
<br/>
<br/>
7.2 Nevertheless, certain provisions of this Privacy Policy are applicable to Visitors, and Visitors are also required to
read and understand the privacy statements set out herein, failing which they are required to leave the Website
immediately.
<br/>
<br/>
7.3 A User will not merely be a Visitor if ;the User has willingly submitted any personal data or information (including
phone numbers, email ids, responses to surveys, etc.) to Sunshubhloans through any means, including email, post,
telephone calls, telephonic messaging or through the registration process on the Website. All such visitors shall be
deemed to be, and will be treated as, End Users for the purposes of this Privacy Policy, and in which case, all the
statements in this privacy policy shall strictly apply to the User.</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>YOUR CONSENT</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>8.1 By using the Website and/ or by providing your information, you consent to the collection and use of the
information you disclose on the Website in accordance with this Privacy Policy, including but not limited to your
consent for sharing your information as per this privacy policy.
<Box m={2}></Box>
8.2 If we decide to change our privacy policy, we will post those changes on this page so that you are always aware
of what information we collect, how we use it, and under what circumstances we disclose it.</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>SECURITY</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>9.1 Sunshubhloans has used all reasonable efforts to prevent unauthorised access to personal information, maintain
data accuracy and ensure appropriate use of any personal information.
<Box m={2}></Box>
9.2 However, you acknowledge that the internet is inherently insecure, and sunshubhloans cannot prevent and is
not responsible for, interception or "hacking' of information transmitted through this Site by unauthorised third
parties.</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>GRIEVANCE OFFICER</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>
10.1 The name and contact details of the Grievance Officer are provided below:<br/><br/>
Name: Sunny Kamlesh Singh.<br/><br/>
E-mail Id: company@sunshubhloans.com<br/><br/>
Address: 401,Royal House,Waliv Naka, Vasai East - 401208<br/><br/>
10.2  Above mentioned grievance officer shall redress the complaint/dispute/grievances within one month from the
date of the receipt of the said complaint
</Typography>
<Box m={2}></Box>
<Typography variant="h6" gutterBottom>BUSINESS/ASSETS SALE OR TRANSFERS</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>
11.1 Sunshubhloans may sell, transfer or otherwise share some or all of its assets, including Your Information in
connection with a merger, acquisition, reorganization or sale of assets or business or in the event of bankruptcy.
Should such a sale or transfer occur, such sunshubhloans entity will reasonably ensure that the Information you have
provided and which we have collected is stored and used by the transferee in a manner that is consistent with this
Privacy Policy. Any third party to which any of sunshubhloans entity transfers or sells as aforesaid will have the right
to continue to use the Information that you provide to us or collected by us immediately prior to such transfer or
sale.
</Typography>

<Box m={2}></Box>
<Typography variant="h6"  gutterBottom >SEVERABILITY</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>
12.1 If at any time during the currency of this Privacy Policy, any provision, condition, term, stipulation, covenant or
undertaking of this instrument is or becomes illegal, void, invalid, prohibited or unenforceable in any respect, the
same shall be ineffective to the extent of such illegality, voidness, invalidity, prohibition or enforceability without
invalidating in any manner whatsoever the remaining provisions hereof or thereof.;
</Typography>


<Box m={2}></Box>
<Typography variant="h6" gutterBottom>INDEMNITY</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>
13.1 You hereby acknowledge indemnifying and holding harmless Sunshubhloans and each of its entities', officers,
directors, contracts or agents and any third party relying on the Information provided by you in the event you are in
breach of this Policy.
<Box m={2}></Box>
13.2 You hereby further acknowledge indemnifying and holding harmless Sunshubhloans and each of its entities',
officers, directors, contracts or agents or any third party in connection with Sunshubhloans for any breach of security
or for any actions of any third party not in relation with Sunshubhloans, that receive User's personal data or events
that are beyond the reasonable control of Sunshubhloans, including acts of the Government, Computer hacking,
Phishing, unauthorized access to Computer data and storage device, computer crashes, breach of security and
encryption, etc.
</Typography>



<Box m={2}></Box>
<Typography variant="h6" gutterBottom>FURTHER ACKNOWLEDGEMENTS</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>
14.1 You hereby acknowledge indemnifying and holding harmless Sunshubhloans and each of its entities', officers,
directors, contracts or agents and any third party relying on the Information provided by you in the event you are in
breach of this Policy.
<Box m={2}></Box>
<ul>
    <li>You hereby acknowledge and agree that this Privacy Policy</li>
    <li>Is clear and easily accessible and provide statements of Sunshubhloans policies and practices with respective to the Information</li>
<li>Provides for the various types of personal or sensitive personal data of information to be collected , Provides for the purposes of collection and usage of the Information</li>
<li>Provides for disclosure of Information; and</li>
<li>Provides for reasonable security practices and procedures.</li>
</ul>

</Typography>




<Box m={2}></Box>
<Typography variant="h6" gutterBottom>CONTACT</Typography>
<Box m={2}></Box>
<Typography variant="body1" gutterBottom>
15.1 If you have any further questions or concern relating to this Privacy Policy, please contact us at:
<br/>
<br/>

Address: 401,Royal House,Waliv Naka, Vasai( East ) , 401208.
<br/>
<br/>
Email: company@sunshubhloans.com

</Typography> */}


             </Container>


        );
    }
}

export default PrivacyPolicy;