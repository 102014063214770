import React, { Component } from 'react';
import {Container,Card, Typography,Table,TableBody,Button,TableCell,TableHead,TableContainer,TableRow,Box,Avatar} from  '@material-ui/core'
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import {withRouter} from 'react-router-dom'
import cookie from 'react-cookies';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as ApiFunctions from '../../util/apifunction'
import UserContext from '../ContextManage/UserContext'



class AllUsers extends Component {
  static contextType =UserContext;


  constructor(props){

    super()
    this.state={
      isloading:true,
      Data:[],
      isError:false,
      PageNumber:1,
      TotalPage:0,
      TotalPost:0,
      PerPage:0,
      ID:null,
      Email:null,
      Role:null,
      Token:null,
      isloggedin:false,
  
    }
  }



async componentDidMount(){

const UserContextData = this.context



await this.setState({
  ID:UserContextData.state.ID,
  Email:UserContextData.state.Email,
  isloggedin:UserContextData.state.loggedin,
  Token:UserContextData.state.Token,
  Role:UserContextData.state.Role
 })

 await this.FetchUsers()
}



  async FetchUsers(){
    const header={
      'Content-Type': 'application/json',
    'x-access-token':this.state.Token
     };
     try{
      const Data = await ApiFunctions.GetApiRequest(`/user/allusers/${this.state.PageNumber}`,header)
   
     
      if(Data){
            this.setState({
              Data:Data.Data,
              isloading:false,
              TotalPost:Data.TotalPost,
              PerPage:Data.PerPage,
              TotalPage:Data.Totalpages
            })
           
         console.log("data",Data)
          }else{
          this.setState({isError:true})   
      }
    }catch(err){
      this.setState({isError:true})
      }
    }



    async Deleteuser(UID){
      const header={
        'Content-Type': 'application/json',
      'x-access-token':this.state.Token
       };
       try{
        const Data = await ApiFunctions.DeleteApiData(`/user/${UID}`,header)

        if(Data){
          alert("sucessfully Deleted the User!")
          window.location.reload()
        }else{
          alert("Can't Delete This User Right Now!")
        }
       
      }catch(err){
        this.setState({isError:true})
        }
    }


    DeleteUser(UID){
      var answer = window.confirm("Are you sure ? you want to Delete this User!");
      if (answer) {
         this.Deleteuser(UID)
      }
      else {
          //some code
      }
    }

 





  async handlechange(event,pagenumber){
    
   await this.setState({PageNumber:pagenumber})
   
     await  this.FetchUsers() 
     
  }





    render() {
        return (

          <Container maxWidth="xl">
            <Box m={4}></Box>
            <Typography variant="h3" color="initial" gutterBottom>All Users</Typography>

      <Card>

     <TableContainer >
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            
            <TableCell align="left">ID</TableCell>
          
            <TableCell align="left">Full Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone No.</TableCell>
            <TableCell align="left">Address</TableCell>

            <TableCell align="left">Role</TableCell>
            <TableCell align="left">Married Status</TableCell>
            <TableCell align="left">Account_Verified</TableCell>
            <TableCell align="left">Created_At</TableCell>
           





          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.Data.map((data) => (
            <TableRow key={data.ID}>
                  <TableCell align="left">{data.ID}</TableCell>
             
          <TableCell component="th" scope="row" align="left">
                {data.Full_Name}
              </TableCell>
              <TableCell align="left">{data.Email}</TableCell>
              <TableCell align="left">{data.PhoneNo}</TableCell>
              <TableCell align="left">{data.Address}</TableCell>
           
              <TableCell align="left">{data.Role}</TableCell>
              <TableCell align="left">{data.Married_Status}</TableCell>
              <TableCell align="left">{data.Account_Verified}</TableCell>
              <TableCell align="left">{new Date(data.Created_At).toString()}</TableCell>
            

            
              <TableCell align="left">


              <IconButton aria-label="delete" onClick={()=>this.DeleteUser(data.ID)} >
          <DeleteIcon />
        </IconButton>
    

    
        <IconButton  aria-label="edit" onClick={()=>this.props.history.push(`/app/edit-user/${data.ID}`)}>
          <EditIcon />
        </IconButton>
    

              </TableCell>


            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


                    </Card>

                    <Box m={4}></Box>
    <Pagination  variant="outlined" color="primary"  count={this.state.TotalPage} page={this.state.PageNumber} onChange={this.handlechange.bind(this)} />
    <Box m={4}></Box>
                    </Container>
        );
    }
}

export default AllUsers;

