import React from 'react';

import {Grid,Container,Typography,Slider,Box,Paper,Divider} from '@material-ui/core'

import {useHistory,withRouter,Link} from 'react-router-dom';



export default function  Calculator(){


  
  const [Amount, SetAmount] = React.useState(5000);
  const [Tenure, SetTenure] = React.useState(3);
  const [Interest, SetInterest] = React.useState(2);


  const handleAmount = (event, newValue) => {

    SetAmount(newValue);
  };


  
  const handleTenure = (event, newValue) => {

    SetTenure(newValue);
  };



  
  const handleInterest = (event, newValue) => {

    SetInterest(newValue);
  };





        return (
            <Container maxWidth="lg">
            <div style={{ display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:50}}>
            <Typography variant="h4" color="primary">Loan EMI calculator</Typography>
        
        <Box m={4}></Box>

         <Grid container spacing={2}>
            
            <Grid items lg={6} md={6}>
            <Paper style={{padding:20,margin:12}}>
                <Box m={4}>
            <Typography variant="h6" color="textSecondary">Loan Amount (INR)  { <span style={{color:"black",float:"right"}}>{Amount}</span>  }</Typography>
            <Box m={4}></Box>
                <Slider value={Amount} 
                 min={3000}
                 max={100000}
                 step={500}
                onChange={handleAmount} aria-labelledby="continuous-slider" />
        
        
                <Box m={4}></Box>
          <Typography variant="h6" color="textSecondary">Loan Tenure (in Months)  { <span style={{color:"black",float:"right"}}>{Tenure}</span>  }</Typography>
          <Box m={4}></Box>
               <Slider value={Tenure} 
                min={1}
                max={12}
               onChange={handleTenure} aria-labelledby="continuous-slider" />


                
               <Box m={4}></Box>
          <Typography variant="h6" color="textSecondary">Rate of Interest ( Monthly )  { <span style={{color:"black",float:"right"}}>{Interest}</span>  }</Typography>
          <Box m={4}></Box>
               <Slider value={Interest} 
                min={1}
                max={5}
                step={1}
               onChange={handleInterest} aria-labelledby="continuous-slider" />

</Box>
</Paper>

            </Grid>

           
            
            <Grid items lg={6} md={6}>

            <Paper style={{padding:20,margin:12}}>

             
       
               <Box m={4}></Box>
          <Typography variant="h6" color="textSecondary">Principal Amount (INR)  { <span style={{color:"black",float:"right"}}>{Math.ceil(parseInt(Amount)/(Tenure))}</span>  }</Typography>
          <Box m={4}></Box>
        <Divider/>



           
        
                <Box m={4}></Box>
          <Typography variant="h6" color="textSecondary">Interest(Monthly)  { <span style={{color:"black",float:"right"}}>{Math.ceil(parseInt(Amount/100)*(Interest))}</span>  }</Typography>
          <Box m={4}></Box>
          <Divider/>
         

          <Box m={4}></Box>
            <Typography variant="h6" color="textSecondary">Monthly EMI (INR) { <span style={{color:"black",float:"right"}}>{ Math.ceil( parseInt(Amount*Interest*Tenure/100+Amount) / Tenure) }</span>  }</Typography>
            <Box m={4}></Box>
              <Divider/>


          <Box m={4}></Box>
          <Typography variant="h6" color="textSecondary">Annual Interest Rate  { <span style={{color:"black",float:"right"}}>{Interest*12}</span>  }</Typography>
          <Box m={4}></Box>
             

<br/>
<br/>
</Paper>
            </Grid>
          


         </Grid>

            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              
            >



        <Grid item xs={12}>
          
          <Paper style={{padding:19}}>
          <Typography variant="h6" color="primary">Total Amount Payable { <span style={{color:"black",float:"right"}}>₹{ Math.ceil( parseInt(Amount*Interest*Tenure/100+Amount)) } </span>  }</Typography>

          </Paper>
  
          
        </Grid>
              
            </Grid>
            <Box m={2}></Box>
                <Typography variant="caption" color="initial">EMI, which stands for Equated Monthly Installments is a fixed amount which you pay against your loan every month to the lender you have borrowed from. Your EMI consists of the principal and interest portion of the loan you have taken. You repay your loan every month for a period of time until it is fully closed. The most common types of loan such as personal loan, home loan, car loan, all are repaid in EMIs.

EMI has three basic parameters associated with it (Amount of loan, rate of Interest and tenure).
We have made this easy, Use loan calculator. Enter your correct loan details to find out your loan EMI and interest amount.
</Typography>
        </div>
           </Container>
        );

}

