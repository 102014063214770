const dayjs = require('dayjs')

export const nextmonths=(date,numMonths,EmiDay)=>{
    const currentDate = date;
    const monthsArray = [];
    
    for(let i = 1; i <= numMonths; i++) {
      const nextMonth = currentDate.add(i, 'month');
      const nextMonthArray = [nextMonth.year(), nextMonth.month() ,EmiDay];
      monthsArray.push(nextMonthArray);
    }
    
    return monthsArray;
}


export const currentmont=()=>{  return dayjs().month()  }