import React, { Component } from 'react';
import {
  Button, Typography, TextField, Box, Grid, Container, FormControl,
  InputLabel, Select, Paper, Avatar, Icon
} from '@material-ui/core'


import UserContext from '../ContextManage/UserContext'


import LoanList from '../AdminComponents/LoanList'

import * as ApiFunctions from '../../util/apifunction'

import Docs from '../CommonComponents/Docs'


import Autocomplete from '@material-ui/lab/Autocomplete';



class Porfile extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      ID: null,
      Email: null,
      Role: null,
      Token: null,
      isloggedin: false,
      Data: {}

    }
  }


  async componentDidMount() {
    const UserContextData = this.context
    await this.setState({
      ID: UserContextData.state.ID,
      Email: UserContextData.state.Email,
      isloggedin: UserContextData.state.loggedin,
      Token: UserContextData.state.Token,
      Role: UserContextData.state.Role
    })


    await this.GetUserProfile()


    let fields = this.state.fields;
    fields["Address"] = this.state.Data.Address
    fields["BankName"] = this.state.Data.BankName
    fields["BankIfsc"] = this.state.Data.BankIfsc
    fields["ANo"] = this.state.Data.ANo
    fields["PNo"] = this.state.Data.PNo
    fields["Mstatus"] = this.state.Data.Married_Status
    fields["BA_Type"] = this.state.Data.BA_Type
    fields["Father_Name"] = this.state.Data.Father_Name
    fields["Bank_Ac_No"] = this.state.Data.Bank_Ac_No
    fields["Alt_No"] = this.state.Data.Alt_No
    fields["Ofc_Name"] = this.state.Data.Ofc_Name
    fields["PinCode"] = this.state.Data.PinCode
    fields["DOB"] = this.state.Data.DOB
    fields["CNAME"] = this.state.Data.CNAME
    fields["RATE"] = this.state.Data.Rate

    //Bank_Ac_No




    await this.setState({ fields })
  }



  //profiledata

  async GetUserProfile() {
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token

    };
    try {
      const Data = await ApiFunctions.GetApiRequest(`/user/customer/profile`, header)


      if (Data.Status === 200) {
        await this.setState({ Data: Data.Data })

      } else {
        this.setState({ isError: true })
      }
    } catch (err) {
      this.setState({ isError: true })
    }
  }




  async updateownprofile() {

    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token
    };


    const data = {
      Bank_Ac_No: this.state.fields["Bank_Ac_No"],
      BankName: this.state.fields["BankName"],
      BankIfsc: this.state.fields["BankIfsc"],
      ANo: this.state.fields["ANo"],
      PNo: this.state.fields["PNo"],
      Address: this.state.fields["Address"],
      mstatus: this.state.fields["Mstatus"],
      BA_Type: this.state.fields["BA_Type"],
      fname: this.state.fields["Father_Name"],
      Ofc_Name: this.state.fields["Ofc_Name"],
      Alt_No: this.state.fields["Alt_No"],
      PinCode: this.state.fields["PinCode"],
      DOB: this.state.fields["DOB"],
      CNAME: this.state.fields["CNAME"]

      //Married_Status

    }


    try {
      const Data = await ApiFunctions.UpdateApiData(`/user/customer/profile`, header, data)

      if (Data.Status === 200) {
        alert("Profile Updated Successfully!")

      }
      if (Data.Status !== 200) {
        alert("Cant Update Porifle Right Now!")
      }

    } catch (err) {
      this.setState({ isError: true })
    }

  }








  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });

  }




  submituserdata(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      this.updateownprofile()
    } else {
      alert("Please Fill The Form")
    }

  }



  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;



    //father name

    if (!fields["Father_Name"]) {
      formIsValid = false;
      errors["Father_Name"] = "Bank_Name Cannot be empty";
    }



    if (typeof fields["Father_Name"] !== "undefined") {
      if (!fields["Father_Name"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)) {
        formIsValid = false;
        errors["Father_Name"] = "Enter a Valid Father Name";
      }
    }




    if (!fields["BankName"]) {
      formIsValid = false;
      errors["BankName"] = "Bank_Name Cannot be empty";
    }



    if (typeof fields["BankName"] !== "undefined") {
      if (!fields["BankName"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)) {
        formIsValid = false;
        errors["BankName"] = "Enter A Valid Bank Name";
      }
    }


    if (!fields["BankIfsc"]) {
      formIsValid = false;
      errors["BankIfsc"] = "Bank_IFSC  Any Loan Can not be empty";

    }



    if (typeof fields["BankIfsc"] !== "undefined") {
      if (!fields["BankIfsc"].match(/^[A-Z]{4}0[A-Z0-9]{6}$/)) {
        formIsValid = false;
        errors["BankIfsc"] = "Enter A Valid IFSC CODE";
      }
    }


    //Bank_Ac_No


    if (!fields["Bank_Ac_No"]) {
      formIsValid = false;
      errors["Bank_Ac_No"] = "Bank Account Number Cant Be Empty";

    }



    if (typeof fields["Bank_Ac_No"] !== "undefined") {
      if (!fields["Bank_Ac_No"].match(new RegExp(/^\d{9,18}$/))) {
        formIsValid = false;
        errors["Bank_Ac_No"] = "Enter A Valid Bank Account Number";
      }
    }



    if (!fields["ANo"]) {
      formIsValid = false;
      errors["ANo"] = "Aadhar Card Number Can Not Be Empty";
    }



    if (typeof fields["ANo"] !== "undefined") {
      if (!fields["ANo"].match(new RegExp(/^\d{4}\s\d{4}\s\d{4}$/))) {
        formIsValid = false;
        errors["ANo"] = "Enter A Valid Aadhar Card Number";
      }
    }









    if (!fields["PNo"]) {
      formIsValid = false;
      errors["PNo"] = " Pan Card Number Can not be empty";
    }




    if (typeof fields["PNo"] !== "undefined") {
      if (!fields["PNo"].match(new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/))) {
        formIsValid = false;
        errors["PNo"] = "Enter A Valid  Pan Card Number";
      }
    }






    if (!fields["Address"]) {
      formIsValid = false;
      errors["Address"] = "Address Can not be empty";
    }

    if (!fields["Mstatus"]) {
      formIsValid = false;
      errors["Mstatus"] = "Married Status Can not be empty";
    }



    if (!fields["PinCode"]) {
      formIsValid = false;
      errors["PinCode"] = "Pincode Can not be empty";
    }

    if (typeof fields["PinCode"] !== "undefined") {
      if (!fields["PinCode"].match(new RegExp(/^[1-9][0-9]{5}$/))) {
        formIsValid = false;
        errors["PinCode"] = "Enter A Valid  PinCode Number";
      }
    }



    this.setState({ errors: errors });
    return formIsValid;
  }





  async VerifyEmail() {

    alert("Email Verification Link Send To Your Email")
    const header = {
      'Content-Type': 'application/json',
      'x-access-token': this.state.Token

    };
    const data = {
      email: this.state.Data.Email
    }
    try {
      const Data = await ApiFunctions.PostApiData(`/user/auth/verification/email-verification`, header, data)


    } catch (err) {
      this.setState({ isError: true })
    }
  }


  render() {

    const { Data } = this.state;



    return (
      <Container component="main" maxWidth="lg" >

        <Box m={2}></Box>
        <Typography variant="h5" color="initial">Your Profile</Typography>
        <Box m={2}></Box>
        <form noValidate style={{ width: '100%', marginTop: 24 }} onSubmit={this.submituserdata.bind(this)}  >
          <Grid container spacing={2}>



            <Grid item xs={12} sm={12} md={5} lg={5}>
              {/* UI FOR THE BASIC DATA */}
              <Paper variant="outlined" style={{ padding: 20 }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <Avatar alt="Remy Sharp" src="/broken-image.jpg">B</Avatar>
                  <Box m={1}></Box>
                  <Typography variant="body1" color="primary">{Data.Full_Name}</Typography>
                  <br />

                  <div style={{ textAlign: "center" }}>
                    <Typography variant="subtitle1" color="initial">{this.state.fields["RATE"]}/5 {<Icon style={{ display: "inline-block", fontSize: 16, color: "#faaf00" }}>star</Icon>}</Typography>

                    <FormControl variant="standard">
                      <InputLabel htmlFor="select_married_status"></InputLabel>
                      <Select
                        native
                        disabled
                        disableUnderline
                        value={this.state.fields["RATE"]}
                        onChange={this.handleChange.bind(this, "RATE")}




                        label="Risk"
                        inputProps={{
                          name: 'Risk',
                          id: 'Risk',
                        }}
                      >

                        <option value={1}>Default</option>
                        <option value={2}>High Risk</option>
                        <option value={3}>Moderate Risk</option>
                        <option value={4}>Moderately Low Risk</option>
                        <option value={5}>Low Risk</option>


                      </Select>
                    </FormControl>

                  </div>

                  <br />
                  <Typography variant="caption" color="initial">{Data.ID}</Typography>



                </div>
                <Box m={2}></Box>
                <Typography variant="subtitle1" color="textSecondary">Gender :-   {<span style={{ color: "black" }}>{Data.Gender}</span>}</Typography>
                <Box m={2}></Box>
                <Typography variant="subtitle1" color="textSecondary">Email :-   {<span style={{ color: "black" }}>{Data.Email}</span>}</Typography>
                <Box m={2}></Box>
                <Typography variant="subtitle1" color="textSecondary">Phone No. :-   {<span style={{ color: "black" }}>{Data.PhoneNo}</span>}</Typography>
                <Box m={2}></Box>





                <FormControl variant="outlined">
                  <InputLabel htmlFor="select_married_status">Married Status</InputLabel>
                  <Select
                    native
                    value={this.state.fields["Mstatus"]}
                    onChange={this.handleChange.bind(this, "Mstatus")}




                    label="Married Status"
                    inputProps={{
                      name: 'Married Status',
                      id: 'select_married_status',
                    }}
                  >

                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Widow">Widow</option>
                    <option value="Widower">Widower</option>
                    <option value="Divorcee">Divorcee</option>

                  </Select>
                </FormControl>
                <br />

                <br />
                <TextField label="Father Name" fullWidth margin="normal" variant="outlined" ref="Father_Name"
                  value={this.state.fields["Father_Name"] || ''}
                  onChange={this.handleChange.bind(this, "Father_Name")}
                />

                <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Father_Name"]}</Typography>



                <FormControl style={{ width: "100%" }} variant="filled">

                  <TextField
                    id="date"
                    label="Birthday"
                    type="date"
                    variant="outlined"
                    defaultValue="2017-05-24"
                    value={this.state.fields["DOB"] || ''}
                    onChange={this.handleChange.bind(this, "DOB")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </FormControl>
                <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["DOB"]}</Typography>



                <Box m={2} />
                <TextField label="Company Name" fullWidth margin="normal" variant="outlined" ref="CNAME"
                  value={this.state.fields["CNAME"] || ''}
                  onChange={this.handleChange.bind(this, "CNAME")}
                />

                <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["CNAME"]}</Typography>


                <Box m={2} />


                <TextField label="Alternate Number" fullWidth margin="normal" variant="outlined" ref="Alt_No"
                  value={this.state.fields["Alt_No"] || ''}
                  onChange={this.handleChange.bind(this, "Alt_No")}
                />

                <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Alt_No"]}</Typography>



                <Grid container spacing={2}>

                  {/* for the additional user data */}
                  <Grid item xs={12} lg={8} xl={8} md={8}>
                    <TextField label="Address" margin="normal" variant="outlined" multiline rows={4} fullWidth
                      value={this.state.fields["Address"] || ''}
                      onChange={this.handleChange.bind(this, "Address")}
                    />
                    <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Address"]}</Typography>


                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <TextField label="Pin Code" margin="normal" variant="outlined"
                      value={this.state.fields["PinCode"] || ''}
                      onChange={this.handleChange.bind(this, "PinCode")}






                    />
                    <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["PinCode"]}</Typography>


                  </Grid>


                </Grid>

              </Paper>


              <Button variant="contained" color="default" onClick={() => this.props.history.push('/app/payment')}>
                Make Payment
              </Button>


            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Paper variant="outlined" style={{ padding: 20 }}>


                <Box m={1}></Box>

                <Typography variant="h6" color="primary">Banking Details</Typography>

                <TextField label="Name As Per Bank Account" margin="normal" fullWidth variant="outlined" ref="Ofc_Name" style={{ textTransform: "uppercase" }}
                  value={this.state.fields["Ofc_Name"] || ''}

                  onChange={this.handleChange.bind(this, "Ofc_Name")}
                />

                <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Ofc_Name"]}</Typography>



                <TextField label="Bank Account Number" margin="normal" fullWidth variant="outlined" type="number"
                  value={this.state.fields["Bank_Ac_No"] || ''}
                  onChange={this.handleChange.bind(this, "Bank_Ac_No")}

                />
                <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["Bank_Ac_No"]}</Typography>


                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    {/* Bank Name */}
                    <Autocomplete
                      value={this.state.fields["BankName"] || ''}

                      onInputChange={(event, newInputValue) => {
                        const fields = this.state.fields;
                        fields["BankName"] = newInputValue;
                        this.setState({ fields })


                      }}
                      id="free-solo-demo"
                      freeSolo
                      options={top100Films.map((option) => option.title)}
                      renderInput={(params) => (
                        <TextField {...params} label="Bank Name" margin="normal" variant="outlined" />
                      )}
                    />
                    <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["BankName"]}</Typography>

                  </Grid>


                  {/* Bank Name Ending */}


                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

                    {/* Bank IFSC starting */}

                    <TextField label="Bank IFSC Code" margin="normal" variant="outlined"
                      value={this.state.fields["BankIfsc"] || ''}
                      onChange={this.handleChange.bind(this, "BankIfsc")}
                    />
                    <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["BankIfsc"]}</Typography>



                    {/* BANK IFSC ENDING */}

                  </Grid>



                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

                    <FormControl variant="outlined" style={{ marginTop: 15 }}>

                      <InputLabel htmlFor="select_married_status">Account Type</InputLabel>
                      <Select
                        native
                        value={this.state.fields["BA_Type"]}
                        onChange={this.handleChange.bind(this, "BA_Type")}




                        label="Account Type"
                        inputProps={{
                          name: 'Account Type',
                          id: 'select_account_type',
                        }}
                      >

                        <option value="SAVINGS">SAVING</option>
                        <option value="CURRENT">CURRENT</option>

                      </Select>
                    </FormControl>


                  </Grid>


                </Grid>

                <Typography variant="h6" color="primary">Identification</Typography>

                <Grid container spacing={2}>


                  <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                    {/* Adhar Crad starting */}




                    <label>Give Space After 4 digit For Aadhar Card</label>
                    <TextField label="Aadhar Card Number" margin="normal" variant="outlined"
                      value={this.state.fields["ANo"] || ''} style={{ width: "100%" }}
                      onChange={this.handleChange.bind(this, "ANo")}
                    />
                    <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["ANo"]}</Typography>


                    {/* Adhar Crad Ending */}

                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                    {/* Pan card Starting */}
                    <br />

                    <TextField label="Pan Card Number" margin="normal" variant="outlined"
                      value={this.state.fields["PNo"] || ''} style={{ width: "100%" }}
                      onChange={this.handleChange.bind(this, "PNo")}
                    />
                    <Typography variant="body2" align="left" color="error" style={{ fontWeight: 700, marginBottom: '20px' }}>{this.state.errors["PNo"]}</Typography>


                    {/* Pan Card Ending */}
                  </Grid>

                </Grid>

                <Box m={2}></Box>
                <Typography variant="subtitle1" color="textSecondary">Created At :-   {<span style={{ color: "black" }}>{new Date(Data.Created_At).toString()}</span>}</Typography>
                <Box m={2}></Box>
                <Typography variant="subtitle1" color="textSecondary">Account Verified:-   {<span style={{ color: "black" }}>{Data.Account_Verified}</span>}</Typography>
                <Box m={2}></Box>
                <Typography variant="subtitle1" color="textSecondary">Email Verified:-   {


                  (Data.EmailVerified === 0) ? (

                    <Button variant="contained" color="default" onClick={() => this.VerifyEmail()}>Verify Email</Button>

                  ) : (
                    <span color="primary">Email is Verified</span>
                  )




                }</Typography>


                <Box m={2}></Box>
                <Button
                  align="center"
                  type="submit"

                  variant="contained" color="primary" value="submit" id="submit"

                >
                  Update
                </Button>
              </Paper>

            </Grid>

          </Grid>

        </form>
        <Box m={2}></Box>

        {(this.state.Data.loans) ? (
          <LoanList loans={this.state.Data.loans} />

        ) : (
          <span>loading....</span>
        )}








        <Box m={2}></Box>


        {(this.state.Data.documents) ? (
          <Docs docs={this.state.Data.documents} />

        ) : (
          <span>loading  Documents....</span>
        )}




      </Container>
    );
  }
}

export default Porfile;




const top100Films = [
  { title: 'Allahabad Bank', year: 1994 },
  { title: 'Andhra Bank', year: 1972 },
  { title: 'Bank of Baroda', year: 1974 },
  { title: 'Bank of India', year: 2008 },
  { title: 'Bank of Maharashtra', year: 1957 },
  { title: "Canara Bank", year: 1993 },
  { title: 'Central Bank of India', year: 1994 },
  { title: 'Corporation Bank', year: 2003 },
  { title: 'Dena Bank', year: 1966 },
  { title: 'ICICI Bank', year: 1950 },
  { title: 'Indian Bank', year: 1999 },

  { title: 'Indian Overseas Bank', year: 2001 },
  { title: 'Oriental Bank of Commerce', year: 1980 },
  { title: 'Punjab & Sind Bank', year: 1994 },
  { title: 'Punjab National Bank', year: 2010 },
  { title: 'Syndicate Bank', year: 2002 },
  { title: "UCO Bank", year: 1975 },
  { title: 'Union Bank of India', year: 1990 },
  { title: 'United Bank Of India (UBI)', year: 1999 },
  { title: 'Vijaya Bank', year: 1954 },
  { title: 'IDBI Bank Limited', year: 1977 },
  { title: 'State Bank of India', year: 2002 },
  { title: 'State Bank Of Bikaner & Jaipur', year: 1995 },
  { title: 'State Bank of Hyderabad', year: 1991 },
  { title: "State Bank of Mysore", year: 1946 },
  { title: 'State Bank of Patiala', year: 1997 },
  { title: 'State Bank of Travancore', year: 1995 },
  { title: 'City Union Bank', year: 1994 },
  { title: 'Dhanalakshmi Bank', year: 2001 },
  { title: 'Federal Bank', year: 1998 },
  { title: 'ING Vysya Bank Ltd', year: 1968 },
  { title: 'Jammu & Kashmir Bank', year: 1998 },
  { title: 'Karnataka Bank', year: 2014 },
  { title: 'Karur Vysya Bank', year: 1942 },
  { title: 'Lakshmi Vilas Bank', year: 1931 },
  { title: 'Nanital Bank', year: 1960 },
  { title: 'The Ratnakar Bank', year: 1999 },
  { title: 'SBI Commercial & International Bank', year: 2011 },
  { title: 'South India Bank', year: 1936 },
  { title: 'Tamilnadu Mercantile Bank', year: 1981 },
  { title: 'United Western Bank', year: 1954 },
  { title: 'IndusInd Bank', year: 2002 },
  { title: 'HDFC Bank', year: 2006 },
  { title: 'ICICI Bank', year: 1991 },
  { title: 'Axis Bank', year: 1985 },
  { title: 'Kotak Mahindra Bank', year: 2014 },
  { title: 'Yes Bank', year: 2000 },
  { title: 'Development Credit Bank (DCB Bank)', year: 2000 },
  { title: 'Citi Bank', year: 2006 },
  { title: 'HSBC', year: 1994 },
  { title: 'Abu Dhabi Commercial Bank', year: 1979 },
  { title: 'Amercian Express Bank', year: 1979 },
  { title: 'Standard Chartered Bank', year: 1950 }


];


const options = top100Films.map((option) => {
  const firstLetter = option.title[0].toUpperCase();
  return {
    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
    ...option,
  };
});